import { useEffect, useState } from "react";
import "./manageuser.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { employementColumns } from "../../components/datatable-coulms/tableCoulmns";
import { getUserType } from "../../redux/features/userTypeSlice";
import dataService from "../../redux/services/data.service";
import { TablePagination, Tooltip } from "@mui/material";
import { ToastContainer, toast } from 'react-toastify';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import { STATUSES } from "../../redux/common-status/constant";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";

const AllManageUser = () => {
  const dispatch = useDispatch();

  const { data, status, error } = useSelector(state => state.userType)
  const { data: list } = data

  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(`{"skip":0,"limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.EMPOLYEE_TYPE_LIST_COLUMNS}]}`)
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  //columns visibility
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [reload, setReload] = useState(false);
  const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])
  useEffect(() => {
    dispatch(getUserType(filterQuery))
    if (status === 'error') { console.log("errror") }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDelete = async (id) => {
    const request = { status: 3 }
    setOpen(true)
    if (open === true) {
      const response = await dataService.deleteUserType(id, request)
      if (response && response.data) {
        toast.success(response.data.message);
        setOpen(false)
        setTimeout(() => {
          dispatch(getUserType(filterQuery))
        }, 800);
      }
    }
  }

  const handleClickOpen = (type, id) => {
    setDeleteId(id)
    if (type === true) {
      handleDelete(id);
    }
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent moduleName="users" rowid={params.row.id} editLinkUrl={`/edit-user/${params.row.id}`} viewLinkUrl="" isDelete={true} editButton={false} viewButton={false} deleteButton={handleClickOpen} />
          </div>
        );
      },
    },
  ];

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    //setLimit(limit)
    dispatch(getUserType(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    dispatch(getUserType(JSON.stringify(newFilterData)))
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // filter
  const onFilterChange = (filterModel, r) => {

    if(filterModel.items.length)
    {
        setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
    }

    if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
    {
        setReload(true);
        setTimeout(()=>
        {
            setReload(false)
            setCurrentPage(0)
            // setPageSize(25)
            setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.EMPOLYEE_TYPE_LIST_COLUMNS}]}`)
            dispatch(getUserType(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.EMPOLYEE_TYPE_LIST_COLUMNS}]}`))
        },0);
    }

    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "equals" ? 'ieq' :
          filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
              filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.EMPOLYEE_TYPE_LIST_COLUMNS}]}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(getUserType(filterData))
    }
    // if (filterModel.items && filterModel.items.length === 0) {
    //   setCurrentPage(0)
    //   // setPageSize(25)
    //   setFilterQuery(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.EMPOLYEE_TYPE_LIST_COLUMNS}]}`)
    //   dispatch(getUserType(`{"skip":0,"limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.EMPOLYEE_TYPE_LIST_COLUMNS}]}`))
    // }
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(getUserType(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } ,"columns":[${DefaultFilterColumns.EMPOLYEE_TYPE_LIST_COLUMNS}]}`)
    }
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div className="datatable">
          <div className="top-headings">
            <h3>All Employment Type</h3>
            <div>
              <Tooltip title="Add New Employment Type ">
                <Link to="/add-new-user" className="btn-add-new ">
                  <AddIcon />
                </Link>
              </Tooltip>
              <RefreshButton api={getUserType} filter={filterQuery} style={{ marginTop: 0 }} />
            </div>
          </div>

          <ToastContainer />
          <div className="custom-pagination-class">
            <CustomPagination />
          </div>
          {(status === STATUSES.LOADING) && <LoaderForm />}
          {
            !reload && 
            <DataGrid
              className="datagrid"
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) =>
                setColumnVisibilityModel(newModel)
              }
              rows={list && list.rows ? list.rows : []}
              columns={employementColumns.concat(actionColumn)}
              pageSize={pageSize}
              style={{ fontSize: 16 }}
              onFilterModelChange={onFilterChange}
              components={{
                Pagination: CustomPagination,
                NoRowsOverlay: CustomNoRowsOverlay
              }}
              onSortModelChange={(sort) => handleSort(sort)}
              hideFooterPagination={true}
            />
          }
        </div>

        {/* for delete popup */}
        <DeleteDialogModal
          open={open}
          onClose={handleClose}
          heading={'Delete ?'}
          paragraph={'Are You Sure To Remove This Employment Type'}
          handleArchive={handleClickOpen}
          id={deleteId}
          moduleName={'AllManageUser'}
        />
      </div>

    </div>
  )
}

export default AllManageUser