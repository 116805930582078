import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { stateCreate, stateEdit, stateUpdate, getStateList, getCountryList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';
import { getGridStringOperators } from "@mui/x-data-grid";

const initialFormData = Object.freeze({
    name: "",
    code: "",
    country: "",
    status: "",

});

const formErrData = Object.freeze({
    codeErr: "State code is required",
    nameErr: "State name is required",
    countryErr: "Country name is required",
    statusErr: "Status is required"
});


const State = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, state_List, state_Create, state_Edit, state_Update, country_List } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.STATE_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    useEffect(() => {
        dispatch(getStateList(filterQuery))
        dispatch(getCountryList(`{ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": True }`))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [])

    if (reload === true && status === `${STATUSES.SUCCESS}_state_Update` && state_Update && state_Update.message) {
        if (state_Update.status === 200) {
            toast.success(state_Update.message)
            dispatch(getStateList(filterQuery))
        } else {
            toast.error(state_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_state_Create` && state_Create && state_Create.message) {
        if (state_Create.status === 200) {
            toast.success(state_Create.message)
            dispatch(getStateList(filterQuery))
        } else {
            toast.error(state_Create.message)
        }
        setReload(false)
    }

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.code === "" || formValues.name === "" || formValues.country === "" || formValues.status === "") {
            setErrStatus(true)
        }

        if (formValues.code !== "" && formValues.name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user?.user?.data.id
            let addvalues = { ...formValues, created_by: userId, country: formValues.country.value ? formValues.country.value : formValues.country }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(stateUpdate({ ID, addvalues }))
            } else {
                dispatch(stateCreate(addvalues))
            }
        }
    }

    const selectValueInLable = (id) => {
        let data = country_List?.data?.rows;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.name })

        })
        return newObject
    }


    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && state_Edit && state_Edit.data) {
        let editData = state_Edit.data
        setFormValues({
            name: editData.name,
            code: editData.code,
            country: selectValueInLable(editData.country_id),
            status: editData.status
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }


    const handleClickEdit = (id) => {
        dispatch(stateEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            code: data.code,
            country: data.country,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        setReload(true)
        dispatch(stateUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getStateList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getStateList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(state_List && state_List.data && state_List.data.rows && state_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getStateList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.STATE_LIST_COLUMNS}] }`)
        }
    }

    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
    const actionColumn = [
        {
            field: "name",
            headerName: "State Name",
            filterOperators,
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.name}
                    </div>
                );
            },
        },
        {
            field: "code",
            headerName: "State Code",
            width: 280,
            filterOperators,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.code}
                    </div>
                );
            },
        },
        {
            field: "country__name",
            headerName: "Country Name",
            width: 280,
            filterOperators,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.country__name}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            filterOperators,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="state"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                //setPageSize(10)
                setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.STATE_LIST_COLUMNS}]}`)
                dispatch(getStateList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.STATE_LIST_COLUMNS}]}`))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.STATE_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getStateList(filterData))
        }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>State List</h3>
                        <div>
                            <Tooltip title="Add State">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getStateList} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {
                        !reload && 
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={state_List?.data?.rows ? state_List.data.rows : []}
                            columns={actionColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                        />
                    }
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px",color:"white" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} State</DialogTitle>

                    <DialogContent style={{ height: "450px" }}>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"State Name"}
                                    name="name"
                                    defaultValue={formValues.name}
                                    type="text"
                                    placeholder="State Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.nameErr}
                                />
                                <InputFields
                                    label={"State Code"}
                                    name="code"
                                    defaultValue={formValues.code}
                                    type="text"
                                    placeholder="State-Code"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.codeErr}
                                />
                                <div className="formInput">
                                    <label >
                                        Select Country
                                    </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        divstyle={{ flexBasis: '100%' }}
                                        placeholder={'Select Country'}
                                        onChange={handleChange}
                                        defaultValue={formValues.country}
                                        isSearchable={true}
                                        name={'name'}
                                        options={
                                             country_List?.data?.rows.map((option) => {
                                                return {
                                                    label: option.name,
                                                    value: option.id,
                                                    name: 'country'
                                                }
                                            })
                                        }
                                    />
                                    {errStatus === true && formValues && formValues.country === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.countryErr}</p>}
                                </div>
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} state`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default State