import React, { useState, useEffect, useMemo } from 'react'
import "./manageProject.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import api from '../../redux/APIS/api';
import DynamicTable from '../../components/dynamic-table/DynamicTable';
import moment from 'moment';
import dataService from '../../redux/services/data.service';
import { getGridSingleSelectOperators } from '@mui/x-data-grid';
import Filter from '../../components/Filter';
import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Refresh, ExpandMore,ExpandLess} from '@mui/icons-material';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import { useDispatch, useSelector } from 'react-redux';
import { defaultMyallprojectColumn, projectByIdWithResources } from '../../redux/features/projectSlice';
import { getGridStringOperators } from '@mui/x-data-grid';

const { REACT_APP_ENV } = process.env;
const default_my_Project_Columns = LocalStorageDataService.get_default_my_project_column()

const MyProjects = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;
  const currentUserRole = rtm_user.user.data && rtm_user.user.data.tms_role && rtm_user.user.data.tms_role.role_key;
  const [projectRows, setProjectRows] = useState([])
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(25)
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})

  const [resourcesOptions, setResourcesOptions] = useState([])
  // custom filter
  const [setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})
  const [resourcesLists, setResourcesLists] = useState([])
  const [expandedID, setExpandedID] = useState()

  const { defaultMyAllProjectColumns } = useSelector(state => state.projects)

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_my_Project_Columns && default_my_Project_Columns.all_project_column ? default_my_Project_Columns.all_project_column : {
    id: false,    
});

const setKeyForList = (obj) => {
  let data;
  if (default_my_Project_Columns) {
      data = { 'all_project_column': obj, 'reported_projectlist_column': default_my_Project_Columns.reported_projectlist_column, 'all_projectlist_column': default_my_Project_Columns.all_projectlist_column, 'task_column': default_my_Project_Columns.task_column }
  } else {
      data = { 'all_project_column': obj }
  }
  if (defaultMyAllProjectColumns.reported_projectlist_column) {
      data['reported_projectlist_column'] = defaultMyAllProjectColumns.reported_projectlist_column
  }
  if (defaultMyAllProjectColumns.all_projectlist_column) {
      data['all_projectlist_column'] = defaultMyAllProjectColumns.all_projectlist_column
  }
  if (defaultMyAllProjectColumns.task_column) {
      data['task_column'] = defaultMyAllProjectColumns.task_column
  }
  dispatch(defaultMyallprojectColumn(data))
}
const filterOperators = getGridStringOperators().filter(({ value }) =>
['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
  const projectsColumns =
    [
      {
        field: "id",
        headerName: "ID",
        width: 70,
        filterable: false,
      },
      {
        field: "project__project_name",
        headerName: "Project",
        filterOperators,
        // hideable: false,
        // filterable:false,
        // sortable:false,

        width: 200,
        renderCell: (params) => {
          return (
            <div className="cellWithImg">
              {params.row.project__project_name}
            </div>
          );
        },
      },
      {
        field: "project__project_key",
        headerName: "Project Key",
        filterOperators,
        width: 150,
        // sortable:false,
      },

      {
        field: "project__is_jira_project_text",
        headerName: "Is Jira Project",
        filterable: false,
        filterOperators,
        width: 170,
        sortable:false,
        renderCell: (params) => {
          return (
            <div className="cellWithImg">
              {params.row.project__is_jira_project_text}{params.row.project__is_jira_project_text==="Yes" && <p>-Non Editable</p>}
            </div>
          );
        },
      },
      // 
      {
        field: "project__resource",
        headerName: "Assignee",
        width: 340,
        sortable:false,
        filterable: false,
        type: 'singleSelect',
        filterOperator: [],
        valueOptions: resourcesOptions,
        renderCell: (params) => {

          return (
            <div style={{ width: '100%' }} >
              <div className="cellWithImg" style={{ width: '90%', display: 'inline-block' }}>
                {params.row.projectsResourcesNames && params.row.projectsResourcesNames.length ?
                  params.row.projectsResourcesNames.map((resource, index) => {
                    return (
                      <p key={index} style={{ margin: 0 }}>
                        {index === 0 && resource} {index === 0 && params.row.projectsResourcesNames.length > 1 && ","}
                        {params.row.projectsResourcesNames.length > 1 && index === 1 && resource} {index === 1 && params.row.projectsResourcesNames.length > 2 && ","}
                        {expandedID === params.id && index > 1 ? `${resource} ${index === params.row.projectsResourcesNames.length - 1 ? '' : ','}` : null}
                      </p>
                    )
                  })
                  : "N/A"
                }
              </div>
              <div style={{ width: '10%', display: 'inline-block' }}>
                {(params.row.projectsResourcesNames && params.row.projectsResourcesNames.length > 2) ?
                  expandedID === params.id ?
                    <Tooltip title="View less">
                      <ExpandLess onClick={() => handleExpansion(params.id)} />
                    </Tooltip> :
                    <Tooltip title="View more">
                      <ExpandMore onClick={() => handleExpansion(params.id)} />
                    </Tooltip>
                  : ""}
              </div>
            </div>
          );
        },
      },
      {
        field: "resource__display_name",
        headerName: "Created By",
        filterable: false,
        // sortable:false,
        width: 150,
        filterOperators,
        renderCell: (params) => {
          return (
            <div className="cellWithImg">
              {params.row?.resource?.display_name ? params.row?.resource?.display_name : "N/A"}
            </div>
          );
        },
      },

      {
        field: "project__created_on",
        headerName: "Created On",
        filterable: false,
        // sortable:false,
        filterOperators,
        width: 120,
        renderCell: (params) => {
          return (
            <div className="cellWithImg">
              {params.row.project__created_on ? moment(params.row.project__created_on).format('DD-MM-YYYY') : "N/A"}
            </div>
          );
        },
      },
      {
        field: "project__updated_on",
        headerName: "Updated On",
        filterable: false,filterOperators,
        // sortable:false,
        width: 120,
        renderCell: (params) => {
          return (
            <div className="cellWithImg">
              {params.row.project__updated_on ? moment(params.row.project__updated_on).format('DD-MM-YYYY') : "N/A"}
            </div>
          );
        },
      },
      {
        field: "project__status",
        headerName: "Status",
        filterable: false,
        width: 90,
        renderCell: (params) => {
          return (
            <div className={`cellWithStatus ${params.row.status}`}>
              {params.row.status === 3 ? "Archive" : params.row.project__status_value }
            </div>
          );
        },
      },
    ];
  const handleExpansion = (e_id) => {
    if (e_id === expandedID)
      return setExpandedID(undefined);
    setExpandedID(e_id);
  }

  // editdata
  const handleEdit = (data) => {
    dispatch(projectByIdWithResources(data.id)).then((res)=>
    {
      navigate("/edit-project", { state: res.payload.data })
    })
  }


  // Load All Projects
  const loadProjects = async () => {
    setLoading(true);
    // let my_project_list_column = DefaultFilterColumns.MY_PROJECT_LIST_COLUMNS;

    const resourcesList = resourcesLists && resourcesLists.length > 0 ? resourcesLists : await loadProjectResources();
    const data = { ...params, filter: { ...params.filter, "status": { "value": "True", "operation": "eq" }, "resource": { "value": userId, "operation": "eq" } }, "skip": skip, "limit": limit }
    // const data = { "filter": { "status": { "value": "True", "operation": "eq" },"resource": { "value": userId, "operation": "eq" } },  "skip": skip, "limit": limit, "columns": my_project_list_column };
    if (resourcesList) {
      const res = await api.get(`/resources/resource-project?where=${JSON.stringify(data)}`)
      if (res && res.data && res.data.data) {
        let resData = res.data.data.rows
        setProjectRows(resData.map((row) => (
          {
            ...row.project,
            project__project_name: row.project.project_name,
            project__project_key: row.project.project_key,
            project__created_on: row.project.created_on,
            project__updated_on: row.project.updated_on,
            // id: row.project.id,
            // status: row.project.status,
            // project__is_jira_project: row.project.is_jira_project,
            // is_private: row.project.is_private,
            // description: row.project.description,
            resource: row?.resource,
            project_type_name: row.project.project_type.project_type_name,
            project__is_jira_project_text: row.project.is_jira_project ? "Yes" : "No",
            project__status_value: row.project.status ? "Active" : "Inactive",
            projectsResources: resourcesList.filter((element) => element.project__id === row.project.id),
            projectsResourcesNames: resourcesList.map((element) => { return element.project__id === row.project.id && element.resource__name }).filter(e => e !== false),
          }
        )))
        setRowCount(res.data.data.count)
      }
    }
    setLoading(false);

  }

  // Load Assigned Resources to Projects

  const loadProjectResources = async () => {
    //  Load all Project Resources
    let my_project_list_column = DefaultFilterColumns.MY_PROJECT_LIST_COLUMNS;
    const qParam = { "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True", "columns": my_project_list_column };
    const res = await api.get(`/resources/resource-project?where=${JSON.stringify(qParam)}`)
    if (res && res.data && res.data.data && res.data.data.rows) {
      const resourceArray = res.data.data.rows
      setResourcesLists(resourceArray)
      return resourceArray
    }
    return [];

  }

  //  LoadResourceList
  const getResources = async () => {
    const res = await dataService.resources_dropDown()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { value: e.id, label: e.name + "(" + e.email + ")" } })
      setResourcesOptions(tempArray)
    }
  }

  // View Project
  const viewProject = (data) => {
    navigate('/view-project', { state: { id: data.id } })
  }


  useEffect(() => {
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, pageSize, params])

  useEffect(() => {
    // eslint-disable-next-line
    getResources();
  }, [])


  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={resourcesOptions} onChange={handleFilterChange} defaultValue={defaultValue} />
    );
  }
  const columns = useMemo(() =>
    projectsColumns.map((col) => {
      if (col.field === "resource") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
    }),
    // eslint-disable-next-line
    [projectsColumns]
  );

  const getRowHeight = (params) => {
    if (params.id === expandedID)
      return 20 * params.model.projectsResourcesNames.length + 20;
    return 52;
  }

  const dynamicTableProps = {}
  if (currentUserRole === "super_admin" || currentUserRole === "admin" || currentUserRole === "hr" ) {
    dynamicTableProps.onEdit = handleEdit
  }

  return (
    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3>My Projects</h3>
          <div>
            {/* <RefreshButton api={getMYTaskLists} filter={filterQuery} /> */}
            <button onClick={() => loadProjects()} className="btn-add-new ">
              <Tooltip title="Reload">
                <Refresh />
              </Tooltip>
            </button>
            <Tooltip title="Back">
              <Link to={-1} className="btn btn-add-new"><ArrowBackIcon /></Link>
            </Tooltip>

          </div>

        </div>
        {/* <ToastContainer /> */}
        {
          <>
            {
              <DynamicTable
                tableColumns={columns}
                tableRows={projectRows || []}
                paginationMode={"server"}
                rowCount={rowCount}
                loading={loading}
                currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                onViewDetail={viewProject}
                // columnVisibilityModel={{
                //   id: false,
                //   status: false,
                //   is_jira_project: false,
                // }}
                columnVisibilityModel={defaultMyAllProjectColumns && defaultMyAllProjectColumns.all_project_column ? defaultMyAllProjectColumns.all_project_column : columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                }
                setLimit={setLimit}
                limit={limit}
                setFieldName={setFieldName}
                setDefaultValue={setDefaultValue}
                getRowHeight={getRowHeight}
                {...dynamicTableProps}
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default MyProjects
