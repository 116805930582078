import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TablePagination, Tooltip } from '@mui/material'
import "./master.scss"
import { DataGrid, getGridSingleSelectOperators } from '@mui/x-data-grid'
import React, { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify'
import LoaderForm from '../../components/common-function/LoaderForm'
import Navbar from '../../components/navbar/Navbar'
import Sidebar from '../../components/sidebar/Sidebar'
import TablePaginationActions from '../../components/TablePaginationActions'
import { STATUSES } from "../../redux/common-status/constant";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import UcActionComponent from '../../components/common-function/UcActionComponent'
import { Close, Add } from '@mui/icons-material'
import dataService from '../../redux/services/data.service'
import { allcredentialHeads, getThirdPartyCredentialHead } from '../../redux/features/cedentialHeadSlice'
import Filter from '../../components/Filter'
import { getThirdPartyDomainList } from '../../redux/features/thirdPartySlice'
import Select from 'react-select';
import CustomNoRowsOverlay from '../../components/CustomEmptyOverlay'
import { RefreshButton } from '../../components/common-function/RefreshButton'
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns'
import GetLocalstorage from '../../components/common-function/GetLocalstorage'
import { getGridStringOperators } from '@mui/x-data-grid'

const ThirdPartyCredentialHead = () => {

    const { REACT_APP_ENV } = process.env;
    const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    let userId = rtm_user?.user?.data && rtm_user.user.data.id

    const navigate = useNavigate()
    const dispatch = useDispatch();
    const { status, error, thirdPartyCredentialsList, credentialHeadList } = useSelector(state => state.credentialHead)
    const { thirdParty_Domain } = useSelector(state => state.thirdParty)

    const [reload, setReload] = useState(false)

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"skip":0, "limit":25,"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.THIRD_PARTY_CREDENTIAL_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);

    const [filterThirdPartyList, setFilterThirdPartyList] = useState([]);
    const [filterHeadList, setFilterHeadList] = useState([]);

    // custom filter
    const [fieldName, setFieldName] = useState("")
    const [options, setOptions] = useState({})
    const [defaultValue, setDefaultValue] = useState({})

    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [openSingle, setOpenSingle] = useState(false)
    const [formData, setFormData] = useState({
        credential_head: "",
        third_party: "",
        status: "",
    })
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    if (status === `${STATUSES.SUCCESS}_credentialHeadList` && filterHeadList && filterHeadList.length === 0 && credentialHeadList && credentialHeadList.data) {
        setReload(false)
        let array = []
         credentialHeadList?.data?.rows.map(e => { return array.push({ value: e.id, label: e.head_name, name: "credential_head" }) })
        array.length > 0 && setFilterHeadList(array)
        array.length > 0 && setOptions({ ...options, credential_head_id: array })
    }

    if (filterThirdPartyList.length === 0 && thirdParty_Domain && thirdParty_Domain.data) {
        setReload(false)
        let array = []
         thirdParty_Domain?.data?.rows.map(e => { return array.push({ value: e.id, label: e.name, name: "third_party" }) })
        array.length > 0 && setFilterThirdPartyList(array)
        array.length > 0 && setOptions({ ...options, third_party_id: array })
    }

    useEffect(() => {
        dispatch(getThirdPartyCredentialHead(filterQuery))
        dispatch(allcredentialHeads())
        dispatch(getThirdPartyDomainList())

        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
       
    }, [])

    //  Open Modal
    const onOpenModal = () => {
        setOpenSingle(true)
    }

    //  Close Modal
    const onCloseModal = () => {
        setOpenSingle(false)
        setFormData({
            credential_head: "",
            third_party: "",
            status: "",
        })
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyCredentialHead(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getThirdPartyCredentialHead(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(thirdPartyCredentialsList && thirdPartyCredentialsList.data && thirdPartyCredentialsList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }
    const selectValueThirdPartyInLable = (id) => {
        let data = thirdParty_Domain?.data.rows;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.name, name: "third_party" })
        })
        return newObject
    }

    const selectValueCredentialInLable = (id) => {
        let data = credentialHeadList?.data?.rows;
        let newObject = null
        data && data.length > 0 && data.map(x => {
            return (x.id === id) && (newObject = { value: x.id, label: x.head_name, name: "credential_head" })
        })
        return newObject
    }

    const onEditBtn = (data) => {
        onOpenModal()
        setFormData({
            credential_head: selectValueCredentialInLable(data.credential_head_id),
            third_party: selectValueThirdPartyInLable(data.third_party_id),
            created_by: data.created_by,
            status: data.status,
            id: data.id
        })
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormData({ ...formData, [name]: value });
    }

    const onSubmit = async (e) => {
        e.preventDefault()

        // validation
        if (!formData.third_party) {
            return toast.error('Third party is required')
        } else if (!formData.credential_head) {
            return toast.error('Credetial head is required')
        } else if (formData.status === "") {
            return toast.error('Status is required')
        }
        setReload(true)
        let request;
        if (formData.id > 0) {
            request = ({
                ...formData,
                status: formData.status,
                credential_head:  formData?.credential_head?.value ? formData.credential_head.value : formData.credential_head,
                third_party:formData?.third_party?.value ? formData.third_party.value : formData.third_party,
                updated_by: userId
            })
        } else {
            request = ({
                ...formData,
                status: formData.status,
                credential_head: formData.credential_head,
                third_party: formData.third_party,
                created_by: userId
            })
        }
        const response = formData.id > 0 ?
            await dataService.update_third_party_credential(formData.id, request)
            : await dataService.save_third_party_credential(request);

        if (response?.data && response.data.status === 200) {
            toast.success(response.data.message)
            dispatch(getThirdPartyCredentialHead(filterQuery))
            onCloseModal();
        } else {
            toast.error(response.data.message)
        }
        setTimeout(() => {
            setReload(false)
        },500)
    }
    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
    const ThirdPartyColumns = [
        {
            field: "third_party_id",
            headerName: "Third Party",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.third_party}
                    </div>
                );
            },
        },
        {
            field: "credential_head_id",
            headerName: "Credential Head",
            width: 280,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.credential_head}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            filterOperators,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName=""
                            moduleName="third-party"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            editButton={() => onEditBtn(params.row)}
                            isDelete={false}
                            viewButton={false}
                            deleteButton={false}
                        />
                    </div>
                );
            },
        },
    ]

    // filter
    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                setFilterQuery(`{"skip":0, "
                ":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.THIRD_PARTY_CREDENTIAL_LIST_COLUMNS}]}`)
                dispatch(getThirdPartyCredentialHead(`{"skip":0, "limit":${limit},"order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.THIRD_PARTY_CREDENTIAL_LIST_COLUMNS}]}`))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                            filterModel.items[0].operatorValue === "is" ? 'eq' :
                                filterModel.items[0].operatorValue

            let field = filterModel.items[0].columnField === 'third_party_id' ? "third_party" :
                filterModel.items[0].columnField === 'credential_head_id' ? "credential_head" :
                    filterModel.items[0].columnField;

            const filterData = `{"filter":{"${[field]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.THIRD_PARTY_CREDENTIAL_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getThirdPartyCredentialHead(filterData))
        }
    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }

    const columns = useMemo(() =>
        ThirdPartyColumns.map((col) => {
            if (col.field === "status") {
                return col;
            }
            return {
                ...col,
                filterOperators: getGridSingleSelectOperators()
                    .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? CustomFilterInputValue
                            : undefined
                    }))
            };
        }), [ThirdPartyColumns]
    );

    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Third Party Credential</h3>
                        <div>
                            <Tooltip title="Add New Third party credential">
                                <button onClick={onOpenModal} className="btn-add-new">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getThirdPartyCredentialHead} filter={filterQuery} style={{ marginTop: 0 }} />
                        </div>
                    </div>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {(status === STATUSES.LOADING || reload === true) && <LoaderForm />}
                    {
                        !reload && 
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={thirdPartyCredentialsList?.data?.rows ? thirdPartyCredentialsList.data.rows : []}
                            columns={columns}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            hideFooterPagination={true}
                        />
                    }
                    
                </div>

                {/* model for add /edit  */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={openSingle}
                    onClose={onCloseModal}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    sx={{ '& .MuiDialog-paper': { height: "450px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={onCloseModal}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px",color:"white"}}>
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                        {formData.id ? "Edit Third Party Credential" : "Add Third Party Credential"}
                    </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">
                                    <label>
                                        Third Party*
                                    </label>
                                    <Select
                                        className="basic-single"
                                        placeholder="Select Third Party"
                                        defaultValue={formData.third_party}
                                        isSearchable={true}
                                        name={"third_party"}
                                        onChange={(e) => handleChange(e)}
                                        options={filterThirdPartyList}
                                    />
                                </div>

                                <div className="formInput">
                                    <label>
                                        Credential Head*
                                    </label>
                                    <Select
                                        className="basic-single"
                                        placeholder="Select Credential Head"
                                        defaultValue={formData.credential_head}
                                        isSearchable={true}
                                        name={"credentails_head"}
                                        onChange={(e) => handleChange(e)}
                                        options={filterHeadList}
                                    />
                                </div>
                            </div>

                            <div className='add-new-user-form'>
                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status*</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                            <button className={reload === true ? 'btn-disable' : 'submit-modal'} disabled={reload === true ? true : false} style={{ marginTop: "40px" }} onClick={onSubmit}>Submit</button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    )
}
export default ThirdPartyCredentialHead