import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./task.scss"
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import { toast, ToastContainer } from "react-toastify";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { getSprintList, getMYTaskList, project_Jira_List, syncProjectAndSprintTask, taskArchive, syncTaskByJiraIssueId, main_Project_List, allResource, jireStatus, allIssuetype, defaultTaskColumn } from "../../redux/features/taskSlice";
import { FormControl, TablePagination, Tooltip } from "@mui/material";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import dataService from '../../redux/services/data.service';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import moment from "moment";
import Select from 'react-select';
import Filter from "../../components/Filter";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Sync } from '@mui/icons-material'
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { getJobPostList } from "../../redux/features/jobPostSlice";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { StoryDetails } from "./StoryDetails";
import { getGridStringOperators } from "@mui/x-data-grid";

const { REACT_APP_API_BASEURL } = process.env;
const user = LocalStorageDataService.userDetail();
const role = user && user.tms_role && user.tms_role.role_name
const default_task_columns = LocalStorageDataService.get_default_column()
const domain_data = LocalStorageDataService.get_domain_data()

function removeDuplicates(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
}
export const ReportedTask = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { innerHeight } = window
    const [projectId, setProjectId] = useState(0);
    const [sprintId, setSprintId] = useState(0);
    const [reload, setReload] = useState(false)
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null)
    const [isCredential, setIsCredential] = useState(false)
    const [openIFtrame, setOpenIFrame] = useState({ status: false, key: "" })
    const { error, status, defaultLoader, myTaskList, projectJiraList, syncTaskList, task_archive, taskSyncByJira, mainProjectList, resource_list, jireStatusList, allIssueList, defaultTaskColumns } = useSelector(state => state.task)
    const { sprintList } = useSelector(state => state.sprint)
    const { jobPost_List } = useSelector(state => state.jobpost)
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const roleWiseFilter = user && `{"filter":{"reported_by":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"}, "skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`
    const [filterQuery, setFilterQuery] = useState(roleWiseFilter)
    const [filterProjectList, setProjectFilterList] = useState([]);
    const [filterResourceList, setFilterResourceList] = useState([]);
    const [filterJiraStatusList, setFilterJiraStatusList] = useState([]);
    const [filterIssueTypeList, setFilterIssueTypeList] = useState([]);
    const [filterSprintList, setFilterSprintList] = useState([]);
    const [filterJobList, setFilterJobList] = useState(false);

    //custom filter
    const [fieldName, setFieldName] = useState("")
    const [options, setOptions] = useState({})
    const [defaultValue, setDefaultValue] = useState({})
    const [uniqueProjectArrayStatus, setUniqueProjectArrayStatus] = useState(true)

    const [downliadFile, setDownloadFile] = useState(false)
    const [resourceProjectList, setResourceProjectList] = useState([])
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_task_columns && default_task_columns.reported_task_column ? default_task_columns.reported_task_column : {
        is_jira_issue: false,
        status: false,
        priority: false,
        story_point: false,
        sprint: false,
        ticket_jira_key: false,
        created_on: false,
        updated_on: false
    });
    const [storyDiv, setStoryDiv] = useState(false)
    const [activeDivs, setActiveDivs] = useState("")

    useEffect(() => {
        if (status === STATUSES.FAILURE) {
            errorHandling(error, navigate)
        }
        if (domain_data === true) {
            dispatch(getJobPostList(`{"order_by":{"column":"-id"},"no_limit":True,"columns":["id","job_title"]}`))
        }

        if (isCredential === false && reload === false) {
            setReload(true)
            loadResourceCredential()
        }
        dispatch(getMYTaskList(roleWiseFilter))
        dispatch(getSprintList())
        dispatch(project_Jira_List())
        dispatch(main_Project_List())
        dispatch(allResource())
        dispatch(jireStatus())
        dispatch(allIssuetype())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (filterProjectList && filterProjectList.length === 0 && status === `${STATUSES.SUCCESS}_mainProjectList` && mainProjectList && mainProjectList.data) {
        let array = []
        mainProjectList.data && mainProjectList.data.rows.map(e => { return array.push({ value: e.id, label: e.project_name }) })
        array.length > 0 && setProjectFilterList(array)
        array.length > 0 && setOptions({ ...options, project: array })
    }

    if (filterResourceList && filterResourceList.length === 0 && status === `${STATUSES.SUCCESS}_resource_list` && resource_list && resource_list.data) {
        let array = []
        resource_list.data && resource_list.data.rows.map(e => { return array.push({ value: e.id, label: e.name + "(" + e.email + ")" }) })
        array.length > 0 && setFilterResourceList(array)
        array.length > 0 && setOptions({ ...options, reported_by: array, assignee: array })
    }

    if (filterJiraStatusList && status === `${STATUSES.SUCCESS}_jireStatusList` && filterJiraStatusList.length === 0 && jireStatusList && jireStatusList.length > 0) {
        let array = []
        jireStatusList?.map(e => { return array.push({ value: e.id, label: e.name }) })
        array.length > 0 && setFilterJiraStatusList(array)
        array.length > 0 && setOptions({ ...options, status: array })
    }

    if (filterIssueTypeList && filterIssueTypeList.length === 0 && status === `${STATUSES.SUCCESS}_allIssueList` && allIssueList && allIssueList.data) {
        let array = []
        allIssueList.data && allIssueList.data.rows.map(e => { return array.push({ value: e.id, label: e.issue_name }) })
        array.length > 0 && setFilterIssueTypeList(array)
        array.length > 0 && setOptions({ ...options, issue_type: array })
    }


    if (filterSprintList && filterSprintList.length === 0 && sprintList && sprintList.data) {
        let array = []
        sprintList.data && sprintList.data.rows.map(e => { return array.push({ value: e.id, label: e.sprint_name }) })
        array.length > 0 && setFilterSprintList(array)
        array.length > 0 && setOptions({ ...options, sprint: array })
    }

    if (filterJobList === false && jobPost_List && jobPost_List.status) {
        let array = []
        if (jobPost_List.status === 200) {
            jobPost_List.data && jobPost_List.data.rows.map(e => { return array.push({ value: e.id, label: e.job_title }) })
            array.length > 0 && setOptions({ ...options, job: array })
        }
        setFilterJobList(array)
    }

    const loadResourceCredential = async () => {
        const res = await dataService.checkResourceCredentialById();
        const result = res && res.data && res.data.data.rows ? res.data.data.rows : ''
        if (result && result.length > 0) {
            setIsCredential(true)

        }
        setReload(false)

    }

    const handleChange = (evt) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            if (name === 'projectId') {
                setSprintId(0)
                setProjectId(value);
                let filteredProjectList = resourceProjectList && resourceProjectList.length > 0 && resourceProjectList.filter(function (el) {
                    return (el.jira_project_id === value);
                });
                if (filteredProjectList && filteredProjectList.length > 0) {
                    let filterData = `{"filter":{"reported_by":{"value":${user.id},"operation": "eq"},"project":{"value":${filteredProjectList[0].project_id},"operation": "eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
                    setFilterQuery(filterData)
                    setCurrentPage(0)
                    dispatch(getMYTaskList(filterData))
                }
            } else if (name === 'sprintId') {
                setProjectId(0);
                setSprintId(value)
                let filteredSprintList = sprintList && sprintList.data && sprintList.data.rows.filter(function (el) {
                    return (el.jira_id === value);
                });

                if (filteredSprintList && filteredSprintList.length > 0) {
                    let filterData = `{"filter":{"reported_by":{"value":${user.id},"operation": "eq"}, "sprint":{"value":${filteredSprintList[0].id},"operation": "eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
                    setFilterQuery(filterData)
                    setCurrentPage(0)
                    dispatch(getMYTaskList(filterData))
                }

            } else {
                setProjectId(0);
                setSprintId(0)
                let filterData = `{"filter":{"reported_by":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
                setFilterQuery(filterData)
                setCurrentPage(0)
                dispatch(getMYTaskList(filterData))
            }
        } else {
            setProjectId(0);
            setSprintId(0)
            setCurrentPage(0)
            setFilterQuery(roleWiseFilter)
            dispatch(getMYTaskList(roleWiseFilter))
        }
    }

    const synTaskList = () => {
        if (projectId !== 0) {
            setReload(`true_synTaskList`)
            dispatch(syncProjectAndSprintTask({ type: 'project', id: projectId }))
        }
        if (sprintId !== 0) {
            setReload('true_synTaskList')
            dispatch(syncProjectAndSprintTask({ type: 'sprint', id: sprintId }))
        }
    }

    // const clr = () => {
    //     setProjectId(0);
    //     setSprintId(0);
    // }

    if (reload === 'true_synTaskList' && status === STATUSES.SUCCESS && syncTaskList && syncTaskList.message) {
        setReload(false)
        if (syncTaskList.message === 'Success') {
            toast.success(syncTaskList.message)
            dispatch(getMYTaskList(roleWiseFilter))
        } else {
            toast(syncTaskList.message);
        }

    }

    const handleClickOpen = (id) => {
        setReload(true)
        if (open === true) {
            dispatch(taskArchive(id))
            setOpen(false)
        }
    }

    const handleClickArchive = (id) => {
        setDeleteId(id)
        setOpen(true)
        // setReload(true)
        // dispatch(taskArchive(id))
    }

    if (reload === true && status === STATUSES.SUCCESS && task_archive && task_archive.message) {
        setReload(false)
        if (task_archive.message === 'Success') {
            // toast("Archive Data Successfully!!")
            toast.success(task_archive.message)
            dispatch(getMYTaskList(roleWiseFilter))
        } else {
            toast(task_archive.message)
        }

    }

    if (reload === true && status === STATUSES.SUCCESS && taskSyncByJira && taskSyncByJira.message) {
        setReload(false)
        if (taskSyncByJira.message === 'Success') {
            //toast("Sync Task By Jira Successfully!!")
            toast.success(taskSyncByJira.message)
            dispatch(getMYTaskList(roleWiseFilter))
        } else {
            toast(taskSyncByJira.message)
        }
    }

    const handleTaskByIdSync = (id) => {
        setReload(true)
        dispatch(syncTaskByJiraIssueId(id))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        //setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMYTaskList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMYTaskList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(myTaskList && myTaskList.data && myTaskList.data.count && myTaskList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "onOrAfter" ? 'date_gte' :
                            filterModel.items[0].operatorValue === "after" ? 'date_gt' :
                                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                                    filterModel.items[0].operatorValue === "before" ? 'date_lt' :
                                        filterModel.items[0].operatorValue === "onOrBefore" ? 'date_lte' :
                                            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue;
            const filterData = `{"filter":{"reported_by":{"value":${user.id},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}}, "order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getMYTaskList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setDefaultValue({})
            setFilterQuery(roleWiseFilter)
            dispatch(getMYTaskList(roleWiseFilter))
        }
    }

    const handleClick = (url) => {
        setDownloadFile(false)
        window.open(url);
    }
    if (downliadFile === true) {
        let api_url = myTaskList && myTaskList.data.download_api_url
        if (REACT_APP_API_BASEURL && api_url) {
            let url = REACT_APP_API_BASEURL + api_url;
            if (url) {
                handleClick(url.replace("api//", "api/"))
                dispatch(getMYTaskList(filterQuery))
                // setLoading(true)
            }
        }
        setDownloadFile(false)
    }
    const activeStoryDiv = (id) => {
        if (id.id) {
            let Id = id.id
            setActiveDivs(`story_${Id}`)
            if (storyDiv > 0) { setStoryDiv(Id) } else { setStoryDiv(Id); }
        } else {
            setActiveDivs(`story_${id}`)
            if (storyDiv > 0) { setStoryDiv(id) } else { setStoryDiv(id); }
        }
    }
    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
    const actionColumn = [
        {
            field: "issue_type", headerName: "", width: 50, type: 'singleSelect', valueOptions: filterIssueTypeList,
            renderCell: (params) => {
                let title = ""
                if (domain_data === true) {
                    if (params.row.issue_type_name === 'Epic') {
                        title = "Jobs"
                    } else if (params.row.issue_type_name === 'Story') {
                        title = "Resumes"
                    } else {
                        title = params.row.issue_type_name
                    }
                } else {
                    title = params.row.issue_type_name
                }
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => params.row.issue_type_name === 'Epic' ? navigate(`/epic-task/${params.row.id}`) : params.row.issue_type_name === 'Story' ? activeStoryDiv(params.row.id) : navigate(`/task/edit/${params.row.id}`)} className="cellWithStatus">
                        {params.row.issue_type && params.row.issue_type.icon_url ?
                            <Tooltip title={params.row.issue_type.issue_name ? params.row.issue_type.issue_name : params.row.issue_type_name}>
                                <img src={params.row.issue_type.icon_url} alt="" />
                            </Tooltip>
                            :
                            params.row.issue_type_icon ?
                                <Tooltip title={title}>
                                    <img src={params.row.issue_type_icon} alt="" />
                                </Tooltip>
                                :
                                <div></div>
                        }
                        {/* {"  "}{params.row.issue_type && params.row.issue_type.issue_name ? params.row.issue_type.issue_name : params.row.issue_type_name} */}
                    </div>
                );
            }
        },
        {
            field: "ticket_key", headerName: "RTMS Key", width: 200,filterOperators,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => params.row.issue_type_name === 'Epic' ? navigate(`/epic-task/${params.row.id}`) : params.row.issue_type_name === 'Story' ? activeStoryDiv(params.row.id) : navigate(`/task/edit/${params.row.id}`)} className="cellWithStatus">
                        {params.row.ticket_key}
                    </div>
                )
            }
        },
        {
            field: "summary", headerName: "summary", width: 300,filterOperators,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} onClick={() => params.row.issue_type_name === 'Epic' ? navigate(`/epic-task/${params.row.id}`) : params.row.issue_type_name === 'Story' ? activeStoryDiv(params.row.id) : navigate(`/task/edit/${params.row.id}`)} className="cellWithStatus">
                        {params.row.summary}
                    </div>
                )
            }
        },
        {
            field: "assignee", headerName: "Assignee", type: 'singleSelect', width: 200, valueOptions: filterResourceList,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer' }} className="cellWithStatus" onClick={() => navigate(`/profile/${params.row.assignee}`)}>
                        <Tooltip title="View Profile">
                            <p>
                                {params.row.assignee && params.row.assignee.name ? params.row.assignee.name : params.row.assignee_name}
                            </p>
                        </Tooltip>
                    </div>
                );
            }
        },
        domain_data === true && {
            field: "job_id", headerName: "Job", type: 'singleSelect', width: 200, valueOptions: filterJobList, filterable: true,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus" >
                        {params.row.job && params.row.job.job_title}

                    </div>
                );
            }
        },
        {
            field: "status", headerName: "Status", type: 'singleSelect', width: 200, valueOptions: filterJiraStatusList,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        <Tooltip title={params.row.status_name}>
                            <p style={{
                                borderRadius: 'inherit', paddingLeft: 12, paddingRight: 12,
                                backgroundColor: params.row.status__workflow_status_category__background_color_code ? params.row.status__workflow_status_category__background_color_code : '#fff',
                                color: params.row.status__workflow_status_category__foreground_color_code ? params.row.status__workflow_status_category__foreground_color_code : '#000'
                            }}>
                                {params.row.status__short_code ? params.row.status__short_code : params.row.status_name}</p>

                        </Tooltip>
                    </div>
                );
            }
        },
        { field: "priority", headerName: "Priority", width: 150 ,filterOperators,},
        { field: "story_point", headerName: "Story Point", width: 300,filterOperators,},
        {
            field: "sprint", headerName: "Sprint", width: 300, type: 'singleSelect', valueOptions: filterSprintList,
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.sprint_name}
                    </div>
                );
            }
        },
        {
            field: "ticket_jira_key", headerName: "JIRA Key", width: 200,filterOperators,
            renderCell: (params) => {
                return (
                    <div style={{ color: 'blue', textUnderlinePosition: 'auto' }} className="cellWithStatus">
                        {params.row.jira_issue_id > 0 ?
                            <a
                                target="_blank"
                                rel="noreferrer" href={`https://unicodesystems.atlassian.net/browse/${params.row.ticket_jira_key}`}  >{params.row.ticket_jira_key}</a>
                            :
                            params.row.ticket_jira_key
                        }
                    </div>
                );
            }
        },
        {
            field: "created_on", headerName: "Created Date", width: 200, type: "date",
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.created_on ? moment(params.row.created_on).format('YYYY-MM-DD') : ""}
                    </div>
                );
            }
        },
        {
            field: "updated_on", headerName: "Updated Date", width: 200, type: "date",
            renderCell: (params) => {
                return (
                    <div className="cellWithStatus">
                        {params.row.updated_on ? moment(params.row.updated_on).format('YYYY-MM-DD') : ""}
                    </div>
                );
            }
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            moduleName="task"
                            rowid={params.row.id}
                            addMoreData={params.row.issue_type_name === 'Story' ? params.row : { jira_issue_id: params.row.jira_issue_id, ticket_jira_key: params.row.ticket_jira_key, openIFtrame: openIFtrame }}
                            editLinkUrl={params.row.issue_type_name === 'Epic' ? `/epic-task/${params.row.id}` : params.row.issue_type_name === 'Story' ? "" : `/task/edit/${params.row.id}`}
                            viewLinkUrl={params.row.issue_type_name === 'Epic' ? `/epic-task/${params.row.id}` : params.row.issue_type_name === 'Story' ? "" : `/task/edit/${params.row.id}`}
                            isDelete={true}
                            editButton={params.row.issue_type_name === 'Story' ? activeStoryDiv : false}
                            viewButton={params.row.issue_type_name === 'Story' ? activeStoryDiv : false}
                            deleteButton={handleClickArchive}
                            syncingButton={handleTaskByIdSync}
                            isSync={isCredential === false ? false : true}
                            deleteIconName="ArchiveIcon"
                            openIframe={setOpenIFrame}
                        />
                    </div>
                );
            },
        },
    ];


    if (projectJiraList && projectJiraList.data && projectJiraList.data && resourceProjectList.length === 0 && uniqueProjectArrayStatus === true) {
        let projectArray = []
        projectJiraList && projectJiraList.data && projectJiraList.data.rows.map(ele => {
            if (ele.project.jira_project_id) {
                projectArray.push({ "jira_project_id": ele.project.jira_project_id, "project_id": ele.project.id, "project_name": ele.project.project_name })
            }
            return projectArray
        })
        setUniqueProjectArrayStatus(false)
        setResourceProjectList(removeDuplicates(projectArray, 'project_id'))

    }

    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
        );
    }
    const setKeyForList = (obj) => {
        let data;
        if (default_task_columns) {
            data = { 'task_column': default_task_columns.task_column, 'reported_task_column': obj, 'my_task_column': default_task_columns.my_task_column, 'my_job_column': default_task_columns.my_job_column }
            //localStorage.setItem(`${REACT_APP_ENV}_${user.id}_task`, JSON.stringify({ 'task_column': default_task_columns.task_column, 'reported_task_column': obj, 'my_task_column': default_task_columns.my_task_column }))
        } else {
            data = { 'reported_task_column': obj }
            //localStorage.setItem(`${REACT_APP_ENV}_${user.id}_task`, JSON.stringify({ 'reported_task_column': obj }))
        }
        if (defaultTaskColumns.task_column) {
            data['task_column'] = defaultTaskColumns.task_column
        }
        if (defaultTaskColumns.my_task_column) {
            data['my_task_column'] = defaultTaskColumns.my_task_column
        }
        if (defaultTaskColumns.my_job_column) {
            data['my_job_column'] = defaultTaskColumns.my_job_column
        }
        dispatch(defaultTaskColumn(data))

    }
    const handleClose = () => {
        setOpen(false)
    }

    const columns = useMemo(() =>
        actionColumn.map((col) => {
            if (col.field === "ticket_key" || col.field === "ticket_jira_key" || col.field === "summary" || col.field === "story_point" || col.field === "created_on" || col.field === "updated_on" || col.field === "is_jira_issue" || col.field === 'priority' || col.field === 'job_id') {
                return col;
            }
            return {
                ...col,
                filterOperators: getGridSingleSelectOperators()
                    .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? CustomFilterInputValue
                            : undefined
                    }))
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [actionColumn]
    );

    const onAddBtn = () => {
        navigate('/task/add', { state: "reported-task" })
    }

    return (
        openIFtrame.status === true ?
            <iframe title="Jira" src={`https://unicodesystems.atlassian.net/browse/embed/${openIFtrame.key}`} frameBorder="0" width="100%" height={innerHeight} allowFullScreen></iframe>
            // <div>
            //     <Button variant="outlined" color="error" onClick={() => setOpenIFrame({ status: false, key: "" })}>close</Button>
            //     <iframe
            //         src={`https://unicodesystems.atlassian.net/${openIFtrame.key}`}
            //         position="absolute"
            //         width="100%"
            //         height={innerHeight} />

            // </div>
            :
            <div className="task">
                <Sidebar />
                <div className="taskContainer">
                    <Navbar />
                    {storyDiv > 0 ?
                        <StoryDetails
                            epicId={storyDiv}
                            hideDiv={setStoryDiv}
                            activeDivs={activeDivs}
                        />
                        :
                        <div className="datatable">
                            <div className="top-headings">
                                <h3>Reported Task</h3>
                                <div>
                                    {isCredential === false &&
                                        <button style={{ borderWidth: 0, borderColor: '#fff', padding: 14 }}>
                                            Jira Syncing Disabled.
                                            <a href="/all-resource-credential">ADD Jira Credential</a>
                                        </button>

                                    }
                                    {domain_data === false && isCredential === true && <FormControl sx={{ m: 1, minWidth: 150 }}>
                                        {sprintId <= 0 ?
                                            <Select
                                                className="task-dropdown"
                                                classNamePrefix="select"
                                                placeholder="Select Project"
                                                defaultValue={projectId}
                                                isSearchable={true}
                                                isClearable
                                                name={"projectId"}
                                                options={
                                                    resourceProjectList && resourceProjectList.length && resourceProjectList.map((option) => {
                                                        return ({ label: option.project_name, value: option.jira_project_id, name: "projectId" })
                                                    })
                                                }
                                                onChange={handleChange}
                                            />
                                            :
                                            <Select
                                                isDisabled={true}
                                                className="task-dropdown"
                                                placeholder="Select Project"
                                            ></Select>
                                        }

                                    </FormControl>}

                                    {domain_data === false && isCredential === true && <FormControl sx={{ m: 1, minWidth: 150 }}>
                                        {projectId <= 0 ?
                                            <Select
                                                id="demo-customized-select-native"
                                                className="task-dropdown"
                                                classNamePrefix="select"
                                                placeholder="Select Sprint"
                                                defaultValue={sprintId}
                                                isSearchable={true}
                                                isClearable
                                                name={"sprintId"}
                                                options={
                                                    sprintList && sprintList.data && sprintList.data.rows.map((option) => {
                                                        return { label: option.sprint_name, value: option.jira_id, name: "sprintId" }
                                                    })
                                                }
                                                onChange={handleChange}
                                            />
                                            :
                                            <Select
                                                isDisabled={true}
                                                className="task-dropdown"
                                                placeholder="Select Sprint"
                                            ></Select>
                                        }

                                    </FormControl>}

                                    {(projectId > 0 || sprintId > 0) ?
                                        isCredential === true &&
                                        <Tooltip title="sync with jira">
                                            <button style={{ marginTop: 12, }} onClick={() => synTaskList()} className="btn-add-new ">
                                                <Sync />
                                            </button>
                                        </Tooltip>
                                        :
                                        domain_data === false && isCredential === true &&

                                        <button style={{ backgroundColor: 'rgb(117, 117, 117)', border: '1px solid rgb(117, 117, 117)', marginTop: 12, }} disabled className="btn-add-new ">
                                            <Tooltip title="sync with jira">
                                                <Sync />
                                            </Tooltip>
                                        </button>

                                    }
                                    {/* {isCredential === true && <button style={{ marginTop: 10 }} onClick={() => clr()} className="btn-add-new ">
                                    Clear
                                </button>
                                } */}
                                    {role && !role.match("Agent") &&
                                        <Tooltip title="add new task">
                                            <span>
                                                <button style={{ marginTop: 12, }} className="btn-add-new " onClick={onAddBtn}>
                                                    <Add />
                                                </button>
                                            </span>
                                        </Tooltip>
                                    }
                                    <RefreshButton api={getMYTaskList} filter={filterQuery} />

                                    {/* <Tooltip title="download csv"><button style={{ marginTop: 12, }} className="btn-add-new " onClick={() => downloadCsvFile()}><Download /></button></Tooltip> */}
                                </div>
                            </div>
                            <ToastContainer />
                            <DataGrid
                                columnVisibilityModel={defaultTaskColumns && defaultTaskColumns.reported_task_column ? defaultTaskColumns.reported_task_column : columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                                }
                                className="datagrid"
                                rows={myTaskList && myTaskList.data && myTaskList.data.rows ? myTaskList.data.rows : []}
                                columns={columns}
                                loading={(reload === 'true_synTaskList' || defaultLoader === true) ? true : false}
                                pageSize={pageSize}
                                style={{ fontSize: 16 }}
                                onFilterModelChange={onFilterChange}
                                components={{
                                    Pagination: CustomPagination,
                                    NoRowsOverlay: CustomNoRowsOverlay
                                }}
                                disableSelectionOnClick
                                // onRowClick={(row)=>{navigate(`edit/${row.id}`)}}
                                //onCellClick={(cell) => { cell.field !== "action" && navigate(`edit/${cell.id}`) }}
                                onPageSizeChange={(pageSize) => handlePageSizeChange(pageSize)}
                            />

                            {/* for delete popup */}
                            <DeleteDialogModal
                                open={open}
                                onClose={handleClose}
                                heading={'Delete ?'}
                                paragraph={'Are You Sure To Remove this Task'}
                                handleArchive={handleClickOpen}
                                id={deleteId}
                            />
                        </div>
                    }
                </div>
            </div>

    )
}

