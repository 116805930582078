import "./widget.scss";
import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import { Link } from "react-router-dom";
import Folder from "@mui/icons-material/Folder";
import CardMembership from "@mui/icons-material/CardMembership";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import FormatAlignLeftOutlinedIcon from '@mui/icons-material/FormatAlignLeftOutlined';
import { Avatar, Card, CardActions, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import { IconButton } from "rsuite";
import TaskIcon from '@mui/icons-material/Task';

const Widget = ({ type, count }) => {
    let data;

    switch (type) {
        case "resources":
            data = {
                title: "RESOURCES",
                isMoney: false,
                link: "See all resources",
                url: "all-resource",
                icon: (
                    <PersonOutlineOutlined
                        className="icon"
                        style={{
                            // color: "crimson",
                            // backgroundColor: " rgba(218,165,32,0.2)",
                            backgroundColor: '#4581e8',
                            color: '#fff'
                        }}
                    />
                ),
            };
            break;
        case "projects":
            data = {
                title: "PROJECTS",
                isMoney: false,
                link: "View all projects",
                url: "all-projects",
                icon: (
                    <Folder className="icon"
                        style={{
                            // color: "goldenrod",
                            // backgroundColor: " rgba(218,165,32,0.2)",
                            backgroundColor: "#efb034",
                            color: '#5d4108'
                        }}
                    />
                ),
            };
            break;
        case "shifts":
            data = {
                title: "SHIFTS",
                isMoney: true,
                link: "View all shifts",
                url: "all-shift",
                icon: (
                    <PersonOutlineOutlined className="icon"
                        style={{
                            // color: "green",
                            // backgroundColor: " rgba(0,128,0,0.2)",
                            color: "#0a4d20",
                            backgroundColor: "#53e082",

                        }}
                    />
                ),
            };
            break;
        case "reports":
            data = {
                title: "REPORTS",
                isMoney: true,
                link: "See all reports",
                url: "attendance-report",
                icon: (
                    <FormatAlignLeftOutlinedIcon className="icon"
                        style={{
                            // color: "purple",
                            // backgroundColor: " rgba(128,0,128,0.2)",
                            color: "#fff",
                            backgroundColor: "#945ed9",

                        }}
                    />
                ),
            };
            break;
        case "tasks":
            data = {
                title: "TASKS",
                isMoney: true,
                link: "See all task",
                url: "my-task",
                icon: (
                    <TaskIcon className="icon"
                        style={{
                            color: "purple",
                            backgroundColor: " rgba(128,0,128,0.2)",
                        }}
                    />
                ),
            };
            break;
        case "myProjects":
            data = {
                title: "PROJECTS",
                isMoney: false,
                link: "View all projects",
                url: "my-projects",
                icon: (
                    <Folder className="icon"
                        style={{
                            color: "goldenrod",
                            backgroundColor: " rgba(218,165,32,0.2)",
                        }}
                    />
                ),
            };
            break;
        default:
            break;
    }

    return (
        // <Card>
        //     <CardHeader
        //         avatar={
        //             <Typography variant="h2" fontSize={"15px"}>{data?.title}</Typography>
        //         }
        //     />
        //     <CardContent sx={{ paddingTop: '0px', paddingBottom: '0px' }}>
        //         <Typography fontWeight={700} variant="h5" color="#42526e">
        //             {data?.isMoney} {count}
        //         </Typography>
        //     </CardContent>
        //     <CardActions sx={{ justifyContent: "space-between", alignItems: "center" }}>
        //         <span className="link"><Link to={'/' + data.url}>{data.link}</Link></span>

        //         <div className="right">{data.icon}</div>
        //     </CardActions>
        <CardContent>
            <Stack className="cardData" width={'100%'} direction={"row"} alignItems={"center"}>
                <Stack width={'100%'} alignItems={"center"} direction={"row"}>
                    <div className="right">{data.icon}</div>
                    <Link style={{textDecoration:"none",color:'#42526e'}} to={'/' + data.url}>
                        <Typography variant="subtitle1" ml={'10px'} fontSize={"12px"} >{data?.title}</Typography>
                    </Link>
                </Stack>
                <Stack width={"100%"} sx={{ textAlign: "end" }} paddingRight={'15px'}>
                    <Typography fontWeight={400} fontSize={"20px"} variant="h5" color="#42526e">
                        {data?.isMoney} {count}
                    </Typography>
                </Stack>
            </Stack>
        </CardContent>
        // </Card>
    );

}

export default Widget
