import http from "../APIS/api";
import formdata_api from "../APIS/formdata_api";
import ces_api from "../APIS/ces_api";
import formdata_ces_api from "../APIS/formdata_ces_api";
import LocalStorageDataService from '../../components/common-function/GetLocalstorage'
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import axios from "axios";
import { updateProgressPer } from "../features/masterSlice";
import moment from "moment";
const { REACT_APP_API_BASEURL, REACT_APP_USEREMAIL, REACT_APP_USERPASSWORD } = process.env;
class DataService {
  getAll() {
    return http.get("/users");
  }

  get(id) {
    return http.get(`/users/${id}`);
  }

  create(data) {
    return http.post("/login", data);
  }

  userLogin(token) {
    return http.get("/user", {
      headers: {
        'Authorization': token,
        //'Cookie':`token=${token}`
      }
    });
  }

  update(id, data) {
    return http.put(`/users/${id}`, data);
  }

  delete(id) {
    return http.delete(`/users/${id}`);
  }

  deleteAll() {
    return http.delete(`/users`);
  }

  findByTitle(title) {
    return http.get(`/users?title=${title}`);
  }

  logout() {
    return http.post("/logout");
  }


  //Department api
  departmentList(filterQuery) {
    return http.get(`/mst/departments?where=${filterQuery}`);
  }

  department_List(filterQuery) {
    return filterQuery ? http.get(`/mst/departments?where=${JSON.stringify(filterQuery)}`)
      :
      http.get(`/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"name"},"no_limit":"True","columns":[${DefaultFilterColumns.DEPARTMENT_DROUPDOWN_COLUMNS}]}`)
  }

  departmentDomainList(filterQuery) {
    return filterQuery ? http.get(`/mst/department-domain?where=${JSON.stringify(filterQuery)}`)
      :
      // http.get('/mst/department-domain?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
      http.get('/mst/department-domain?where={"filter":{"status":{"value":"True","operation":"eq"}} ,"no_limit":"True"}')

  }

  assign_department_domain(data) {
    return data.id ? http.put(`/mst/department-domain/${data.id}`, data)
      :
      http.post('/mst/department-domain', data)
  }

  addDepartment(data) {
    return http.post("/mst/departments", data);
  }

  departmentById(id) {
    return http.get(`/mst/departments/${id}`);
  }

  updateDepartment(id, data) {
    return http.put(`/mst/departments/${id}`, data);
  }

  deleteDepartment(id, data) {
    return http.delete(`/mst/departments/${id}`, data);
  }


  //role api
  roleList(filterQuery) {
    return http.get(`/mst/tms-roles?where=${filterQuery}`);
  }

  addRole(data) {
    return http.post("/mst/tms-roles", data);
  }

  roleById(id) {
    return http.get(`/mst/tms-roles/${id}`);
  }

  updateRole(id, data) {
    return http.put(`/mst/tms-roles/${id}`, data);
  }

  deleteRole(id, data) {
    return http.delete(`/mst/tms-roles/${id}`, data);
  }

  //user Type api
  userTypeList(filterQuery) {
    return http.get(`mst/employment-type?where=${filterQuery}`);
  }

  addUserType(data) {
    return http.post("/mst/employment-type", data);
  }

  userTypeById(id) {
    return http.get(`/mst/employment-type/${id}`);
  }

  updateUserType(id, data) {
    return http.put(`/mst/employment-type/${id}`, data);
  }

  deleteUserType(id, data) {
    return http.delete(`/mst/employment-type/${id}`, data);
  }

  country_list() {
    return http.get('mst/country')
  }

  get_country_wise_state_list(filterQuery) {
    return http.get(`/mst/state?where=${filterQuery}`)
  }

  ///resource list

  resource_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/resources/main?where=${filterQuery}`)
    } else {
      let user = LocalStorageDataService.userDetail();
      let tmsRole = user && user.tms_role
      if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
        return http.get(`/resources/main?where={"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`)
      } else {
        return http.get(`/resources/main?where={"filter":{"id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`)
      }
    }
  }

  resource_list_for_task(filterQuery) {
    if (filterQuery) {
      return http.get(`/resources/main?where=${filterQuery}`)

    }
    return http.get(`/resources/main?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`)
  }

  ///resource view by id

  resource_view(id) {
    return http.get(`/resources/main/${id}`);
  }

  ///resource add

  resource_add(data) {
    return http.post("/resources/main", data);
  }

  ///resource edit
  resource_edit(id, data) {
    return http.put(`/resources/main/${id}`, data);
  }

  ///resource delete

  resource_delete(id, data) {
    return http.delete(`/resources/main/${id}`, data);
  }
  /// resource sync by jira api
  sync_resources() {
    return http.get(`/jira/get-users/0/24`);
  }

  //all  role lis 
  all_role_list() {
    return http.get(`/mst/tms-roles?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.ROLE_DROUPDOWN_COLUMNS}]}`);
  }

  employment_type_list(id) {
    return http.get(`/mst/employment-type?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.EMPLOYMENT_DROUPDOWN_COLUMNS}]}`);
  }
  // all depattment list

  all_department_list() {
    return http.get(`/mst/departments?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.DEPARTMENT_DROUPDOWN_COLUMNS}]}`);
  }
  department_type_list() {
    return http.get(`/mst/departments?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.DEPARTMENT_DROUPDOWN_COLUMNS}]}`);
  }
  // all emp. type list
  all_emp_type_list() {
    return http.get(`/mst/employment-type?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"colunms":[${DefaultFilterColumns.EMPLOYMENT_DROUPDOWN_COLUMNS}]}`);
  }

  tms_role_list(id) {
    return http.get(`/mst/tms-roles?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.ROLE_DROUPDOWN_COLUMNS}]}`);
  }
  tms_role_client_list(id) {
    return http.get(`/mst/tms-roles?where={"filter":{"status":{"value":"True","operation": "eq"},"is_for_client":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.ROLE_DROUPDOWN_COLUMNS}]}`);
  }


  create_resources(data) {
    return http.post(`/resources/main`, data);
  }

  update_resources(id, data) {
    return http.put(`/resources/main/${id}`, data);
  }
  update_status_resources(id, data) {
    return http.put(`/resources/status-update/${id}`, data);
  }

  image_upload(data) {
    return ces_api.post(`/file/upload`, data);
  }

  //Credential head api
  credentialHeadList(filterQuery) {
    return http.get(`mst/credentials-head?where=${filterQuery}`);
  }

  addCredentialHead(data) {
    return http.post("/mst/credentials-head", data);
  }

  credentialHeadById(id) {
    return http.get(`/mst/credentials-head/${id}`);
  }

  updateCredentialHead(id, data) {
    return http.put(`/mst/credentials-head/${id}`, data);
  }

  deleteCredentialHead(id, data) {
    return http.delete(`/mst/credentials-head/${id}`, data);
  }

  //Resource Credential api
  resourceCredentialList(filterQuery) {
    let user = LocalStorageDataService.userDetail();
    let tmsRole = user && user.tms_role
    if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
      return http.get(`/resources/credentials?where=${filterQuery}`);
    } else {
      return http.get(`/resources/credentials?where={"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"no_limit":True}`)
    }
  }

  checkResourceCredentialById() {
    let user = LocalStorageDataService.userDetail();
    return user && http.get(`/resources/credentials?where={"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.MY_RESOURCE_CREDENTIAL_COLUMNS}]}`)
  }

  addResourceCredential(data) {
    return http.post("/resources/credentials", data);
  }

  resourceCredentialById(id) {
    return http.get(`/resources/credentials/${id}`);
  }

  updateResourceCredential(id, data) {
    return http.put(`/resources/credentials/${id}`, data);
  }

  deleteResourceCredential(id, data) {
    return http.delete(`/resources/credentials/${id}`, data);
  }

  ///attendance list

  attandance_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/resources/attendance?where=${filterQuery}`);
    } else {
      let user = LocalStorageDataService.userDetail();
      let data = {
        "filter": {
          "resource_id": {
            "value": user.id,
            "operation": "eq"
          }
        },
        "order_by": { "column": "-punchin_date" },
        "no_limit": "True"
      }
      return http.get(`/resources/attendance?where=${JSON.stringify(data)}`);
    }
  }

  attandance_detail_by_id(id) {
    let data = {
      "resource_attendance_id": {
        "value": id,
        "operation": "eq"
      }
    }
    return http.get(`/resources/attendance-detail?where={ "filter": ${JSON.stringify(data)}, "no_limit":True}`);
  }

  attandance_report_list(filterQuery) {
    return http.get(`/report/attendance?where=${filterQuery}`);
  }
  attandance_regulization_by_date(data) {
    let user = LocalStorageDataService.userDetail();
    let data0 = {
      "filter": {
        "resource_attendance_id": {
          "value": data.id,
          "operation": "eq"
        },
        "resource_id": {
          "value": data.resource_id ? data.resource_id : user.id,
          "operation": "eq"
        },
        // "punchin_date":{
        //   "value": data.current_date,
        //   "operation": "eq"
        // }
      }
    }
    return http.get(`/resources/regularization-requests?where=${JSON.stringify(data0)} `);
  }

  attandance_regulization_create(data) {
    return http.post(`/resources/regularization-requests`, data);
  }
  attandance_regulization_update(id, data) {
    return http.put(`/resources/regularization-request/${id}`, data);
  }

  attandance_regulization_by_id(id) {
    return http.get(`/resources/regularization-request/${id}`);
  }
  team_regular_list(filterQuery) {
    return http.get(`/resources/regularization-requests?where=${filterQuery}`);
  }
  get_consolidate_monthly_list(filterQuery) {
    return http.get(`/report/attendance/consolidate-monthly?where=${filterQuery}`)
  }

  get_resume_upload_report_list(filterQuery) {
    return http.get(`/report/recruitment/upload-resume?where=${filterQuery}`)
  }

  get_download_url_resume(filter) {
    return http.get(`/report/resume-zipdownload?where=${filter}`)
  }

  project_jira_list() {
    let user = LocalStorageDataService.userDetail();
    let tmsRole = user && user.tms_role
    if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
      return http.get(`/resources/resource-project?where={"order_by":{"column":"-id"},"no_limit":True}`);
    } else {
      let data = {
        "filter": {
          "resource_id": {
            "value": user.id,
            "operation": "eq"
          }
        },
        "no_limit": "True"
      }
      return http.get(`resources/resource-project?where=${JSON.stringify(data)}`);
    }
  }

  resource_project_list(filter) {
    return http.get(`resources/resource-project?where=${filter}`);
  }

  project_list_for_task() {
    let user = LocalStorageDataService.userDetail();
    let tmsRole = user && user.tms_role
    if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
      return http.get(`resources/resource-project?where={"order_by":{"column":"-id"},"no_limit": "True"}`);
    } else {
      let data = {
        "filter": {
          "resource_id": {
            "value": user.id,
            "operation": "eq"
          }
        },
        "no_limit": "True"
      }
      return http.get(`resources/resource-project?where=${JSON.stringify(data)}`);
    }
  }

  project_list(filter) {
    if (filter) {
      return http.get(`/projects/main?where=${filter}`);
    } else {
      return http.get(`/projects/main?where={ "order_by":{"column":"-id"},"no_limit":True,"columns":[${DefaultFilterColumns.MAIN_PROJECT_DROUPDOWN_COLUMNS}]}`);
    }
  }
  jira_status(filter) {
    // let data ={"filter":{"status":{"value":true,"operation":"eq"},"no_limit":True}}
    //?where=${JSON.stringify(data)}
    if (filter) {
      return http.get(`/jira-rtms/workflow-status?where=${filter}`);
    }
    return http.get(`/jira-rtms/workflow-status?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"-id"},"no_limit":True,"columns":[${DefaultFilterColumns.WORKFLOW_DROUPDOWN_COLUMNS}]}`);
  }
  punchInOut(data) {
    return http.post(`resources/attendance`, data);
  }

  jira_status_list(data) {
    //${JSON.stringify(data)}
    if (data) {
      return http.get(`/jira-rtms/workflow-status?where=${data}`);
    }
    return http.get(`/jira-rtms/workflow-status?where={"order_by":{"column":"-id"},"no_limit":True}`);
  }

  jira_status_create(data) {
    return http.post(`/jira-rtms/workflow-status`, data);
  }

  jira_status_edit(id) {
    return http.get(`/jira-rtms/workflow-status/${id}`);
  }

  jira_status_update(id, data) {
    return http.put(`/jira-rtms/workflow-status/${id}`, data);
  }

  jira_status_archive(id) {
    return http.delete(`/jira-rtms/workflow-status/${id}`, { status: 3 });
  }
  /// jira status syncing 
  jira_status_sync() {
    return http.get(`/jira/get-workflows/0/20`);
  }

  sprint_list() {
    return http.get(`/jira-rtms/sprints?where={"order_by":{"column":"-id"},"no_limit":True}`);
  }
  issue_type_list() {
    return http.get(`/jira-rtms/issue-types?where={"order_by":{"column":"-id"},"no_limit":True,"columns":[${DefaultFilterColumns.ISSUE_TYPE_DROUPDOWN_COLUMNS}]}`);
  }

  sprint_sync(project_id) {
    /// /api/jira/get-sprints/23/0/5 Where 23 is projct_id (in RTMS), 0 -> Skip, 5 -> Limit
    return http.get(`/jira/get-sprints/${project_id}/0/5`);
  }


  get_task_type_list(project_id) {
    return http.get(`/jira-rtms/issue-types?where={"filter":{"project_id":{"value":${project_id},"operation": "eq"}},"columns":["id", "issue_name"],"no_limit":True}`);
  }
  task_type_list() {
    return http.get(`/jira-rtms/issue-types?where={"no_limit":True}`);
  }

  work_flow_status_list(filterData) {
    return http.get(`/projects/workflow-status-mapping?where=${filterData}`);
  }
  get_default_work_flow_status_list(filterData) {
    return http.get(`/projects/workflow?where=${filterData}`);

  }
  get_zoom_meeting(filterData) {
    return http.get(`/zoom/zoom-meeting-details?where=${filterData}`);

  }
  get_call_log(filterData) {
    return http.get(`/report/call-details?where=${filterData}`);

  }

  get_my_call_log_report(filterData) {
    return http.get(`/tts/call_details-report?where=${filterData}`);
  }

  get_my_call_log(filterData) {
    return http.get(`/tts/call_details?where=${filterData}`);
  }
  get_email_log(filterData) {
    return http.get(`/emails/sent-logs?where=${filterData}`);
  }
  get_agent_wise_resource(filterData) {
    return http.get(`/resources/agent-data?where=${filterData}`);

  }
  get_hourly_call_report(filterData) {
    return http.get(`/report/hourly-call/agent-performance?where=${filterData}`)
  }
  call_log_report(filterData) {
    return http.get(`/report/call-details?where=${filterData}`);

  }
  get_my_job_list(filterData) {
    return http.get(`/jira-rtms/issues/epic-lists?where=${filterData}`);
  }

  get_upload_file_list(filterData) {
    return http.get(`/recruitment/upload-resume-file-status?where=${filterData}`);
  }

  post_comment(data) {
    return http.post(`/jira-rtms/comments`, data);

  }
  get_history_list(filterData) {
    // return http.get(`/jira-rtms/issue-history-logs?where=${filterData}`);rtms_issue_id
    return http.get(`/jira-rtms/issue-all-change-log?where={"filter":{"issue_id":{"value":${filterData}, "operation":"eq"}}, "order_by":{"column":"-created_on"}, "no_limit":True}`)

  }

  get_my_task_list(filterData) {
    if (filterData) {
      return http.get(`/jira-rtms/issues?where=${filterData}`);
    } else {
      let user = LocalStorageDataService.userDetail();
      const roleWiseFilter = `{"or":{"assignee":{"value":${user.id},"operation": "eq"},"reported_by":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
      return http.get(`/jira-rtms/issues?where=${roleWiseFilter}`);
    }

  }
  task_view(id) {
    return http.get(`/jira-rtms/issues/${id}`);
  }

  get_task_list(filterData) {
    if (filterData) {
      return http.get(`/jira-rtms/issues?where=${filterData}`);
    } else {
      let user = LocalStorageDataService.userDetail();
      const tmsRole = user && user.tms_role
      const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ? `{"order_by":{"column":"-id"}, "no_limit":True}` : `{"or":{"assignee":{"value":${user.id},"operation": "eq"},"reported_by":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`
      return http.get(`/jira-rtms/issues?where=${roleWiseFilter}`);
    }
  }
  /// only return jira task
  get_jira_task_issue_list(filterData){
    return http.get(`/jira-rtms/jira-issues?where=${filterData}`)
  }
  get_sprint_list() {
    return http.get(`/jira-rtms/sprints?where={"filter":{"status":{"value":True,"operation":"eq"}},"order_by":{"column":"-id"} ,"no_limit":"True","columns":[${DefaultFilterColumns.SPRINT_DROUPDOWN_COLUMNS}]}`);
  }


  //my_work_history
   get_work_history_list(filterData){
    if(filterData){
      return http.get(`/jira-rtms/my-work-history?where=${filterData}`)
    }
    else{


      return http.get(`/jira-rtms/my-work-history`)
    }
   }

  //---------------task ----------------//
  create_task(data) {
    return http.post(`/jira-rtms/issues`, data);

  }
  create_team_member(data) {
    return http.post(`/jira-rtms/epic-team-members`, data);
  }
  team_member_list(filterData) {
    return http.get(`/jira-rtms/epic-team-members?where=${filterData}`);

  }

  update_team_member(id, data) {
    return http.put(`/jira-rtms/epic-team-members/${id}`, data);
  }
  create_task_file(data) {
    return formdata_api.post(`/recruitment/upload-resume`, data)
  }

  get_member_list(filter) {
    return http.get(`/jira-rtms/epic-team-members?where=${filter}`)
  }

  sync_task_project(project_id) {
    return http.get(`/jira/get-issues/0/20?query=project=${project_id}`);
  }
  sync_task_sprint(sprint_id) {
    return http.get(`/jira/get-issues/0/20?query=sprint=${sprint_id}`);
  }

  task_archive(id) {
    return http.put(`/jira-rtms/issue/${id}`, { status: 3 });
  }

  task_sync_by_jira_id(id) {
    return http.get(`/jira/get-issue/${id}`);
  }

  get_task_By_id(id, filter) {
    if (filter) {
      return http.get(`/jira-rtms/issue/${id}?where={"columns":[${filter}]}`);

    }
    return http.get(`/jira-rtms/issue/${id}`);
  }

  update_task(id, data) {
    return http.put(`/jira-rtms/issue/${id}`, data);
  }
  //////-------------end task---------------//

  //api for shift 
  shiftList(filterQuery) {
    return http.get(`/mst/shift?where=${filterQuery}`);
  }

  shiftBYId(id) {
    return http.get(`/mst/shift/${id}`);
  }
  // api for myProject list
  myProjects_list(rtm_user) {
    let resource_id = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id;
    let data = {
      "filter": {
        "status": { "value": "True", "operation": "eq" },
        "resource_id": {
          "value": resource_id,
          "operation": "eq"
        }
      }, "order_by": { "column": "-id" }
    }
    return http.get(`resources/resource-project?where=${JSON.stringify(data)}`);
  }

  myEvaluationByProjectId_list(filterQuery) {

    return http.get(`resources/resource-project?where=${JSON.stringify(filterQuery)}`);
  }

  // api for task list
  tasksList(rtm_user) {
    let resource_id = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id;
    let data = {
      "or": {
        "assignee": {
          "value": resource_id,
          "operation": "eq"
        },
      },
      "columns": ["id", "summary", "status_id", "job_id", "created_on", "sprint", "epic_id", "assignee_id", "reported_by", "issue_type", "project_id", "ticket_key", "status", "story_point", "updated_on", "is_jira_issue", "priority", "status__name", "jira_issue_id"]

    }
    return http.get(`jira-rtms/issues?where=${JSON.stringify(data)}`);
  }

  //My Resource Credential api
  myResourceCredentialList(filterQuery) {
    return http.get(`/resources/credentials?where=${filterQuery}`)
  }

  issue_assignee_change() {
    return http.get(`jira-rtms/issue-assignee-change?where={"no_limit":True}`)
  }

  issue_assignee_change_update(id, data) {
    return http.put(`/jira-rtms/issue-assignee-change/${id}`, data)
  }

  issue_status_change_update(id, data) {
    return http.get(`/jira-rtms/issue-status-change/${id}`, data)
  }

  issue_status_change() {
    return http.put(`jira-rtms/issue-status-change?where={"no_limit":True}`)
  }

  jira_comments() {
    return http.get(`/jira-rtms/comments?where={"no_limit":True}`)
  }

  jira_comments_by_id(id) {
    return http.get(`jira-rtms/comments?where={"filter":{"rtms_issue_id":{"value":${id},"operation": "eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`)
  }

  jira_comments_update_by_id(id, data) {
    return http.put(`/jira-rtms/comment/${id}`, data)
  }

  task_assignee_update(issue_id, resource_id, assign_to_me) {
    if (assign_to_me) {
      return http.put(`/jira/task-assignee/${issue_id}/${resource_id}/${assign_to_me}`)
    }
    return http.put(`/jira/task-assignee/${issue_id}/${resource_id}/0`)
  }

  task_status_update(id, data) {
    return http.put(`/jira/issue-status/${id}`, data)
  }

  // resource list with active status 
  resource_list_status() {
    let user = LocalStorageDataService.userDetail();
    let tmsRole = user && user.tms_role
    if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin' || tmsRole.role_key === 'hr')) {
      return http.get(`/resources/main?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`)
    } else {
      return http.get(`/resources/main?where={"filter":{"id":{"value":${user.id},"operation": "eq"},"status":{"value":"True","operation": "eq"}}, "no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`)
    }
  }

  // credentail head list with active status
  credential_list_status() {
    return http.get(`mst/credentials-head?where={"filter":{"status":{"value":"True","operation": "eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.CREDENTIAL_HEAD_DROUPDOWN_COLUMNS}]}`);
  }

  forgotPassword(data) {
    return http.post(`/forgot-password`, data)
  }

  resetPassword(data) {
    return http.post(`/reset-password`, data)
  }

  // Resources Options for DropDown
  resources_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/resources/main?where=${JSON.stringify(filterQuery)}`) :
      http.get(`/resources/main?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`)
  }

  // Departments Options for DropDown
  departments_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/mst/departments?where=${JSON.stringify(filterQuery)}`) :
      http.get(`/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"} , "no_limit":True,"columns":[${DefaultFilterColumns.DEPARTMENT_DROUPDOWN_COLUMNS}]}`)
  }
  // Roles Options for DropDown
  roles_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/mst/tms-roles?where=${JSON.stringify(filterQuery)}`)
      :
      http.get(`/mst/tms-roles?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"} , "no_limit":True,"columns":[${DefaultFilterColumns.ROLE_DROUPDOWN_COLUMNS}]}`)
  }
  // Shifts Options for DropDown
  shifts_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/mst/shift?where=${JSON.stringify(filterQuery)}`) :
      http.get(`/mst/shift?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"-id"} , "no_limit":True,"columns":[${DefaultFilterColumns.SHIFT_DROUPDOWN_COLUMNS}]}`)
  }
  attandance_list_for_dashboard() {
    let user = LocalStorageDataService.userDetail();
    let data = {
      "filter": {
        "resource_id": {
          "value": user.id,
          "operation": "eq"
        }
      },
      "order_by": { "column": "-id" },
    }
    return http.get(`/resources/attendance?where=${JSON.stringify(data)}`);
  }
  //month wise attendace report api
  monthly_attandance_report_list(filterQuery) {
    return http.get(`/report/attendance/monthly?where=${filterQuery}`);
  }

  // COMP-OFF MODULE 
  compoff_list(filterQuery) {
    return filterQuery ? http.get(`/resources/compoff?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/resources/compoff?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"-created_on"} ,"no_limit":"True"}')
  }

  // Save / Update comp-off Data
  compoff_save(data) {
    return data.id ? http.put(`/resources/compoff/${data.id}`, data)
      :
      http.post('/resources/compoff', data)
  }

  //team attendance list api
  team_attendance_list(filterQuery) {
    let user = LocalStorageDataService.userDetail();
    return http.get(`/resources/attendance/team/${user.id}?where=${filterQuery}`);
  }

  //all resources by reporting manager for filtering
  all_resources_by_manager() {
    let user = LocalStorageDataService.userDetail();
    return http.get(`/resources/main?where={"filter":{"reporting_manager":{"value":${user.id},"operation": "eq"}}, "no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`)
  }

  // HOLIDAYS MODULE //
  // Holidays List
  holiday_list(filterQuery) {
    return filterQuery ? http.get(`/mst/holiday-list?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/mst/holiday-list?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }
  // Save / Update Holiday Data
  save_holiday(data) {
    return data.id ? http.put(`/mst/holiday-list/${data.id}`, data)
      :
      http.post('/mst/holiday-list', data)
  }
  // Delete Holiday
  delete_holiday(id) {
    return http.delete(`/mst/holiday-list/${id}`);
  }
  // mst/holiday-calendar-list
  holiday_calender_list(filterQuery) {
    return filterQuery ? http.get(`/mst/holiday-calendar-list?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/mst/holiday-calendar-list?where={"filter":{}, "order_by":{"column":"-created_on"} ,"no_limit":"True"}')
  }
  // Save / Update Holiday Data
  save_holiday_date(data) {
    return data.id ? http.put(`/mst/holiday-calendar-list/${data.id}`, data)
      :
      http.post('/mst/holiday-calendar-list', data)
  }
  // Delete Holiday
  delete_holiday_date(id) {
    return http.delete(`/mst/holiday-calendar-list/${id}`);
  }

  ///get menu list
  menuList(filterQuery) {
    return http.get(`/base/menu?where=${filterQuery}`);
  }
  all_sprint_list(filterQuery) {
    return http.get(`/jira-rtms/sprints?where=${filterQuery}`);
  }

  //post menu create
  postmenu(data) {
    if (data.id) {
      return http.put(`/base/menu/${data.id}`, data)
    } else {
      return http.post('/base/menu', data)
    }
  }
  getRoleMenu(filterQuery) {
    return http.get(`/base/menu-role?where=${filterQuery}`);
  }

  getRoleMenuNewSideBar(role_id) {
    return http.get(`/base/menu/by-role/${role_id}`);
  }

  getRoleMenus(data) {
    let url = REACT_APP_API_BASEURL + 'base/menu-role?where=' + JSON.stringify(data.filter)
    axios({
      'method': 'GET',
      'url': url,
      "headers": {
        "Content-type": "application/json",
        "Authorization": data.tokens,
      }
    }).then(
      (res) => {
      }
    ).catch((error) => { })
  }

  postSingleBaseMenu(data) {
    if (data.id) {
      return http.put(`/base/menu-role/${data.id}`, data)
    } else {
      return http.post('/base/menu-role', data)
    }
  }
  postAssignMenuRole(data) {
    return http.post('/base/menu-role/assign', data)
  }

  // ________third Party Start_______________//
  all_third_party_list(filterQuery) {
    return http.get(`/mst/third-party?where=${filterQuery}`);
  }
  third_party_list(filterQuery) {
    return http.get(`/mst/third-party?where=${filterQuery}`);
  }
  third_party_create(data) {
    return http.post(`/mst/third-party`, data);
  }
  third_party_edit(id) {
    return http.get(`/mst/third-party/${id}`);
  }
  third_party_update(id, data) {
    return http.put(`/mst/third-party/${id}`, data);
  }
  third_party_archive(id, data) {
    return http.delete(`/mst/third-party/${id}`, data);
  }
  // ________third Part End_________________//

  // ____________DOMAIN_______________ //
  // Domain List
  domain_list(filterQuery) {
    return filterQuery ? http.get(`/mst/domains?where=${JSON.stringify(filterQuery)}`)
      :
      http.get(`/mst/domains?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"name"} ,"no_limit":"True","columns":[${DefaultFilterColumns.DOMAIN_DROUPDOWN_COLUMNS}]}`)
  }
  // Save / Update Domain Data
  save_domain(data) {
    return data.id ? http.put(`/mst/domain/${data.id}`, data)
      :
      http.post('/mst/domains', data)
  }
  // Delete Domain
  delete_domain(id) {
    return http.delete(`/mst/domain/${id}`);
  }

  // Projects List
  projects_list(filterQuery) {
    return filterQuery ? http.get(`/projects/main?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/projects/main?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"project_name"} ,"no_limit":"True"}')
  }


  // Projects Resource Mapping
  project_resource_mapping_list() {
    return http.get(`/resources/resource-project?where={ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True" }`)
  }

  project_resource_mapping_list_columns() {
    return http.get(`/resources/resource-project?where={ "filter": { "status": { "value": "True", "operation": "eq" } }, "no_limit": "True","columns":[${DefaultFilterColumns.RESOURCE_WISE_PROJECT_DROUPDOWN_COLUMNS}] }`)
  }

  // Project By Id
  project_by_id(id) {
    return http.get(`/projects/main/${id}`)
  }

  // Project By Roles
  project_role_master(filterQuery) {
    return http.get(`/projects/role-master?where=${filterQuery}`)
  }

  // Create Role in Project 
  project_role_master_save(data) {
    return http.post(`/projects/role-master`, data)
  }

  // Update Role in Project 
  project_role_master_update(id, data) {
    return http.put(`/projects/role-master/${id}`, data)
  }



  // Save / Update Domain Data
  assign_project_domain(data) {
    return http.post('/projects/project-domains/assign', data)
  }
  // ____________DOMAIN End_______________ //
  //-----------upload resume start----------//
  postResume(data, obj) {

    const progressConfig = {
      onUploadProgress: function (progress) {
        const { loaded, total } = progress
        const percentageProgress = Math.floor((loaded / total) * 100);
        obj.dispatch(updateProgressPer(percentageProgress));
        if (percentageProgress === 100) {
          setTimeout(() => {
            obj.dispatch(updateProgressPer(0));
          }, 1000);
        }

      },
    }
    return formdata_api.post('/recruitment/upload-resume', data, progressConfig)
  }
  //-----------end--------------------------//

  // assign domain third party  List
  third_party_domain(filterQuery) {
    return filterQuery ? http.get(`/mst/third-party?where=${(filterQuery)}`)
      :
      http.get(`/mst/third-party?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True","columns":[${DefaultFilterColumns.THIRD_PARTY_DROUPDOWN_COLUMNS}]}`)
  }
  // Save / Update assign Domain Data
  assign_domain_thirdparty(data) {
    return http.post('/mst/domain-third/assign', data)
  }
  // ____________WORKFLOWS_______________ //
  // workflow List
  workflow_list(filterQuery) {
    return filterQuery ? http.get(`/projects/workflow?where=${JSON.stringify(filterQuery)}`)
      :
      http.get(`/projects/workflow?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"name"} ,"no_limit":"True"}`)
  }
  // Save / Update workflow Data
  save_workflow(data) {
    return data.id ? http.put(`/projects/workflow/${data.id}`, data)
      :
      http.post('/projects/workflow', data)
  }
  // Delete workflow
  delete_workflow(id) {
    return http.delete(`/projects/workflow/${id}`);
  }

  // Status List
  status_list(filterQuery) {
    return filterQuery ? http.get(`/jira-rtms/workflow-status?where=${JSON.stringify(filterQuery)}`)
      :
      http.get(`/jira-rtms/workflow-status?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True","columns":[${DefaultFilterColumns.WORKFLOW_DROUPDOWN_COLUMNS}]}`)
  }

  // Project workflow Status mapping List
  workflow_status_mapping_list(filterQuery) {
    return filterQuery ? http.get(`/projects/workflow-status-mapping?where=${JSON.stringify(filterQuery)}`)
      :
      http.get('/projects/workflow-status-mapping?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"name"} ,"no_limit":"True"}')
  }
  // Assign workflow Data
  assign_project_workflow(data) {
    return data.id ? http.put(`/projects/workflow-status-mapping/${data.id}`, data)
      :
      http.post('/projects/workflow-status-mapping', data)
  }
  // ____________WORKFLOWS End_______________ //

  //__________ThirdParty Credential Start_________//

  //get
  third_party_credential(filterQuery) {
    return http.get(`/mst/third-credential?where=${(filterQuery)}`)
  }
  // Save
  save_third_party_credential(data) {
    return http.post('/mst/third-credential', data)
  }
  //update
  update_third_party_credential(id, data) {
    return http.put(`/mst/third-credential/${id}`, data)
  }
  //__________ThirdParty Credential Edd//

  // ________jobpost Start_______________//
  all_job_post_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/job-post?where=${filterQuery}`);
    } else {
      return http.get(`/mst/job-post?where={"no_limit":True}`);
    }
  }
  job_post_list(filterQuery) {
    return http.get(`/recruitment/job?where=${filterQuery}`);
  }
  job_post_create(data) {
    return http.post(`/recruitment/job`, data);
  }
  job_post_edit(id) {
    return http.get(`/recruitment/job/${id}`);
  }
  job_post_update(id, data) {
    return http.put(`/recruitment/job/${id}`, data);
  }
  job_post_archive(id, data) {
    return http.delete(`/recruitment/job/${id}`, data);
  }
  // ________jobpost End_________________//

  // ________skill Start_______________//
  all_skill_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/skills?where=${filterQuery}`);
    } else {
      return http.get(`/mst/skills?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.SKILL_DROUPDOWN_COLUMNS}]}`);
    }

  }
  skill_list(filterQuery) {
    return http.get(`/mst/skills?where=${filterQuery}`);
  }
  skill_create(data) {
    return http.post(`/mst/skills`, data);
  }
  skill_edit(id) {
    return http.get(`/mst/skill/${id}`);
  }
  skill_update(id, data) {
    return http.put(`/mst/skill/${id}`, data);
  }
  skill_archive(id, data) {
    return http.delete(`/mst/skill/${id}`, data);
  }
  // ________skill End_________________// 

  interview_schedule_status_list(filterQuery) {
    return http.get(`/mst/interview-schedule-status?where=${filterQuery}`);
  }
  interview_schedule_status_create(data) {
    return http.post(`/mst/interview-schedule-status`, data);
  }
  interview_schedule_status_edit(id) {
    return http.get(`/mst/interview-schedule-status/${id}`);
  }
  interview_schedule_status_update(id, data) {
    return http.put(`/mst/interview-schedule-status/${id}`, data);
  }

  // ________industry Start_______________//
  all_industry_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/industry?where=${filterQuery}`);
    } else {
      return http.get(`/mst/industry?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.INDUSTRY_DROUPDOWN_COLUMNS}]}`);
    }

  }
  industry_list(filterQuery) {
    return http.get(`/mst/industry?where=${filterQuery}`);
  }
  industry_create(data) {
    return http.post(`/mst/industry`, data);
  }
  industry_edit(id) {
    return http.get(`/mst/industry/${id}`);
  }
  industry_update(id, data) {
    return http.put(`/mst/industry/${id}`, data);
  }
  industry_archive(id, data) {
    return http.delete(`/mst/industry/${id}`, data);
  }
  // ________industry End_________________//

  // _________Qualification Start_______________//
  all_qualification_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/education-qualification?where=${filterQuery}`);
    } else {
      return http.get(`/mst/education-qualification?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.QUALIFICATION_DROUPDOWN_COLUMNS}]}`);
    }

  }
  qualification_list(filterQuery) {
    return http.get(`/mst/education-qualification?where=${filterQuery}`);
  }
  qualification_create(data) {
    return http.post(`/mst/education-qualification`, data);
  }
  qualification_edit(id) {
    return http.get(`/mst/education-qualification/${id}`);
  }
  qualification_update(id, data) {
    return http.put(`/mst/education-qualification/${id}`, data);
  }
  qualification_archive(id, data) {
    return http.delete(`/mst/education-qualification/${id}`, data);
  }
  // ________qualification End_________________//

  // calling api 
  calling_api(data) {
    return http.post('/tts/click_to_call', data)
  }
  call_disconnect_api(id) {
    return http.get(`/tts/disconnect/${id}`)
  }

  // irrelavant Comment
  irrelavantComment_add(data) {
    return http.put('/jira-rtms/is-irrelevant', data)
  }

  candidate_phone_add(data) {
    return http.post('/candidate/candidate-story-tel', data)
  }
  // epic list for upload resume
  get_epic_list_for_upload_resume(project_id, epic_id) {
    return http.get(`/jira-rtms/issues?where={"filter":{"project_id":{"value":${project_id},"operation": "eq"},"issue_type":{"value":${epic_id},"operation": "eq"}},"no_limit":"True","columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]} `);
  }
  // epic list for upload resume
  epic_list_for_upload_resume(epic_id) {
    return http.get(`/jira-rtms/issues?where={"filter":{"issue_type":{"value":${epic_id},"operation": "eq"}},"no_limit":"True","columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]} `);
  }

  // get category for email
  get_category_by_domain(id) {
    return http.get(`emails/domain-template/${id}`)

  }
  // get emails/template-masters
  get_email_template_master(id) {
    return http.get(`/emails/templates?where={"filter":{"template_type_master_id":{"value":${id},"operation":"eq"}} ,"no_limit":"True"}`)
  }

  //send email api 
  send_email(data) {
    return http.post('/emails/send-email', data)
  }

  // zoom api 
  get_mst_zoom_account() {
    return http.get('/mst/zoom-account')
  }
  //create zoom api 
  create_zoom_meeting(id, data) {
    return http.post(`/zoom/create-meeting/${id}`, data)
  }
  // attachment api 
  create_attachment(data) {
    return http.post(`/file/upload`, data)
  }
  // attachment api 
  create_attachment_ces(data) {
    return formdata_ces_api.post(`/file/upload`, data)
  }
  // api for sovern credentails 
  sovern_credential_list(filterQuery) {
    return http.get(`/recruitment/sovren-credential?where=${filterQuery}`);
  }
  create_sovern(data) {
    return http.post(`/recruitment/sovren-credential`, data)
  }
  update_sovern_status(id, data) {
    return http.put(`/recruitment/sovren-credential/${id}`, data)
  }

  //api for  agent call performance report 
  agent_call_performance_report(filterQuery) {
    return http.get(`/report/call/agent-performance?where=${JSON.stringify(filterQuery)}`)
  }

  //api for resume analysis report 
  resume_analysis_report(filterQuery) {
    return http.get(`/report/resume/analysis?where=${JSON.stringify(filterQuery)}`)
  }

  //Skil Options for DropDown
  skil_dropDown(filterQuery = null) {
    return filterQuery ? http.get(`/mst/skills?where=${JSON.stringify(filterQuery)}`) :
      http.get(`/mst/skills?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"-id"} ,"no_limit":True,"columns":[${DefaultFilterColumns.SKILL_DROUPDOWN_COLUMNS}]}`)
  }

  // ____________Report master Start_______________//
  all_report_master(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/reports?where=${filterQuery}`);
    } else {
      return http.get(`/mst/reports?where={"no_limit":True}`);
    }
  }
  report_master_create(data) {
    return http.post(`/mst/reports`, data);
  }
  report_master_edit(id) {
    return http.get(`/mst/reports/${id}`);
  }
  report_master_update(id, data) {
    return http.put(`/mst/reports/${id}`, data);
  }
  //  ___________Report master End_________________//

  //mapping domain for DropDown
  mapping_domain(filterQuery) {
    return filterQuery ? http.get(`/projects/default-settings?where=${JSON.stringify(filterQuery)}`) :
      http.get('/projects/default-settings?where={"filter":{"status":{"value":"True","operation":"eq"}}, "order_by":{"column":"-id"} ,"no_limit":True}')
  }

  // __________default setting Start_______________//
  all_default_setting_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/projects/default-settings?where=${filterQuery}`);
    } else {
      return http.get(`/projects/default-settings?where={"no_limit":True}`);
    }
  }
  default_setting_list(filterQuery) {
    return http.get(`/projects/default-settings?where=${filterQuery}`);
  }
  default_setting_create(data) {
    return http.post(`/projects/default-settings`, data);
  }
  default_setting_edit(id) {
    return http.get(`/projects/default-setting/${id}`);
  }
  default_setting_update(id, data) {
    return http.put(`/projects/default-setting/${id}`, data);
  }
  default_setting_archive(id, data) {
    return http.delete(`/projects/default-setting/${id}`, data);
  }
  // ________default setting  End_________________//

  project_type_list_option(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/project-type?where=${filterQuery}`);
    } else {
      return http.get(`/mst/project-type?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True}`);
    }
  }

  department_list_option(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/departments?where=${filterQuery}`);
    } else {
      return http.get(`/mst/departments?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True}`);
    }
  }

  post_remind_data(data) {
    return http.post(`/resources/reminders`, data);
  }

  get_reminder_list(filterQuery) {
    return http.get(`/resources/reminders?where=${filterQuery}`);
  }

  update_reminder(id, data) {
    return http.put(`/resources/reminder/${id}`, data);
  }

  get_notification_list(filterQuery) {
    return http.get(`/notifications?where=${filterQuery}`);
  }

  // ________ screening form Start _______________//
  all_screeningform_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/recruitment/default-screen-forms?where=${filterQuery}`);
    } else {
      return http.get(`/recruitment/default-screen-forms?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True}`);
    }
  }
  screening_form_list(filterQuery) {
    return http.get(`/recruitment/default-screen-forms?where=${filterQuery}`);
  }
  screening_form_create(data) {
    return http.post(`/recruitment/default-screen-forms`, data);
  }
  screening_form_edit(id) {
    return http.get(`/recruitment/default-screen-forms/${id}`);
  }
  screening_form_update(id, data) {
    return http.put(`/recruitment/default-screen-forms/${id}`, data);
  }
  screening_form_archive(id, data) {
    return http.delete(`/recruitment/default-screen-forms/${id}`, data);
  }

  //job screening form
  jobpost_screeningForm(data) {
    return http.post(`/recruitment/job-screen-forms`, data);
  }
  jobpost_screeningForm_edit(data) {
    return http.get(`/recruitment/job-screen-forms?where=${data}`);
  }
  jobpost_screeningForm_update(id, data) {
    return http.put(`/recruitment/job-screen-forms/${id}`, data);
  }

  update_job_approval_status(id, data) {
    return http.put(`/recruitment/job/${id}`, data);
  }

  //stort screening form
  story_screeningForm(data) {
    return http.post(`/recruitment/story-screen-forms`, data);
  }

  update_story_screeningForm_data(data) {
    return http.put(`/recruitment/story-screen-form-data/${data.id}`, data.data);
  }

  update_story_screeningForm(data) {
    return http.put(`/recruitment/story-screen-forms/${data.id}`, data.data);
  }
  get_story_screeningForm_byId(story_id) {
    return http.get(`/recruitment/story-screen-forms?where={"filter":{"story_id":{"value":"${story_id}","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.STORY_SCREENING_LIST_COLUMNS}]}`);
  }
  // ________ screening form End _________________//

  //Marking api
  // ________ source type Start _______________//
  all_sourcetype_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/client/mst/source-type?where=${filterQuery}`);
    } else {
      return http.get(`/client/mst/source-type?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True}`);
    }
  }
  source_type_list(filterQuery) {
    return http.get(`/client/mst/source-type?where=${filterQuery}`);
  }
  source_type_create(data) {
    return http.post(`/client/mst/source-type`, data);
  }
  source_type_edit(id) {
    return http.get(`/client/mst/source-type/${id}`);
  }
  source_type_update(id, data) {
    return http.put(`/client/mst/source-type/${id}`, data);
  }
  source_type_archive(id, data) {
    return http.delete(`/client/mst/source-type/${id}`, data);
  }
  // ________ source type End _________________//

  // ________ communication type Start _______________//
  all_communicationtype_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/client/mst/communication-type?where=${filterQuery}`);
    } else {
      return http.get(`/client/mst/communication-type?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True}`);
    }
  }
  communication_type_list(filterQuery) {
    return http.get(`/client/mst/communication-type?where=${filterQuery}`);
  }
  communication_type_create(data) {
    return http.post(`/client/mst/communication-type`, data);
  }
  communication_type_edit(id) {
    return http.get(`/client/mst/communication-type/${id}`);
  }
  communication_type_update(id, data) {
    return http.put(`/client/mst/communication-type/${id}`, data);
  }
  communication_type_archive(id, data) {
    return http.delete(`/client/mst/communication-type/${id}`, data);
  }
  // ________ communication type End _________________//


  // __________Client api Start_______________//
  get_client_list(filterQuery) {
    return http.get(`/client/information?where=${filterQuery}`);
  }
  create_client(data) {
    return http.post(`/client/information`, data);
  }
  get_client_by_id(id) {
    return http.get(`/client/information/${id}`);
  }
  update_client_by_id(id, data) {
    return http.put(`/client/information/${id}`, data);
  }
  archive_client_by_id(id, data) {
    return http.delete(`/client/information/${id}`, data);
  }

  get_master_source_type_list(filterQuery) {
    return http.get(`/client/mst/source-type?where=${filterQuery}`);
  }

  // ________ client api End _________________//

  //--------------Resume parse api---------------------///
  get_resume_parse_list(filterQuery) {
    return http.get(`/recruitment/upload-resume-file-status?where=${filterQuery}`);
  }
  get_resume_parse_by_id(id) {
    return http.get(`/recruitment/upload-resume-file-status/${id}`);
  }

  update_resume_parse_by_id(id, data) {
    return http.put(`/recruitment/upload-resume-file-status/${id}`, data);

  }
  //--------------end -------------------------------///


  get_project_workflow_status(id) {
    return http.get(`/projects/workflow/epic/${id}`);
  }

  // -------------ces api section-------------------//
  technology_list(filterQuery) {
    return ces_api.get(`/mst/technology?where=${filterQuery}`);
  }

  department_list_by_industryId(filterQuery) {
    return ces_api.get(`/mst/industry-departments?where=${filterQuery}`)
  }

  technology_list_by_department_industryId(filterQuery) {
    return ces_api.get(`/mst/industry-department-specialization-map?where=${filterQuery}`)
  }

  questionlevel_list(filterQuery) {
    return ces_api.get(`/mst/question-level?where=${filterQuery}`);
  }

  questionSet_list(filterQuery) {
    return ces_api.get(`/mst/set-tech?where=${filterQuery}`);
  }
  exam_schedule_set_batch_list(filterQuery) {
    return ces_api.get(`/mst/exam-schedule?where=${filterQuery}`);
  }

  ces_batch_list(filterQuery) {
    return ces_api.get(`/mst/batch?where=${filterQuery}`);

  }
  ces_batch_technology_filter_list(filterQuery) {
    return ces_api.post("/mst/batch-tech", filterQuery);

  }

  create_candidate(data) {
    return ces_api.post(`/evaluation/candidate-registration`, data);
  }

  update_candidate(id, data) {
    return ces_api.put(`/evaluation/candidate-registration/${id}`, data);
  }

  get_candidate(filter) {
    return ces_api.get(`/evaluation/candidate-registration?where=${filter}`);
  }
  get_candidate_roles(filter) {
    return ces_api.get(`/mst/tms-roles?where=${filter}`);
  }
  candidate_exam_schedule_create(data) {
    return ces_api.post(`/evaluation/candidate/exam-schedule`, data);
  }
  question_pe_type(filter) {
    return ces_api.get(`/evaluation/questions?where=${filter}`);
  }
  question_type_list(filterQuery) {
    if (filterQuery) {
      return ces_api.get(`/mst/question-type?where=${filterQuery}`);
    } else {
      return ces_api.get(`/mst/question-type?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`);
    }
  }
  get_candidate_user() {
    let data = {
      email: REACT_APP_USEREMAIL,
      password: REACT_APP_USERPASSWORD
    }
    return ces_api.post("/login", data);
  }

  candidate_user_login(token) {
    return ces_api.get("/user", {
      headers: {
        'Authorization': token,
      }
    });
  }

  get_candidate_exam_schedule_list(filter) {
    return ces_api.get(`/evaluation/candidate/exam-schedule?where=${filter}`);
  }
  get_candidate_exam_result_list(filter) {
    return ces_api.get(`/evaluation/candidate/exam-data?where=${filter}`);
  }
  update_candiate_email_from_story(data) {
    return http.post("/candidate/candidate-story-email", data);

  }
  // -------------end ces api section -----------//

  manage_availability(filter) {
    return http.get(`recruitment/interviewer-availability?where=${filter}`);
  }

  // ________interview Start_______________//
  all_interview_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/recruitment/interviewer-details?where=${filterQuery}`);
    } else {
      return http.get(`/recruitment/interviewer-details?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True}`);
    }

  }
  interview_list(filterQuery) {
    return http.get(`/recruitment/interviewer-details?where=${filterQuery}`);
  }
  interview_report_list(filterQuery) {
    return http.get(`/report/daily-interview-schedule?where=${filterQuery}`);

  }
  interview_create(data) {
    return http.post(`/recruitment/interviewer-details`, data);
  }
  interview_edit(id) {
    return http.get(`/recruitment/interviewer-details/${id}`);
  }
  interview_update(id, data) {
    return http.put(`/recruitment/update-interview-status/${id}`, data);
  }
  interview_feedback_update(id, data) {
    return http.put(`/recruitment/interviewer-details/${id}`, data);
  }
  interview_archive(id, data) {
    return http.delete(`/recruitment/interviewer-details/${id}`, data);
  }

  interview_reschedule(id, data) {
    return http.put(`/recruitment/update-interview-status/${id}`, data);
  }
  // ________interview End_________________//

  candidate_list(filter) {
    return http.get(`/candidate/information?where=${filter}`);

  }

  master_schedule_status_list(filter) {
    return http.get(`/mst/interview-schedule-status?where=${filter}`);

  }

  check_manage_avl_list(filter) {
    return http.get(`/recruitment/interviewer-availability?where=${filter}`);

  }

  comment_interviewer_list(filter) {
    return http.get(`/recruitment/interview-schedule-status-log?where=${filter}`);
  }

  test_result_display(story_id) {
    return http.get(`/recruitment/result-display/${story_id}`);
  }


  //-----------manage availability start----------//
  create_availability(data) {
    return http.post(`/recruitment/interviewer-schedule-setting`, data);
  }
  get_availability(filterQuery) {
    return http.get(`/recruitment/interviewer-setting?where=${filterQuery}`);
  }
  getUserDetailsApi() {
    return http.get(`/user`);
  }
  get_availability_details_ById(filterQuery) {
    return http.get(`/recruitment/get-schedule-setting-detail?where=${filterQuery}`);
  }
  edit_availability(id) {
    return http.get(`/recruitment/interviewer-schedule-setting/${id}`);
  }
  update_availability(id, data) {
    return http.put(`/recruitment/interviewer-schedule-setting/${id}`, data);
  }
  availability_by_fromDate(filterQuery) {
    return http.get(`/recruitment/interviewer-setting/schedule-details?where=${filterQuery}`);
  }
  //-----------manage availability end----------//

  resources_technology_dropDown(filterQuery) {
    return http.get(`/resources/interviewer-technology?where=${JSON.stringify(filterQuery)}`);
  }


  // ________Location Start_______________//
  all_location_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/city?where=${filterQuery}`);
    } else {
      return http.get(`/mst/city?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.LOCATION_DROUPDOWN_COLUMNS}]}`);
    }

  }
  location_list(filterQuery) {
    return http.get(`/mst/city?where=${filterQuery}`);
  }
  location_create(data) {
    return http.post(`/mst/city`, data);
  }
  location_edit(id) {
    return http.get(`/mst/city/${id}`);
  }
  location_update(id, data) {
    return http.put(`/mst/city/${id}`, data);
  }
  location_archive(id, data) {
    return http.delete(`/mst/city/${id}`, data);
  }
  // ________Location End_________________// 
  // ----------Evaluation Question Set API------------//
  evaluation_question_set_create(data) {
    return http.post(`/evaluation/questions`, data);

  }
  evaluation_question_set_edit(id) {
    return http.get(`/evaluation/questions/${id}`);

  }
  evaluation_question_set_update(id, data) {
    return http.put(`/evaluation/questions/${id}`, data);

  }

  evaluation_question_set_list(filterQuery) {
    return http.get(`/evaluation/questions?where=${JSON.stringify(filterQuery)}`);

  }

  evaluation_question_attchement_post(data) {
    return formdata_api.post(`/file/evaluation/upload`, data);

  }

  evaluation_review_create(data) {
    return http.post(`/evaluation/review-detail`, data);

  }
  // ----------end------------------------------------//
  get_project_role(filterQuery) {
    if (filterQuery) {
      return http.get(`/projects/role-master?where=${JSON.stringify(filterQuery)}`);
    } else {
      return http.get(`/projects/role-master?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.PROJECT_ROLE_DROUPDOWN_COLUMNS}]}`);
    }
  }

  //-----------add country start----------//
  all_country_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/country?where=${filterQuery}`);
    } else {
      return http.get(`/mst/country?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.COUNTRY_DROUPDOWN_COLUMNS}]}`);
    }
  }
  // eslint-disable-next-line
  country_list(filterQuery) {
    return http.get(`/mst/country?where=${filterQuery}`);
  }
  country_create(data) {
    return http.post(`/mst/country`, data);
  }
  country_edit(id) {
    return http.get(`/mst/country/${id}`);
  }
  country_update(id, data) {
    return http.put(`/mst/country/${id}`, data);
  }
  country_archive(id, data) {
    return http.delete(`/mst/country/${id}`, data);
  }

  //-----------add country ends----------//

  //-----------payment type start----------//

  pymenttype_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/payment-type?where=${filterQuery}`);
    } else {
      return http.get(`/mst/payment-type?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`);
    }
  }

  payment_type_create(data) {
    return http.post(`/mst/payment-type`, data);
  }
  payment_type_edit(id) {
    return http.get(`/mst/payment-type/${id}`);
  }
  payment_type_update(id, data) {
    return http.put(`/mst/payment-type/${id}`, data);
  }


  //-----------payment type ends----------//




  //-----------Meeting Provider start----------//

  meeting_provider_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/meeting-provider?where=${filterQuery}`);
    } else {
      return http.get(`/mst/meeting-provider?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`);
    }
  }

  meeting_provider_create(data) {
    return http.post(`/mst/meeting-provider`, data);
  }
  meeting_provider_edit(id) {
    return http.get(`/mst/meeting-provider/${id}`);
  }
  meeting_provider_update(id, data) {
    return http.put(`/mst/meeting-provider/${id}`, data);
  }


  //-----------Meeting provider ends----------//



  // ________vacancy Start_______________//
  all_vacancy_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/vacancy?where=${filterQuery}`);
    } else {
      return http.get(`/mst/vacancy?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.VACANCY_DROUPDOWN_COLUMNS}]}`);
    }

  }
  vacancy_list(filterQuery) {
    return http.get(`/mst/vacancy?where=${filterQuery}`);
  }
  vacancy_create(data) {
    return http.post(`/mst/vacancy`, data);
  }
  vacancy_edit(id) {
    return http.get(`/mst/vacancy/${id}`);
  }
  vacancy_update(id, data) {
    return http.put(`/mst/vacancy/${id}`, data);
  }
  vacancy_archive(id, data) {
    return http.delete(`/mst/vacancy/${id}`, data);
  }
  // ________vacancy End_________________// 

  // ________state Start_______________//
  all_state_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/state?where=${filterQuery}`);
    } else {
      return http.get(`/mst/state?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.STATE_DROUPDOWN_COLUMNS}]}`);
    }

  }
  state_list(filterQuery) {
    return http.get(`/mst/state?where=${filterQuery}`);
  }
  state_create(data) {
    return http.post(`/mst/state`, data);
  }
  state_edit(id) {
    return http.get(`/mst/state/${id}`);
  }
  state_update(id, data) {
    return http.put(`/mst/state/${id}`, data);
  }
  state_archive(id, data) {
    return http.delete(`/mst/state/${id}`, data);
  }

  // ________state ends_______________//

  // ________Hiring Request Start_______________//
  all_hiring_request_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/recruitment/hiring-request?where=${filterQuery}`);
    } else {
      return http.get(`/recruitment/hiring-request?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.HIRING_REQUEST_DROUPDOWN_COLUMNS}]}`);
    }

  }
  hiring_request_list(filterQuery) {
    return http.get(`/recruitment/hiring-request?where=${filterQuery}`);
  }
  hiring_request_create(data) {
    return http.post(`/recruitment/hiring-request`, data);
  }
  hiring_request_edit(id) {
    return http.get(`/recruitment/hiring-request/${id}`);
  }
  hiring_request_update(id, data) {
    return http.put(`/recruitment/hiring-request/${id}`, data);
  }
  hiring_request_archive(id, data) {
    return http.delete(`/recruitment/hiring-request/${id}`, data);
  }

  hiring_request_soft_delete(data) {
    return http.put(`/recruitment/hiring-job-soft-delete`, data)
  }

  // ________Hiring Request ends_______________//

  // ________Working Mode Start_______________//
  all_workingmode_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/working-modes?where=${filterQuery}`);
    } else {
      return http.get(`/mst/working-modes?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.WORKINGMODE_DROUPDOWN_COLUMNS}]}`);
    }
  }
  workingmode_list(filterQuery) {
    return http.get(`/mst/working-modes?where=${filterQuery}`);
  }
  workingmode_create(data) {
    return http.post(`/mst/working-modes`, data);
  }
  workingmode_edit(id) {
    return http.get(`/mst/working-mode/${id}`);
  }
  workingmode_update(id, data) {
    return http.put(`/mst/working-mode/${id}`, data);
  }
  workingmode_archive(id, data) {
    return http.delete(`/mst/working-modes/${id}`, data);
  }
  // ________Working Mode End_________________// 

  // ________more specification Mode Start_______________//
  all_more_specification_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/specification?where=${filterQuery}`);
    } else {
      return http.get(`/mst/specification?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.MORE_SPECIFICATION_DROUPDOWN_COLUMNS}]}`);
    }

  }
  more_specification_list(filterQuery) {
    return http.get(`/mst/specification?where=${filterQuery}`);
  }
  more_specification_create(data) {
    return http.post(`/mst/specification`, data);
  }
  more_specification_edit(id) {
    return http.get(`/mst/specification/${id}`);
  }
  more_specification_update(id, data) {
    return http.put(`/mst/specification/${id}`, data);
  }
  more_specification_archive(id, data) {
    return http.delete(`/mst/specification/${id}`, data);
  }
  // ________more specification End_________________// 

  // ________Urgency Type Start_______________//
  all_urgencytype_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/urgency?where=${filterQuery}`);
    } else {
      return http.get(`/mst/urgency?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.URGENCY_DROUPDOWN_COLUMNS}]}`);
    }

  }
  urgencytype_list(filterQuery) {
    return http.get(`/mst/urgency?where=${filterQuery}`);
  }
  urgencytype_create(data) {
    return http.post(`/mst/urgency`, data);
  }
  urgencytype_edit(id) {
    return http.get(`/mst/urgency/${id}`);
  }
  urgencytype_update(id, data) {
    return http.put(`/mst/urgency/${id}`, data);
  }
  urgencytype_archive(id, data) {
    return http.delete(`/mst/urgency/${id}`, data);
  }
  // ________Urgency Type End_________________// 

  // ________Core settings Start_______________//
  all_coresettings_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/core-setting/tms-settings?where=${filterQuery}`);
    } else {
      return http.get(`/core-setting/tms-settings?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.CORESETTINGS_DROUPDOWN_COLUMNS}]}`);
    }

  }
  coresettings_list(filterQuery) {
    return http.get(`/core-setting/tms-settings?where=${filterQuery}`);
  }
  coresettings_create(data) {
    return http.post(`/core-setting/tms-settings`, data);
  }
  coresettings_edit(id) {
    return http.get(`/core-setting/tms-settings/${id}`);
  }
  coresettings_update(id, data) {
    return http.put(`/core-setting/tms-settings/${id}`, data);
  }
  coresettings_archive(id, data) {
    return http.delete(`/core-setting/tms-settings/${id}`, data);
  }
  // ________Core settings End_________________// 

  // ________hiring file upload start_________________// 
  hiring_attach_file(data) {
    return formdata_api.post(`/file/upload/multiple`, data);
  }

  hiring_attach_remove(data) {
    return http.put(`/file/remove`, data);
  }
  // ________Type of Service Start_______________//
  all_servicetype_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/service-types?where=${filterQuery}`);
    } else {
      return http.get(`/mst/service-types?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.SERVICETYPE_DROUPDOWN_COLUMNS}]}`);
    }

  }
  servicetype_list(filterQuery) {
    return http.get(`/mst/service-types?where=${filterQuery}`);
  }
  servicetype_create(data) {
    return http.post(`/mst/service-types`, data);
  }
  servicetype_edit(id) {
    return http.get(`/mst/service-type/${id}`);
  }
  servicetype_update(id, data) {
    return http.put(`/mst/service-type/${id}`, data);
  }
  servicetype_archive(id, data) {
    return http.delete(`/mst/service-type/${id}`, data);
  }
  // ________Type of Service End_________________// 


  // ________Job Location Start_______________//
  all_job_location_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/job-locations?where=${filterQuery}`);
    } else {
      return http.get(`/mst/job-locations?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.JOB_LOCATION_DROUPDOWN_COLUMNS}]}`);
    }

  }
  job_location_list(filterQuery) {
    return http.get(`/mst/job-locations?where=${filterQuery}`);
  }
  job_location_create(data) {
    return http.post(`/mst/job-locations`, data);
  }
  job_location_edit(id) {
    return http.get(`/mst/job-location/${id}`);
  }
  job_location_update(id, data) {
    return http.put(`/mst/job-location/${id}`, data);
  }
  job_location_archive(id, data) {
    return http.delete(`/mst/job-location/${id}`, data);
  }
  // ________Job Location End_________________// 

  // ________Hiring Mode Start_______________//

  all_hiring_mode_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/hiring-modes?where=${filterQuery}`);
    } else {
      return http.get(`/mst/hiring-modes?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.HIRING_MODE_DROUPDOWN_COLUMNS}]}`);
    }

  }
  hiring_mode_list(filterQuery) {
    return http.get(`/mst/hiring-modes?where=${filterQuery}`);
  }
  hiring_mode_create(data) {
    return http.post(`/mst/hiring-modes`, data);
  }
  hiring_mode_edit(id) {
    return http.get(`/mst/hiring-mode/${id}`);
  }
  hiring_mode_update(id, data) {
    return http.put(`/mst/hiring-mode/${id}`, data);
  }
  hiring_mode_archive(id, data) {
    return http.delete(`/mst/hiring-mode/${id}`, data);
  }
  // ________Hiring Mode End_________________//


  // ________Specialisations Start_______________//

  all_specialisations_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/specialization?where=${filterQuery}`);
    } else {
      return http.get(`/mst/specialization?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.SPECIALISATIONS_DROUPDOWN_COLUMNS}]}`);
    }

  }
  specialisations_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/specialization?where=${filterQuery}`);
    } else {
      return http.get(`/mst/specialization?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.SPECIALISATIONS_DROUPDOWN_COLUMNS}]}`);
    }

  }
  specialisations_create(data) {
    return http.post(`/mst/specialization`, data);
  }
  specialisations_edit(id) {
    return http.get(`/mst/specialization/${id}`);
  }
  specialisations_update(id, data) {
    return http.put(`/mst/specialization/${id}`, data);
  }
  specialisations_archive(id, data) {
    return http.delete(`/mst/specialization/${id}`, data);
  }
  // ________specialization End_________________//


  // ________Hiring Mode End_________________//

  // ________Screening Master Start_______________//

  all_screening_master_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/test-type-list?where=${filterQuery}`);
    } else {
      return http.get(`/mst/test-type-list?where={"filter":{"status":{"value":1,"operation":"eq"}},"no_limit":"True"}`);
    }

  }
  screening_master_list(filterQuery) {
    return http.get(`/mst/test-type-list?where=${filterQuery}`);
  }
  screening_master_create(data) {
    return http.post(`/mst/test-type`, data);
  }
  screening_master_edit(id) {
    return http.get(`/mst/test-type/${id}`);
  }
  screening_master_update(id, data) {
    return http.put(`/mst/test-type/${id}`, data);
  }
  screening_master_archive(id, data) {
    return http.delete(`/mst/test-type/${id}`, data);
  }
  // ________Screening Master End_________________//
  //-----------purchased Packeges type start----------//

  purchased_Packeges_list(filterQuery) {
    return http.get(`/mst/purchasedpackage?where=${filterQuery}`);
  }

  purchased_Packeges_create(data) {
    return http.post(`/mst/purchasedpackage`, data);
  }
  purchased_Packeges_edit(id) {
    return http.get(`/mst/purchasedpackage/${id}`);
  }
  purchased_Packeges_update(id, data) {
    return http.put(`/mst/purchasedpackage/${id}`, data);
  }


  //-----------payment type ends----------//
  // ________Currency Start_______________//

  all_currency_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/currency?where=${filterQuery}`);
    } else {
      return http.get(`/mst/currency?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.CURRENCY_DROUPDOWN_COLUMNS}]}`);
    }

  }
  currency_list(filterQuery) {
    return http.get(`/mst/currency?where=${filterQuery}`);
  }
  currency_create(data) {
    return http.post(`/mst/currency`, data);
  }
  currency_edit(id) {
    return http.get(`/mst/currency/${id}`);
  }
  currency_update(id, data) {
    return http.put(`/mst/currency/${id}`, data);
  }
  currency_archive(id, data) {
    return http.delete(`/mst/currency/${id}`, data);
  }
  // ________Currency End_________________//

  // ________Industry - Department Start_______________//

  all_industry_department_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/industry-department?where=${filterQuery}`);
    } else {
      return http.get(`/mst/industry-department?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.INDUSTRY_DEPARTMENT_DROUPDOWN_COLUMNS}]}`);
    }

  }
  industry_department_list(filterQuery) {
    return http.get(`/mst/industry-department?where=${filterQuery}`);
  }
  industry_department_create(data) {
    return http.post(`/mst/industry-department`, data);
  }
  // { "filter": {"status": {"value": "True", "operation": "eq" } },"no_limit": True}
  industry_department_edit(id) {
    return http.get(`/mst/industry-department?where={"filter":{"industry_id":{"value": ${id},"operation": "eq"},"status":{"value": "True","operation": "eq"}}}`);
  }
  industry_department_update(id, data) {
    return http.post(`/mst/industry-department?where={"filter":{"industry_id":{"value": ${id},"operation": "eq"},"status":{"value": "True","operation": "eq"}}}`, data);
  }
  industry_department_archive(id, data) {
    return http.delete(`/mst/industry-department/${id}`, data);
  }
  // ________Industry - Department End_________________//

  // ________contract type Start_______________//

  all_contracttype_List(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/contract-type?where=${filterQuery}`);
    } else {
      return http.get(`/mst/contract-type?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.CONTRACT_TYPE_DROUPDOWN_COLUMNS}]}`);
    }

  }
  contracttype_List(filterQuery) {
    return http.get(`/mst/contract-type?where=${filterQuery}`);
  }
  contracttype_create(data) {
    return http.post(`/mst/contract-type`, data);
  }
  contracttype_edit(id) {
    return http.get(`/mst/contract-type/${id}`);
  }
  contracttype_update(id, data) {
    return http.put(`/mst/contract-type/${id}`, data);
  }
  contracttype_archive(id, data) {
    return http.delete(`/mst/contract-type/${id}`, data);
  }
  // ________Contract Type End_________________// 

  // ________industry-Department-Specialisation Start_______________//

  all_industryDepartmentSpecialisation_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/industry-department-specialization-map?where=${filterQuery}`);
    } else {
      return http.get(`/mst/industry-department-specialization-map?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.INDUSTRYDEPARTMENTSPECIALISATION_DROUPDOWN_COLUMNS}]}`);
    }

  }
  industryDepartmentSpecialisation_list(filterQuery) {
    return http.get(`/mst/industry-department-specialization-map?where=${filterQuery}`);
  }
  industryDepartmentSpecialisation_create(data) {
    return http.post(`/mst/industry-department-specialization-map`, data);
  }
  industryDepartmentSpecialisation_edit(id) {
    return http.get(`/mst/industry-department-specialization-map/${id}`);

  }
  industryDepartmentSpecialisation_update(id, data) {
    return http.put(`/mst/industry-department-specialization-map/${id}`, data);
  }
  industryDepartmentSpecialisation_archive(id, data) {
    return http.delete(`/mst/industry-department-specialization-map/${id}`, data);
  }
  // ________industry-Department-Specialisation End_________________//

  // ________ Hirring request step start _________________// 

  client_list_all(filterQuery) {
    if (filterQuery) {
      return http.get(`/client/information?where=${filterQuery}`);
    } else {
      return http.get(`/client/information?where={"no_limit":True,"columns":[${DefaultFilterColumns.CLIENT_DROPDOWN_COLUMNS_H}]}`);
    }
  }

  industry_list_by_client(filterQuery) {
    return http.get(`/client/client-dropdown?client_id=${filterQuery}`);
  }

  department_list_by_clientInd(filterQuery) {
    if (filterQuery) {
      let client_id = filterQuery.client_id
      let industry_id = filterQuery.industry_id
      return http.get(`/client/client-dropdown?client_id=${client_id}&industry_id=${industry_id}`);
    }
  }

  specialization_list_by_clientIndDepartment(filterQuery) {
    if (filterQuery) {
      let client_id = filterQuery.client_id
      let industry_id = filterQuery.industry_id
      let department_id = filterQuery.department_id
      return http.get(`/client/client-dropdown?client_id=${client_id}&industry_id=${industry_id}&department_id=${department_id}`);
    }
  }

  skill_list_by_clientIndDepartmentSpecil(filterQuery) {
    if (filterQuery) {
      // let client_id = filterQuery.client_id
      // let industry_id = filterQuery.industry_id
      // let department_id = filterQuery.department_id
      return http.get(`/mst/industry-department-specialization?where=${filterQuery}`);
    }
  }

  all_template_category(filterQuery) {
    if (filterQuery) {
      return http.get(`/emails/template-category?where=${filterQuery}`);
    } else {
      return http.get(`/emails/template-category?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.CURRENCY_DROUPDOWN_COLUMNS}]}`);
    }

  }
  template_category_list(filterQuery) {
    return http.get(`/emails/template-category?where=${filterQuery}`);
  }
  template_category_create(data) {
    return http.post(`/emails/template-category`, data);
  }
  template_category_edit(id) {
    return http.get(`/emails/template-category/${id}`);
  }
  template_category_update(id, data) {
    return http.put(`/emails/template-category/${id}`, data);
  }
  template_category_archive(id, data) {
    return http.delete(`/emails/template-category/${id}`, data);
  }



  all_template_category_for_email(filterQuery) {
    if (filterQuery) {
      return http.get(`/emails/template-category?where=${filterQuery}`);
    } else {
      return http.get(`/emails/template-category?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True}`);
    }
  }

  getAllHeads(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/interview-feedback-head?where=${filterQuery}`);
    } else {
      return http.get(`/mst/interview-feedback-head?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`);
    }
  }

  getClientInterviewer(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/interview-feedback-head?where=${filterQuery}`);
    } else {
      return http.get(`/mst/interview-feedback-head?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`);
    }
  }

  // ________ Hirring request step start _________________// 


  // ________Client Resource Start_______________//

  all_client_resource_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/client/resources?where=${filterQuery}`);
    } else {
      return http.get(`/client/resources?where={"filter":{"status":{"value":"True","operation":"eq"}},"no_limit":True,"columns":[${DefaultFilterColumns.CLIENT_RESOURCE_DROUPDOWN_COLUMNS}]}`);
    }

  }
  client_resource_list(filterQuery) {
    return http.get(`/client/resources?where=${filterQuery}`);
  }
  client_resource_create(data) {
    return http.post(`/client/resources`, data);
  }
  client_resource_edit(id) {
    return http.get(`/client/resources/${id}`);
  }
  client_resource_update(id, data) {
    return http.put(`/client/resources/${id}`, data);
  }
  client_resource_archive(id, data) {
    return http.delete(`/client/resources/${id}`, data);
  }
  client_list_for_resource(id) {
    return http.get(`/client/information?where={"no_limit":True,"columns":["id", "name", "is_internal"]}`);
  }
  client_resource_skill_list(id) {
    return http.get(`/client/industry-skills?where={"no_limit":True}`);
  }
  client_resource_status(id, data) {
    return http.put(`/client/resource/status-update/${id}`, data);
  }


  // ________ client resource End_________________//


  //-----------industry dep specializaton adn skill mapping start----------//

  indus_dep_spec_skill_list(filterQuery) {
    return http.get(`/mst/industry-department-specialization?where=${filterQuery}`);
  }

  indus_dep_spec_skill_create(data) {
    return http.post(`/mst/industry-department-specialization`, data);
  }
  indus_dep_spec_skill_edit(id) {
    return http.get(`/mst/industry-department-specialization/${id}`);
  }
  indus_dep_spec_skill_update(id, data) {
    return http.put(`/mst/industry-department-specialization/${id}`, data);
  }
  industry_depspecialization_list_data(filterQuery) {
    return http.get(`/mst/industry-department-specialization-map?where=${filterQuery}`);
  }
  // /api/recruitment/hiring-interview-feedback
  // Interviewer Feedback form get api

  interviewer_feedback_list(filterQuery) {
    return http.get(`/recruitment/hiring-feedback-by-story/${filterQuery}`);

  }
  //feed back heads
  get_feedback_heads(id) {
    return http.get(`/recruitment/hiring-interview-feedback-heads-story-wise/${id}?where={"no_limit":"true","order_by":{"column":"-id"}, "columns":["interview_feedback_head__id","interview_feedback_head__head_name"]}`);
  }

  interview_feedback_add(data) {
    return http.post('/recruitment/hiring-interview-feedback-details', data)
  }

  //-----------Client api's start----------//

  client_crud_list(filterQuery) {
    return http.get(`/client/information?where=${filterQuery}`);
  }

  client_crud_create(data) {
    return http.post(`/client/information`, data);
  }
  client_crud_edit(id) {
    return http.get(`/client/information/${id}`);
  }
  client_crud_update(id, data) {
    return http.put(`/client/information/${id}`, data);
  }

  industry_department_list_data(filterQuery) {
    return http.get(`/mst/industry-department?where=${filterQuery}`);
  }

  // candidate screening test creation
  ces_candidate_test_cteation(data) {
    return ces_api.post("/mst/hiring/create-set-exam-schedule", data);
  }

  ces_qusetionlist_by_testtype(filterQuery) {
    return ces_api.get(`/evaluation/question-test-types?where=${JSON.stringify(filterQuery)}`);

  }

  question_create(data) {
    return ces_api.post(`/evaluation/save-question-for-test-types`, data)
  }


  //  ces api
  // hiring question screening test get 
  question_type(filterQuery) {
    return ces_api.get(`mst/question-type?where=${filterQuery}`);
  }

  screen_flow_test_api(data) {
    return http.post(`/mst/screen-flow-management`, data);

  }

  get_screen_flow_test_api(filter) {
    return http.get(`/mst/screen-flow-management?where=${filter}}`);
  }

  // agent flow api start
  // screen test on the bases on  story id
  get_screen_test(filterQuery) {
    if (filterQuery) {
      return http.get(`recruitment/hiring-story-screenflow?where=${filterQuery}`)
    } else {
      return http.get(`recruitment/hiring-story-screenflow?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`)
    }
  }

  save_candidate_schedule_data(data) {
    return ces_api.post(`/evaluation/exam-schedule/by-agent`, data)
  }

  get_qusetion_details_by_id(id) {
    return ces_api.get(`/evaluation/questions/get-questions/${id}`)
  }

  exam_post_submit(data) {
    return ces_api.post(`/evaluation/candidate/exam-data`, data);
  }

  exam_get_data(id) {
    return ces_api.get(`/evaluation/candidate/exam-data?where={"filter":{"candidate_exam_schedule_id":{"value":${id},"operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`)
  }

  get_exam_schedule_status(schedule_id, data) {
    return ces_api.put(`/evaluation/candidate/exam-schedule-status/${schedule_id}`, { schedule_status: data });
  }

  save_skip_data(id, data) {
    return http.put(`/recruitment/hiring-story-screenflow-skip/${id}`, data);
  }
  // agent flow api end

  //-----------resume source start----------//

  resume_source_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/resume-source?where=${filterQuery}`);
    } else {
      return http.get(`/mst/resume-source?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`);
    }
  }

  resume_source_create(data) {
    return http.post(`/mst/resume-source`, data);
  }
  resume_source_edit(id) {
    return http.get(`/mst/resume-source/${id}`);
  }
  resume_source_update(id, data) {
    return http.put(`/mst/resume-source/${id}`, data);
  }


  //-----------resume source ends----------//
  // salary budget

  //----------------resume template start-------//

  template() {
    return http.get("/recruitment/source-templates")
  }
  //--------------resume termplate end --------//

  job_budget_revise(data) {
    return http.post(`/recruitment/job-budget-revise`, data)
  }

  job_budget_log(filterQuery) {
    return http.get(`recruitment/job-budget-log?where=${filterQuery}`)
  }

  //-----------Resume Format type type start----------//

  resume_format_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/resume-format?where=${filterQuery}`);
    } else {
      return http.get(`/mst/resume-format?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`);
    }
  }

  resume_format_create(data) {
    return http.post(`/mst/resume-format`, data);
  }
  resume_format_edit(id) {
    return http.get(`/mst/resume-format/${id}`);
  }
  resume_format_update(id, data) {
    return http.put(`/mst/resume-format/${id}`, data);
  }

  industry_list_ces(filterQuery) {
    return ces_api.get(`/mst/industry?where=${filterQuery}`);
  }

  department_list_ces(filterQuery) {
    return ces_api.get(`/mst/departments?where=${filterQuery}`);
  }


  //-----------Resume Format type type ends----------//

  //------------ Reset Password or Change Password ------//

  change_password = (data, id) => {
    return http.put(`/update-password/${id}`, data)
  }

  //------------ Reset Password or Change Password End ------//

  hiring_request_level_updates(id, data) {
    return http.put(`/recruitment/update-hiring-request-level/${id}`, data)
  }


  //-----------Master Status Category starts----------//

  master_status_category_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/jira-rtms/workflow-status-category?where=${filterQuery}`);
    } else {
      return http.get(`/jira-rtms/workflow-status-category?where={"filter":{"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`);
    }
  }

  master_status_category_create(data) {
    return http.post(`/jira-rtms/workflow-status-category`, data);
  }

  master_status_category_edit(id) {
    return http.get(`/jira-rtms/workflow-status-category/${id}`);
  }

  master_status_category_update(id, data) {
    return http.put(`/jira-rtms/workflow-status-category/${id}`, data);
  }

  //-----------Master Status Category ends----------//


  // you got the api of bio metric then u can  use that api here or this previous working than  u can use it
  create_bio_profile(data) {
    return http.post(`/bio_metric/profile`, data);
  }

  //Daily WOrklog summary
  daily_worklog_summary_list(filterQuery) {
    if (filterQuery) {
      return http.get(`resources/daily-work-summary?where=${filterQuery}`);
    } else {
      return http.get(`resources/daily-work-summary?where={"filter":{ "status":{"value":True, "operation":"eq"}}, "no_limit":True}`);
    }
  }
  
  daily_worklog_summary_team_efficiency(filterQuery) {
    if (filterQuery) {
      return http.get(`resources/show-team-efficiency?where=${filterQuery}`);
    } else {
      return http.get(`resources/show-team-efficiency?where={"filter":{ "status":{"value":True, "operation":"eq"}}, "no_limit":True, "date":${moment().format("YYYY-DD-MM")}}`);
    }
  }
  //Add Worklog Summary
  // https://dev-tm.mightcode.com/api/resources/daily-work-summary
  add_worklog_summary(data) {
    return http.post('/resources/daily-work-summary', data)
  }


  //Activity List
  activity_list(filterQuery) {
    if (filterQuery) {
      return http.get(`/mst/activity?where=${filterQuery}`);
    } else {
      return http.get(`/mst/activity?where={"filter":{ "status":{"value":True, "operation":"eq"}}, "no_limit":True}`);
    }
  }

  email_tamplate_listing(filterQuery) {
    if (filterQuery)
      return http.get(`/emails/templates?where=${filterQuery}`)
  }

  email_tamplate_listing_update(id, data) {
    return http.put(`/emails/template/${id}`, data);
  }

  //create email template 
  emailTemplateCreate(data) {
    return http.post(`/emails/templates`, data);
  }

  all_email_template(filterQuery) {
    if (filterQuery) {
      return http.get(`/emails/templates?where=${filterQuery}`);
    } else {
      return http.get(`/emails/templates?where={"order_by":{"column":"-id"},"skip":0, "no_limit":True}`);
    }
  }

  email_template_byid(id) {
    return http.get(`/emails/template/${id}`);
  }

  email_template_update(id, data) {
    return http.put(`/emails/template/${id}`,data);
  }


  worklog_summary_edit(id) {
    return http.get(`/resources/daily-work-summary/${id}`);
  }

  worklog_summary_update(id, data) {
    return http.put(`/resources/daily-work-summary/${id}`, data);
  }

  worklog_summary_delete(id) {
    return http.delete(`/resources/daily-work-summary/${id}`);
  }



}

export default new DataService();