import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { STATUSES } from "../common-status/constant";
import dataService from "../services/data.service";

export const getTemplateCategory = createAsyncThunk("get/templatecategory", async (data) => {
    const res = await dataService.all_template_category_for_email(data);
    const resp = res.data;
    return resp;
}
);

export const emailTempleteCreate = createAsyncThunk("email-template/create", async (data) => {
    const res = await dataService.emailTemplateCreate(data);
    return res.data;
});

export const getAllEmailTemplates = createAsyncThunk("get/all-email-templates",async (data) => {
        const res = await dataService.all_email_template(data);
        const resp = res.data;
        return resp;
    }
);

export const emailTemplateById = createAsyncThunk("email_template/edit", async (id) => {
    const res = await dataService.email_template_byid(id);
    return res.data;
});

export const emailTemplateUpdate = createAsyncThunk("email_template/update", async (data) => {
    const res = await dataService.email_template_update(data.ID, data.addValues);
    return res.data;
});


const emailTemplateSlice = createSlice({
    name: "emailTemplateSlice",
    initialState: {
        status: 'idle',
        error: '',
        emailTemplateCategory: [],
        createEmailTemplete: [],
        emailTemplates:[],
        emailTemplate_byid:[],
        updateEmailTemplate:[],
    },
    reducers: {},
    extraReducers(builder) {
        builder
            //get 
            .addCase(getTemplateCategory.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getTemplateCategory.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.emailTemplateCategory = action.payload;

            })
            .addCase(getTemplateCategory.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error

            })

            //create email templete
            .addCase(emailTempleteCreate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(emailTempleteCreate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_createEmailTemplete`;
                state.createEmailTemplete = action.payload;
            })
            .addCase(emailTempleteCreate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

            //update email templete
            .addCase(emailTemplateUpdate.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(emailTemplateUpdate.fulfilled, (state, action) => {
                state.status = `${STATUSES.SUCCESS}_UpdateEmailTemplete`;
                state.updateEmailTemplate = action.payload;
            })
            .addCase(emailTemplateUpdate.rejected, (state, action) => {
                state.status = STATUSES.FAILURE
                state.error = action.error
            })

              //get email templetes
              .addCase(getAllEmailTemplates.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(getAllEmailTemplates.fulfilled, (state, action) => {
                state.status = STATUSES.SUCCESS;
                state.emailTemplates = action.payload;

            })
            .addCase(getAllEmailTemplates.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

                //get email templetes by id
                .addCase(emailTemplateById.pending, (state, action) => {
                    state.status = STATUSES.LOADING;
                })
                .addCase(emailTemplateById.fulfilled, (state, action) => {
                    state.status = STATUSES.SUCCESS;
                    state.emailTemplate_byid = action.payload;
    
                })
                .addCase(emailTemplateById.rejected, (state, action) => {
                    state.status = STATUSES.FAILURE;
                    state.error = action.error
                })
    },
});


export default emailTemplateSlice.reducer;