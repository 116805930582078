import './manage-credential-heads/manageCredential.scss'
import React, { useState, useEffect, useMemo } from 'react'
import dataService from '../../redux/services/data.service';
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { toast, ToastContainer } from "react-toastify";
import { TablePagination,Tooltip } from "@mui/material";
import LoaderForm from '../../components/common-function/LoaderForm';
import UcActionComponent from '../../components/common-function/UcActionComponent';
import TablePaginationActions from '../../components/TablePaginationActions';
import Filter from '../../components/Filter';
import { allcredentialHeads, getMyCredentials,defaultresourceColumn } from '../../redux/features/cedentialHeadSlice';
import { useDispatch, useSelector } from 'react-redux';
import LocalStorageDataService from '../../components/common-function/GetLocalstorage'
import { STATUSES } from "../../redux/common-status/constant";
import DeleteDialogModal from '../../components/common-function/DeleteDialogModal';
import { ArrowBack } from '@mui/icons-material';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import { getGridStringOperators } from '@mui/x-data-grid';

const default_resourcecred_Columns = LocalStorageDataService.get_default_resource_credential_column()

export default function MyResourceCredential() {



  let user = LocalStorageDataService.userDetail();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { myCredentialsList, credentialHeadList, status, error,defaultresourceColumns } = useSelector(state => state.credentialHead)
  const { data: list } = myCredentialsList;
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  //pagination 
  const [currentPage, setCurrentPage] = useState(0);
  const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"},"is_global":{"value":"False","operation":"eq"}},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MY_RESOURCE_CREDENTIAL_COLUMNS}]}`)
  const [pageSize, setPageSize] = useState(25)
  const [limit, setLimit] = useState(25)
  const [filterHeadList, setFilterHeadList] = useState([]);

  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [options, setOptions] = useState({})
  const [defaultValue, setDefaultValue] = useState({})

  //columns visibility
  // const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_resourcecred_Columns && default_resourcecred_Columns.all_resource_cred_column ? default_resourcecred_Columns.all_resource_cred_column : {
    id: false,
  });
  
  const [reload, setReload] = useState(false);
  const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

  const setKeyForList = (obj) => {
    let data;
    if (default_resourcecred_Columns) {
      data = { 'all_resource_cred_column': obj, 'reported_resourcecred_column': default_resourcecred_Columns.reported_resourcecred_column, 'cred_column': default_resourcecred_Columns.cred_column, 'task_column': default_resourcecred_Columns.task_column }
    } else {
      data = { 'all_resource_cred_column': obj }
    }
    if (defaultresourceColumns.reported_resourcecred_column) {
      data['reported_resourcecred_column'] = defaultresourceColumns.reported_resourcecred_column
    }
    if (defaultresourceColumns.cred_column) {
      data['cred_column'] = defaultresourceColumns.cred_column
    }
    if (defaultresourceColumns.task_column) {
      data['task_column'] = defaultresourceColumns.task_column
    }
    dispatch(defaultresourceColumn(data))
  }

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
      setShowLoader(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    dispatch(getMyCredentials(filterQuery));
    dispatch(allcredentialHeads())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (status === `${STATUSES.SUCCESS}_credentialHeadList` && filterHeadList && filterHeadList.length === 0 && credentialHeadList && credentialHeadList.data) {
    setShowLoader(false)
    let array = []
    credentialHeadList.data && credentialHeadList.data.rows.map(e => { return array.push({ value: e.id, label: e.head_name }) })
    array.length > 0 && setFilterHeadList(array)
    array.length > 0 && setOptions({ ...options, credentails_head_id: array })
  }


  const handleDelete = async (id) => {
    const request = { status: 3 }
    setOpen(true)
    if (open === true) {
      const response = await dataService.deleteResourceCredential(id, request)
      if (response) {
        toast.success("Record Deleted Successfully!");
        setOpen(false)
        dispatch(getMyCredentials(filterQuery))
      }
    }
  }

  const handleClickOpen = (type, id) => {
    setDeleteId(id)
    if (type === true) {
      handleDelete(id);
    }
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const onEdit = async (id) => {
    navigate(`/edit-resource-credential/${id}`, { state: "myResource" })
  }

  const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
  const actionColumn = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      filterable: false,
    },
    {
      field: "credentails_head_id",
      headerName: "Credentail Head",
      type: 'singleSelect',
      width: 230,
      valueOptions: filterHeadList,
      renderCell: (params) => {
        return (
          <div>
            {params.row.credentails_head}
          </div>
        );
      },
    },
    {
      field: "resource_id",
      headerName: "Resource Name",
      width: 200,
      filterable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.resource}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      filterOperators,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status === true ? 'Active' : 'Inactive'}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: "120",
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent moduleName="resource-credential" rowid={params.row.id} editLinkUrl="" viewLinkUrl={`/view-credential/${params.row.id}`} isDelete={true} editButton={onEdit} viewButton={false} deleteButton={handleClickOpen} />
          </div>
        );
      },
    },
  ];

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    //setLimit(limit)
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getMyCredentials(JSON.stringify(newFilterData)));
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(getMyCredentials(JSON.stringify(newFilterData)));
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={LocalStorageDataService.get_page_item_list()}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // filter
  const onFilterChange = (filterModel, r) => {
    
    if(filterModel.items.length)
    {
        setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
    }

    if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
    {
        setReload(true);
        setTimeout(()=>
        {
          setReload(false)
          setCurrentPage(0);
          setFilterQuery(`{"filter":{"resource_id":{"value":${user.id},"operation": "eq"},"is_global":{"value":"False","operation":"eq"}}, "skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MY_RESOURCE_CREDENTIAL_COLUMNS}]}`)
          dispatch(getMyCredentials(`{"filter":{"resource_id":{"value":${user.id},"operation": "eq"},"is_global":{"value":"False","operation":"eq"}}, "skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MY_RESOURCE_CREDENTIAL_COLUMNS}]}`));
          setDefaultValue("")
        },0);
    }

    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
      setFieldName(filterModel.items[0].columnField)
    }
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "equals" ? 'ieq' :
          filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
            filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
              filterModel.items[0].operatorValue === "is" ? 'eq' :
                filterModel.items[0].operatorValue;
      // let field = filterModel.items[0].columnField === 'credentails_head' ? "credentails_head_id" :
      // filterModel.items[0].columnField;
      const filterData = `{"filter":{"resource_id":{"value":${user.id},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit}}`
      setFilterQuery(filterData);
      setCurrentPage(0);
      dispatch(getMyCredentials(filterData));

    }
    // if (filterModel.items && filterModel.items.length === 0) {
    //   setCurrentPage(0);
    //   setFilterQuery(`{"filter":{"resource_id":{"value":${user.id},"operation": "eq"},"is_global":{"value":"False","operation":"eq"}}, "skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MY_RESOURCE_CREDENTIAL_COLUMNS}]}`)
    //   dispatch(getMyCredentials(`{"filter":{"resource_id":{"value":${user.id},"operation": "eq"},"is_global":{"value":"False","operation":"eq"}}, "skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MY_RESOURCE_CREDENTIAL_COLUMNS}]}`));
    //   setDefaultValue("")
    // }
  }

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
    );
  }

  const columns = useMemo(() =>
    actionColumn.map((col) => {
      if (col.field === "status") {
        return col;
      }
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent
              ? CustomFilterInputValue
              : undefined
          }))
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [actionColumn]
  );

  return (
    <div className="listing">
      <Sidebar />
      <div className="listingContainer">
        <Navbar />

        <div className="datatable">
          <div className="datatableTitle">
            <h3>My Resource Credential</h3>
            <div>
              <RefreshButton api={getMyCredentials} filter={filterQuery} style={{ marginRight: 5 }} />
              <Tooltip title="Back">
                <button onClick={() => navigate(-1)} className="btn-add-new ">
                  <ArrowBack />
                </button>
              </Tooltip>
            </div>
          </div>

          <ToastContainer />
          <div className="custom-pagination-class">
            <CustomPagination />
          </div>
          {(status === STATUSES.LOADING || showLoader) && <LoaderForm />}
          {
            !reload &&
            <DataGrid
              className="datagrid"
              columnVisibilityModel={defaultresourceColumns && defaultresourceColumns.all_resource_cred_column ? defaultresourceColumns.all_resource_cred_column : columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
              }
              rows={list && list.rows ? list.rows : []}
              columns={columns}
              pageSize={pageSize}
              style={{ fontSize: 16 }}
              onFilterModelChange={onFilterChange}
              components={{
                Pagination: CustomPagination,
              }}
              hideFooterPagination={true}
            />
          }
        </div>

        {/* for delete popup */}
        <DeleteDialogModal
          open={open}
          onClose={handleClose}
          heading={'Delete ?'}
          paragraph={'Are you sure to remove this credential'}
          handleArchive={handleClickOpen}
          id={deleteId}
          moduleName={"MyResourceCredential"}
        />

      </div>

    </div>
  )
}
