import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./attendacecalender.scss"
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import moment from "moment";
import { useState, useEffect } from "react";
import { Button, TablePagination, Tooltip } from "@mui/material";
import Slider from '@mui/material/Slider';
import { attandanceList,defaultAttendenceColumn } from "../../redux/features/calendarSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import LoaderForm from "../../components/common-function/LoaderForm";
import TablePaginationActions from "../../components/TablePaginationActions";
import LocalStorageDataService from '../../components/common-function/GetLocalstorage'
import { timeConvert } from "../../components/utlis/CalculationTime"
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { ArrowBack } from "@mui/icons-material";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { getGridDateOperators } from "@mui/x-data-grid";
import { getGridStringOperators } from "@mui/x-data-grid";

const default_attendence_columns = LocalStorageDataService.get_default_attendence_column()
const AttendancePresence = () => {

    let user = LocalStorageDataService.userDetail();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [dateState, setDateState] = useState(new Date());
    const { attendanceData, status, error,defaultAttendenceColumns } = useSelector(state => state.calender)
    const [attendance, setAttendance] = useState([])
    const [loader, setLoader] = useState(true)

    //pagination 
    const [currentPage, setCurrentPage] = useState(0);
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-id" },"skip":0,"limit":25, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [count, setCount] = useState(0)
    // const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    //columns visibility
    // const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_attendence_columns && default_attendence_columns.attendence_column ? default_attendence_columns.attendence_column : {
        current_status: false,
        id:false,
    }); 
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});    

    const setKeyForList = (obj) => {
        let data;
        if (default_attendence_columns) {
            data = { 'attendence_column': obj, 'reported_attendence_column': default_attendence_columns.reported_attendence_column, 'attendence_presence_column': default_attendence_columns.attendence_presence_column, 'phone_column': default_attendence_columns.phone_column }
        } else {
            data = { 'attendence_column': obj }
        }
        if (defaultAttendenceColumns.reported_attendence_column) {
            data['reported_attendence_column'] = defaultAttendenceColumns.reported_attendence_column
        }
        if (defaultAttendenceColumns.attendence_presence_column) {
            data['attendence_presence_column'] = defaultAttendenceColumns.attendence_presence_column
        }
        if (defaultAttendenceColumns.phone_column) {
            data['phone_column'] = defaultAttendenceColumns.phone_column
        }
        dispatch(defaultAttendenceColumn(data))
    }

    useEffect(() => {
        dispatch(attandanceList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            errorHandling(error, navigate)
            setLoader(false)
        }
        setInterval(() => setDateState(new Date()), 60 * 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loader === true && status === STATUSES.SUCCESS && attendanceData && attendanceData.data && attendance.length === 0) {
        setLoader(false)
        const attenance_data = attendanceData.data && attendanceData.data.rows;
        setCount(attendanceData.data && attendanceData.data.count);
        let binding_attendance = []
        attenance_data.map(item => {
            return binding_attendance.push(
                {
                    date: item.punchin_date,
                    punchin_time: item.punchin_time,
                    punch_out_time: item.punchin_date === moment().format('YYYY-MM-DD') && !item.punch_out_time ? '-' : item.punch_out_time ? item.punch_out_time : 'missing',
                    current_status: item.current_status,
                    //title: item.resource.display_name,
                    id: item.id,
                    day_duration: item.durations ? item.durations : 0,
                    //user_id: item.resource_id,
                    work_duration_minutes: item.work_duration_minutes ? item.work_duration_minutes : 0,
                    break_duration_minutes: item.break_duration_minutes ? item.break_duration_minutes : 0,
                    color: item.punchin_time && !item.punch_out_time ? '#fbc02d' : '#86c06a',
                }
            )
        })
        setAttendance(binding_attendance)
    }

    const calculateTimeHour = (data) => {
        let cal = '0: 00';
        let work_duration_minutes = data.work_duration_minutes;
        let break_duration_minutes = data.break_duration_minutes;
        if (work_duration_minutes > 0) {
            cal = timeConvert(work_duration_minutes + break_duration_minutes)
            //cal = (work_duration_minutes + break_duration_minutes) / 60
        }
        return cal
    }
   
    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains'].includes(value),
        );
    const presenceColumn = [
        {
            field: "id",
            headerName: "ID",
            width: 70,
            filterable: false,
        },
        {
            field: "date",
            headerName: "Date",
            width: 160,
           filterable:false,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus`}>
                        {moment(params.row.date).format('ll')}
                    </div>
                );
            },
        },
        {
            field: "hour",
            headerName: "Hours",
            filterable: false,
            width: 160,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus}`}>
                        {calculateTimeHour(params.row)}
                    </div>
                );
            },
        },
        { field: "punchin_time", headerName: "Punch In Time", width: 230,filterOperators },
        { field: "punch_out_time", headerName: "Punch Out Time", width: 230 ,filterOperators},
    ];

    //custom pagination
    const handlePagination = (data) => {
        setLoader(true)
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        //setLimit(limit)
        setAttendance([])
        dispatch(attandanceList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setLoader(true)
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setAttendance([])
        dispatch(attandanceList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={count || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={LocalStorageDataService.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // filter
    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setLoader(true);
            setTimeout(()=>
            {
                setLoader(false)
                setCurrentPage(0)
                // setPageSize(10)
                setFilterQuery(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-punchin_date" },"skip":0,"limit":${limit}}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]`)
                dispatch(attandanceList(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-punchin_date" },"skip":0,"limit":${limit}}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]`))
             },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' :
                            filterModel.items[0].operatorValue === "is" ? 'ieq' :
                                filterModel.items[0].operatorValue
            const filterData = `{"filter":{"resource_id":{"value":${user.id},"operation": "eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0,"limit":${limit}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(attandanceList(filterData))
        }
        // if (filterModel.items && filterModel.items.length === 0) {
        //     setCurrentPage(0)
        //     // setPageSize(10)
        //     setFilterQuery(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-punchin_date" },"skip":0,"limit":${limit}}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]`)
        //     dispatch(attandanceList(`{"filter":{"resource_id":{"value":${user.id},"operation":"eq"}},"order_by":{ "column": "-punchin_date" },"skip":0,"limit":${limit}}, "columns":[${DefaultFilterColumns.ATTENDANCE_PRESENSE_LIST_COLUMNS}]`))
        // }
    }

    return (
        <div className="listing">
            <Sidebar />
            <div className="listingContainer">
                <Navbar />
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        '& > :not(style)': {

                            width: '100%',
                            // height: 150,
                            // margin:'2px'
                        },
                    }}
                >
                    <Paper style={{ textAlign: 'center', marginRight: 10, marginBottom: 5, width: '49%' }} elevation={2} >
                        <h3 style={{ margin: 20 }}>Today Date & Time</h3>

                        <Button variant="outlined" >
                            {dateState.toLocaleString('en-US', {
                                hour: 'numeric',
                                minute: 'numeric',
                                second: 'numeric',
                                hour12: true,
                            })}
                        </Button>

                        <p style={{ fontSize: 14, margin: 10 }} >
                            {dateState.toLocaleDateString('en-GB', {
                                day: 'numeric',
                                month: 'short',
                                year: 'numeric',
                            })}
                        </p>
                    </Paper>
                    <Paper style={{ textAlign: 'center', marginBottom: 5, width: '49%' }} elevation={2} >
                        <h3 style={{ margin: 20 }}>Timing</h3>
                        <div style={{ textAlign: 'center', marginRight: 30, marginLeft: 30, padding: 10 }}>
                            <Slider
                                track="inverted"
                                step={null}
                                aria-labelledby="track-inverted-range-slider"
                                value={[40, 60]}
                                marks={[
                                    {
                                        value: 0,
                                        label: '0',
                                    },
                                    {
                                        value: 40,
                                        label: '',
                                    },
                                    {
                                        value: 50,
                                        label: 'lunch-break ',
                                    },
                                    {
                                        value: 60,
                                        label: '',
                                    },
                                    {
                                        value: 100,
                                        label: '9h',
                                    },]}

                            />
                        </div>
                    </Paper>
                    {/* <Paper style={{ textAlign: 'center' }} elevation={2} >
                            <h3 style={{ margin: 20 }}>Clock Out Timing</h3>
                            <Table aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right" ><Button variant="outlined" >{attendance.map(x => x.date === moment().format('YYYY-MM-DD') && calculateTimeHour(x))}</Button></TableCell>
                                        <TableCell><Button variant="outlined" >out</Button></TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                        </Paper> */}
                </Box>
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>My Presence</h3>
                        <div>
                            <Tooltip title="Back">
                                <button onClick={() => navigate(-1)} className="btn btn-add-new" style={{ marginRight: "10px" }}><ArrowBack /></button>
                            </Tooltip>
                            <RefreshButton api={attandanceList} filter={filterQuery} style={{ marginTop: 5 }} />
                        </div>
                    </div>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {(status === STATUSES.LOADING || loader === true) && <LoaderForm />}
                    {
                        !loader &&
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={defaultAttendenceColumns && defaultAttendenceColumns.attendence_column ? defaultAttendenceColumns.attendence_column : columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                            }
                            rows={attendance}
                            columns={presenceColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            hideFooterPagination={true}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default AttendancePresence