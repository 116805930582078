import React,{ useEffect, useState } from 'react'
import './holiday.scss'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { toast, ToastContainer } from 'react-toastify';
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"
import { holidayList } from "../../redux/features/holidaySlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import Button from '../../components/button/Button';
import { Tooltip } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { RefreshButton } from '../../components/common-function/RefreshButton';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';

const { REACT_APP_ENV } = process.env;

const Holidays = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;
  const userRole = rtm_user?.user?.data?.tms_role.role_key || null;

  const { holidays, status,
    // error, apiResponse, 
    count } = useSelector(state => state.holiday)
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [params, setParams] = useState({})
  const [setFieldName] = useState("")
  const [limit,setLimit] = useState(25)
  const setDefaultValue =useState({})[1]
  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [updating, setUpdating] = useState(false);
  const [editable, setEditable] = useState(false);

  const whereClause = { ...params, "skip": skip, "limit": pageSize || 25, "order_by": { column: "-id" } }

  const [holidaysData, setHolidaysData] = useState({
    // name:"Deepawali",

  })

  const modalDetail = {
    title: "Holiday Details",
    editModalTitle: "Edit Holiday",
    addModalTitle: "Add Holiday",
    formInputs: [
      { id: 3, name: "name", label: "Holiday Name", placeholder: "Enter Name", type: "text", required: true, disabled: false },
      {
        id: 2, name: "status", label: "Status", placeholder: "Enter For Date", type: "select",
        options: [
          { id: 1, label: "Active", value: true },
          { id: 2, label: "Inactive", value: false },
        ],
        required: true, disabled: false
      },

      { id: 2, name: "description", label: "Descriptions", placeholder: "Enter Descriptions", type: "textarea", required: false, disabled: false },
    ],
  }

  const holidaysColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "name",
      headerName: "Name",
      width: 275,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
      renderCell: (params) => {
        return (
          <Tooltip describeChild
            title={params.row.description}
          >
            <p> {params.row.description} </p>
          </Tooltip>)
      }
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,

      type: 'singleSelect',
      valueOptions: [
        { label: "Active", value: true },
        { label: "Inactive", value: false },
      ],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`} >
            <p>{params.row.status === true ? 'Active' : 'Inactive'}</p>
          </div>
        );
      },
    },
  ];

  const onSubmit = async (data) => {
    const finalData = holidaysData.id ? { ...holidaysData, ...data, updated_by: userId } : { ...data, created_by: userId }
    const res = await dataService.save_holiday(finalData);
    if (res.data && res.status === 200) {
      toast.success(res.data.message)
      dispatch(holidayList(whereClause))
      onCloseModal();
    }
    else {
      toast.error(res.data.message)
    }
  }

  const onView = (data) => {
    setHolidaysData({ ...data });
    setEditable(false);
    setOpen(true);

  }

  // Edit Shift
  const handleEdit = (data) => {
    setHolidaysData({ ...holidaysData, ...data, updatedBy: 1 });
    setUpdating(true)
    setEditable(true);
    setOpen(true);
  }

  // Delete Holiday
  const deleteHoliday = async (data) => {
    let addvalues = {
      description: data.description,
      name: data.name,
      status: !data.status,
      updated_by: GetLocalstorage.userDetail().id,
      id: data.id
    }
    const res = await dataService.save_holiday(addvalues)
    if (res && res.data && res.data.status === 200) {
      toast.success(res.data.message)
      dispatch(holidayList(whereClause))
    } else {
      toast.error(res.data.message)
    }
  }

  //  Open Modal
  const onOpenModal = () => {
    setOpen(true)
  }

  //  AddHoliday Modal
  const onAddHoliday = () => {
    setOpen(true)
    setEditable(true);
  }

  //  Close Modal
  const onCloseModal = () => {
    setOpen(false)
    setUpdating(false);
    setEditable(false);
    setHolidaysData({});
  }

  useEffect(() => {
    dispatch(holidayList(whereClause))
    // eslint-disable-next-line
  }, [skip, params])

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3 >Holidays </h3>
          <div>
            {
              (userRole === 'admin' || userRole === 'super-admin' || userRole === "hr") &&
              <Button title={" "}
                preAddIcon className="btn-add-new "
                onClick={onAddHoliday}
                tooltip="Add Holiday"
              />
            }
            <RefreshButton api={holidayList} filter={whereClause} style={{ marginTop: 0 }} />

            <Tooltip title="Back">
              <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
            </Tooltip>

          </div>
        </div>

        <ToastContainer />
        <>
          {
            <DynamicTable
              moduleName="holidays"
              tableColumns={holidaysColumns}
              tableRows={holidays || []}
              currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
              setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
              setFieldName={setFieldName} setDefaultValue={setDefaultValue}
              paginationMode={"server"}
              rowCount={count}
              loading={status === STATUSES.LOADING}
              columnVisibilityModel={{
                id: false,
              }}
              onViewDetail={onView}
              onEdit={handleEdit}
              deleteRow={deleteHoliday}
              limit={limit}
              setLimit={setLimit}
            />
          }
        </>
        {
          // holidaysData && holidaysData.id &&
          <RegularizeModal
            modalDetail={modalDetail}
            open={open}
            onOpen={onOpenModal}
            onClose={onCloseModal}
            // onCancel={onCancel}
            onSubmit={onSubmit}
            hideBackdrop
            fullWidth
            maxWidth
            updating={updating}
            editData={holidaysData}
            editable={editable}
            formLoading={formLoading}
            setFormLoading={setFormLoading}
            additionalActions={modalDetail.additionalActions}
            onAdditionalAction={() => {
              console.log("Hello from Additional Action")
            }}
          />
        }
      </div>
    </div>
  )
}

export default Holidays