import { useEffect, useMemo, useState } from "react"
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import dataService from "../../redux/services/data.service"
import "./attendacecalender.scss"

import { Tooltip } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import RegularizeModal from "../../components/common-function/RegularizeModal"
import { toast, ToastContainer } from 'react-toastify';
import { STATUS } from "../../redux/common-status/constant"
import { useNavigate } from "react-router-dom"
import { getGridDateOperators, getGridSingleSelectOperators } from "@mui/x-data-grid"
import Filter from "../../components/Filter"
import { Refresh } from "@mui/icons-material"
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns"
import { getGridStringOperators } from "@mui/x-data-grid"
const { REACT_APP_ENV } = process.env;



const CompOffListing = ({ resourceId, resourceManagerId }) => {

  const navigate = useNavigate();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))

  const [compOffs, setCompOffs] = useState([])

  // Options for DropDown
  const [resourcesOptions, setResourcesOptions] = useState([])
  const [managerResourcesOptions, setManagerResourcesOptions] = useState([])

  // Pagination and Filter for DynamicTable
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const [limit,setLimit] = useState(25)
  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)

  const [compOffDetail, setCompOffDetail] = useState({})
  // custom filter
  const [fieldName, setFieldName] = useState("")
  const [defaultValue, setDefaultValue] = useState({})
  const [options, setOptions] = useState({})

  const modalDetail = {
    title: "Comp-off Approval",
    editModalTitle: `Comp-off Approval`,
    formInputs: [
      { id: 1, name: "for_date", label: "For Date", placeholder: "Enter For Date", type: "textWithLabel", required: true, disabled: true },
      {
        id: 2, name: "status", label: "Status", placeholder: "Enter For Date", type: "status_text",
        options: [
          { id: 1, label: "PENDING", value: "4" },
          { id: 2, label: "APPROVE", value: "5" },
          { id: 3, label: "CANCEL", value: "6" },
          { id: 4, label: "REJECT", value: "7" },
        ],
        required: true, disabled: true
      },
      { id: 3, name: "extra_duration_minutes", label: "Extra Minutes", placeholder: "Enter Extra Minutes", type: "text", required: true, disabled: true },
      { id: 4, name: "approved_minutes", label: "Approve Minutes", placeholder: "Enter Approve Minutes", type: "text", required: false, disabled: false },
      { id: 2, name: "reason", label: "Remark", placeholder: "Enter Remark", type: "textarea", required: false, disabled: false },
    ],
    additionalActions: [
      { id: 1, name: "approve", label: "Approve", className: "btn-submit", disabled: false },
      { id: 2, name: "reject", label: "Reject", className: "btn-cancel", disabled: false },
    ]
  }
  const filterOperators = getGridStringOperators().filter(({ value }) =>
  ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
const filterOperatorsForDate = getGridDateOperators().filter(({ value }) =>
  ['is'].includes(value),
);
const filterOperatorsForSelect = getGridSingleSelectOperators().filter(({ value }) =>
  ['is'].includes(value),
);
  const compOffColumns = [
    { field: "id", headerName: "ID", width: 70, filterable: false, },
    {
      field: "resource_id",
      headerName: "Name",
      width: 200,
      type: 'singleSelect',
      filterable: resourceId ? false : true,
      sortable: resourceId ? false : true,

      valueOptions: managerResourcesOptions,
      renderCell: (params) => {
        return (
          <p> {params.row.name} </p>
        )
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 275,
      filterable: false,
      sortable: true,
    },
    {
      field: "for_date",
      headerName: "For Date",
      type: "date",
      width: 130,
      filterOperators:filterOperatorsForDate,
    },
    {
      field: "extra_duration_minutes",
      headerName: "Extra Minutes",
      width: 130,
      filterOperators,
    },
    {
      field: "approved_minutes",
      headerName: "Approved Minutes",
      width: 150,
      filterOperators,
    },
    {
      field: "resource_manager_id",
      headerName: "Resource Manager",
      width: 200,
      type: 'singleSelect',
      valueOptions: resourcesOptions,

      renderCell: (params) => {
        return (
          <p> {params.row.resource_manager_name} </p>
        )
      }
    },
    {
      field: "approved_by",
      headerName: "Approved by",
      width: 200,
      type: 'singleSelect',
      valueOptions: resourcesOptions,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus `}>
            {/* <select defaultValue={params.row.approved_by}>
              </select> */}
            {
              resourcesOptions.map((resource, index) => {
                return (
                  params.row.approved_by === resource.value && <p key={index}>{resource.label} </p>
                )
              })
            }
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      filterOperators:filterOperatorsForSelect,
      type: 'singleSelect',
      valueOptions: [
        { label: "Approved", value: 5 },
        { label: "Cancelled", value: 6 },
        { label: "Pending", value: 4 },
        { label: "Rejected", value: 7 }
      ],
      renderCell: (params) => {
        return (
          <div className="cellAction" style={params.row.status === 4 ? { color: '#cc9600' } :
            params.row.status === 5 ? { color: 'green' } :
              params.row.status === 6 ? { color: '#808080' } :
                params.row.status === 7 ? { color: 'rgb(151, 15, 15)' } :
                  { color: '#000' }
          }>
            <p>{params.row.status === 4 ? "Pending" :
              params.row.status === 5 ? "Approved" :
                params.row.status === 6 ? "Cancelled" :
                  params.row.status === 7 ? "Rejected" : "No Request"}</p>
          </div>
        );
      },
    },
  ];

  // Load CompOff List
  const loadCompOff = async () => {
    const qparam = resourceId ? { ...params, filter: { ...params.filter, "resource_id": { "value": resourceId, "operation": "eq" } }, skip: skip, "limit": pageSize || 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }
      : resourceManagerId ? { ...params, filter: { ...params.filter, "resource_manager_id": { "value": resourceManagerId, "operation": "eq" } }, skip: skip, "limit": pageSize || 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }
        : { ...params, filter: { ...params.filter }, skip: skip, "limit": pageSize || 25, "coulmns": DefaultFilterColumns.MY_COMP_OFF_LISTING_COLUMNS }
    setLoading(true)
    const res = await dataService.compoff_list(qparam);
    if (res && res.data && res.data.data && res.data.data.rows) {
      setCompOffs(res.data.data.rows.map((row, index) => (
        {
          ...row,
          resource_id: row.resource.id,
          name: row.resource.name,
          email: row.resource.email,
          department: row.resource.department,
          role: row.resource.role,
          extra_working_minutes: row.resource_attendance.extra_working_minutes,
          resource_manager_name: row.resource_manager.name,
          resource_manager_id: row.resource_manager.id,
          status_text: STATUS.COMPOFF
        }
      )
      ));
      setRowCount(res.data.data.count)
    }
    setLoading(false);
  }

  //  LoadResourceList
  const getResources = async () => {
    const res = await dataService.resources_dropDown()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { value: e.id, label: e.name + "(" + e.email + ")" } })
      setResourcesOptions(tempArray)
      tempArray.length > 0 && setOptions({ ...options, resource_manager_id: tempArray, approved_by: tempArray })
    }
  }

  //  Load Manager ResourceList
  const getManagerResources = async () => {
    const res = await dataService.all_resources_by_manager()
    if (res && res.data && res.data.data && res.data.data.rows) {
      let tempArray = res.data.data.rows.map(e => { return { value: e.id, label: e.name } })
      setManagerResourcesOptions(tempArray)
      tempArray.length > 0 && setOptions({ ...options, resource_id: tempArray })
    }
  }

  // ON View CompOff
  const viewCompOff = (data) => {
    setCompOffDetail({ ...data, approved_minutes: data.extra_duration_minutes || 0, status_text: STATUS.COMPOFF });
    onOpen();
  }

  // Modal Open
  const onOpen = () => {
    setOpen(true);
  }

  // Modal Close
  const onClose = () => {
    setCompOffDetail({})
    setOpen(false);
  }


  // onAdditionalAction 
  const onAdditionalAction = async (data, action) => {
    setFormLoading(true)
    if (action === "approve" && !data.approved_minutes) {
      toast.error("Approved Minutes required!");
      setFormLoading(false)
      return;
    }
    const finalData = {
      "id": data.id,
      "resource": data.resource.id,
      "resource_attendance": data.resource_attendance.id,
      "approved_minutes": parseFloat(data.approved_minutes),
      "approved_by": rtm_user.user.data.id,
      "reason": data.reason,
      "status": action === "approve" ? STATUS.COMPOFF.APPROVED : "reject" ? STATUS.COMPOFF.REJECTED : data.status,
    }
    const res = await dataService.compoff_save(finalData)
    if (res && res.data && res.data.status === 200) {
      toast.success(res.data.message);
      onClose();
      loadCompOff()
      setCompOffDetail({})
    } else {
      toast.error(res.data.message)
    }
    setCompOffDetail({})
    setFormLoading(false)
  }

  useEffect(() => {
    loadCompOff()
    // dispatch(compoffList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, pageSize, params])
  useEffect(() => {
    getResources()
    getManagerResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      // <Filter options={options[fieldName]} onChange={handleFilterChange} defaultValue={defaultValue} />
      fieldName === "resource_id" ?
        <Filter options={managerResourcesOptions} onChange={handleFilterChange} defaultValue={defaultValue} />
        : fieldName === "resource_manager_id" ?
          <Filter options={resourcesOptions} onChange={handleFilterChange} defaultValue={defaultValue} />
          : fieldName === "approved_by" ?
            <Filter options={resourcesOptions} onChange={handleFilterChange} defaultValue={defaultValue} />
            : ""
    );
  }

  const columns = useMemo(() =>
    compOffColumns.map((col) => {
      if (col.field === "resource_id" || col.field === "resource_manager_id" || col.field === "approved_by") {
        return {
          ...col,
          filterOperators: getGridSingleSelectOperators()
            .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
            .map((operator) => ({
              ...operator,
              InputComponent: operator.InputComponent
                ? CustomFilterInputValue
                : undefined
            }))
        };
      }
      return col;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [compOffColumns]
  );

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3 > {!resourceId ? "Team Comp-off" : "My Comp-off"} </h3>
          <div>
            <button onClick={() => loadCompOff()} className="btn-add-new ">
              <Tooltip title="Reload">
                <Refresh />
              </Tooltip>
            </button>
            <Tooltip title="Back">
              <button onClick={() => navigate(-1)} className="btn-add-new "><ArrowBackIcon /></button>
            </Tooltip>
          </div>
        </div>

        <ToastContainer />
        <>
          {
            <DynamicTable
              moduleName="compoff"
              tableColumns={columns}
              tableRows={compOffs || []}
              currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
              setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
              paginationMode={"server"}
              rowCount={rowCount}
              loading={loading}
              columnVisibilityModel={{
                id: false,
              }}
              onViewDetail={viewCompOff}
              setFieldName={setFieldName}
              setDefaultValue={setDefaultValue}
              limit={limit}
              setLimit={setLimit}
            />
          }
        </>
        {
          compOffDetail && compOffDetail.id &&
          <RegularizeModal
            modalDetail={modalDetail}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            // onCancel={onCancel}
            // onSubmit={onSubmit}
            hideBackdrop
            fullWidth
            maxWidth
            updating
            editData={compOffDetail}
            editable={compOffDetail.status === 4 && resourceManagerId}
            formLoading={formLoading}
            setFormLoading={setFormLoading}
            additionalActions={modalDetail.additionalActions}
            onAdditionalAction={onAdditionalAction}

          />
        }
      </div>

    </div>
  )
}

export default CompOffListing