import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import { toast, ToastContainer } from "react-toastify";
import { Checkbox, Dialog, DialogContent, TablePagination, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { UrgencyCreate, , UrgencyUpdate, getUrgencyList } from "../../redux/features/masterSlice";
import { CoreSettingsCreate, CoreSettingsEdit, CoreSettingsUpdate, getCoreSettingsList } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import { Add, Close } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields, InputTextareaField } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { getGridStringOperators } from "@mui/x-data-grid";
// import { json } from "react-router-dom";

const initialFormData = Object.freeze({
    setting_display_name: "",
    setting_key: "",
    value: "",
    status: "",
    is_json_value: false,
});

const formErrData = Object.freeze({
    coreSettingsErr: "setting name is required",
    statusErr: "Status is required",
    valueErr: "Value is required"
});


const CoreSettings = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    // const { status, error, urgencytype_List, urgencytype_Create, urgencytype_Edit, urgencytype_Update } = useSelector(state => state.master)
    const { status, error, coresettings_List, coresettings_Create, coresettings_Edit, coresettings_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [jsonValueChecked, setJsonValueChecked] = useState("false")

    // const [DisableButton,setDisableButton] = useState(false)

    useEffect(() => {
        dispatch(getCoreSettingsList(filterQuery))

        // eslint-disable-next-line
    }, [])

    // error handling
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)

            setReload(false)
        }
        // eslint-disable-next-line
    }, [error])

    if (reload === true && status === `${STATUSES.SUCCESS}_coresettings_Update` && coresettings_Update && coresettings_Update.message) {
        if (coresettings_Update.status === 200) {
            toast.success(coresettings_Update.message)
            dispatch(getCoreSettingsList(filterQuery))
        } else {
            toast.error(coresettings_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_coresettings_Create` && coresettings_Create && coresettings_Create.message) {
        if (coresettings_Create.status === 200) {
            toast.success(coresettings_Create.message)
            dispatch(getCoreSettingsList(filterQuery))
        } else {
            toast.error(coresettings_Create.message)
        }
        setReload(false)
    }


    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
    }

    const handleChange = (evt) => {


        const { name, value, checked } = evt.target || evt;
        // value = type === "checkbox" ? checked : value;

        if (name === 'is_json_value' ) {
            setFormValues({ ...formValues, [name]: checked })
            setJsonValueChecked(true)
        } else {
            setFormValues({ ...formValues, [name]: value })
            setJsonValueChecked(false)
        }
    }

    const handleSettingKeyChange = (evt) => {
        const { value } = evt.target
        let newValue = value.toLowerCase().split(' ').join('-')
        setFormValues({ ...formValues, setting_display_name: value, setting_key: newValue })

    }


    const submitData = (e) => {
        e.preventDefault()

        if (formValues.setting_display_name === "" || formValues.status === "") {
            // setDisableButton(false)
            setErrStatus(true)
        }

        if (formValues.setting_display_name !== "" && formValues.status !== "") {
            const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id
            let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
            setErrStatus(false)
            // setDisableButton(true)
            setReload(true)
            handleClose()
            if (ID) {
                dispatch(CoreSettingsUpdate({ ID, addvalues }))
            } else {
                dispatch(CoreSettingsCreate(addvalues))
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && coresettings_Edit && coresettings_Edit.data) {
        let editData = coresettings_Edit.data
        setFormValues({  
            setting_display_name: editData.setting_display_name,
            setting_key: editData.setting_key,
            value: editData.setting_value ?  typeof(editData.setting_value) === "string" ? editData.setting_value : JSON.stringify(editData.setting_value):editData.key_value  ,
            status: editData.status,
            id: editData?.id,
            is_json_value: editData.is_json_value,


        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(CoreSettingsEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            setting_display_name: data.setting_display_name,
            setting_key: data.setting_key,
            is_json_value: data.is_json_value,
            value: data.key_value ? typeof(data.key_value) === "string" ? data.key_value : JSON.stringify(data.key_value) : data.setting_value,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        setReload(true)
        dispatch(CoreSettingsUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setLimit(limit)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCoreSettingsList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCoreSettingsList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(coresettings_List && coresettings_List.data && coresettings_List.data.rows && coresettings_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getCoreSettingsList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS}] }`)
        }
    }

    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
    const actionColumn = [
        {
            field: "setting_display_name",
            headerName: "Setting Name",
            filterOperators,
            width: 320,
            renderCell: (params) => {
                return (
                    <div>          

                        {params.row.setting_display_name}

                    </div>
                );
            },
        },
        {
            field: "setting_key",
            headerName: "Setting Key",
            filterOperators,
            width: 360,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.setting_key}

                    </div>
                );
            },
        },
        {
            field: "setting_value",
            headerName: "Setting Value",
            filterOperators,
            width: 280,
            renderCell: (params) => {
                return (
                    
                    <div >
                        { params.row.setting_value ? 

                        typeof (params.row.setting_value) === "string" ? params.row.setting_value : JSON.stringify(params.row.setting_value)
                        : params.row.key_value
                        }
                        {/* {params.row.is_json_value ===true ? "true" : "false" } */}
                        {/* {params.row.setting_value && params.row.setting_value  ? params.row.setting_value : "N/A"  } */}

                    </div>    
                                    
                );
                
            },
        },
        
        
        {
            field: "status",
            headerName: "Setting Status",
            width: 160,
            filterOperators,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="core-settings"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];
    
    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getCoreSettingsList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            //setPageSize(10)
            setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.CORESETTINGS_LIST_COLUMNS}]}`)
        }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Core Setting</h3>
                        <div>
                            <Tooltip title="Add Core Setting">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getCoreSettingsList} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    <div className="custom-pagination-class">
             <CustomPagination />
          </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    <DataGrid
                        className="datagrid"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            setColumnVisibilityModel(newModel)
                        }
                        rows={coresettings_List && coresettings_List.data && coresettings_List.data.rows ? coresettings_List.data.rows : []}
                        columns={actionColumn}
                        pageSize={pageSize}
                        style={{ fontSize: 16 }}
                        onFilterModelChange={onFilterChange}
                        disableSelectionOnClick
                        components={{
                            Pagination: CustomPagination,
                            NoRowsOverlay: CustomNoRowsOverlay
                        }}
                        onSortModelChange={(sort) => handleSort(sort)}
                        hideFooterPagination={true}
                    />
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px",style:"white" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Core Settings </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                {
                                    <div className="formInput">
                                        <label>
                                            Setting Name
                                        </label>
                                        <input
                                            label={"Setting Name"}
                                            name="setting_display_name"
                                            defaultValue={formValues.setting_display_name}
                                            type="text"
                                            placeholder="Setting-Name"
                                            onBlur={handleSettingKeyChange}
                                            errStatus={errStatus}
                                            formErr={formErr.coreSettingsErr}
                                        />
                                    </div>
                                }
                                {formValues.setting_display_name ? <div className="formInput">
                                    <label>
                                        Setting Key*
                                    </label>
                                    <input
                                        readOnly={ID ? true : false}
                                        label={"Setting Key"}
                                        name="setting_key"
                                        defaultValue={formValues.setting_display_name.toLowerCase().split(' ').join('-')}
                                        type="text"
                                        placeholder="Setting-Key"
                                    />
                                </div> : ""
                                }


                                {jsonValueChecked === true ?

                                    <InputTextareaField

                                        label={"Setting Value"}
                                        name="value"
                                        defaultValue={formValues.value}
                                        type="text"
                                        placeholder="value"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.valueErr}
                                    />
                                    :

                                    <InputFields
                                        label={"Setting Value"}
                                        name="value"
                                        defaultValue={formValues.value}
                                        type="text"
                                        placeholder="value"
                                        onChange={handleChange}
                                        errStatus={errStatus}
                                        formErr={formErr.valueErr}
                                    />
                                }


                                {
                                    <div className="formInput" style={{ marginTop: 20 }} >
                                        <Checkbox
                                            className="checkbox"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            name={"is_json_value"}
                                            defaultChecked={formValues.is_json_value}
                                            //   checked={}
                                            onChange={handleChange}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                        />
                                        <label>
                                            JSON Value
                                        </label>
                                    </div>
                                }


                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <button className={reload === true ? 'btn-disable':'submit-modal'}
                            disabled={reload === true ? true :false } onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} setting`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default CoreSettings;