import { Add, Close } from "@mui/icons-material";
import { Button, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TablePagination, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from '../../components/common-function/LoaderForm'
import { RefreshButton } from "../../components/common-function/RefreshButton";
import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import { getProjectRoleMaster } from "../../redux/features/projectSlice";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import dataService from "../../redux/services/data.service";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import TablePaginationActions from "../../components/TablePaginationActions";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { getGridStringOperators } from "@mui/x-data-grid";



const ManagingProjectRoles = () => {

    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.PROJECT_ROLE_COLUMN}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [currentPage, setCurrentPage] = useState(0);



    const [openSingle, setOpenSingle] = useState(false)
    const [formData, setFormData] = useState({
        role_name: "",
        status: "",
    })
    const [reload, setReload] = useState(false)

    const dispatch = useDispatch()

    const { project_status, projects_roles } = useSelector(state => state.projects)

    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    useEffect(() => {

        dispatch(getProjectRoleMaster(filterQuery))

    }, [])

    const onEditBtn = (data) => {
        onOpenModal()
        setFormData({
            role_name: data?.role_name,
            role_key: data?.role_key,
            status: data?.status,
            id: data?.id
        })
    }


    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormData({ ...formData, [name]: value });
    }


    const onSubmit = async (e) => {
        e.preventDefault()


        // validation
        if (!formData.role_name) {
            return toast.error('Role Name is required')
        } else if (formData.status === "") {
            return toast.error('Status is required')
        }
        setReload(true)
        let request;
        if (formData.id > 0) {
            request = ({
                ...formData,
                status: formData.status,
                role_name: formData?.role_name.trim() !== "" && formData.role_name,
                
            })
        } else {
            request = ({
                ...formData,
                status: formData.status,
                role_name: formData.role_name.trim() !== "" && formData.role_name,
            })
        }

        const response = formData.id > 0 ?
            await dataService.project_role_master_update(formData.id, request)
            : await dataService.project_role_master_save(request);

        if (response?.data?.status === 200) {
            toast.success(response?.data?.message)
            dispatch(getProjectRoleMaster(filterQuery))
            onCloseModal();
        } else {
            toast.error(response?.data?.message)
        }
        setTimeout(() => {
            setReload(false)
        }, 500)
    }
    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
    const ProjectRolesColumn = [
        {
            field: "role_name",
            headerName: "Role Name",
            width: 280,
            filterOperators,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.role_name}
                    </div>
                );
            },
        },
        {
            field: "role_key",
            headerName: "Role Slug",
            width: 280,
            filterOperators,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.role_key}
                    </div>
                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            width: 160,
            filterOperators,
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName=""
                            moduleName="third-party"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            editButton={() => onEditBtn(params.row)}
                            isDelete={false}
                            viewButton={false}
                            deleteButton={false}
                        />
                    </div>
                );
            },
        },
    ]


    //  Open Modal
    const onOpenModal = () => {
        setOpenSingle(true)
    }

    //  Close Modal
    const onCloseModal = () => {
        setOpenSingle(false)
        setFormData({
            role_name: "",
            role_key: "",
            status: "",
            id: ""
        })
    }



    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getProjectRoleMaster(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getProjectRoleMaster(JSON.stringify(newFilterData)))
    }
    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(projects_roles && projects_roles.count ? projects_roles.count : 0)}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }


    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                //setPageSize(10)
                setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.PROJECT_ROLE_COLUMN}]}`)
                dispatch(getProjectRoleMaster(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.PROJECT_ROLE_COLUMN}]}`))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.PROJECT_ROLE_COLUMN}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getProjectRoleMaster(filterData))
        }
    }


    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Manage Project Roles</h3>
                        <div>
                            <Tooltip title="Add New Project Role">
                                <button onClick={onOpenModal} className="btn-add-new">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getProjectRoleMaster} filter={filterQuery} style={{ marginTop: 0 }} />
                        </div>
                    </div>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {(project_status === STATUSES.LOADING || reload === true) && <LoaderForm />}
                    {
                        !reload &&
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={projects_roles?.rows ? projects_roles.rows : []}
                            columns={ProjectRolesColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            hideFooterPagination={true}
                        />
                    }
                </div>

                {/* model for add /edit  */}
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={openSingle}
                    onClose={onCloseModal}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                    sx={{ '& .MuiDialog-paper': { height: "450px" } }}
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={onCloseModal}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right:"10px",color:"white" }}>
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                        {formData.id ? "Edit Project Role" : "Add Project Role"}

                    </DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <div className="formInput">

                                    <label >
                                        Role Name*
                                    </label>
                                    <input
                                        name="role_name"
                                        type="text"
                                        defaultValue={formData.role_name}
                                        placeholder="Type Role Name"
                                        onChange={(e) => handleChange(e)}
                                    />
                                </div>

                                {formData.id ? <div className="formInput">
                                    <label >
                                        Role Slug*
                                    </label>
                                    <input
                                        readOnly
                                        name="role_key"
                                        type="text"
                                        defaultValue={formData.role_key}
                                        placeholder="Type Role Slug"
                                        onChange={(e) => handleChange(e)}
                                    />

                                </div> : ""}
                                <div className="formInput">
                                    <FormControl>
                                        <FormLabel id="demo-row-radio-buttons-group-label">Status*</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="status"
                                            value={formData.status}
                                            onChange={(e) => { handleChange(e) }}
                                        >
                                            <FormControlLabel value={true} control={<Radio />} label="Active" />
                                            <FormControlLabel value={false} control={<Radio />} label="Inactive" />
                                        </RadioGroup>
                                    </FormControl>
                                </div>
                            </div>
                            <button className={reload === true ? 'btn-disable' : 'submit-modal'} disabled={reload === true ? true : false} style={{ marginTop: "40px" }} onClick={onSubmit}>Submit</button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </div >
    )
}


export default ManagingProjectRoles;