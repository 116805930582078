import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import React, { useRef } from 'react';
import { toast, ToastContainer } from "react-toastify";
import { Dialog, DialogContent, Menu, MenuItem, Paper, Skeleton, Stack, Switch, TablePagination, Tooltip, Typography } from "@mui/material";
import LinearProgress from '@mui/material/LinearProgress';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { countryCreate, countryEdit, countryUpdate, getCountryList } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
// import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import LoaderForm from "../../components/common-function/LoaderForm";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import TablePaginationActions from "../../components/TablePaginationActions";
import {  AttachFile } from "@mui/icons-material";
import { Add, Close, Edit } from "@mui/icons-material";
import { InputFields, InputRadioGroupFields } from "../../components/common-function/InputFields";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import dataService from "../../redux/services/data.service";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import ArchiveIcon from '@mui/icons-material/Archive';
import { DataGridPro, getGridStringOperators, gridClasses } from "@mui/x-data-grid-pro";
import Draggable from 'react-draggable';
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
const { REACT_APP_API_BASEURL } = process.env;

const initialFormData = Object.freeze({
    name: "",
    code: "",
    status: "",
});

const formErrData = Object.freeze({
    nameErr: "Country name is required",
    codeErr: "Country code is required",
    statusErr: "Status is required"
});

const skeletonBase = { name: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', code: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO', status: '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' };

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-modal"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const Country = () => {
    const { REACT_APP_ENV } = process.env;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, country_List, country_Create, country_Edit, country_Update } = useSelector(state => state.master)
    const [currentPage, setCurrentPage] = useState(0);
    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [changeStatus, setChangeStatus] = useState('');
    const [filterClosingFields, setFilterClosingFields] = useState({columnField: '', operatorValue:''});
    const [files, setFiles] = useState()
    const [selectedFile, setSelectedFile] = useState(`${REACT_APP_API_BASEURL}file/download/2709`);
    const uploadInputRef = useRef(null);

    const [gridRows, setGridRows] = useState([]);
    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [skeletonRows, setSkeletonRows] = useState(Array(15).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const editID = useRef()


    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    useEffect(() => {
        dispatch(getCountryList(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
    }, [])
    useEffect(() => {
        stopOnRowScrollEnd.current = true;
        setReload(true);
        setSkeletonRows(() => Array(JSON.parse(filterQuery).limit).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })))
        dispatch(getCountryList(filterQuery))
    }, [filterQuery])

    if (reload === true && status === `${STATUSES.SUCCESS}_country_Update` && country_Update && country_Update.message) {
        if (country_Update.status === 200) {
            let str = new String(JSON.stringify({ ...JSON.parse(filterQuery), skip: 0, limit: gridRows.length }));
            setFilterQuery(str)
            toast.success(country_Update.message)
        } else {
            let copyLoadedRows = [...gridRows];
            let index = copyLoadedRows.findIndex(item => item.id === editID.current.id)
            copyLoadedRows.splice(index, 1, editID.current)
            setGridRows(copyLoadedRows);
            toast.error(country_Update.message)
        }
        setReload(false)
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_country_Create` && country_Create && country_Create.message) {
        if (country_Create.status === 200) {
            toast.success(country_Create.message)
            dispatch(getCountryList(filterQuery))
        } else {
            toast.error(country_Create.message)
        }
        setReload(false)
    }

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
        setContextMenu(null);
        setSelectedFile(`${REACT_APP_API_BASEURL}file/download/2709`)
    }

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);

        switch (actionType) {
            case 'status':
                handleArchive(row);
                break;

            case 'edit':
                handleClickEdit(row.id)
                break;
        }
        setContextMenu(null);

    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })

    }


    const submitData = async (e) => {
        e.preventDefault()

        if (formValues.code === "" || formValues.name === "" || formValues.status === "") {
            setErrStatus(true)
            return;
        }

        if (files) {
            const acceptedLogoFormat = ["jpg", "png"]
            if (!acceptedLogoFormat.includes(files.type.split("/")[1])) {
                toast.error("Please select image in jpg or png")
                return
            }
        }
        if (files) {
            const maxSizeInBytes = 512 * 1024
            if (files.size > maxSizeInBytes) {
                toast.error("Image size should be less than 512 KB");
                return;
            }
        }
        let formData = new FormData();
        formData.append('file', files);
        formData.append('upload_type', "country");
        formData.append('display_name', "country");

        const res = await dataService.create_attachment(formData)
        const response = res.data

        if (files && response.status === 200) {
            const logo = response?.data?.api_path_for_download

            if (formValues.code !== "" && formValues.name !== "" && formValues.status !== "") {
                const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
                let userId = rtm_user?.user?.data && rtm_user.user.data.id
                let addvalues = { ...formValues, created_by: userId, assigned_by: userId, logo: logo }
                setErrStatus(false)
                setReload(true)
                handleClose()
                if (ID) {
                    dispatch(countryUpdate({ ID, addvalues }))
                } else {
                    dispatch(countryCreate(addvalues))
                }
            }
        }else{
            if (formValues.code !== "" && formValues.name !== "" && formValues.status !== "") {
                const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
                let userId = rtm_user?.user?.data && rtm_user.user.data.id
                let addvalues = { ...formValues, created_by: userId, assigned_by: userId }
                setErrStatus(false)
                setReload(true)
                handleClose()
                if (ID) {
                    dispatch(countryUpdate({ ID, addvalues }))
                } else {
                    dispatch(countryCreate(addvalues))
                }
            }
        }
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && country_Edit && country_Edit.data) {
        let editData = country_Edit.data
        setFormValues({
            name: editData.name,
            code: editData.code,
            status: editData.status
        })
        const path = editData?.logo?.slice(1) || ""
        setSelectedFile(`${REACT_APP_API_BASEURL}${path}`)
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }

    if (reload === true && status === STATUSES.SUCCESS && country_List && country_List.message) {
        if (country_List.status === 200) {

            setGridRows(country_List.data.rows);
            setSkeletonRows([])
            SET_MAX_ROW_LENGTH(country_List.data.count);
        }
        else {
            setSkeletonRows([])
            toast.error(country_List.message);
        }
        setReload(false)
    }

    const handleClickEdit = (id) => {
        dispatch(countryEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            name: data.name,
            code: data.code,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        clearGridListing.current = true;
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        setReload(true)
        dispatch(countryUpdate({ ID, addvalues }))
    }

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCountryList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getCountryList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(country_List && country_List.data && country_List.data.rows && country_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getCountryList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}] }`)
        }
    }
    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'startsWith', 'endsWith'].includes(value),
);

    const actionColumn = [
        {
            field: "name",
            headerName: "Country Name",
            headerAlign: 'center',
            align: 'center',
            width: 400, maxWidth: 650,
            filterOperators,
            renderCell: (params) => {
                return (
                    params.row.name === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <Stack direction={"row"} display={"flex"} justifyContent={"start"} width={'20%'}>
                            <Typography variant="p">
                                {params.row.name}
                            </Typography>
                        </Stack>
                );
            },
        },
        {
            field: "code",
            headerName: "Country Code",
            headerAlign: 'center',
            align: 'center',
            width: 400, maxWidth: 650,
            filterOperators,
            renderCell: (params) => {
                return (
                    params.row.code === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <Stack direction={"row"} display={"flex"} justifyContent={"start"} width={'20%'}>
                            <Typography variant="p">
                                {params.row.code}
                            </Typography>
                        </Stack>

                );
            },
        },
        {
            field: "status",
            headerName: "Status",
            headerAlign: 'center',
            align: 'center',
            filterOperators,
            width: 400, maxWidth: 650,
            renderCell: (params) => {
                return (
                    // <div className={`cellWithStatus ${params.row.status}`}>
                    //     {params.row.status === true ? 'Active' : 'Inactive'}
                    // </div>
                    params.row.status === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
                        <Stack width={'20%'} justifyContent={"space-between"} direction="horizontal">
                            <Stack>
                                <Switch {...label} defaultChecked={params.row.status} onChange={() => handleClickArchive(params.row)} />
                            </Stack>
                            <Stack className="cellAction">
                                <UcActionComponent
                                    deleteIconName="ArchiveIcon"
                                    moduleName="country"
                                    rowid={params.row.id}
                                    addMoreData=""
                                    editLinkUrl=""
                                    viewLinkUrl=""
                                    isDelete={true}
                                    editButton={handleClickEdit}
                                    viewButton={false}
                                    deleteButton={() => handleArchive(params.row)}
                                />
                            </Stack>
                        </Stack>
                );
            },
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     width: 150, maxWidth: 150,
        //     hideable: false,
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             params.row.action === '$2a$12$TNYmmOkdvr53xuB2eXc1MOXF69.1zhEOYrxGZ2RmAqE2iP3kor4EO' ? <Skeleton width={320} /> :
        //                 <div className="cellAction">
        //                     <UcActionComponent
        //                         deleteIconName="ArchiveIcon"
        //                         moduleName="country"
        //                         rowid={params.row.id}
        //                         addMoreData=""
        //                         editLinkUrl=""
        //                         viewLinkUrl=""
        //                         isDelete={true}
        //                         editButton={handleClickEdit}
        //                         viewButton={false}
        //                     // deleteButton={() => handleArchive(params.row)} 
        //                     />
        //                 </div>
        //         );
        //     },
        // },
    ];

    const onFilterChange = (filterModel, r) => {

        if (filterModel.items.length) {
            setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
        }

        if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
            setReload(true);
            setTimeout(() => {
                setReload(false)
                setCurrentPage(0)
                setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`)
                dispatch(getCountryList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`))
            }, 0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getCountryList(filterData))
        }
    }

    let file = null;

    const onFileChange = async (event) => {
        file = event.target.files[0];
        setFiles(file);

        if (file.type.includes("image")) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setSelectedFile(e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            setSelectedFile(null);
        };
    }

    const fetchFurtherRows = (viewPortRowSize) => {
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.limit = gridRows.length + viewPortRowSize > MAX_ROW_LENGTH ? MAX_ROW_LENGTH - gridRows.length : viewPortRowSize;
        setFilterQuery(JSON.stringify(updatedFilter));
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            fetchFurtherRows(params.viewportPageSize);
        }
    };

  

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };
    return (
        <div className="jiraStatus">
             <Sidebar />
            <div className="jiraStatusContainer">
            <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <Stack width={'100%'} justifyContent={"space-between"} direction={"row"}>
                        <Stack direction={'row'}>
                            <Typography variant="h3" fontSize={'32px'} fontWeight={700} color={"#42526e"}>Country List</Typography>
                        </Stack>
                        <Stack direction={'row'}>
                            <Tooltip title="Add Country">
                                <Button variant="outlined" onClick={handleOpen} endIcon={<BorderColorTwoToneIcon />}>
                                    Create
                                </Button>
                            </Tooltip>
                            <RefreshButton api={getCountryList} filter={filterQuery} style={{ marginTop: 0 }} />
                        </Stack>
                    </Stack>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {
                        // !reload &&
                        <DataGridPro
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={gridRows.concat(skeletonRows)}
                            columns={actionColumn}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                                // LoadingOverlay: LinearProgress,
                                NoRowsOverlay: CustomNoRowsOverlay,
                                ColumnSortedAscendingIcon: UnfoldMoreIcon,
                                ColumnSortedDescendingIcon: UnfoldMoreIcon,
                            }}
                            loading={(status === STATUSES.LOADING || reload === true || reload === 'edit_data')}
                            componentsProps={{
                                row: {
                                    onContextMenu: handleContextMenu,
                                }
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                            onRowsScrollEnd={handleOnRowScrollEnd}
                            getRowClassName={(params) =>
                                params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                            }
                            sx={{
                                boxShadow: '1px 13px 31px -15px gray',
                                borderRadius: '15px',
                                height: '700px',
                                '& .MuiDataGrid-cell:hover': {
                                    color: '#000',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    color: '#1B3764',
                                    fontWeight: '650'
                                },
                                '& .even.MuiDataGrid-row': {
                                    backgroundColor: 'white'
                                },
                                '& .odd.MuiDataGrid-row': {
                                    backgroundColor: '#F2F5FA'
                                },
                                '& .MuiDataGrid-row:hover': {
                                    backgroundColor: '#b8d3fd',
                                },
                                "& ::-webkit-scrollbar": {
                                    width: "8px",
                                    height: "8px",
                                },
                                "& ::-webkit-scrollbar-track": {
                                    backgroundColor: "#f5f5f5",
                                },
                                "& ::-webkit-scrollbar-thumb": {
                                    borderRadius: "10px",
                                    boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                                    backgroundColor: "#f5f5f5",
                                },
                                '& .MuiDataGrid-menuIconButton': {
                                    rotate: '90deg',
                                    marginRight: '20px'
                                },
                                '& .MuiDataGrid-iconButtonContainer': {
                                    marginLeft: '10px'
                                },
                                ".cellAction": {
                                    display: 'none'
                                },
                                [`& .${gridClasses.row}`]: {
                                    "&:hover, &.Mui-hovered": {
                                        ".cellAction": {
                                            display: 'block'
                                        }
                                    }
                                }

                            }}
                        />
                    }
                </div>
                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    PaperComponent={PaperComponent}
                    aria-labelledby="draggable-dialog-modal"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px",color:"white" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ backgroundImage: "linear-gradient(to right, rgb(123, 213, 213) 17%, rgb(120, 127, 246) 77%)", color: '#fff', marginBottom: "20px", fontWeight: '700', cursor: "grabbing" }}>{ID ? 'Edit' : 'Add'} Country</DialogTitle>

                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Country"}
                                    name="name"
                                    defaultValue={formValues.name}
                                    type="text"
                                    placeholder="Country Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.nameErr}
                                />
                                <InputFields
                                    label={"Country Code"}
                                    name="code"
                                    defaultValue={formValues.code}
                                    type="text"
                                    placeholder="Country-Code"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.codeErr}
                                />
                                <div style={{ display: "flex" }}>
                                    <label>Add Country Flag</label>
                                    <input
                                        label="Add Country Flag"
                                        id="fileUpload"
                                        ref={uploadInputRef}
                                        type="file"
                                        multiple
                                        style={{ display: 'none' }}
                                        onChange={onFileChange}
                                        name="file"
                                    />
                                    <Tooltip title="Add Attachment">
                                        <span style={{ cursor: 'pointer' }}>
                                            <AttachFile
                                                className={`attach_icon`}
                                                onClick={() =>
                                                    uploadInputRef.current && uploadInputRef.current.click()
                                                }
                                            />
                                        </span>
                                    </Tooltip>
                                    {selectedFile && (
                                        <div className="image-preview-container">
                                            <img style={{ height: "40px", width: "60px" }} src={selectedFile} alt="Selected File Preview" />
                                        </div>
                                    )}
                                </div>
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />

                            </div>
                            <Button variant="contained" color="info" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} Country`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference="anchorPosition"
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                    componentsProps={{
                        root: {
                            onContextMenu: (e) => {
                                e.preventDefault();
                                handleClose();
                            }
                        }
                    }}
                >
                    <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                    <MenuItem onClick={() => handleMenuAction('status')} sx={{ color: 'rgba(220,0,0,.8)' }} > <ArchiveIcon sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Active / In Active</MenuItem>

                </Menu>
            </div>
        </div >
    )
}

export default Country;