import { CheckCircleOutline, Close, CloudDownloadOutlined, Delete, Edit, HighlightOff, MoreHoriz, ReplayCircleFilled, Sync } from "@mui/icons-material";
import { Box, Button, DialogTitle, Divider, FormGroup, IconButton, LinearProgress, Menu, MenuItem, Skeleton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { InputFieldss, SingleDatePickerInputField } from "../../components/common-function/InputFields";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { activity, dailyWorkFlowSummaryDelete, dailyWorkFlowSummaryEdit, dailyWorkFlowSummaryUpdate, worklogAddNew } from "../../redux/features/dashboardSlice";
import "react-datepicker/dist/react-datepicker.css";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { DataGridPro, gridClasses, getGridStringOperators } from "@mui/x-data-grid-pro";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { STATUSES } from "../../redux/common-status/constant";
import dataService from "../../redux/services/data.service";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import LoaderForm from "../../components/common-function/LoaderForm";
import { resourceListOption } from "../../redux/features/optionsSlice";
import { styled } from '@mui/material/styles';
import api from "../../redux/APIS/api";


const { REACT_APP_API_BASEURL } = process.env;

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#0c356a' : '#0c356a',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    }
}));
//FormData
const initialFormData = Object.freeze({
    resource: null,
    activity: null,
    project: null,
    work_summary: "",
    date: "",
    from_time: "",
    to_time: "",
    duration_display: "",
    issue: null,
});

function timeDiffCalc(dateFuture, dateNow) {
    let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;

    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;
    // calculate secounds
    let calcHourToMin = hours * 60 + minutes + 1
    // let hourMin = `${hours}.${n(minutes)}H`
    let hourMin = Number(calcHourToMin / 60).toFixed(2) + "H"
    return hourMin;
}

function diff_hours(time1, time2) {
    let hour1 = time1.split(':')[0];
    let hour2 = time2.split(':')[0];
    let min1 = time1.split(':')[1];
    let min2 = time2.split(':')[1];

    let diff_hour = hour2 - hour1;
    let diff_min = min2 - min1;
    if (diff_hour >= 0) {
        if (diff_min >= 0) {
            return true
        } else if (diff_hour > 0 && diff_min < 0) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }

}
const userId = GetLocalstorage?.userDetail()?.id
const userName = GetLocalstorage.userDetail()?.name + "(" + GetLocalstorage.userDetail()?.email + ")"

export default function DailyWorkLog({ clearOpen, setClearOpen, setOpenModal, activityDropDownList, projectDropDownList }) {
    const { activityList, status, daily_work_log_update, update_status, daily_work_log_edit } = useSelector(state => state.dashboardCard)
    const dispatch = useDispatch();
    const { worklog_create, daily_work_log_delete, error } = useSelector(state => state.dashboardCard)
    const { createAllResource } = useSelector(state => state.dropdownOptions)
    const [formValues, setFormValues] = useState(initialFormData)
    const [reload, setReload] = useState(false)
    const [limit, setLimit] = useState(25)
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"resource":{"value":${GetLocalstorage?.userDetail().id},"operation":"eq"},"date":{"value":"${moment().format("YYYY-MM-DD")}","operation":"eq"}},"skip":0,"limit":${Number(limit)},"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`)

    const [resource, setResource] = useState(GetLocalstorage?.userDetail().id)
    const [new_date, setNew_date] = useState(moment().format('YYYY-MM-DD'))
    const [resourceLabel, setResourceLabel] = useState({ value: userId, label: userName })

    // grid states starts
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false, resource__name: false });
    const [filterModal, setFilterModel] = useState({ items: [], linkOperator: 'and', quickFilterLogicOperator: "and", quickFilterValues: [] });
    const skeletonBase = { resource__name: '_skeleton_', resource_id: '_skeleton_', activity__activity_title: '_skeleton_', project_id: '_skeleton_', ticket_jira_key: '_skeleton_', work_summary: '_skeleton_', project__project_name: '_skeleton_', from_time: '_skeleton_', to_time: '_skeleton_', activity_id: '_skeleton_', duration_display: '_skeleton_', date: '_skeleton_' };

    const [MAX_ROW_LENGTH, SET_MAX_ROW_LENGTH] = useState(null);
    const [gridRows, setGridRows] = useState([]);
    const [skeletonRows, setSkeletonRows] = useState(Array(25).fill(skeletonBase).map((_, ind) => ({ ..._, id: 'skeleton' + ind })));
    let stopOnRowScrollEnd = useRef(false);
    let clearGridListing = useRef(false);
    // const filterableColumns = ['activity', 'work_summary', 'form_time', 'to_time', 'duration', 'project', 'activity_id', 'project_id'];
    const [selectedRow, setSelectedRow] = useState();
    const [contextMenu, setContextMenu] = useState(null);
    const [showResourceName, setShowResourceName] = useState(false);
    const [displayDuration, setDisplayDuration] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false)
    const [resourceType, setResourceType] = useState('')
    const [totalHours, setTotalHours] = useState(0)
    const [efficiency, setEfficiency] = useState(0)
    const [taskLoader, setTaskLoader] = useState(false)

    let isAnyChange = useRef({})
    // grid states ends

    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const [jiraProject, setJiraProject] = useState("True")
    const [projectList, setProjectList] = useState([])
    const [loadTypeData, setLoadTypeData] = useState("refresh")
    const [toggelDWS, setToggelDWS] = useState(false)
    const [issueList, setIssueList] = useState([])
    const [showIssue, setShowIssue] = useState(false)
    const [activeEdit, setActiveEdit] = useState(false)
    const [projectSync, setProjectSync] = useState(false)
    const [projectIssueFilter, setProjectIssueFilter] = useState({
        "filter": {}, "order_by": { "column": "-jira_issue_id" }, "skip": 0, "limit": 25,
        "columns": ["id", "ticket_jira_key", "status__name", "epic_id", "assignee_id", "reported_by", "issue_type", "ticket_key", "status", "updated_on", "project_id",
        ]
    })
    const [issueCount, setIssueCount] = useState(0)

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setRefreshLoading(false)
            setReload(false)
        }
    }, [error, status])

    const allProjectByfilter = async (key) => {
        setJiraProject(key)
        let query = `{"filter":{"is_jira_project": {"value":"${key}", "operation":"eq"}},"order_by":{"column":"project_name"},"no_limit":"True","columns":[${DefaultFilterColumns.MAIN_PROJECT_DROUPDOWN_COLUMNS}]}`
        const res = await dataService.project_list(query)
        const response = res?.data
        if (response && response?.status === 200) {
            setProjectList(response.data)
        } else {
            toast.error(response.message)
        }

    }

    const apiCallTeamList = async (filterData = null) => {
        setRefreshLoading(true)
        let filterSet = filterData ? JSON.parse(filterData) : JSON.parse(filterQuery)
        let newFilter
        let flag = false
        if (filterSet.date) {
            flag = true
        } else {
            flag = false
            newFilter = `{ "filter": { "status": {"value": "True","operation": "eq"} },"date":"${new_date}","skip":${Number(filterSet.skip)}, "limit":${Number(filterSet.limit)},"order_by":{"column":"name"},"columns":["id", "name","email", "emp_code"]}`

        }

        const res = await dataService.daily_worklog_summary_team_efficiency(flag === true ? JSON.stringify(filterSet) : newFilter)
        const response = res?.data
        if (response && response?.status === 200) {
            if (response?.data) {
                let listData = response?.data?.rows?.length > 0 ? response?.data?.rows : []
                setTotalHours(response?.data?.total_logged_hour || 0)
                setEfficiency(response?.data?.efficiency || 0)
                setRefreshLoading(false)
                SET_MAX_ROW_LENGTH(response?.data?.count);
                if (clearGridListing.current === true || loadTypeData === "create" || loadTypeData === 'delete' || loadTypeData === 'update' || loadTypeData === "filter" || loadTypeData === "myrecord") {
                    setGridRows(listData);
                    setSkeletonRows([])
                    setLoadTypeData("")
                    clearGridListing.current = false;
                } else {
                    setGridRows((pre) => [...pre, ...listData]);
                    setSkeletonRows([])
                }
            } else {
                setLoadTypeData("")
                setSkeletonRows([])
                setGridRows([])
            }
            setReload(false)
        } else {
            setRefreshLoading(false)
            toast.error(response.message)
        }

    }


    const apiCallList = async (filterData = null, type = null) => {
        setRefreshLoading(true)
        let filterSet = filterData ? JSON.parse(filterData) : JSON.parse(filterQuery)
        let newFilter
        let flag = false
        if (filterSet.date) {
            setGridRows([])
            setLoadTypeData("myrecord")
            flag = true
            newFilter = filterString()
        } else {
            flag = false

        }
        let finalQuery = flag === true ? newFilter : JSON.stringify(filterSet)

        const res = await dataService.daily_worklog_summary_list(finalQuery)
        const response = res?.data
        if (response && response?.status === 200) {
            if (response?.data) {
                let listData = response?.data?.rows?.length > 0 ? response?.data?.rows : response?.data?.not_filled_dts?.length > 0 ? response?.data?.not_filled_dts : []
                setTotalHours(response?.data?.total_logged_hour || 0)
                setEfficiency(response?.data?.efficiency || 0)
                setRefreshLoading(false)
                SET_MAX_ROW_LENGTH(response?.data?.count);
                if (clearGridListing.current === true || loadTypeData === "create" || loadTypeData === 'delete' || loadTypeData === 'update' || loadTypeData === "filter" || loadTypeData === "myrecord" || type !== null) {
                    setGridRows(listData);
                    setSkeletonRows([])
                    setLoadTypeData("")
                    clearGridListing.current = false;
                } else {
                    let copyLoadedRows = [...gridRows, ...listData];
                    let fout = []
                    copyLoadedRows = copyLoadedRows.filter(item => {
                        if (fout.includes(item.id)) return false
                        fout.push(item.id)
                        return true
                    })
                    setGridRows(copyLoadedRows);
                    setSkeletonRows([])
                }
            } else {
                setLoadTypeData("")
                setSkeletonRows([])
                setGridRows([])
            }
            setReload(false)
        } else {
            setRefreshLoading(false)
            toast.error(response.message)
        }

    }

    // refresh code
    const refresh = () => {
        setRefreshLoading(true)
        clearGridListing.current = true
        let filterData
        if (resourceType == 'all-filled') {
            filterData = `{"filter":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

        } else if (resourceType == 'not-filled') {
            filterData = `{"filter":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":25, "not_filled":"True","columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

        } else {
            filterData = `{"filter":{"resource":{"value":${resource},"operation": "eq"},"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":25,"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

        }
        if (filterModal?.items?.length) {
            let tempFilter = JSON.parse(filterData)
            let filterKeyOperator = "filter"
            let flag = false
            filterModal?.items.map(e => {
                if (e.value) {
                    flag = true
                }
                filterKeyOperator = filterModal.linkOperator === "and" ? "filter" : "or"

            })
            if (flag === true) {
                let operators = { contains: 'icontains', equals: 'ieq', is: 'eq', startsWith: 'istartsWith', endsWith: 'iendsWith' }

                let filter = filterModal?.items?.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                if (filter) {

                    tempFilter[filterKeyOperator] = filter
                }
                tempFilter[filterKeyOperator]["date"] = { value: new_date, operation: "eq" }
                if (resourceType == "") {
                    tempFilter[filterKeyOperator]["resource"] = { value: resource, operation: "eq" }

                }
            }
            filterData = JSON.stringify(tempFilter)
        }
        apiCallList(filterData)
    }

    const handleChange = (evt, name) => {
        if (evt) {
            const { name, value } = evt?.target || evt;
            let x_time = name === "from_time" ? `${value}:00` : formValues.from_time
            let y_time = name === "to_time" ? `${value}:00` : formValues.to_time
            let x = x_time && `${moment().format('YYYY/MM/DD')} ${x_time}`
            let y = y_time && `${moment().format('YYYY/MM/DD')} ${y_time}`

            if (name === "from_time") {
                setDisplayDuration(true)
                let timeCalu = x && y && timeDiffCalc(new Date(x), new Date(y))
                let diffTime = x && y && diff_hours(value, formValues.to_time)
                timeCalu ? setFormValues({ ...formValues, "duration_display": diffTime ? timeCalu : "-" + timeCalu, [name]: `${value}` }) :
                    setFormValues({ ...formValues, [name]: `${value}` })
                if (!diffTime && x && y) {
                    setDisplayDuration(false)
                    return toast.error('From time is always less than To time!')
                }
                setTimeout(() => {
                    setDisplayDuration(false)
                }, 50);
            } else if (name === "to_time") {
                setDisplayDuration(true)
                let timeCalu = x && y && timeDiffCalc(new Date(x), new Date(y))
                let diffTime = x && y && diff_hours(formValues.from_time, value)
                timeCalu ? setFormValues({ ...formValues, "duration_display": diffTime ? timeCalu : "-" + timeCalu, [name]: `${value}` }) :
                    setFormValues({ ...formValues, [name]: `${value}` })
                if (!diffTime && x && y) {
                    setDisplayDuration(false)
                    return toast.error('To time is always greater than from time!')
                }
                setTimeout(() => {
                    setDisplayDuration(false)
                }, 50);
            } else if (name === "activity") {
                setFormValues({ ...formValues, [name]: value })
                let isTypeJira = activityList?.rows?.length > 0 && activityList?.rows.filter(item => item.id === value && (item.activity_title === "Jira" || item.activity_title === "jira"))
                if (isTypeJira.length > 0) {
                    allProjectByfilter("True")
                    if (formValues.project) {
                        setTaskLoader(true)
                        let projectId = formValues?.project?.value ? formValues?.project?.value : formValues?.project;
                        taskUpdatedList(0, projectId)

                    } else {
                        setIssueList([])
                    }
                } else {
                    setIssueList([])
                    setShowIssue(false)
                }

            } else if (name === "project") {
                setFormValues({
                    ...formValues, [name]: value,
                    issue: null
                })
                if (formValues.activity) {
                    let isTypeJira = activityList?.rows?.length > 0 && activityList?.rows.filter(item => (item.id === formValues.activity || item.id === formValues.activity.value) && (item.activity_title === "Jira" || item.activity_title === "jira"))
                    if (isTypeJira.length > 0) {
                        setTaskLoader(true)
                        taskUpdatedList(0, value)

                    } else {
                        setShowIssue(false)
                        setIssueList([])
                    }
                } else {
                    setShowIssue(false)
                    setIssueList([])
                }
            } else {
                setFormValues({ ...formValues, [name]: value })
            }
        } else {
            if (name === 'issue' && showIssue === true) {
                setTaskLoader(true)
                let projectId = formValues?.project?.value ? formValues?.project?.value : formValues?.project;
                taskUpdatedList(0, projectId)
            } else {
                setFormValues({
                    ...formValues,
                    issue: null
                })
            }

        }

    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (formValues.activity === null || formValues.project === null || formValues.work_summary === "" || formValues.from_time === "" || formValues.to_time === "") {
            toast.error("Please Fill the all mendatory fields")
            return;
        } else if (showIssue === true && (formValues.issue === null || formValues.issue?.value === null)) {
            toast.error("Please Fill the all mendatory fields")
            return;

        } else {
            const addvalues = {
                resource: GetLocalstorage.userDetail().id,
                date: moment(new Date()).format("YYYY-MM-DD"),
                activity: formValues.activity.value ? formValues.activity.value : formValues.activity.label === null ? null : formValues.activity,
                project: formValues.project.value ? formValues.project.value : formValues.project.label === null ? null : formValues.project,
                duration_display: formValues.duration_display,
                from_time: formValues.from_time + ":00",
                to_time: formValues.to_time + ":00",
                work_summary: formValues.work_summary,
                issue: showIssue === true ? formValues.issue?.value ? formValues.issue.value : formValues.issue?.label === null ? null : formValues.issue : null
            }

            if (formValues.id) {
                let ID = formValues.id
                dispatch(dailyWorkFlowSummaryUpdate({ ID, addvalues }))
            } else {
                dispatch(worklogAddNew(addvalues))
            }
            setReload('submit')
        }
    }

    const handleClear = () => {
        setFormValues(initialFormData)
        setIssueList([])
        setShowIssue(false)
        setReload('clear')
        setTimeout(() => {
            setReload(false)
        }, 60);
    }

    if (reload === 'submit' && worklog_create && worklog_create.message) {
        if (worklog_create.status === 200) {
            toast.success(worklog_create.message)
            setFormValues(initialFormData)
            clearGridListing.current = true
            setGridRows([])
            setLoadTypeData("create")
            refresh()
            handleClear()
            setTimeout(() => {
                dispatch(worklogAddNew("type"))
            }, 100);
        } else {
            let filterActivity = activityList?.rows?.length > 0 ? activityList?.rows.filter(item => item.id === formValues.activity) : formValues.activity
            let filterProject = projectList?.rows?.length > 0 ? projectList?.rows.filter(item => item.id === formValues.project) : formValues.project
            let projectIs = filterProject.length > 0 ? { label: filterProject[0].project_name, value: filterProject[0].id } : formValues.project;
            let activityIs = filterActivity.length > 0 ? { label: filterActivity[0].activity_title, value: filterActivity[0].id } : formValues.activity;
            let filterIssue = issueList?.length > 0 ? issueList?.filter(item => item.id === formValues.issue) : formValues.issue
            let issueIs = filterIssue?.length > 0 ? { label: filterIssue[0].ticket_jira_key, value: filterIssue[0].id } : formValues.issue

            setFormValues(
                {
                    ...formValues,
                    'project': projectIs,
                    "activity": activityIs,
                    "issue": issueIs,
                    "from_time": formValues.from_time ? formValues.from_time?.split(":")[0] + ":" + formValues.from_time?.split(":")[1] : formValues.from_time,
                    "to_time": formValues.to_time ? formValues.to_time?.split(":")[0] + ":" + formValues.to_time?.split(":")[1] : formValues.to_time
                })
            toast.error(worklog_create.message)
            dispatch(worklogAddNew("type"))
        }
        setReload(false)
    }
    const taskUpdatedList = async (skips, projectId) => {
        let filters = projectIssueFilter
        filters["filter"]["project"] = { "value": projectId, "operation": "eq" }
        if (filters?.filter?.ticket_jira_key) {
            delete filters["filter"]["ticket_jira_key"]
        }
        filters.skip = skips

        const req = await dataService.get_jira_task_issue_list(JSON.stringify(filters));
        const response = await req.data;
        if (response.status === 200) {
            setTaskLoader(false)
            let listData = response?.data?.rows
            let copyLoadedRows = skips === 0 ? listData : [...issueList, ...listData];
            let fout = []
            copyLoadedRows = copyLoadedRows.filter(item => {
                if (fout.includes(item.id)) return false
                fout.push(item.id)
                return true
            })
            setShowIssue(true)
            setIssueCount(response?.data?.count)
            setIssueList(copyLoadedRows)
        } else {
            toast.error(response.message)
            setIssueCount(0)
            setShowIssue(true)
            setTaskLoader(false)
            setIssueList([])

        }

    }

    useEffect(() => {
        dispatch(activity('{"filter":{ "status":{"value":True, "operation":"eq"}},"order_by":{"column":"activity_title"},"no_limit":True}'))
        dispatch(resourceListOption())
        allProjectByfilter("True")
    }, [dispatch])

    useEffect(() => {
        if (toggelDWS === true) {
            apiCallTeamList()
        } else {
            refresh()
            //apiCallList()
        }
        setReload(true);
        // eslint-disable-next-line
    }, [filterQuery]);

    const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals' /* add more over time */].includes(value),
    );


    const columnsEffeciency = [
        {
            field: 'name',
            sortable: false,
            filterable: false,
            headerName: 'Resource Name',
            width: 200,
            renderCell: (params) => {
                return (
                    (params?.row?.name === "_skeleton_" || params?.row?.name === "_skeleton_") ?
                        params?.row?.name ?
                            <Skeleton width={"300px"} />
                            : params?.row?.name
                        : params?.row?.name
                );
            },
        },
        {
            field: 'emp_code',
            sortable: false,
            filterable: false,
            headerName: 'Employee Code',
            width: 200,
            renderCell: (params) => {
                return (
                    (params?.row?.emp_code === "_skeleton_" || params?.row?.emp_code === "_skeleton_") ?
                        params?.row?.emp_code ?
                            <Skeleton width={"300px"} />
                            : params?.row?.emp_code
                        : params?.row?.emp_code
                );
            },
        },
        {
            field: 'email',
            sortable: false,
            filterable: false,
            headerName: 'Resource Email',
            width: 200,
            renderCell: (params) => {
                return (
                    (params?.row?.email === "_skeleton_" || params?.row?.email === "_skeleton_") ?
                        params?.row?.email ?
                            <Skeleton width={"300px"} />
                            : <div>{params?.row?.email && <Tooltip title={params?.row?.email}><p>{params?.row?.email}</p></Tooltip>}</div>
                        : <div>{params?.row?.email && <Tooltip title={params?.row?.email}><p>{params?.row?.email}</p></Tooltip>}</div>
                );
            },
        },
        {
            field: 'total_logged_hour',
            sortable: false,
            headerName: 'Logged Hours',
            width: 200,
            renderCell: (params) => {
                return (
                    params?.row?.total_logged_hour === "_skeleton_" ? <Skeleton width={"300px"} /> : <p>{params?.row?.total_logged_hour}</p>
                );
            },
        },
        {
            field: 'efficiency',
            sortable: false,
            headerName: 'Efficiency',
            width: 200,
            renderCell: (params) => {
                return (
                    params?.row?.efficiency === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.efficiency}</Typography>
                );
            },
        }

    ]

    const columns = [
        {
            field: 'resource__name',
            sortable: false,
            filterOperators,
            filterable: false,
            hideable: showResourceName === true ? true : false,
            headerName: 'Resource Name',
            width: 195,
            renderCell: (params) => {
                return (
                    (params?.row?.resource__name === "_skeleton_" || params?.row?.name === "_skeleton_") ?
                        params?.row?.resource__name ?
                            <Skeleton width={"300px"} />
                            : params?.row?.resource__name
                        : params?.row?.name
                );
            },
        },
        {
            field: 'activity_id',
            sortable: false,
            filterable: (showResourceName === false || resourceType == "all-filled") ? true : false,
            type: 'singleSelect',
            valueOptions: activityDropDownList,
            headerName: 'Activity',
            width: showResourceName === true ? 130 : 190,
            renderCell: (params) => {
                return (
                    params?.row?.activity__activity_title === "_skeleton_" ? <Skeleton width={"300px"} /> : <p>{params?.row?.activity__activity_title}</p>
                );
            },
        },
        {
            field: 'project__project_name',
            sortable: false,
            filterOperators,
            filterable: (showResourceName === false || resourceType == "all-filled") ? true : false,
            headerName: "Project",
            width: 190,
            renderCell: (params) => {
                return (
                    params?.row?.project__project_name === "_skeleton_" ? <Skeleton width={"300px"} /> :
                        <>
                            <Typography>{params?.row?.project__project_name}</Typography>
                        </>
                );
            },
        },
        {
            field: 'issue__ticket_jira_key',
            sortable: false,
            filterOperators,
            filterable: (showResourceName === false || resourceType == "all-filled") ? true : false,
            headerName: "Jira Issue",
            width: 190,
            renderCell: (params) => {
                return (
                    params?.row?.issue__ticket_jira_key === "_skeleton_" ? <Skeleton width={"300px"} /> :
                        <>
                            <Typography>{params?.row?.issue__ticket_jira_key}</Typography>
                        </>
                );
            },
        },
        {
            field: 'work_summary',
            sortable: false,
            filterOperators,
            filterable: (showResourceName === false || resourceType == "all-filled") ? true : false,
            headerName: 'Work Summary',
            width: showResourceName === true ? 400 : 430,
            renderCell: (params) => {
                return (
                    params?.row?.work_summary === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.work_summary && <Tooltip title={params?.row?.work_summary}><p>{params?.row?.work_summary}</p></Tooltip>}</Typography>
                );
            },
        },
        {
            field: 'from_time',
            sortable: false,
            filterOperators,
            filterable: (showResourceName === false || resourceType == "all-filled") ? true : false,
            headerName: "From Time",
            width: 115,
            renderCell: (params) => {
                return (
                    params?.row?.from_time === "_skeleton_" ? <Skeleton width={"300px"} /> :
                        <Typography>{params?.row?.from_time ? params?.row?.from_time?.split(':')[0] + ":" + params?.row?.from_time?.split(':')[1] : ""}</Typography>
                );
            },
        },
        {
            field: 'to_time',
            sortable: false,
            filterOperators,
            filterable: (showResourceName === false || resourceType == "all-filled") ? true : false,
            headerName: "To Time",
            width: 115,
            renderCell: (params) => {
                return (
                    params?.row?.to_time === "_skeleton_" ? <Skeleton width={"300px"} /> :
                        <Typography>{params?.row?.to_time ? params?.row?.to_time?.split(':')[0] + ":" + params?.row?.to_time?.split(':')[1] : ""}</Typography>
                );
            },
        },
        {
            field: 'duration_display',
            sortable: false,
            filterable: (showResourceName === false || resourceType == "all-filled") ? true : false,
            filterOperators,
            headerName: "Duration",
            width: 115,
            renderCell: (params) => {
                return (
                    params?.row?.duration_display === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.duration_display}</Typography>
                );
            },
        },

    ]
    const actionColumns = [
        {
            field: "action",
            headerName: "Action",
            width: 110,
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="actionButton">
                        <UcActionComponent
                            deleteIconName=""
                            moduleName="core-settings"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            viewButton={false}
                            editButton={() => +params?.row?.resource_id === +GetLocalstorage?.userDetail().id ? handleClickEdit(params.row.id) : false}
                            deleteButton={() => +params?.row?.resource_id === +GetLocalstorage?.userDetail().id ? handleArchive(params.row) : false}
                        />
                    </div>
                );
            },
        }
    ]

    const combineBothFilter = (name, value, type) => {
        let filterData = { "filter": {}, "order_by": { "column": ["resource__name", "from_time"] }, "skip": 0, "limit": Number(limit), "columns": DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS_JSON }

        if (filterQuery) {
            let filterConvertJson = JSON.parse(filterQuery)
            if (filterConvertJson?.filter?.activity_id) {
                filterData['filter']['activity_id'] = filterConvertJson?.filter?.activity_id

            }
            if (filterConvertJson?.filter?.project__project_name) {
                filterData['filter']['project__project_name'] = filterConvertJson?.filter?.project__project_name

            }
            if (filterConvertJson?.filter?.issue__ticket_jira_key) {
                filterData['filter']['issue__ticket_jira_key'] = filterConvertJson?.filter?.issue__ticket_jira_key

            }
            if (filterConvertJson?.filter?.work_summary) {
                filterData['filter']['work_summary'] = filterConvertJson?.filter?.work_summary

            }
            if (filterConvertJson?.filter?.to_time) {
                filterData['filter']['to_time'] = filterConvertJson?.filter?.to_time

            }
            if (filterConvertJson?.filter?.from_time) {
                filterData['filter']['from_time'] = filterConvertJson?.filter?.from_time

            }
            if (filterConvertJson?.filter?.duration_display) {
                filterData['filter']['duration_display'] = filterConvertJson?.filter?.duration_display

            }


        }
        if (type == 'resource') {
            filterData['filter']['date'] = { "value": name == 'date' ? value : new_date, "operation": "eq" }
            filterData['filter']['resource'] = { "value": name == 'date' ? resource : value, "operation": "eq" }
        }
        if (type == 'all-filled') {
            filterData['filter']['date'] = { "value": name == 'date' ? value : new_date, "operation": "eq" }
        }

        return filterData
    }

    const onFilterChange = (evt) => {
        const { name, value, label } = evt.target || evt;
        let filterData;
        clearGridListing.current = true;
        if (name) {
            if (value === '-1') {
                setResourceLabel({ value: value, label: 'All DWS Filled' })
                setResourceType('all-filled')
                setShowResourceName(true)
                setColumnVisibilityModel({ id: false, resource__name: true })
                filterData = JSON.stringify(combineBothFilter('date', new_date, 'all-filled'))
                //filterData = `{"filter":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":${Number(limit)},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

            } else if (value === 'not-filled') {
                setResourceLabel({ value: value, label: 'All DWS Not Filled' })
                setResourceType('not-filled')
                setShowResourceName(true)
                setColumnVisibilityModel({ id: false, resource__name: true })
                filterData = `{"filter":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":${Number(limit)}, "not_filled":"True","columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`
            } else {
                setResource(value)
                setResourceType('')
                setResourceLabel({ value: value, label: label })
                setShowResourceName(false)
                setColumnVisibilityModel({ id: false, resource__name: false })

                filterData = JSON.stringify(combineBothFilter('resource', value, 'resource'))
                //filterData = `{"filter":{"resource":{"value":${value},"operation": "eq"},"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":${Number(limit)},"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`
            }
        } else {
            setNew_date(evt)
            if (toggelDWS === true) {
                filterData = `{ "filter": { "status": {"value": "True","operation": "eq"} },"date":"${evt}","order_by":{"column":"name"},"skip":0, "limit":25, "columns":["id", "name", "email", "emp_code"]}`
            } else {
                if (showResourceName === true && resourceType == 'all-filled') {
                    setColumnVisibilityModel({ id: false, resource__name: true })
                    filterData = JSON.stringify(combineBothFilter('date', evt, 'all-filled'))
                    //filterData = `{"filter":{"date":{"value":"${evt}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":${Number(limit)},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`
                } else if (showResourceName === true && resourceType == 'not-filled') {
                    setColumnVisibilityModel({ id: false, resource__name: true })
                    filterData = `{"filter":{"date":{"value":"${evt}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":${Number(limit)}, "not_filled": "True","columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`
                } else {
                    filterData = JSON.stringify(combineBothFilter('date', evt, 'resource'))
                    //filterData = `{"filter":{"date":{"value":"${evt}","operation": "eq"},"resource":{"value":${resource},"operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":${Number(limit)},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`
                }
            }

        }
        setGridRows([])
        setLoadTypeData("filter")
        toggelDWS === true ? apiCallTeamList(filterData) : apiCallList(filterData)
    }

    const handleClose = () => {
        setReload('clear')
        setToggelDWS(false)
        setClearOpen(true)
        setContextMenu(null);
        setResourceType("")
        handleClear()
        setGridRows([])
        setFormValues(initialFormData)
        setNew_date(moment().format('YYYY-MM-DD'))
        setResource(GetLocalstorage?.userDetail().id)
        setResourceLabel({ value: userId, label: userName })
        clearGridListing.current = true;
        setShowResourceName(false)
        apiCallList(`{"filter":{"resource":{"value":${GetLocalstorage?.userDetail().id},"operation":"eq"},"date":{"value":"${moment().format("YYYY-MM-DD")}","operation":"eq"}},"skip":0,"limit":${Number(limit)},"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`, "refresh")
        setTimeout(() => {
            setReload(false)
        }, 500);
        setTimeout(() => {
            setClearOpen(false)
        }, 300)
    }

    const loadServerRows = (viewPortRowSize) => {
        setReload(true)
        let filterData
        let updatedFilter = JSON.parse(filterQuery);
        updatedFilter.skip = gridRows.length;
        updatedFilter.order_by = toggelDWS === true ? { "column": ["id", "name", "email", "emp_code"] } : { "column": ["resource__name", "from_time"] };
        updatedFilter.limit = gridRows.length >= 25 ? Number(gridRows.length) + Number(limit) : Number(limit)
        let isSkip = Number(gridRows.length)
        let isLimit = gridRows.length >= 25 ? Number(gridRows.length) + Number(limit) : 25

        if (toggelDWS === false) {
            if (resourceType == 'all-filled') {
                filterData = `{"filter":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":${Number(isSkip)}, "limit":${Number(isLimit)},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

            } else if (resourceType == 'not-filled') {
                filterData = `{"filter":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":${Number(isSkip)}, "limit":${Number(isLimit)}, "not_filled":"True","columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

            } else {
                filterData = `{"filter":{"resource":{"value":${resource},"operation": "eq"},"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":${Number(isSkip)}, "limit":${Number(isLimit)},"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

            }
        } else {
            filterData = `{ "filter": { "status": {"value": "True","operation": "eq"} },"date":"${new_date}","skip":${Number(isSkip)}, "limit":${Number(isLimit)},"order_by":{"column":"name"},"columns":["id", "name","email", "emp_code"]}`

        }
        setFilterQuery(filterData);
    }

    const handleOnRowScrollEnd = (params) => {
        if (stopOnRowScrollEnd.current) {
            stopOnRowScrollEnd.current = false;
            return;
        }
        if (gridRows.length < MAX_ROW_LENGTH && skeletonRows.length === 0) {
            loadServerRows(params.viewportPageSize);
        }
    };

    // update section 
    if (reload === 'submit' && update_status === `${STATUSES.SUCCESS}_daily_work_log_update` && daily_work_log_update && daily_work_log_update.message) {
        if (daily_work_log_update.status === 200) {
            toast.success(daily_work_log_update.message)
            clearGridListing.current = true
            setGridRows([])
            setLoadTypeData("update")
            refresh()
            //handleClear()
            setFormValues(initialFormData)
            setShowIssue(false)
            setIssueList([])
            dispatch(dailyWorkFlowSummaryUpdate('type'))
        } else {
            let filterActivity = activityList?.rows?.length > 0 ? activityList?.rows.filter(item => item.id === formValues.activity) : formValues.activity
            let filterProject = projectList?.rows?.length > 0 ? projectList?.rows.filter(item => item.id === formValues.project) : formValues.project
            let projectIs = filterProject.length > 0 ? { label: filterProject[0].project_name, value: filterProject[0].id } : formValues.project;
            let activityIs = filterActivity.length > 0 ? { label: filterActivity[0].activity_title, value: filterActivity[0].id } : formValues.activity;
            let filterIssue = issueList?.length > 0 ? issueList?.filter(item => item.id === formValues.issue) : formValues.issue
            let issueIs = filterIssue?.length > 0 ? { label: filterIssue[0].ticket_jira_key, value: filterIssue[0].id } : formValues.issue
            setFormValues(
                {
                    ...formValues,
                    'project': projectIs,
                    "activity": activityIs,
                    "issue": issueIs,
                    "from_time": formValues.from_time ? formValues.from_time?.split(":")[0] + ":" + formValues.from_time?.split(":")[1] : formValues.from_time,
                    "to_time": formValues.to_time ? formValues.to_time?.split(":")[0] + ":" + formValues.to_time?.split(":")[1] : formValues.to_time

                })
            dispatch(dailyWorkFlowSummaryUpdate('type'))
            toast.error(daily_work_log_update.message)
        }
        setReload(false)
        setActiveEdit(false)
    }

    const handleClickEdit = (id) => {
        dispatch(dailyWorkFlowSummaryEdit(id))
        setReload('edit_data')
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_daily_work_log_edit` && daily_work_log_edit) {
        if (daily_work_log_edit.status === 200) {
            let editData = daily_work_log_edit.data
            setFormValues({
                resource: { label: editData?.resource?.name, value: editData?.resource_id },
                activity: { label: editData?.activity?.activity_title, value: editData?.activity_id },
                project: { label: editData?.project?.project_name, value: editData?.project_id },
                work_summary: editData?.work_summary,
                date: editData?.date,
                from_time: editData.from_time ? editData.from_time?.split(":")[0] + ":" + editData.from_time?.split(":")[1] : editData.from_time,
                to_time: editData.to_time ? editData.to_time?.split(":")[0] + ":" + editData.to_time?.split(":")[1] : editData.to_time,
                duration_display: editData?.duration_display,
                id: editData?.id,
                issue: { label: editData?.issue?.ticket_jira_key, value: editData?.issue_id },

            })
            let isTypeJira = activityList?.rows?.length > 0 && activityList?.rows.filter(item => item.id === editData.activity_id && (item.activity_title === "Jira" || item.activity_title === "jira"))
            if (isTypeJira.length > 0) {
                setShowIssue(true)
                setActiveEdit(true)
                allProjectByfilter("True")
                if (editData?.project_id) {
                    setTaskLoader(true)
                    taskUpdatedList(0, editData?.project_id)
                }
            }
        } else {
            setActiveEdit(false)
            toast.error(daily_work_log_edit.message)
        }
        setReload(false)
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
    }

    const handleClickArchive = (data) => {
        setArchiveOpen(false)
        let copyRows = [...gridRows];
        let index = copyRows.findIndex(item => item.id === data.id);
        copyRows.splice(index, 1, { ...skeletonBase, id: data.id });
        setGridRows(copyRows);
        let ID = data.id;
        setReload('delete')
        dispatch(dailyWorkFlowSummaryDelete(ID))
    }

    // update section 
    if (reload === 'delete' && update_status === `${STATUSES.SUCCESS}_daily_work_log_delete` && daily_work_log_delete && daily_work_log_delete.message) {
        if (daily_work_log_delete.status === 200) {
            toast.success(daily_work_log_delete.message)
            setGridRows([])
            refresh()
            clearGridListing.current = true
            setLoadTypeData("delete")
            dispatch(dailyWorkFlowSummaryDelete('type'))
        } else {
            dispatch(dailyWorkFlowSummaryDelete('type'))
            toast.error(daily_work_log_delete.message)
        }
        setReload(false)
    }

    const filterString = (filterKeyOperator = "filter") => {
        let filterData
        if (toggelDWS === false) {
            if (resourceType == 'all-filled') {
                filterData = `{"${filterKeyOperator}":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

            } else if (resourceType == 'not-filled') {
                filterData = `{"${filterKeyOperator}":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":25, "not_filled":"True","columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

            } else {
                filterData = `{"${filterKeyOperator}":{"resource":{"value":${resource},"operation": "eq"},"date":{"value":"${new_date}","operation":"eq"}},"skip":0, "limit":25,"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`

            }
        } else {
            filterData = `{ "filter": { "status": {"value": "True","operation": "eq"} },"date":"${new_date}","skip":0, "limit":25,"order_by":{"column":"name"},"columns":["id", "name","email", "emp_code"]}`

        }
        return filterData
    }

    const onGridFilterChange = (props) => {
        if (props.items.length > columns.length)
            return;

        if (props.items.length === 0) {
            setFilterModel(props)
            setGridRows([]);
            setFilterQuery(filterString());
            return;
        }
        stopOnRowScrollEnd.current = false;
        setLimit(25)
        if (filterModal?.items?.length > props?.items?.length) {
            setFilterModel(props)
            if (props?.items?.length === 0) {
                setFilterQuery(`{"filter":{"resource":{"value":${resource},"operation":"eq"},"date":{"value":"${new_date}","operation":"eq"}},"skip":0,"limit":25,"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`)
            }
            if (props?.items?.length > 0) {
                let filterItems = props.items;
                let operators = { contains: 'icontains', equals: 'ieq', is: 'eq', startsWith: 'istartsWith', endsWith: 'iendsWith' }
                if (filterItems?.length) {
                    let valContainers = filterItems.filter(item => item?.value)
                    if (valContainers?.length) {
                        let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                        // if no changes on filter open starts
                        let filterKeyOperator = props.linkOperator === "and" ? "filter" : "or"
                        let count
                        if (props?.items?.length > 0) {
                            count = props?.items.reduce((c, { columnField: key }) => (c[key] = (c[key] || 0) + 1, c), {});
                        }
                        if (count.activity_id > 1 || count.issue > 1 || count.work_summary > 1 || count.project_id > 1 || count.to_time > 1 || count.from_time > 1 || count.duration_display > 1) {
                            toast.warn("Please select distinct column fields!")
                            return;
                        }
                        let strin = JSON.parse(filterString(filterKeyOperator))
                        if (filter) {

                            strin[filterKeyOperator] = filter
                        }
                        strin[filterKeyOperator]["date"] = { value: new_date, operation: "eq" }
                        if (resourceType == "") {
                            strin[filterKeyOperator]["resource"] = { value: resource, operation: "eq" }

                        }
                        setReload(true)
                        setGridRows([])
                        setFilterQuery(JSON.stringify(strin));

                    }
                }
            }
            return;
        } else if (filterModal?.items?.length < props?.items?.length && props?.items?.length <= 5) {
            setFilterModel(props)
            return;
        } else if (filterModal?.items?.length === props?.items?.length) {
            setFilterModel(props)
            let filterItems = props.items;
            let operators = { contains: 'icontains', equals: 'ieq', is: 'eq', startsWith: 'istartsWith', endsWith: 'iendsWith' }
            if (filterItems?.length) {
                let valContainers = filterItems.filter(item => item?.value)
                if (valContainers?.length) {
                    let filter = valContainers.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                    // if no changes on filter open starts
                    props.items.map((elem, index) => {
                        if ((filterModal.items[index].columnField !== elem.columnField || filterModal.items[index].operatorValue !== elem.operatorValue || filterModal.items[index].value !== elem.value))
                            return isAnyChange.current[index] = true;
                        else
                            return isAnyChange.current[index] = false;

                    })
                    // no change (! reverse by not operator)
                    if (!(Object.values(isAnyChange.current).every(item => item === false))) {
                        setGridRows([]);
                    }
                    let filterKeyOperator = props.linkOperator === "and" ? "filter" : "or"
                    let count
                    if (props?.items?.length > 0) {
                        count = props?.items.reduce((c, { columnField: key }) => (c[key] = (c[key] || 0) + 1, c), {});
                    }
                    if (count.activity_id > 1 || count.issue > 1 || count.work_summary > 1 || count.project_id > 1 || count.to_time > 1 || count.from_time > 1 || count.duration_display > 1) {
                        toast.warn("Please select distinct column fields!")
                        return;
                    }
                    let strin = JSON.parse(filterString(filterKeyOperator))
                    if (filter) {

                        strin[filterKeyOperator] = filter
                    }
                    strin[filterKeyOperator]["date"] = { value: new_date, operation: "eq" }
                    if (resourceType == "") {
                        strin[filterKeyOperator]["resource"] = { value: resource, operation: "eq" }

                    }
                    setReload(true)
                    setGridRows([])
                    setFilterQuery(JSON.stringify(strin));

                }
            }
        }
    }

    const handleMenuAction = (actionType) => {
        let row = gridRows.find(item => item.id === selectedRow);
        if (row?.resource_id === GetLocalstorage.userDetail()?.id) {
            switch (actionType) {
                case 'delete':
                    handleArchive(row);
                    break;
                case 'edit':
                    handleClickEdit(row.id)
                    break;
                default:
            }
            setContextMenu(null);
        } else {
            setContextMenu(null);
            return toast.warning('Only creator perform edit and delete action!')
        }
    }

    const handleContextMenu = (event) => {
        event.preventDefault();
        setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
        setContextMenu(
            contextMenu === null
                ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
                : null
        );
    };

    // sorting
    const handleSort = (sort) => {
        setGridRows([])
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = Number(limit);
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            // dispatch(getAllTemplateCategoryList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{"filter":{"resource":{"value":${GetLocalstorage?.userDetail().id},"operation":"eq"},"date":{"value":"${new_date}","operation":"eq"}},"skip":0,"limit":25,"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`)
        }
    }

    const myRecordsList = (id) => {
        setClearOpen(true)
        setShowResourceName(false)
        setResourceType("")
        setResource(id)
        setResourceLabel({ value: id, label: userName })
        clearGridListing.current = true;
        setColumnVisibilityModel({ id: false, resource__name: false })
        let filterData = JSON.stringify(combineBothFilter('resource', id, 'resource'))
        // let filterData = `{"filter":{"resource":{"value":${id},"operation": "eq"},"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"skip":0, "limit":${Number(limit)},"order_by":{"column":["resource__name", "from_time"]},"columns":[${DefaultFilterColumns.WORK_LOG_SUMMARY_COLUMNS}]}`
        setFilterQuery(filterData)
        setLoadTypeData("myrecord")
        let filterActivity = activityList?.rows?.length > 0 ? activityList?.rows.filter(item => item.id === formValues.activity) : formValues.activity
        let filterProject = projectList?.rows?.length > 0 ? projectList?.rows.filter(item => item.id === formValues.project) : formValues.project
        let projectIs = filterProject.length > 0 ? { label: filterProject[0].project_name, value: filterProject[0].id } : formValues.project;
        let activityIs = filterActivity.length > 0 ? { label: filterActivity[0].activity_title, value: filterActivity[0].id } : formValues.activity;
        let filterIssue = issueList?.length > 0 ? issueList?.filter(item => item.id === formValues.issue) : formValues.issue
        let issueIs = filterIssue?.length > 0 ? { label: filterIssue[0].ticket_jira_key, value: filterIssue[0].id } : formValues.issue
        setFormValues(
            {
                ...formValues,
                'project': projectIs,
                "activity": activityIs,
                "issue": issueIs,
                "from_time": formValues.from_time ? formValues.from_time?.split(":")[0] + ":" + formValues.from_time?.split(":")[1] : formValues.from_time,
                "to_time": formValues.to_time ? formValues.to_time?.split(":")[0] + ":" + formValues.to_time?.split(":")[1] : formValues.to_time

            })
        setTimeout(() => {
            setClearOpen(false)
        }, 200)
    }

    const hideMenuAction = () => {
        setContextMenu(null)
    }

    const combineBothFilterCSV = (name, value, type) => {
        let tempFilter = { "filter": {}, "order_by": { "column": ["resource__name", "from_time"] }, "no_limit": "True", "for_download": "True" }
        if (filterModal?.items?.length) {
            let flag = false
            filterModal?.items.map(e => {
                if (e.value) {
                    flag = true
                }
            })
            if (flag === true) {
                let operators = { contains: 'icontains', equals: 'ieq', is: 'eq', startsWith: 'istartsWith', endsWith: 'iendsWith' }

                let filter = filterModal?.items?.reduce((acc, item) => ({ ...acc, [item.columnField]: { value: item.value, operation: operators[item.operatorValue] } }), {})
                if (filter) {

                    tempFilter['filter'] = filter
                }
            }
        }

        if (type == 'resource') {
            tempFilter['filter']['date'] = { "value": name == 'date' ? value : new_date, "operation": "eq" }
            tempFilter['filter']['resource'] = { "value": name == 'date' ? resource : value, "operation": "eq" }
        }
        if (type == 'all-filled') {
            tempFilter['filter']['date'] = { "value": name == 'date' ? value : new_date, "operation": "eq" }
        }

        return tempFilter
    }



    const downloadCsvFile = async () => {
        let filterData
        if (resourceType == 'all-filled') {
            filterData = JSON.stringify(combineBothFilterCSV('date', new_date, 'all-filled'))
        } else if (resourceType == 'not-filled') {
            filterData = `{"filter":{"date":{"value":"${new_date}","operation":"eq"}},"order_by":{"column":["resource__name", "from_time"]},"no_limit":"True","for_download" :"True", "not_filled":"True"}`

        } else {
            filterData = JSON.stringify(combineBothFilterCSV('resource', resource, 'resource'))

        }
        if (toggelDWS === true) {
            filterData = `{ "filter": { "status": {"value": "True","operation": "eq"} },"date":"${new_date}","no_limit":"True","for_download" :"True","order_by":{"column":"name"},"columns":["id", "name", "email", "emp_code"]}`

        }
        const res = toggelDWS === true ? await dataService.daily_worklog_summary_team_efficiency(filterData) : await dataService.daily_worklog_summary_list(filterData)
        const response = res?.data
        if (response && response?.status === 200) {
            if (response?.data) {
                let api_url = response?.data && response?.data?.download_api_url;
                if (REACT_APP_API_BASEURL && api_url) {
                    let url = REACT_APP_API_BASEURL + api_url;
                    if (url) {
                        let new_url = url?.replace("api//", "api/")
                        window.open(new_url);

                    }
                }
            }
        } else {
            toast.error(response.message)
        }

    }

    // useEffect(() => {
    //     if (formValues.project && formValues.activity && showIssue === true && taskloader === "success" && activeEdit === false) {
    //         let isTypeJira = activityList?.rows?.length > 0 && activityList?.rows.filter(item => item.id === formValues.activity && (item.activity_title === "Jira" || item.activity_title === "jira"))
    //         if (isTypeJira.length > 0) {
    //             formValues?.project && taskUpdatedList(0, formValues?.project)
    //         }

    //     }

    //     if (daily_work_log_edit.data && showIssue === true && taskloader === "success" && activeEdit === true) {
    //         let isTypeJira = activityList?.rows?.length > 0 && activityList?.rows.filter(item => item.id === daily_work_log_edit?.data?.activity_id && (item.activity_title === "Jira" || item.activity_title === "jira"))
    //         if (isTypeJira.length > 0) {
    //             daily_work_log_edit?.data?.project_id && taskUpdatedList(0, daily_work_log_edit?.data?.project_id)
    //         }
    //         setActiveEdit(false)

    //     }
    // }, [taskloader, activeEdit])




    const refreshToggel = () => {
        setRefreshLoading(true)
        clearGridListing.current = true
        let filterData = `{ "filter": { "status": {"value": "True","operation": "eq"} },"date":"${new_date}","skip":0, "limit":25,"order_by":{"column":"name"}, "columns":["id", "name", "email", "emp_code"]}`
        setLoadTypeData("refresh")
        apiCallTeamList(filterData)
    }

    useEffect(() => {
        setGridRows([])
        if (toggelDWS === true) {
            let filterData = `{ "filter": { "status": {"value": "True","operation": "eq"} },"date":"${new_date}","skip":0, "limit":25,"order_by":{"column":"name"}, "columns":["id", "name", "email", "emp_code"]}`
            apiCallTeamList(filterData)
        } else {
            refresh()
        }
    }, [toggelDWS])

    const syncProjectJira = async () => {
        setProjectSync(true)
        if (formValues.project) {
            let filterProject = projectList?.rows?.length > 0 ? projectList?.rows.filter(item => item.id === formValues.project) : formValues.project
            let jira_project_id = filterProject?.length > 0 && filterProject[0].jira_project_id;
            if (jira_project_id) {
                const res = await api.get(`/jira/get-issues/0/50?query=project=${jira_project_id}&is_global=1`)
                if (res && res?.data?.status === 200) {
                    setProjectSync(false)
                    toast.success(res?.data?.message)
                    setTaskLoader(true)
                    let projectId = formValues?.project?.value ? formValues?.project?.value : formValues?.project;
                    taskUpdatedList(0, projectId)
                } else {
                    toast.error(res?.data?.message)
                    setProjectSync(false)
                }
            } else {
                toast.warning("Project is missing please contact to admin!")
            }

        } else {
            toast.warning("Select Project")
        }
    }

    const menuScroll = (scroll) => {
        let skips = Number(projectIssueFilter.skip)
        let countSkip = Number(issueCount)
        let updateDataLength = issueList?.length;
        if (scroll.isTrusted === true && countSkip > updateDataLength) {
            let projectId = formValues?.project?.value ? formValues?.project?.value : formValues?.project;
            setTaskLoader(true)
            taskUpdatedList(25 + skips, projectId)
        }
    }

    const searchIssueAPICall = async (evt) => {
        let projectId = formValues?.project?.value ? formValues?.project?.value : formValues?.project;
        let filterProject = projectList?.rows?.length > 0 ? projectList?.rows.filter(item => item.id === projectId) : projectId
        let project_id = filterProject?.length > 0 && filterProject[0].id;
        let filters = projectIssueFilter
        filters["filter"]["project"] = { "value": project_id, "operation": "eq" }
        filters["filter"]["ticket_jira_key"] = { "value": evt, "operation": "icontains" }
        filters.skip = 0

        await dataService.get_jira_task_issue_list(JSON.stringify(filters)).then((res) => {
            let response = res.data;
            if (res?.data?.status === 200) {
                let listData = response?.data?.rows
                let copyLoadedRows = listData;
                let fout = []
                copyLoadedRows = copyLoadedRows.filter(item => {
                    if (fout.includes(item.id)) return false
                    fout.push(item.id)
                    return true
                })
                setIssueCount(response?.data?.count)
                if (copyLoadedRows.length > 0) {
                    setTaskLoader(false)
                    setIssueList(copyLoadedRows)
                } else {
                    toast.info('This ticket was not found!')
                    setTaskLoader(true)
                    taskUpdatedList(0, projectId)
                }
            } else {
                setTaskLoader(false)
                toast.error(response.message)
            }
        }).catch(err => {
            setTaskLoader(false)
            toast.error(err)
        })

    }

    const filetrIssueName = (evt) => {
        if (evt) {
            searchIssueAPICall(evt)
        }
    }

    return (
        <div>
            <ToastContainer />
            <Box
                sx={{ width: 1500 }}
                role="presentation"
            >
                <div>
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={() => { setOpenModal(false); handleClose() }}
                        aria-label="close"
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            color: "#fff"
                        }}
                    >
                        <Close />
                    </Button>

                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography > Daily Worklog Summary  </Typography>
                            <Switch checked={toggelDWS ? true : false} onChange={() => setToggelDWS(!toggelDWS)} inputProps={{ 'aria-label': 'ant design' }} />
                            <Typography >Show Team Efficiency</Typography>
                        </Stack>

                    </DialogTitle>
                    <Divider />
                </div>

                <form>

                    {/* filtet section  */}
                    {clearOpen === true ? <LoaderForm /> :
                        <div className="add-new-user-form">
                            {toggelDWS === false &&
                                <div className="formInput" style={{ flexBasis: "25%" }}>
                                    <label>  Resources  </label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        sx={{ height: "40px" }}
                                        options={
                                            createAllResource?.length > 0 ? createAllResource?.map((option) => {
                                                return {
                                                    label: option?.label,
                                                    value: option.id,
                                                    name: "resource"
                                                }
                                            }) : []
                                        }
                                        defaultValue={resourceLabel}
                                        onChange={onFilterChange}
                                    />

                                    {(GetLocalstorage?.userDetail()?.id !== resource || showResourceName === true) &&
                                        <Typography variant="subtitle1" sx={{ cursor: "pointer", color: "#0e76cc" }} ml={"5px"}
                                            onClick={() => myRecordsList(GetLocalstorage.userDetail().id)} >
                                            My records
                                        </Typography>
                                    }
                                </div>
                            }
                            {toggelDWS === true &&
                                <div className="formInput" style={{ flexBasis: "12%", marginTop: "33px" }}>
                                    <Stack direction={"row"} alignItems={'center'}>
                                        <Typography variant="subtitle1" fontWeight={'bold'}> All DWS Filled  &nbsp; </Typography>
                                    </Stack>
                                </div>
                            }
                            <div className="formInput" style={{ flexBasis: "25%" }}>
                                <SingleDatePickerInputField
                                    dateFormat="YYYY-MM-DD"
                                    name="date"
                                    label={"Date"}
                                    placeholderText="DD-MM-YYYY"
                                    max={moment().format('YYYY-MM-DD')}
                                    onChange={onFilterChange}
                                    defaultValue={new_date}
                                />

                            </div>
                            <div className="formInput" style={{ flexBasis: "8%", marginTop: "15px" }}>
                                <Stack direction={"row"}>
                                    <div className="formInputWorkLog" style={{ marginTop: "3px", display: "flex", marginLeft: "2px" }}>
                                        <Tooltip title={'Download'} placement="top">
                                            <IconButton style={{ backgroundColor: "transparent" }}
                                                onClick={downloadCsvFile}

                                            >
                                                <CloudDownloadOutlined color="info" sx={{ fontSize: "35px" }} />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title={'Refresh'} placement="top">
                                            <IconButton onClick={toggelDWS === false ? refresh : refreshToggel} style={{ backgroundColor: "transparent" }}>
                                                <ReplayCircleFilled color="info" sx={{ fontSize: "35px" }} />
                                            </IconButton>
                                        </Tooltip>

                                    </div>
                                </Stack>
                            </div>
                            <div className="formInput" style={{ flexBasis: "36%", marginTop: "33px" }}>
                                {
                                    showResourceName === false && toggelDWS === false &&
                                    <Stack direction={"row"} alignItems={'center'}>
                                        <Typography variant="subtitle1" fontWeight={'bold'}>Total Logged Hours: &nbsp; </Typography>
                                        <Typography variant="subtitle1"> {totalHours}H</Typography>
                                        <Typography variant="subtitle1" fontWeight={'bold'} ml={'8px'}>Efficiency:  &nbsp;</Typography>
                                        <Typography variant="subtitle1"> {efficiency} </Typography>
                                    </Stack>
                                }
                            </div>
                        </div>
                    }

                    <Divider />

                    {/* add form  section*/}
                    {(reload === 'edit_data' || reload === 'submit' || reload === 'clear' || clearOpen === true) ? <LoaderForm /> :
                        GetLocalstorage?.userDetail()?.id === resource && new_date === moment().format('YYYY-MM-DD') && showResourceName === false && toggelDWS === false &&
                        <div className="add-new-user-form" style={{ marginTop: "20px" }}>
                            <Stack direction={"row"} width={"100%"} justifyContent={'space-between'}>
                                <Stack direction={"row"} justifyContent={'space-between'} width={"100%"}>
                                    <div className="formInputWorkLog">
                                        <label > Activity  <span style={{ color: "red" }}>*</span> </label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            sx={{ height: "40px" }}
                                            options={activityList?.rows?.length > 0 ? activityList?.rows.map((option) => {
                                                return {
                                                    label: option.activity_title,
                                                    value: option.id,
                                                    name: "activity"
                                                }
                                            }) : []}
                                            name="activity"
                                            onChange={handleChange}
                                            defaultValue={formValues.activity}
                                            key={""}
                                        />
                                    </div>
                                    <div className="formInputWorkLog" style={{ flexBasis: "18%" }}>
                                        <label>  Project  <span style={{ color: "red" }}>*</span></label>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            sx={{ height: "40px" }}
                                            options={projectList?.rows?.length > 0 ? projectList?.rows.map((option) => {
                                                return {
                                                    label: option.project_name,
                                                    value: option.id,
                                                    name: "project"
                                                }
                                            }) : []
                                            }
                                            name="project"
                                            onChange={handleChange}
                                            defaultValue={formValues.project}
                                        />
                                        <FormGroup>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography style={{ fontSize: 12 }}>Non Jira Project</Typography>
                                                <AntSwitch checked={jiraProject === "True" ? true : false} onChange={() => allProjectByfilter(jiraProject === "True" ? "False" : "True")} inputProps={{ 'aria-label': 'ant design' }} />
                                                <Typography style={{ fontSize: 12 }}>Is Jira Project</Typography>
                                            </Stack>

                                        </FormGroup>
                                    </div>

                                    {showIssue === true &&
                                        <div className="formInputWorkLog" style={{ flexBasis: "15%" }}>
                                            <label>  Select Issue  <span style={{ color: "red" }}>*</span></label>

                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isClearable
                                                isLoading={taskLoader}
                                                sx={{ height: "40px" }}
                                                options={issueList?.length > 0 ? issueList.map((option) => {
                                                    return {
                                                        label: option?.ticket_jira_key,
                                                        value: option?.id,
                                                        name: "issue"
                                                    }
                                                }) : []
                                                }
                                                onMenuScrollToBottom={menuScroll}
                                                name="issue"
                                                onChange={(evt) => handleChange(evt, 'issue')}
                                                defaultValue={formValues.issue}
                                                onInputChange={filetrIssueName}
                                            />
                                        </div>
                                    }
                                    {showIssue === true &&
                                        <div className="formInputWorkLog" style={{ marginTop: 33, flexBasis: 0 }}>
                                            {projectSync === true ?
                                                <Tooltip title={"Sync Jira Loading..."}>
                                                    <Sync color="#808080" />
                                                </Tooltip>
                                                :
                                                <Tooltip title={"Sync Jira"}>
                                                    <Sync style={{ color: '#0288d1' }} onClick={() => syncProjectJira()} />
                                                </Tooltip>
                                            }
                                        </div>
                                    }

                                    <div className="formInputWorkLog" style={showIssue === true ? { flexBasis: "20%" } : { flexBasis: "35%" }}>
                                        <label>Work Summary  <span style={{ color: "red" }}>*</span></label>
                                        <textarea
                                            type="text"
                                            label="Work Summary (*)"
                                            name={"work_summary"}
                                            onChange={handleChange}
                                            defaultValue={formValues.work_summary}
                                            style={{ height: "43px" }}
                                        ></textarea>
                                    </div>

                                    <div className="formInputWorkLog" style={{ flexBasis: "8%" }}>
                                        <InputFieldss
                                            type="time"
                                            label={<p>From Time<span style={{ color: "red" }}>*</span></p>}
                                            name="from_time"
                                            onChange={handleChange}
                                            defaultValue={formValues.from_time}
                                        />
                                    </div>

                                    <div className="formInputWorkLog" style={{ flexBasis: "8%" }}>
                                        <InputFieldss
                                            type="time"
                                            label={<p>To Time<span style={{ color: "red" }}>*</span></p>}
                                            name="to_time"
                                            onChange={handleChange}
                                            className={'formInputWorkLog'}
                                            defaultValue={formValues.to_time}

                                        />
                                    </div>

                                    <div className="formInputWorkLog" style={{ flexBasis: "8%" }}>
                                        <label> Duration <span style={{ color: "red" }}>*</span></label>
                                        {displayDuration === true ? "" :
                                            <input
                                                disabled
                                                name={"duration_display"}
                                                type="text"
                                                placeholder="Duration"
                                                onChange={handleChange}
                                                defaultValue={formValues.duration_display}
                                                style={{ width: '90px' }}
                                            />

                                        }

                                    </div>



                                </Stack>
                                <Stack direction={"row"}>
                                    <div className="formInputWorkLog" style={{ marginTop: "3px", display: "flex", marginLeft: "8px" }}>
                                        <Tooltip title={'Save'} placement="top">
                                            <IconButton style={{ backgroundColor: "transparent" }}
                                                onClick={handleSubmit}
                                                disabled={formValues?.duration_display.match("-") || !formValues.activity || !formValues.work_summary || !formValues.from_time || !formValues.to_time || !formValues.project || (showIssue === true && !formValues.issue) ? true : false}
                                            >
                                                <CheckCircleOutline color="success" sx={{ fontSize: "35px" }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Clear'} placement="top">
                                            <IconButton onClick={handleClear} style={{ backgroundColor: "transparent" }}>
                                                <HighlightOff color="error" sx={{ fontSize: "35px" }} />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Stack>
                            </Stack>
                        </div>
                    }

                    {/* listing form  section*/}
                    {refreshLoading === true && <LoaderForm />}
                    <DataGridPro
                        className="datagrid-drawer"
                        filterMode="server"
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                        rows={gridRows?.concat(skeletonRows)}
                        columns={toggelDWS === true ? columnsEffeciency : (GetLocalstorage?.userDetail()?.id === resource && new_date === moment().format('YYYY-MM-DD') && showResourceName === false) ? columns.concat(actionColumns) : columns}
                        filterModel={filterModal}
                        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'}
                        components={{
                            ColumnMenuIcon: MoreHoriz,
                            LoadingOverlay: LinearProgress,
                            NoRowsOverlay: CustomNoRowsOverlay,
                        }}
                        componentsProps={
                            (GetLocalstorage?.userDetail()?.id === resource && new_date === moment().format('YYYY-MM-DD')) && showResourceName === false &&
                            { row: { onContextMenu: handleContextMenu } }
                        }
                        deleteFilterItem
                        sx={{
                            height: 700,
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#438bf969",
                            },
                            ".actionButton": {
                                display: 'none'
                            },
                            [`& .${gridClasses.row}`]: {
                                "&:hover, &.Mui-hovered": {
                                    ".actionButton": {
                                        display: 'block'
                                    }
                                }
                            }
                        }}
                        onRowsScrollEnd={handleOnRowScrollEnd}
                        hideFooter
                        rowThreshold={.7}
                        onFilterModelChange={onGridFilterChange}
                        onSortModelChange={(sort) => handleSort(sort)}
                    />

                </form>

            </Box>


            {/* action menu */}
            <Menu
                open={contextMenu !== null}
                onClose={hideMenuAction}
                onClick={hideMenuAction}
                anchorReference="anchorPosition"
                anchorPosition={contextMenu !== null
                    ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                    : undefined
                }
                componentsProps={{
                    root: {
                        onContextMenu: (e) => {
                            e.preventDefault();
                            hideMenuAction();
                        }
                    }
                }}
            >
                <MenuItem onClick={() => handleMenuAction('edit')} sx={{ color: 'rgba(0,0,220,.8)' }}> <Edit sx={{ marginRight: '8px', fontSize: '.98rem' }} /> Edit</MenuItem>
                <MenuItem onClick={() => handleMenuAction('delete')} sx={{ color: 'rgba(220,0,0,.8)' }} > <Delete sx={{ marginRight: '8px', fontSize: '.98rem' }} />Delete</MenuItem>
            </Menu>

            {/* delete */}
            <DeleteDialogModal
                open={archiveOpen}
                onClose={setArchiveOpen}
                heading={'Delete ?'}
                paragraph={`Are you sure to delete`}
                handleArchive={handleClickArchive}
                id={archiveId}
            />

        </div >


    )
}

