import React, { useMemo, useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./resource.scss";
import { DataGrid, getGridSingleSelectOperators } from "@mui/x-data-grid";
import { resourceColumns } from "../../components/datatable-coulms/tableCoulmns";
import { Link, } from "react-router-dom";
import CONSTANTS from "../../components/constant/constantComponents";
import { useDispatch, useSelector } from "react-redux";
import { resourceList, resourceDeleteById, resourceSyncByJira, allRoleList, allDepartmentList, allEmpTypeList, allResourcesList, defaultresourcelistColumn } from "../../redux/features/resourceSlice";
import { STATUSES } from "../../redux/common-status/constant";
import { TablePagination, Tooltip } from "@mui/material";
import LoaderForm from "../../components/common-function/LoaderForm";
import Sync from '@mui/icons-material/Sync';
import { toast, ToastContainer } from "react-toastify";
import UcActionComponent from "../../components/common-function/UcActionComponent";
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import Filter from "../../components/Filter";
import TablePaginationActions from "../../components/TablePaginationActions";
import AddIcon from '@mui/icons-material/Add';
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { getGridStringOperators } from "@mui/x-data-grid";
import moment from "moment/moment";
const user = LocalStorageDataService.userDetail();
const tmsRole = user && user.tms_role

const default_resource_list_Columns = LocalStorageDataService.get_default_resource_list_column()


const ResourcesList = () => {
  const { REACT_APP_ENV } = process.env;
  const dispatch = useDispatch();
  // const navigate = useNavigate()
  const { data, status, error, deleteResource, syncResource, roleList, departmentList, empTypeList, activeResourceFilter, defaultresourcelistColumns } = useSelector(state => state.resource)
  const { data: list } = data;
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null)
  const [syncLoading, setSyncLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  const roleWiseFilter = tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin' || tmsRole.role_key === 'hr') ? `{"skip":0, "limit":25,"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.RESOURCE_LIST_COLUMNS}]}` : `{"filter":{"id":{"value":${user.id},"operation": "eq"}},"skip":0,"limit":25,"order_by":{"column":"-id"}}`
  const [filterQuery, setFilterQuery] = useState(roleWiseFilter)
  const [filterRoleQuery, setFilterRoleQuery] = useState([])
  const [filterDepartmentQuery, setFilterDepartmentQuery] = useState([])
  const [filterEmpTypeQuery, setFilterEmpTypeuery] = useState([])
  const [filterActiveResourcesQuery, setFilterActiveResourcesQuery] = useState([])
  const [fieldName, setFieldName] = useState("")
  const [options, setOptions] = useState({})
  const [defaultValue, setDefaultValue] = useState({})
  //columns visibility
  // const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false, tms_role_name: false });
  const [updateStatus, setUpdateStatus] = useState({})

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_resource_list_Columns && default_resource_list_Columns.all_resource_column ? default_resource_list_Columns.all_resource_column : {
    department_id: false,
    user_id: false,
    id: false,
    shift: false,
  });

  const [reload, setReload] = useState(false);
  const [filterClosingFields, setFilterClosingFields] = useState({ columnField: '', operatorValue: '' });

  const setKeyForList = (obj) => {
    let data;
    if (default_resource_list_Columns) {
      data = { 'all_resource_column': obj, 'reported_resource_column': default_resource_list_Columns.reported_resource_column, 'resourcelist_column': default_resource_list_Columns.resourcelist_column, 'task_column': default_resource_list_Columns.task_column }
    } else {
      data = { 'all_resource_column': obj }
    }
    if (defaultresourcelistColumns.reported_resource_column) {
      data['reported_resource_column'] = defaultresourcelistColumns.reported_resource_column
    }
    if (defaultresourcelistColumns.resourcelist_column) {
      data['resourcelist_column'] = defaultresourcelistColumns.resourcelist_column
    }
    if (defaultresourcelistColumns.task_column) {
      data['task_column'] = defaultresourcelistColumns.task_column
    }
    dispatch(defaultresourcelistColumn(data))
  }
  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message)
      setSyncLoading(false)
    }
    // eslint-disable-next-line
  }, [error])

  useEffect(() => {
    dispatch(allRoleList())
    dispatch(allDepartmentList())
    dispatch(allEmpTypeList())
    dispatch(allResourcesList())
    // dispatch(resourceList(roleWiseFilter))

    if (localStorage.getItem(`${REACT_APP_ENV}_resourcefilter`) !== null) {
      dispatch(resourceList(JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_resourcefilter`))))
    } else {
      dispatch(resourceList(roleWiseFilter))
    }
    // eslint-disable-next-line
  }, [])


  window.addEventListener("beforeunload", function (event) {
    if (localStorage.getItem(`${REACT_APP_ENV}_resourcefilter`) !== null) {
      localStorage.removeItem(`${REACT_APP_ENV}_resourcefilter`);
    }
  });



  const handleDelete = (id) => {
    setDeleteId(id)
    if (id && id.status === true) {
      setUpdateStatus("InActive")
    } else {
      setUpdateStatus("Active")
    }
    setOpen(true)
  }

  if (syncLoading === true && status === `${STATUSES.SUCCESS}_deleteResource` && deleteResource && deleteResource.status) {
    setSyncLoading(false)
    if (deleteResource.status === 200) {
      toast.success(deleteResource.message)
      dispatch(resourceList(roleWiseFilter))
    } else {
      toast.error(deleteResource.message)
    }
    // setTimeout(() => {
    //   dispatch(resourceList(roleWiseFilter))
    // }, 500);
  }

  if (syncLoading === true && status === `${STATUSES.SUCCESS}_syncResource` && syncResource && syncResource.message) {
    setSyncLoading(false)
    if (syncResource.status === 200) {
      toast.success(syncResource.message)
    } else {
      toast.error(syncResource.message)
    }
    setTimeout(() => {
      dispatch(resourceList(roleWiseFilter))
    }, 500);
  }

  if (status === `${STATUSES.SUCCESS}_roleList` && filterRoleQuery && filterRoleQuery.length === 0 && roleList && roleList.data && roleList.data.rows) {
    let array = []
    roleList.data && roleList.data.rows.map(e => { return array.push({ value: e.id, label: e.role_name, id: e.id }) })
    array.length > 0 && setFilterRoleQuery(array)
    array.length > 0 && setOptions({ ...options, tms_role: array })
  }


  if (status === `${STATUSES.SUCCESS}_departmentList` && filterDepartmentQuery && filterDepartmentQuery.length === 0 && departmentList && departmentList.data && departmentList.data.rows) {
    let array = []
    departmentList.data && departmentList.data.rows.map(e => { return array.push({ label: e.name, value: e.id, id: e.id }) })
    array.length > 0 && setFilterDepartmentQuery(array)
    array.length > 0 && setOptions({ ...options, department: array })
  }

  if (status === `${STATUSES.SUCCESS}_empTypeList` && filterEmpTypeQuery && filterEmpTypeQuery.length === 0 && empTypeList && empTypeList.data && empTypeList.data.rows) {
    let array = []
    empTypeList.data && empTypeList.data.rows.map(e => { return array.push({ value: e.id, label: e.employment_type, id: e.id }) })
    array.length > 0 && setFilterEmpTypeuery(array)
    array.length > 0 && setOptions({ ...options, employment_type: array })
  }

  if (status === `${STATUSES.SUCCESS}_resourceList` && filterActiveResourcesQuery && filterActiveResourcesQuery.length === 0 && activeResourceFilter && activeResourceFilter.data && activeResourceFilter.data.rows) {
    let array = []
    activeResourceFilter.data && activeResourceFilter.data.rows.map(e => { return array.push({ value: e.id, label: e.display_name + "(" + e.email + ")", id: e.id }) })
    array.length > 0 && setFilterActiveResourcesQuery(array)
    array.length > 0 && setOptions({ ...options, reporting_manager: array })
  }

  const handleClickOpen = (data) => {
    let id = data.id
    const request = {
      status: !data.status,
    }
    setSyncLoading(true)
    if (open === true) {
      dispatch(resourceDeleteById({ id, request }))
      setOpen(false)
    }
  }

  const syncResources = () => {
    setSyncLoading(true)
    dispatch(resourceSyncByJira())
  }
  const filterOperators = getGridStringOperators().filter(({ value }) =>
        ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
    );
  const resourceColumns = [
    // { field: "id", headerName: "ID", width: 80,  },
    { field: "emp_code", headerName: "Emp. Code", width: 150,filterOperators, },
    {
      field: "name",
      headerName: "Name",
      width: 200,filterOperators,

      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.name}
          </div>
        );
      },
    },
    {
      field: "display_name",
      headerName: "Display Name",
      width: 150,
      filterOperators,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.display_name}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 300,filterOperators, },
    {
      field: "is_interviewer", headerName: "Is Interviewer", width: 175,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.is_interviewer ? 'Yes' : 'No'}
          </div>
        );
      }
    },
    {
      field: "shift",
      headerName: "Shift",
      width: 300,
      filterOperators,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.shift?.shift_name ? params.row.shift.shift_name : params.row.shift} &nbsp;
            {
              params.row.shift ?
                (params.row.shift_start_time ? "(" + moment("10/10/2022 " + params.row.shift_start_time).format('LT') : "") + " - " + (params.row.shift_end_time ? moment("10/10/2022 " + params.row.shift_end_time).format('LT') : "") + ")" : ""
            }
          </div>
        );
      },
    },
    { field: "contact_no", headerName: "Contact No.", width: 150,filterOperators, },
    { field: "jira_account", headerName: "Jira Account", width: 200 ,filterOperators,},
  ];
  const actionColumn = [
    {
      field: "department",
      headerName: "Department",
      width: 150,
      filterOperators,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.department && params.row.department.name ? params.row.department.name : params.row.department_name}
          </div>
        );
      },
    },
    {
      field: "tms_role",
      headerName: "Role",
      width: 150,
      filterOperators,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.tms_role && params.row.tms_role.role_name ? params.row.tms_role.role_name : params.row.tms_role_name}
          </div>
        );
      },
    },
    {
      field: "employment_type",
      headerName: "Employment Type",
      filterOperators,
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.employment_type && params.row.employment_type.employment_type ? params.row.employment_type.employment_type : params.row.employment_type_name}
          </div>
        );
      },
    },
    {
      field: "reporting_manager",
      headerName: "Manager",
      width: 250,
      type: 'singleSelect',
      valueOptions: filterActiveResourcesQuery,
      renderCell: (params) => {
        return (
          <div className="cellWithStatus">
            {params.row.reporting_manager_name}
          </div>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      width: 160,
      filterOperators,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.status === true ? 'Active' : 'Inactive'}
          </div>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 120,
      hideable: false,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <UcActionComponent
              moduleName="resource"
              rowid={params.row.id}
              addMoreData={params.row}
              editLinkUrl={`edit/${params.row.id}`}
              viewLinkUrl={`view/${params.row.id}`}
              isDelete={true}
              resfilterQuery={filterQuery}
              deleteIconName="ArchiveIcon"
              editButton={false}
              viewButton={false}
              deleteButton={handleDelete}
              activeInactive={"resourceList"}
              status={params.row.status}
            />
          </div>
        );
      },
    },
  ];

  const onFilterChange = (filterModel, r) => {

    if (filterModel.items.length) {
      setFilterClosingFields(() => ({ columnField: filterModel.items[0].columnField, operatorValue: filterModel.items[0].operatorValue }))
    }

    if (filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem") {
      setReload(true);
      setTimeout(() => {
        setReload(false)
        setCurrentPage(0)
        // setPageSize(25)
        setFilterQuery(roleWiseFilter)
        dispatch(resourceList(roleWiseFilter))
        setDefaultValue("")
      }, 0);
    }

    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
      setFieldName(filterModel.items[0].columnField)
    }
    if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value && filterModel.items[0].value != '"' && !/[\\#&"]/.test(filterModel.items[0].value)) {
      let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
        filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
          filterModel.items[0].operatorValue === "equals" ? 'eq' :
            filterModel.items[0].operatorValue === "is" ? 'eq' :
              filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
      const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":${limit},"order_by":{"column":"-id"}, "columns":[${DefaultFilterColumns.RESOURCE_LIST_COLUMNS}]}`
      setFilterQuery(filterData)
      setCurrentPage(0)
      dispatch(resourceList(filterData))
      localStorage.setItem(`${REACT_APP_ENV}_resourcefilter`, JSON.stringify(filterData))
    }
    // if (filterModel.items && filterModel.items.length === 0 || !filterModel) {
    //   setCurrentPage(0)
    //   // setPageSize(25)
    //   setFilterQuery(roleWiseFilter)
    //   dispatch(resourceList(roleWiseFilter))
    //   setDefaultValue("")
    // }
  }

  // Custom filter
  function CustomFilterInputValue(props) {
    const { item, applyValue } = props;
    const handleFilterChange = (evt) => {
      if (evt) {
        setDefaultValue(evt)
        const { value } = evt;
        applyValue({ ...item, value: value });
      }
    };
    return (
      <Filter options={options[fieldName]} onChange={(e) => handleFilterChange(e)} defaultValue={defaultValue} />
    );
  }

  const columns = useMemo(() =>
    actionColumn.map((col) => {
      if (col.field === "status") {
        return col;
      }
      return {
        ...col,
        filterOperators: getGridSingleSelectOperators()
          .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
          .map((operator) => ({
            ...operator,
            InputComponent: operator.InputComponent
              ? CustomFilterInputValue
              : undefined
          }))
      };
    }),
    // eslint-disable-next-line
    [actionColumn]
  );

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = data * pageSize
    newFilterData['limit'] = limit
    //setLimit(limit)
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(resourceList(JSON.stringify(newFilterData)))
  }

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize.target.value)
    setSyncLoading(false)
    setCurrentPage(0)
    setLimit(newPageSize.target.value)
    let newFilterData = JSON.parse(filterQuery);
    newFilterData['skip'] = 0
    newFilterData['limit'] = newPageSize.target.value
    setFilterQuery(JSON.stringify(newFilterData))
    dispatch(resourceList(JSON.stringify(newFilterData)))
  }

  const handleClose = () => {
    setOpen(false)
  }

  function CustomPagination() {
    return (
      <TablePagination
        component="div"
        count={(list && list.rows && list.count) || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={LocalStorageDataService.get_page_item_list()}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) =>
          <TablePaginationActions {...subProps}
            handlePagination={handlePagination}
          />}
      />
    );
  }

  // sorting
  const handleSort = (sort) => {
    if (sort.length) {
      let newFilterData = JSON.parse(filterQuery);
      newFilterData['skip'] = 0;
      newFilterData['limit'] = limit;
      newFilterData['order_by'] = {
        "column": sort[0].sort === "desc" ?
          '-' + sort[0].field : sort[0].field
      }
      setFilterQuery(JSON.stringify(newFilterData))
      dispatch(resourceList(JSON.stringify(newFilterData)))
    } else {
      setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" }, "columns":[${DefaultFilterColumns.RESOURCE_LIST_COLUMNS}] }`)
    }
  }

  return (
    <div className="resource">
      <Sidebar />
      <div className="resourceContainer">
        <Navbar />
        <ToastContainer />
        <div className="datatable">
          <div className="top-headings">
            <h3>{CONSTANTS.RESOURCE.LIST}</h3>
            <div>
              <Tooltip title="Jira Status ">
                <button className="btn-add-new" onClick={() => syncResources()} >
                  <Sync />
                </button>
              </Tooltip>
              <Tooltip title="Add new Resource">
                <Link to="add" className="btn-add-new">
                  <AddIcon />
                </Link>
              </Tooltip>
              <RefreshButton api={resourceList} filter={filterQuery} style={{ marginTop: 5 }} />
            </div>
          </div>
          <div className="custom-pagination-class">
            <CustomPagination />
          </div>
          {(syncLoading === true || status === STATUSES.LOADING) && <LoaderForm />}
          {
            !reload &&
            <DataGrid
              columnVisibilityModel={defaultresourcelistColumns && defaultresourcelistColumns.all_resource_column ? defaultresourcelistColumns.all_resource_column : columnVisibilityModel}
              onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
              }
              className="datagrid"
              rows={list && list.rows ? list.rows : []}
              columns={resourceColumns.concat(columns)}
              pageSize={pageSize}
              style={{ fontSize: 16 }}
              onFilterModelChange={onFilterChange}
              components={{
                Pagination: CustomPagination,
                NoRowsOverlay: CustomNoRowsOverlay
              }}
              onSortModelChange={(sort) => handleSort(sort)}
              hideFooterPagination={true}
            />
          }
          {/* {error && <p style={{ color: "#96332c", fontSize: 12 }}> {error.message}</p>} */}
        </div>


        {/* for delete popup */}
        <DeleteDialogModal
          open={open}
          onClose={handleClose}
          heading={updateStatus}
          paragraph={`Are You Sure To ${updateStatus} this Resource`}
          handleArchive={handleClickOpen}
          id={deleteId}
        />
      </div>

    </div>
  )
}

export default ResourcesList