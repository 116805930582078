import { Skeleton, Stack, Switch, Tooltip, Typography } from "@mui/material";
import moment from "moment";
import { Navigate } from "react-router-dom";

export const countryColumns = [
  { field: "id", headerName: "ID", width: 70 },
  {
    field: "name",
    headerName: "Country Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.name}
        </div>
      );
    },
  },
  { field: "code", headerName: "Code", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

export const resourceColumns = [
  // { field: "id", headerName: "ID", width: 80,  },
  { field: "emp_code", headerName: "Emp. Code", width: 150 },
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "display_name",
    headerName: "Display Name",
    width: 150,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.display_name}
        </div>
      );
    },
  },
  { field: "email", headerName: "Email", width: 300 },
  {
    field: "is_interviewer", headerName: "Is Interviewer", width: 175,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.is_interviewer ? 'Yes' : 'No'}
        </div>
      );
    }
  },
  {
    field: "shift",
    headerName: "Shift",
    width: 300,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.shift?.shift_name ? params.row.shift.shift_name : params.row.shift} &nbsp;
          {
            params.row.shift ?
              (params.row.shift_start_time ? "(" + moment("10/10/2022 " + params.row.shift_start_time).format('LT') : "") + " - " + (params.row.shift_end_time ? moment("10/10/2022 " + params.row.shift_end_time).format('LT') : "") + ")" : ""
          }
        </div>
      );
    },
  },
  { field: "contact_no", headerName: "Contact No.", width: 150 },
  { field: "jira_account", headerName: "Jira Account", width: 200 },
];

//columns heading for department
export const departmentColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Department Name", width: 230 },
  { field: "code", headerName: "Code", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for role
export const roleColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "role_name", headerName: "Role Name", width: 230 },
  { field: "role_key", headerName: "Role Key", width: 230 },
  { field: "role_description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

/// attendance report status column 
export const attendanceReportStatusColumns = [
  //{ field: "id", headerName: "ID", width: 70 },
  { field: "emp_code", headerName: "Employee Number", width: 230 },
  { field: "display_name", headerName: "Employee Name", width: 230 },
  { field: "total_day", headerName: "Total Days", width: 230 },
  { field: "actual_working_day", headerName: "Actual Working Day", width: 230 },
  { field: "present_days", headerName: "Present Days", width: 230 },
  { field: "total_holiday", headerName: "Total Holidays", width: 230 },
  { field: "total_weekend", headerName: "Total Weekend", width: 230 },
];


//columns heading for user type
export const userTypeColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "type_name", headerName: "User Type Name", width: 230 },
  { field: "type_key", headerName: "User Type Key", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for Employment type
export const employementColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "employment_type", headerName: "Employment Type Name", width: 290 },
  //{ field: "employment_type_key", headerName: "Employment Type Key", width: 270 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for credential Heads
export const credentialHeadColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "head_name", headerName: "Head Name", width: 250 },
  { field: "description", headerName: "Description", width: 330 },
  {
    field: "status",
    headerName: "Status",
    width: 120,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for resource credential
export const resourceCredentialColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  {
    field: "credentails_head",
    headerName: "Credentail Head",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.credentails_head}
        </div>
      );
    },
  },
  {
    field: "resource",
    headerName: "Resource Name",
    width: 230,
    renderCell: (params) => {
      return (
        <div>
          {params.row.resource}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for user type
export const jiraStatusTypeColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Status Name", width: 230 },
  {
    field: "short_code", headerName: "Short Code", width: 230,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.short_code}`}
          style={{
            backgroundColor: params.row.workflow_status_category?.background_color_code,
            color: params.row.workflow_status_category?.foreground_color_code
          }}
        >
          {params.row.short_code ? params.row.short_code : "N/A"}
        </div>
      );
    },
  },

  {
    field: "workflow_status_category_id", headerName: "Category", width: 230, filterable: false,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.workflow_status_category}`}>
          {params.row.workflow_status_category?.status_category_name ? params.row.workflow_status_category.status_category_name : "N/A"}
        </div>
      );
    },
  },
  { field: "description", headerName: "Description", width: 300 },

  // {
  //   field: "is_jira_status",
  //   headerName: "Is Jira Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.is_jira_status}`}>
  //         {params.row.is_jira_status === true ? 'Yes' : 'No'}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

//columns heading for credential Heads
export const sprintColumn = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "sprint_name", headerName: "Sprint Name", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
  {
    field: "is_jira_status",
    headerName: "Is Jira Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.is_jira_status}`}>
          {params.row.is_jira_status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
];

export const resourceReportColumns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.name}
        </div>
      );
    },
  },
  { field: "email", headerName: "Email", width: 300 },
  { field: "emp_code", headerName: "Employee Code", width: 300 }
];

export const attendanceReportColumns = [
  {
    field: "punchin_date",
    headerName: "Date",
    type: 'date',
    width: 200
  },
  { field: "punchin_time", headerName: "Punch In Time", width: 200 },
  { field: "punch_out_time", headerName: "Punch Out Time", width: 200 }
];

export const attendanceDetailsReportColumns = [
  {
    field: "created_on", headerName: "Date", type: 'date', width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {moment(params.row.created_on).format('YYYY-MM-DD')}
        </div>
      );
    },
  },
  { field: "durations", headerName: "Duration", width: 200 },
  { field: "break", headerName: "Break Time", width: 200 },
  { field: "punchin_time", headerName: "Punch In Time", width: 200 },
  { field: "punch_out_time", headerName: "Punch Out Time", width: 200 }
];

export const taskListColumns = [

  {
    field: "issue_type_icon", headerName: "Issue Type", width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.issue_type?.icon_url ?
            <img src={params.row.issue_type.icon_url} alt="" />
            :
            params.row.issue_type_icon ?
              <img src={params.row.issue_type_icon} alt="" />
              :
              <div></div>
          }
          {"  "}{params.row.issue_type?.issue_name ? params.row.issue_type.issue_name : params.row.issue_type}
        </div>
      );
    }
  },
  {
    field: "issue_type", headerName: "Issue Name", width: 200, hideable: true,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.issue_type?.issue_name ? params.row.issue_type.issue_name : params.row.issue_type}
        </div>
      );
    }
  },
  { field: "ticket_key", headerName: "RTMS Key", width: 200 },
  { field: "ticket_jira_key", headerName: "JIRA Key", width: 200 },
  { field: "summary", headerName: "summary", width: 300 },
  { field: "story_point", headerName: "Story Point", width: 300 },
  { field: "sprint", headerName: "Sprint", width: 300 },
  {
    field: "created_on", headerName: "Created Date", width: 200, type: "date",
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.created_on ? moment(params.row.created_on).format('YYYY-MM-DD') : ""}
        </div>
      );
    }
  },
  {
    field: "updated_on", headerName: "Updated Date", width: 200, type: "date",
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.updated_on ? moment(params.row.updated_on).format('YYYY-MM-DD') : ""}
        </div>
      );
    }
  },
  {
    field: "is_jira_issue", headerName: "Is Jira Issue", width: 200,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.is_jira_issue}`}>
          {params.row.is_jira_issue === true ? 'Active' : 'Inactive'}
        </div>
      );
    }
  },



]

//columns heading for month wise attendance report
export const MonthWiseAttendanceReportColumns = [
  { field: "emp_code", headerName: "Emp Code", sortable: false, filterable: false, width: 130 },
  { field: "display_name", headerName: "Name", sortable: false, filterable: false, width: 230 },
  { field: "attendance_month", headerName: "Month", sortable: false, filterable: false, width: 130 },
  { field: "total_working_days", headerName: "Total Working Days", sortable: false, filterable: false, width: 230 },
  { field: "actual_working_days", headerName: "Actual Working Days", sortable: false, filterable: false, width: 220 },
  { field: "total_working_hours", headerName: "Total Working Hours", sortable: false, filterable: false, width: 230 },
  { field: "break_duration_minutes", headerName: "Total Break Hours", sortable: false, filterable: false, width: 230 },
  { field: "total_effective_working_hours", headerName: "Total Effective Working Hours", sortable: false, filterable: false, width: 300 },
  { field: "expected_working_hours", headerName: "Expected Working Hours", sortable: false, filterable: false, width: 250 },
]

//columns heading for Menu List
export const menuListColumn = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "title", headerName: "Title", width: 230 },
  { field: "url_slug", headerName: "Slug", width: 230 },
  { field: "description", headerName: "Description", width: 230 },
  {
    field: "roles",
    headerName: "Permission",
    width: 300,
    filterable: false,
    renderCell: (params) => {
      let list = params.row.roles && params.row.roles.length > 0 ? params.row.roles : []
      let resArr = [];
      list.length > 0 && list.filter(function (item) {
        let i = resArr.findIndex(x => (x.role_id === item.role_id && x.menu_id === item && x.status === item.status));
        if (i <= -1) {
          resArr.push(item);
        }
        return null;
      });
      return (
        <div className={`cellWithStatus ellips-css-data`}>
          {resArr.length > 0 && resArr.map((e, index) => `${index ? ', ' : ''}${(e.role_name).trim()}`)}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];
//columns heading for attendance report
export const AttendanceReportColumns = [
  {
    field: "punchin_date", headerName: 'Date', type: 'date', width: 150, sortable: false, filterable: false, renderCell: (params) => {
      return (
        <div style={params.row.punchin_date === 'No Of Days' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.punchin_date}
        </div>
      );
    },
  },
  {
    field: "name", headerName: 'Name', width: 210, sortable: false, filterable: false, renderCell: (params) => {
      return (
        <div style={params.row.name === 'Total Break Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.name}
        </div>
      );
    }
  },
  {
    field: "break_hours", headerName: 'Break Hours', sortable: false, filterable: false, width: 240, renderCell: (params) => {
      return (
        <div style={params.row.break_hours === 'Total Effective Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.break_hours}
        </div>
      );
    }
  },
  {
    field: "effective_working_hours", headerName: 'Actual Working Hours', sortable: false, filterable: false, width: 240,
    renderCell: (params) => {
      return (
        <div style={params.row.effective_working_hours === 'Total Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.effective_working_hours}
        </div>
      );
    }
  },
  {
    field: "working_hours", headerName: 'Total Working Hours', sortable: false, filterable: false, width: 240,
    renderCell: (params) => {
      return (
        <div style={params.row.working_hours === 'Total Expected Working Hours' ? { fontWeight: 'bold' } : {}} className={`cellWithStatus`}>
          {params.row.working_hours}
        </div>
      );
    }
  },
  { field: "expected_working_hours", headerName: 'Expected Working Hours', sortable: false, filterable: false, width: 240 },
  { field: "emp_code", headerName: 'Emp Code', width: 150, sortable: false, filterable: false },
  { field: "punchin_time", headerName: 'Punch In Time', width: 200, sortable: false, filterable: false, },
  { field: "punch_out_time", headerName: 'Punch Out Time', width: 240, sortable: false, filterable: false, },
  { field: "expected_punch_in_date_time", headerName: 'Expected Punch-In Date-Time', sortable: false, filterable: false, width: 280 },
  { field: "expected_punch_out_date_time", headerName: 'Expected Punch-Out Date-Time', sortable: false, filterable: false, width: 280 },
]

//columns heading for third party
export const ThirdPartyColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "name", headerName: "Name", width: 230 },
  { field: "code", headerName: "Code", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

//columns heading for third party
export const JobPostColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  {
    field: "job_title",
    headerName: "Job Title",
    width: 230,
    renderCell: (params) => {
      return <div onClick={() => {
        Navigate(`/epic-task/${params.row.id}`)
      }}>
        {params.row.job_title}
      </div>
    }
  },
  {
    field: "job_description", headerName: "Job Description", width: 300, height: 200,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ellips-css-data`}>
          <Tooltip title={<span dangerouslySetInnerHTML={{ __html: params.row.job_description }} />} >
            <span dangerouslySetInnerHTML={{ __html: (params.row.job_description).substring(0, 15) + "..." }} />
          </Tooltip>
        </div>
      );
    }
  },
  { field: "work_mode", headerName: "Work Mode", width: 300 },
  {
    field: "job_experience", headerName: "Work Experience", width: 150,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          {params.row.job_experience} {params.row.experience_type}
        </div>
      );
    }
  },
  {
    field: "industry__industry_name", headerName: "Industry", width: 230,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          {params.row.industry ? params.row.industry.industry_name : "N/A"}
        </div>
      );
    }
  },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 220,
  //   renderCell: (params) => {
  //     const createJobPostStatus = Object.freeze({
  //       // 0: "Request for approval",
  //       // 1: "Approval pending",
  //       // 2: "Approved",
  //       // 3: "Rejected",
  //       // 4: "Live",
  //       // 5: "On Hold",
  //       // 6: "Closed"

  //       0: "Pending",
  //       1: "Request For Approval",
  //       2: "Approved",
  //       3: "Rejected",
  //       4: "Live",
  //       5: "On Hold",
  //       6: "Closed"
  //     })
  //     return (
  //       <div className={`cellWithStatus`}>
  //         {/* + params.row.status */}
  //         {createJobPostStatus[params.row.approver_status] ? createJobPostStatus[params.row.approver_status] : "-"}
  //       </div>
  //     );
  //   },
  // }
];

export const SkillColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "skill_name", headerName: "Skills", width: 230 },
  // { field: "skill_key", headerName: "Skill-Key", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const InterviewScheduleStatusColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "status_name", headerName: "Status Name", width: 230 },
  { field: "description", headerName: "Description", width: 350 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const IndustryColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "industry_name", headerName: "Industry Name", width: 230 },
  { field: "industry_type", headerName: "Industry Type", width: 230 },
  { field: "description", headerName: "Description", width: 300 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const QualificationColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "education_qualification_name", headerName: "Education Name", width: 230 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const phoneCallDetailsColumn = [
  { field: 'id', headerName: 'ID', width: 10 },
  { field: 'destination_number', headerName: 'Destination Number', width: 160 },
  { field: 'agent_name', headerName: 'Agent Name', width: 160 },
  { field: 'call_duration', headerName: 'Call Duration(in sec)', width: 200 },
  { field: 'answer_duration', headerName: 'Answer Duration(in sec)', width: 200 },
  {
    field: "direction", headerName: "Call Type", width: 200,
    type: 'singleSelect',
    valueOptions: [{ value: 0, label: 'In-bound' }, { value: 1, label: 'Out-bound' }],
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.direction}`}>
          {params.row.direction === 1 ? 'Out-bound' : 'In-bound'}
        </div>
      );
    },
  },
  {
    field: "call_date", headerName: "Call Date", type: 'date', width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {moment(params.row.call_date).format('DD-MM-YYYY')}
        </div>
      );
    },
  },
  {
    field: 'call_time',
    headerName: 'Call Time',
    width: 150,
  },
  { field: 'state', headerName: 'State', width: 180 },
  {
    field: 'description',
    headerName: 'Description',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.description ? params.row.description : ""} >
          <div className="cellWithStatus">
            {params.row.description}
          </div>
        </Tooltip >
      )
    }
  },
];

export const myPhoneCallDetailsColumn = [
  { field: 'id', headerName: 'ID', width: 10 },
  { field: 'destination_number', headerName: 'Destination Number', width: 190 },
  { field: 'call_duration', headerName: 'Call Duration(in sec)', width: 200 },
  { field: 'answer_duration', headerName: 'Answer Duration(in sec)', width: 200 },
  {
    field: "direction", headerName: "Call Type", width: 200,
    type: 'singleSelect',
    valueOptions: [{ value: 0, label: 'In-bound' }, { value: 1, label: 'Out-bound' }],
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.direction}`}>
          {params.row.direction === 1 ? 'Out-bound' : 'In-bound'}
        </div>
      );
    },
  },
  {
    field: "call_date", headerName: "Call Date", type: 'date', width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {moment(params.row.call_date).format('DD-MM-YYYY')}
        </div>
      );
    },
  },
  {
    field: 'call_time',
    headerName: 'Call Time',
    width: 150,
  },
  { field: 'state', headerName: 'State', width: 180 },
  {
    field: 'description',
    headerName: 'Description',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.description ? params.row.description : ""} >
          <div className="cellWithStatus">
            {params.row.description}
          </div>
        </Tooltip >
      )
    }
  },
];

export const zoomCallDetailsColumn = [
  { field: 'id', headerName: 'ID', width: 10 },
  { field: 'zoom_meeting_id', headerName: 'Zoom Meeting Id', width: 200 },
  { field: 'topic', headerName: 'Topic', width: 250 },
  {
    field: 'agenda',
    headerName: 'Agenda',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.agenda ? params.row.agenda : ""} >
          <div className="cellWithStatus">
            {params.row.agenda}
          </div>
        </Tooltip >
      )
    }
  },
  { field: 'duration', headerName: 'Duration (in min)', width: 210 },
  {
    field: 'host_email',
    headerName: 'Host Email',
    width: 250,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.host_email ? params.row.host_email : ""} >
          <div className="cellWithStatus">
            {params.row.host_email}
          </div>
        </Tooltip >
      )
    }
  },
  {
    field: 'uuid',
    headerName: 'UUID',
    width: 210,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.uuid ? params.row.uuid : ""} >
          <div className="cellWithStatus">
            {params.row.uuid}
          </div>
        </Tooltip >
      )
    }
  },
  {
    field: 'created_on',
    headerName: 'Created On',
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          {moment(params.row.created_on).format('DD-MM-YYYY HH:mm')}
        </div>
      );
    },
  },
];

export const ReportMasterColumns = [
  { field: "report_name", headerName: "Report Name", width: 240 },
  { field: "report_key", headerName: "Report Key", width: 230 },
  { field: "description", headerName: "Description", width: 310 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const DefaultsettingColumns = [
  {
    field: "setting_name", headerName: "Setting Name", width: 230, renderCell: (params) => {
      return (
        <Tooltip title={params.row.setting_name ? params.row.setting_name : ""} >
          <div>{params.row.setting_name} </div>
        </Tooltip>);
    },
  },
];

export const RemainderColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  // {
  //   field: "story", headerName: "RTMS key", width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus`}>
  //         {params.row.story && params.row.story.ticket_key}
  //       </div>
  //     );
  //   }
  // },
  // {
  //   field: "project", headerName: "Project", width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus`}>
  //         {params.row.project && params.row.project.project_name}
  //       </div>
  //     );
  //   }
  // },
  {
    field: "notification_date", headerName: "Date And Time", width: 230, type: "date",
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          {moment(params.row.notification_date + ' ' + params.row.notification_time).format('DD-MM-YYYY HH:mm A')}
        </div>
      );
    }
  },
  {
    field: "comment", headerName: "Comment", width: 230,
    renderCell: (params) => {
      return (
        <Tooltip title={params.row.comment}>
          <div className={`cellWithStatus ellips-css-data`}>
            {params.row.comment}
          </div>
        </Tooltip>
      );
    }
  },
  // {
  //   field: "resource_id", headerName: "Resource", width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus`}>
  //         {params.row.resource && params.row.resource.display_name}
  //       </div>
  //     );
  //   }
  // },
  // {
  //   field: "is_viewed",
  //   headerName: "Mark Read",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.is_viewed}`}>
  //         {params.row.is_viewed === true ? 'Read' : 'Unread'}
  //       </div>
  //     );
  //   },
  // }
];

export const screeningFormColumns = [
  { field: "title", headerName: "Screening Question Set", width: 340 },
  // { field: "form_key", headerName: "Form Key", width: 340 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];


//marketing table column 
export const sourceTypeColumns = [
  { field: "source_type_name", headerName: "Source Type Name", width: 240 },
  { field: "description", headerName: "Description", width: 340 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const communicationTypeColumns = [
  // { field: "id", headerName: "ID", width: 70, filterable: false, },
  { field: "communication_type_name", headerName: "Communication Type Name", width: 300 },
  { field: "description", headerName: "Description", width: 340 },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];

export const ResumeParseColumns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "file_name", headerName: "File Name", width: 250 },
  // {
  //   field: "epic", headerName: "Epic", width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus`}>
  //                   {params.row.epic}

  //       </div>
  //     )
  //   }
  // },
  {
    field: "project", headerName: "Project Name", width: 160, filterable: false,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          {params.row.project.project_name}
        </div>
      )
    }
  },
  {
    field: "parsed_data",
    headerName: "Contact Information",
    width: 350,
    filterable: false,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          {params.row.parsed_data.Value?.ResumeData.ContactInformation &&
            <div style={{ backgroundColor: '#fffbfb', padding: '10px' }}>
              <p><span>Candidate Name :</span> {params.row.parsed_data.Value.ResumeData.ContactInformation.CandidateName?.GivenName}</p>
              <p><span>Location :</span>{params.row.parsed_data.Value.ResumeData.ContactInformation.Location?.StreetAddressLines}</p>
              <p><span>Contacts :</span>{params.row.parsed_data.Value.ResumeData.ContactInformation.Telephones?.map((e, index) => `${index ? ', ' : ''}${(e.SubscriberNumber).trim()}`)}</p>
              <p><span>Emails :</span>{
                params.row.parsed_data.Value.ResumeData.ContactInformation.emailsddresses ?
                  params.row.parsed_data.Value.ResumeData.ContactInformation.emailsddresses.map((e, index) => `${index ? ', ' : ''}${(e).trim()}`)
                  :
                  params.row.parsed_data.Value.ResumeData.ContactInformation.EmailAddresses.map((e, index) => `${index ? ', ' : ''}${(e).trim()}`)}
              </p>
            </div>}
        </div>
      );
    },
  },
];


export const InterviewColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  {
    field: "candidate", headerName: "Candidate Name", width: 230, filterable: false, renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          <Tooltip title={params.row.candidate?.candidate_name}>
            <p>{params.row.candidate?.candidate_name}</p>
          </Tooltip>
        </div>
      )
    }
  },
  {
    field: "interviewer", headerName: "Interview Name", width: 230, filterable: false, renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          {params.row.interviewer?.display_name}
        </div>
      )
    }
  },

  {
    field: "meeting_url", headerName: "Meeting Url", width: 300, filterable: false, renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          <Tooltip title={params.row.meeting_url}>
            <p>{params.row.meeting_url}</p>
          </Tooltip>
        </div>
      )
    }
  },
  { field: "scheduled_date", headerName: "Scheduled Date", width: 150, filterable: false, type: 'date' },
  { field: "scheduled_time", headerName: "Scheduled Time", width: 150, filterable: false },
  {
    field: "job", headerName: "Job Title", width: 230, filterable: false, renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          <Tooltip title={params.row.job?.job_title}>
            <p>{params.row.job?.job_title}</p>
          </Tooltip>
        </div>
      )
    }
  },
  {
    field: "feedback_by_interviewer", headerName: "Interviewer Feedback", width: 300, filterable: false, renderCell: (params) => {
      return (
        <div className={`cellWithStatus`}>
          <Tooltip title={params.row.feedback_by_interviewer}>
            <p>{params.row.feedback_by_interviewer}</p>
          </Tooltip>
        </div>
      )
    }
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    filterable: false,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];
//availability table column 
export const availabilityColumns = [
  {
    field: "resource_id", headerName: "Interviewer Name", width: 240, filterable: false,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.interviewer_details?.name ? params.row.interviewer_details.name : "N/A"}
        </div>
      );
    },
  },
  {
    field: "from_date", headerName: "From Date", type: 'date', width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.from_date ? moment(params.row.from_date).format('DD-MM-YYYY') : "N/A"}
        </div>
      );
    },
  },
  {
    field: "expiry_date", headerName: "To Date", type: 'date', width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithStatus">
          {params.row.expiry_date ? moment(params.row.expiry_date).format('DD-MM-YYYY') : "N/A"}
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    width: 160,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  }
];


//columns heading for Evaluation project
export const EvaluationProjectColumns = [
  { field: "id", headerName: "ID", width: 70, filterable: false, },
  // {
  //   field: "project", headerName: "Project Name", width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus`}>
  //         {params.row.project && params.row.project.project_name}
  //       </div>
  //     );
  //   },
  // },
  // {
  //   field: "role", headerName: "Project Role", width: 230,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus`}>
  //         {params.row.role && params.row.role.role_name}
  //       </div>
  //     );
  //   }
  // },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   filterable:false,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status === true ? 'Active' : 'Inactive'}
  //       </div>
  //     );
  //   },
  // }


];

//template category table column 
export const templateCategoryColumns = [
  // { field: "id", headerName: "ID", width:"10px" },
  {
    field: "template_category", headerName: "Category Name", width: 240, renderCell: params => {
      return (
        params?.row?.template_category === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.template_category}</Typography>
      )
    }
  },
  {
    field: "description", headerName: "Description", width: 340, renderCell: params => {
      return (
        params?.row?.description === "_skeleton_" ? <Skeleton width={"300px"} /> : <Typography>{params?.row?.description}</Typography>
      )
    }

  },

];

// master status category grid columns
export const masterStatusCategoryColumns = [
  {
    field: "id",
    headerName: "ID",
    width: 200,
    filterable: false
  },
  {
    field: "status_category_name",
    headerName: "Category Name",
    width: 200,
  },
  {
    field: "foreground_color_code",
    headerName: "Text Color Code",
    width: 200,
    renderCell: (params) => {
      return (
        <div>
          {params?.row?.foreground_color_code ? params?.row?.foreground_color_code.slice(1,) : "N/A"}
        </div>
      );
    },
  },
  {
    field: "background_color_code",
    headerName: "Background Color Code",
    width: 200,
    renderCell: (params) => {
      return (
        <div>
          {params?.row?.background_color_code ? params?.row?.background_color_code.slice(1,) : "N/A"}
        </div>
      );
    },
  },
  {
    field: "key",
    headerName: "Key",
    width: 200,
  },
  {
    field: "description",
    headerName: "Description",
    width: 200,
  },
  {
    field: "status",
    headerName: "Status",
    width: 200,
    renderCell: (params) => {
      return (
        <div className={`cellWithStatus ${params.row.status}`}>
          {params.row.status === true ? 'Active' : 'Inactive'}
        </div>
      );
    },
  },
]