import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./manage-client.scss"
import { toast, ToastContainer } from "react-toastify";
import { Checkbox, FormControlLabel, Radio, RadioGroup, Tooltip, } from "@mui/material";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountryList, getPurchesdpackage, getStateList, getIndustryList, ClientcrudCreat, getIndustryDepartmentListData, getIndusDepSpecListData, getServiceTypeList, getLocationList, getIndsDepSpecSkill, ClientcrudUpdate, fileUpload, emptyFileData } from "../../redux/features/masterSlice";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
// import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import LoaderForm from "../../components/common-function/LoaderForm";
import { InputFields } from "../../components/common-function/InputFields";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
// import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import Select from 'react-select';
import dataService from "../../redux/services/data.service";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { getresumeSource, getresumetemplate, postUploadResume } from "../../redux/features/masterSlice";
import formdata_api from "../../redux/APIS/formdata_api";
import { set } from "date-fns";
const initialFormData = Object.freeze({
    name: "",
    address: "",
    country: "",
    state: "",
    city: "",
    client_status: null,
    zip: null,
    service_type: "",
    purchased_package: "",
    contact_person: [{
        name: "",
        contact: "",
        email: "",
    }],
    skills_data: [{
        industry: null,
        department: null,
        specialization: null,
        skills: null,
        industry_data: null,
        department_data: null,
        specialization_data: null,
        skill_datas: null

    }],

});

const initialFormDataForIDS = {
    industry: null,
    department: null,
    specialization: null,
    industry_data: null,
    department_data: null,
    specialization_data: null,
    skills: null,
    skill_datas: null
}


const formErrData = Object.freeze({
    nameErr: "Name is required",
    addressErr: "Client Address is required",
    countryErr: "Country upto date is required",
    stateErr: "State is required",
    cityErr: "City is required",
    contact_Person_NameErr: "Contact Person Name is required",
    contact_Person_Email_IdErr: "Contact Person email is required",
    contact_Person_Phone_NumberErr: "Contact Person Phone Number is required",
    // departmentsErr: "Department is required",
    client_statusErr: "Status is required",
    industryErr: "Industry is required",
    // specializationErr: "specialization is required",
    // skillsErr: "Skills is required",
    serviceErr: "service is required",
    zipErr: "Zip Code is required",
    purchasePackageErr: "purchasePackage is required"
});


const AddnewClient = ({ title }) => {
    // const { REACT_APP_ENV } = process.env;
    const { REACT_APP_API_BASEURL } = process.env;
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [open, setOpen] = useState(false);
    const [formErr, setFormErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, country_List, state_List, industry_List, clientCrud_Update, clientCrud_creat, industryDeprt_List, industryDepSpeci_List, servicetype_List, purchasedPackeges_List, location_List, indsDepSpecSkill_List, fileUploadData, clientCrud_Edit } = useSelector(state => state.master)
    const [formValues, setFormValues] = useState(initialFormData)
    const [formValuesForIDS, setFormValuesForIDS] = useState([initialFormDataForIDS])

    const [ID, setID] = useState("");
    const [reload, setReload] = useState(false)
    const [filterQuery, setFilterQuery] = useState(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True","columns":[${DefaultFilterColumns.PURCHASED_PACKAGE_LIST_COLUMNS}]}`)
    const [countryfilterQuery, setCountryFilterQuery] = useState(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True","columns":[${DefaultFilterColumns.COUNTRY_LIST_COLUMNS}]}`)

    const [updatedStateList, setUpdatedStateList] = useState([])
    const [updatedDepList, setUpdatedDepList] = useState([])
    const [updatedspecList, setUpdatedspecList] = useState([])
    const [updatedSkillList, setUpdatedSkillList] = useState([])
    // const [industryId, setIndustyId] = useState(null)
    const [mobValid, setMobValid] = useState(false)
    const [zipValid, setZipValid] = useState(false)
    const [file, setFile] = useState('')
    const [logoname, setlogoName] = useState(null)
    const [addContactPerson, setAddContactPerson] = useState({
        name: "",
        contact: "",
        email: "",
    })
    const [skillData, setSkillData] = useState({
        industry: null,
        department: null,
        specialization: null,
        skills: null,
    })
    // const [skillShowData, setSkillShowData] = useState({
    //     industry: null,
    //     department: null,
    //     specialization: null,
    //     skills: null,
    // })
    const [countryId, setCountryId] = useState(null)
    const [updatedCityList, setUpdatedCityList] = useState([])
    const [logo, setLogo] = useState(null);

    // const location = useLocation()
    // const pathName = location?.pathname || "";
    // const isEdit = location?.state?.isEdit || "";

    const [selectedIndustry, setSelectedIndustry] = useState(null);
    const [validateErr, setValidateErr] = useState('');
    const [readFileData, setReadFileData] = useState('')
    const [apiUrl,setApiUrl]=useState((REACT_APP_API_BASEURL && REACT_APP_API_BASEURL.slice(0, -1)) || "")

    useEffect(() => {
        dispatch(getLocationList(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getPurchesdpackage(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getServiceTypeList(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        // dispatc(`{"filter":{"client_status":{'value':1,"operation": "eq"}},"no_limit":"True"}`))
        dispatch(getIndustryList(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True","columns":[${DefaultFilterColumns.INDUSTRY_LIST_COLUMNS}]}`))
        dispatch(getIndustryDepartmentListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getIndusDepSpecListData(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getIndsDepSpecSkill(`{"filter":{"status":{'value':"True","operation": "eq"}},"order_by":{"column":"-id"},"no_limit":"True"}`))
        dispatch(getStateList(`{"filter":{"status":{'value':"True","operation": "eq"}},"no_limit":"True","order_by":{"column":"-id"},"columns":[${DefaultFilterColumns.STATE_LIST_COLUMNS}]}`))
        dispatch(getCountryList(countryfilterQuery))
        dispatch(getPurchesdpackage(filterQuery))
        if (status === STATUSES.FAILURE && error) {
            setReload(false)
            errorHandling(error, navigate)
        }
        // if (id) {
        //     loadData();
        // }
        // eslint-disable-next-line
    }, [dispatch])

    useEffect(() => {
        if (id && industryDepSpeci_List?.data) {
            loadData();
        }
        if (title === 'Add New Client') {
            dispatch(emptyFileData())
        }
    }, [id, industryDepSpeci_List])

    if (reload === true && status === `${STATUSES.SUCCESS}_clientCrud_Update` && clientCrud_Update && clientCrud_Update.message) {
        if (clientCrud_Update.status === 200) {
            setLogo('');
            toast.success(clientCrud_Update.message)
            setTimeout(() => {
                navigate("/all-client")
            }, 2000)


        } else {
            toast.error(clientCrud_Update.message)
        }
        setReload(false)
    }


    if (reload === true && status === `${STATUSES.SUCCESS}_clientCrud_creat` && clientCrud_creat && clientCrud_creat.message) {

        if (clientCrud_creat.status === 200) {

            toast.success(clientCrud_creat.message)

            setTimeout(() => {
                navigate("/all-client")
            }, 2000)
        } else {
            toast.error(clientCrud_creat.message)
        }
        setReload(false)
    }
    if (reload === 'imageUpload' && status === `${STATUSES.SUCCESS}_fileUpload` && fileUploadData && fileUploadData.message) {

        if (fileUploadData.status === 200) {
            setFile(fileUploadData?.data?.api_path_for_download)
            // setLogo(fileUploadData?.data?.display_name)
            setLogo(fileUploadData?.data?.api_path_for_download)
            setlogoName(fileUploadData?.data?.display_name)

            toast.success(fileUploadData.message)

        } else {
            toast.error(fileUploadData.message)
        }
        setReload(false)
    }

    // const handleClose = () => {
    //     if (ID) { setID(0); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
    //     setErrStatus(false);
    // }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const handleCountryChange = (e) => {
        const statelist = state_List?.data?.rows?.filter((options) => options.country === e.value)
        setUpdatedStateList(statelist)
        setCountryId(e.value)
    }

    const handleStateChange = (e) => {
        const citylist = location_List?.data?.rows.map((options) => {
            if (e.value == options?.state_id && options?.country_id == countryId) {
                return options
            } else {
                return [];
            }
        }).flat()
        setUpdatedCityList(citylist ? citylist : [])
    }

    const handleIndustrychange = (evt, index) => {
        setUpdatedDepList([])

        const deplist = industryDeprt_List?.data?.rows?.filter((options) => options?.industry?.id === evt.value)
        setUpdatedDepList(deplist[0]?.department)

        const exectingData = [...formValuesForIDS]

        if (selectedIndustry !== evt.value) {
            exectingData[index] = {
                ...formValuesForIDS[index],
                industry: evt.value,
                industry_data: evt,
                department: null,
                department_data: null,
                specialization: null,
                specialization_data: null,
                departmentList: deplist[0]?.department ?? [],
            };
            setSelectedIndustry(evt.value);
        } else {
            exectingData[index] = {
                ...formValuesForIDS[index],
                industry: evt.value,
                industry_data: evt,
                department_data: null,
                departmentList: deplist[0]?.department ?? [],
            };
        }
        setFormValuesForIDS(exectingData)
    }
    const handleDepartmentChange = (evt, index) => {
        const speclist = industryDepSpeci_List?.data?.rows.filter((options) =>
            evt.value === options?.department_id && options.industry_id === formValuesForIDS[index].industry
        );

        setUpdatedspecList(speclist[0]?.specialization_data ?? [])

        const exectingData = [...formValuesForIDS]
        exectingData[index] = {
            ...formValuesForIDS[index],
            department: evt.value,
            department_data: evt,
            specialization: null,
            specialization_data: null,
            specializationList: speclist[0]?.specialization ?? [],
        };

        if (formValuesForIDS[index].industry !== selectedIndustry) {
            exectingData[index].industry = null;
            exectingData[index].industry_data = null;
        }

        setFormValuesForIDS(exectingData);
    };


    const handleSpecializationChange = (evt, index) => {
        const skilllist = indsDepSpecSkill_List?.data?.rows.filter((options) => evt.value === options?.specialization_id);
        const skilllist2 = skilllist?.filter((each, index) => {
            return index === skilllist.findIndex(o => each.skill.id === o.skill.id);
        });
        setUpdatedSkillList(skilllist2?.map(each => each.skill))

        const exectingData = [...formValuesForIDS]
        exectingData[index] = {
            ...formValuesForIDS[index],
            specialization: evt.value,
            specialization_data: evt,
            skills: null,
            skill_datas: null
        }
        setFormValuesForIDS(exectingData)


    }

    const handleCheckboxChange = (evt) => {
        setFormValues({ ...formValues, is_internal: evt.target.checked })
    };

    const addMoreIndustryDepartment = () => {
        setFormValuesForIDS([...formValuesForIDS, initialFormDataForIDS])
    }

    const removeMoreIndustryDepartment = (index) => {
        setFormValuesForIDS(formValuesForIDS.filter((itm, i) => i != index))
    }

    const handleLogoChange = (event) => {
        const selectedFile = event.target.files[0];
        setLogo(URL.createObjectURL(selectedFile));
        setReload('imageUpload')
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("upload_type", 'client');
        formData.append("display_name", selectedFile.name);
        dispatch(fileUpload(formData))
        setFile(selectedFile)
        readURL(selectedFile);
        // setLogo(URL.createObjectURL(selectedFile));
    };
    const handleSkill = (evt, index) => {
        // dispatch(getIndsDepSpecSkill(`{"filter":{"industry_id":{"value":${formValuesForIDS[index].industry},"operation": "eq"} ,"department_id":{"value":${formValuesForIDS[index].department},"operation": "eq"} ,"status":{"value":True,"operation": "eq"}},"order_by":{"column":"-id"},"skip":0, "limit":10}`))
        // setSkillData({ ...skillData, skills: evt.map((skill) => skill.value), skill_datas: evt })

        const exectingData = [...formValuesForIDS]
        exectingData[index] = {
            ...formValuesForIDS[index],
            skills: evt.map((skill) => skill.value),
            skill_datas: evt,
        }
        setFormValuesForIDS(exectingData)
    }

    const submitData = (e) => {
        e.preventDefault()
        // let isValidEmail = isValidEmail(addContactPerson.email)
        if (formValues.address === "" || formValues.name === "" || formValues.client_status === null || formValues.country === "" || formValues.state === "" || formValues.city === "" || formValues.zip === "" || addContactPerson.name === "" || addContactPerson.email === "" || addContactPerson.contact !== "" ?  mobValid === false : addContactPerson.contact === "" || zipValid === true) {
            setErrStatus(true)
        }
        if (!formValues.zip) {
            setZipValid(true);
        }
        const isValidate = validate(addContactPerson.email)



        if (isValidate?.email) {
            setErrStatus(true);
            setValidateErr(isValidate.email)
            setFormErr({ ...formErr, emailErr: 'Invalid email address' })
            return 0;
        }
        // if (!(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(addContactPerson.email))) {
        //     setFormErr({...formErr,emailErr : 'Invalid email address'})
        //     setErrStatus(true);
        // }
        if (formValues.address !== "" && formValues.name !== "" && formValues.client_status !== null && formValues.country !== "" && formValues.state !== "" && formValues.city !== "" && addContactPerson.name !== "" && addContactPerson.email !== "" && addContactPerson.contact !== "" && mobValid === true && zipValid !== true) {
            // const rtm_user = JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
            // let userId = rtm_user.user && rtm_user.user.data && rtm_user.user.data.id

            const { client_status } = formValues
            const skills_data = formValuesForIDS.map(item => {
                let { industry, department, specialization, skills } = item;
                // let dep = Number(department);
                // let spec = Number(specialization);
                let skill = skills?.map(each => Number(each))
                // return {industry,department: [dep],specialization: [spec], skills: skill}
                return { industry, department, specialization, skills: skill ?? [] }

            })

            let addvalues = {
                ...formValues,
                client_status: client_status * 1,
                // created_by: userId,
                contact_person: [addContactPerson],
                skills_data: skills_data,
                country: formValues.country && formValues.country.label ? formValues.country.value : formValues.country,
                state: formValues.state && formValues.state.label ? formValues.state.value : formValues.state,
                city: formValues.city && formValues.city.label ? formValues.city.value : formValues.city,
                purchased_package: formValues.purchased_package && formValues.purchased_package.label ? formValues.purchased_package.value : formValues.purchased_package,
                service_type: formValues.service_type && formValues.service_type.label ? formValues.service_type.value : formValues.service_type, logo: file ? file : logo
            }
            setErrStatus(false)

            setReload(true)
            if (ID) {
                dispatch(ClientcrudUpdate({ ID, addvalues: { ...addvalues } }))
                if (file) {
                    // const formData = new FormData();
                    // formData.append("file", file);

                    // formData.append("id", `${ID}`);
                    // formData.append("upload_type", `client`);
                    // formData.append("display_name", file.name);
                    // dispatch(fileUpload(formData))

                }

                dispatch(ClientcrudUpdate({ ID, addvalues }))

                // if (res?.status === 200) {
                //     toast.success("Client Updated Succesfully")
                //      navigate("/all-client")
                // }
            } else {
                dispatch(ClientcrudCreat(addvalues))
                // if (res?.status === 200) {
                //     toast.success("Client Added Succesfully")
                //     navigate("/all-client")
                // }
            }

            // setLogo(null)
        }



    }

    useEffect(() => {
        const exectingData = [...formValuesForIDS]

        const deplist = industryDepSpeci_List?.data?.rows.filter((options) =>
            options.industry_id === exectingData[0]?.industry_data?.value
        );
        exectingData[0] = {
            ...formValuesForIDS[0],

            departmentList: deplist ? deplist[0]?.department : [],
        }
        setFormValuesForIDS(exectingData)
    }, [industryDepSpeci_List])


    const loadData = async () => {
        setReload(true)
        const res = await dataService.client_crud_edit(id).catch(error => {
            setReload(false)
            return error;
        })

        if (res && res.data && res.data.data) {
            const editData = res.data.data
            setID(editData.id)
            setFormValues({
                name: editData.name,
                address: editData.address,
                country: {
                    // label: editData?.country?.name,
                    label:   (
                        <div>
                          {editData?.country?.logo && (
                            <img style={{ height: "20px", width: "30px", marginRight: "8px" }} src={`${apiUrl}${editData?.country?.logo}`} alt="" />
                          )}
                         {`(${editData?.country?.code}) ${editData?.country?.name}`} 
                        </div>
                      ),
                    value: editData?.country?.id,
                    name: "country"
                },
                state: {
                    label: editData?.state?.name,
                    value: editData?.state?.id,
                    name: "state"
                },
                city: {
                    label: editData?.city?.city_name,
                    value: editData?.city?.id,
                    name: "city"
                },
                zip: editData?.zip,
                client_status: editData?.client_status,
                service_type: {
                    label: editData?.service_type?.name,
                    value: editData?.service_type?.id,
                    name: "service_type"
                },
                purchased_package: {
                    label: editData?.purchased_package?.name,
                    value: editData?.purchased_package?.id,
                    name: "purchased_package"
                },
                contact_person: [{
                    name: editData?.contact_person[0]?.name,
                    contact: editData?.contact_person[0]?.contact,
                    email: editData?.contact_person[0]?.email,
                }],
                skills_data: [{
                    industry: editData?.skills_data[0]?.industry,
                    department: editData?.skills_data[0]?.department,
                    specialization: editData?.skills_data[0]?.specialization,
                    skills: editData?.skills_data[0]?.skill,
                }],
                industry: editData?.skills_data?.length > 0 && editData?.skills_data.map((item) => {
                    return {
                        label: item?.industry.industry_name,
                        value: item?.industry.id,
                        name: "industry"
                    }
                }),
                department: {
                    label: editData?.skills_data[0]?.department?.name,
                    value: editData?.skills_data[0]?.department?.id,
                    name: "department",
                },
                specialization: editData?.skills_data[0]?.specialization,
                skills: editData?.skills_data[0]?.skill,
                is_internal: editData.is_internal ? editData.is_internal : false,
                logo: editData?.logo

            })
            setLogo(editData?.logo)
            setlogoName(editData?.logo);

            // const deplist = industryDepSpeci_List?.data?.rows.filter((options) =>
            //     options.industry_id === editData?.skills_data[0]?.industry.id,
            // );

            const speclist = industryDepSpeci_List?.data?.rows.filter((options) =>
                editData?.skills_data[0]?.department?.id === options?.department_id
            );



            setUpdatedspecList(speclist[0]?.specialization_data ?? [])

            const skilllist = indsDepSpecSkill_List?.data?.rows.filter((options) => editData?.skills_data[0]?.specialization?.id === options?.specialization_id);

            setUpdatedSkillList(skilllist?.map(each => each.skill))
            const exectingData = [...formValuesForIDS]
            exectingData[0] = {
                ...formValuesForIDS[0],
                industry_data: {
                    label: editData?.skills_data[0]?.industry.industry_name,
                    value: editData?.skills_data[0]?.industry.id,
                    name: "industry"
                },

                department_data: {
                    label: editData?.skills_data[0]?.department?.name,
                    value: editData?.skills_data[0]?.department?.id,
                    name: "department",
                },
                specialization_data: { value: editData?.skills_data[0]?.specialization?.id, label: editData?.skills_data[0]?.specialization?.name, name: "specialization" },
                departmentList: [industryDepSpeci_List?.data?.rows?.filter((options) => options?.industry_id === editData?.skills_data[0]?.industry?.id)[0]?.department],
                industry: editData?.skills_data && editData?.skills_data[0]?.industry?.id,
                department: editData?.skills_data && editData?.skills_data[0]?.department?.id,
                specialization: editData?.skills_data && editData?.skills_data[0]?.specialization?.id,
                skills: editData?.skills_data && editData?.skills_data[0]?.skill,
                skill_datas: editData?.skills_data && editData?.skills_data[0]?.skills?.map(i => ({ value: i.id, label: i.skill_name, name: "skills" }))
            }

            // const deplist = industryDeprt_List?.data?.rows?.filter((options) => options?.industry?.id === evt.value)
            // setUpdatedDepList(deplist[0]?.department)
            setFormValuesForIDS(exectingData)

            isValidMobileNumber(editData?.contact_person[0]?.contact);
            setAddContactPerson({
                name: editData?.contact_person[0]?.name,
                contact: editData?.contact_person[0]?.contact,
                email: editData?.contact_person[0]?.email,
            })

            setSkillData({
                industry: editData?.skills_data && editData?.skills_data[0]?.industry,
                industry_data: editData?.skills_data && editData?.skills_data[0]?.industry_data,
                department: editData?.skills_data && editData?.skills_data[0]?.department,
                department_data: editData?.skills_data && editData?.skills_data[0]?.department_data,
                specialization: editData?.skills_data && editData?.skills_data[0]?.specialization,
                specialization_data: editData?.skills_data && editData?.skills_data[0]?.specialization_data,
                skills: editData?.skills_data && editData?.skills_data[0]?.skills,
                skill_datas: editData?.skills_data && editData?.skills_data[0]?.skill_datas
            })
            setReload(false)
        } else {
            setReload(false)
        }
    }

    const mobileNumberRegex = /^[6-9]\d{9}$/;

    function isValidMobileNumber(mobileNumber) {
        setErrStatus(true);
        if (mobileNumber.length < 10) {
            setMobValid(false)
            return setFormErr({ ...formErr, contact_Person_Phone_NumberErr: "Mobile number should have at least 10 digits." });
        } else if (mobileNumber.length > 10) {
            setMobValid(false)
            return setFormErr({ ...formErr, contact_Person_Phone_NumberErr: "Mobile number should not exceed 10 digits." });
        } else if (!mobileNumberRegex.test(mobileNumber)) {
            setMobValid(false)
            return setFormErr({ ...formErr, contact_Person_Phone_NumberErr: "Invalid mobile number. The first digit should be between 6 and 9." });
        } else {
            setMobValid(true)
            setErrStatus(false);
            return setFormErr({ ...formErr, contact_Person_Phone_NumberErr: "Valid mobile number." });
        }
    }

    function handleContactChange(e) {
        isValidMobileNumber(e.target.value)
        setAddContactPerson({ ...addContactPerson, contact: e.target.value })

    }
    const zipNumberRegex = /^(\d{5}(-\d{4})?|\d{6}|\d{7})$/;
    function isValidZip(zipNumber) {
        if (zipNumber.length < 6) {
            setZipValid(true);

            return setFormErr({ ...formErr, zipErr: "Zip Code should have at least 6 digits." });
        } else if (zipNumber.length === 7) {
            setZipValid(true);
            return setFormErr({ ...formErr, zipErr: "Invalid zip code" })
        }
        // else if (zipNumber.length > 6) {
        //     setZipValid(true)
        //     return setFormErr({ ...formErr, zipErr: "Zip Code should not exceed 6 digits." });
        // } 
        else if (!zipNumberRegex.test(zipNumber)) {
            setZipValid(true)
            return setFormErr({ ...formErr, zipErr: "Invalid Zip Code." })
        } else {
            setZipValid(false)
            return setFormErr({ ...formErr, zipErr: "Valid Zip Code." });
        }
    }

    function handleZipChange(e) {
        isValidZip(e.target.value)
        setFormValues({ ...formValues, zip: e.target.value })
    }
    function readURL(input) {

        let reader = new FileReader();
        reader.onload = function (e) {
            setReadFileData(e.target.result);
        };

        reader.readAsDataURL(input);
    }

    // const isValidEmail = (email) => {
    //     if (!email) {
    //         setFormErr({ ...formErr, emailErr: 'Email is Required' })
    //     } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    //         setFormErr({ ...formErr, emailErr: 'Invalid email address' })
    //     }
    //     else {
    //         setFormErr({ ...formErr, emailErr: true })
    //     }
    // }
    const validate = (email) => {
        const errors = {}
        if (!email) {
            errors.email = 'Required EmailId'
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            errors.email = 'Invalid email address'
        } else {
            return false;
        }
        return errors
    }
    return (
        <div className="master-clients">
            <Sidebar />
            <div className="master-clients-container">
                <Navbar />

                <ToastContainer />
                <div className="top-headings">
                    <h3>{title}</h3>
                    <Tooltip title="Back">
                        <Link to={"/all-client"} className="btn btn-add-new"><ArrowBackIcon /></Link>
                    </Tooltip>
                </div>
                {/* {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />} */}
                <div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') ? <LoaderForm /> :
                        <form>
                            <div>
                                {/* Client details section */}
                                <div className="client-progress-detailss"> Client Details</div>
                                <div className="add-new-user-form">
                                    <div className="formInput">
                                        <InputFields
                                            label={" Name "}
                                            name="name"
                                            defaultValue={formValues.name}
                                            type="text"
                                            placeholder="Name"
                                            onChange={handleChange}
                                            errStatus={errStatus}
                                            formErr={formErr.nameErr}
                                        />
                                    </div>
                                    <div className="formInput">
                                        <label>Logo</label>
                                        <label id="file-input-label" style={{ border: "1px solid gray", padding: "1px", borderRadius: "4px", display: 'flex', justifyContent: 'space-between', height:'40px', paddingLeft:'10px' }} for="logoInput">

                                            {/* {!formValues.logo ? ( formValues.id && !fileUploadData.data )?.display_name ?'"Choose Logo"': logo : formValues.logo} */}
                                            {
                                                ID ? logoname : file ? logoname : "Choose Logo"
                                            }
                                            {/* {
                                                ID ? <img style={{height:'35px', width: '35px', borderRadius: '50%', padding: 'none'}} src = {`${REACT_APP_API_BASEURL.slice(0,-1) + logo}`}/> : file ? <img style={{height:'35px', width: '35px', borderRadius: '50%', padding: 'none'}} src = {logo} />  : ""
                                            } */}
                                            {
                                                ID ? <Avatar src={`${REACT_APP_API_BASEURL.slice(0, -1) + logo}`} /> : (file ? <Avatar src={`${REACT_APP_API_BASEURL.slice(0, -1) + fileUploadData?.data?.api_path_for_download}`} /> : " ")
                                            }

                                            {/* {
                                                ID ? <Avatar src={`${REACT_APP_API_BASEURL.slice(0, -1) + logo}`} /> : (file ? <Avatar src={logo} /> : " ")
                                            } */}

                                            {

                                                // formValues.logo && formValues.id ? formValues.logo : formValues.id && !formValues.logo ? file : "Choose Logo"
                                                //  fileUploadData.data?.display_name || "Choose"         

                                            }
                                        </label>
                                        <input
                                            type="file"
                                            id="logoInput"
                                            accept="image/*"
                                            onChange={handleLogoChange}
                                            style={{ display: "none" }}
                                        />
                                    </div>
                                    <div className="formInput">
                                        <InputFields
                                            label={"Address"}
                                            name="address"
                                            defaultValue={formValues.address}
                                            type="text"
                                            placeholder="Client Address"
                                            onChange={handleChange}
                                            errStatus={errStatus}
                                            formErr={formErr.addressErr}
                                        />
                                    </div>
                                    <div className="formInput">
                                        <label>Country</label>
                                        <Select
                                            // className="basic-single"
                                            classNamePrefix="select"
                                            placeholder={'Select Country'}
                                            name={'country'}
                                            defaultValue={formValues?.country}
                                            onChange={(e) => {
                                                handleChange({...e, value:e.label.props["data-cid"]})
                                                handleCountryChange({...e, value:e.label.props["data-cid"]})
                                            }}
                                            isSearchable={true}
                                            options={
                                                country_List && country_List.data && country_List.data.rows.map((option) => {
                                                    return {
                                                        // label: option.name,
                                                        label: (
                                                            <div data-cid={option.id}>
                                                              {option.logo && (
                                                                <img style={{ height: "20px", width: "30px", marginRight: "8px" }} src={`${apiUrl}${option?.logo}`} alt="" />
                                                              )}
                                                               {`(${option.code}) ${option.name}`} 
                                                            </div>
                                                          ),
                                                        value: option.name,
                                                        name: "country"
                                                    }
                                                })
                                            }
                                        />
                                        {errStatus === true && formValues.country === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.countryErr}</p>}
                                    </div>
                                    <div className="formInput">
                                        <label>State</label>
                                        <Select
                                            classNamePrefix="select"
                                            label={"Select State"}
                                            placeholder={'Select State'}
                                            name={'state'}
                                            onChange={(e) => {
                                                handleChange(e)
                                                handleStateChange(e)
                                            }}
                                            defaultValue={formValues.state}
                                            isSearchable={true}
                                            options={
                                                updatedStateList && updatedStateList.map((option) => {
                                                    return {
                                                        label: option.name,
                                                        value: option.id,
                                                        name: "state"
                                                    }
                                                })
                                            }
                                        />
                                        {errStatus === true && formValues.state === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.stateErr}</p>}
                                    </div>
                                    <div className="formInput">
                                        <label> City</label>
                                        <Select
                                            classNamePrefix="select"
                                            label={"City"}
                                            placeholder={'Select City'}
                                            name={'city'}
                                            onChange={handleChange}
                                            defaultValue={formValues.city}
                                            errStatus={errStatus}
                                            isSearchable={true}
                                            options={
                                                updatedCityList && updatedCityList.map((option) => {
                                                    return {
                                                        label: option.city_name,
                                                        value: option.id,
                                                        name: "city"
                                                    }
                                                })
                                            }
                                        />
                                        {errStatus === true && formValues.city === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.cityErr}</p>}
                                    </div>
                                    <div className="formInput">
                                        <InputFields
                                            label={"Zip Code"}
                                            name="zip"
                                            defaultValue={formValues.zip}
                                            type="text"
                                            placeholder="Zip Code"
                                            onChange={handleZipChange}
                                            errStatus={errStatus}
                                            style={{ WebkitAppearance: 'none', appearance: 'none', margin: 0 }}
                                        />
                                        {errStatus === true && zipValid === true && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr?.zipErr}</p>}
                                    </div>
                                    <div className="formInput">
                                        <label>Status</label>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="client_status"
                                            value={formValues.client_status}
                                            onChange={handleChange}
                                            type="number"
                                        >
                                            <FormControlLabel value={1} control={<Radio />} label="Active" />
                                            <FormControlLabel value={0} control={<Radio />} label="Inactive" />
                                            {/* <FormControlLabel value={2} control={<Radio />} label="Defualter" /> */}
                                        </RadioGroup>
                                    </div>

                                    <div className="formInput">
                                        <Checkbox
                                            id="isInternal"
                                            className="checkbox"
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            name={"is_internal"}
                                            defaultChecked={formValues.is_internal}
                                            onChange={handleCheckboxChange}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }}
                                        />
                                        <label> Is Internal</label>
                                    </div>
                                </div>

                                {/* contact person details section */}
                                <div className="client-progress-detailss"> Contact Person Details</div>
                                <div className="add-new-user-form">

                                    <div className="formInput">
                                        <InputFields
                                            label={" Name "}
                                            name="name"
                                            defaultValue={addContactPerson.name}
                                            type="text"
                                            placeholder="Contact Person Name"
                                            onChange={(e) => setAddContactPerson({ ...addContactPerson, name: e.target.value })}
                                            errStatus={errStatus}
                                            formErr={formErr.contact_Person_NameErr}
                                        />
                                    </div>
                                    <div className="formInput">
                                        <InputFields
                                            label={"Email"}
                                            name="email"
                                            defaultValue={addContactPerson.email}
                                            type="email"
                                            placeholder="Email"
                                            onChange={(e) => setAddContactPerson({ ...addContactPerson, email: e.target.value })}
                                            errStatus={errStatus}
                                        // formErr={formErr.contact_Person_Email_IdErr}
                                        // validateErr={errStatus !== false ? validateErr : formErr.contact_Person_Email_IdErr}
                                        />
                                        {(errStatus === true && validate(addContactPerson.email)) && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr?.contact_Person_Email_IdErr}</p>}

                                    </div>
                                    <div className="formInput">
                                        <InputFields
                                            label={"Phone Number "}
                                            name="contact"
                                            defaultValue={addContactPerson.contact}
                                            type="text"
                                            placeholder="Phone Number"
                                            onChange={handleContactChange}
                                            errStatus={errStatus}
                                        // formErr={formErr.contact_Person_Phone_NumberErr}
                                        />
                                        {errStatus === true && mobValid === false && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr?.contact_Person_Phone_NumberErr}</p>}
                                    </div>

                                </div>

                                {/* Technical Specification details section */}
                                <div className="client-progress-detailss"> Technical Specification</div>
                                {
                                    formValuesForIDS.map((item, index) => (
                                        <div className="add-new-user-form">
                                            <div className="formInput">
                                                <label >Select Industry
                                                    {index === 0 ?
                                                        <AddCircleOutline onClick={() => addMoreIndustryDepartment()} style={{ marginLeft: "5px", color: "green", cursor: "pointer" }} />
                                                        :
                                                        <RemoveCircleOutline onClick={() => removeMoreIndustryDepartment(index)} style={{ marginLeft: "5px", color: "red", cursor: "pointer" }} />
                                                    }
                                                </label>
                                                <Select
                                                    classNamePrefix="select"
                                                    placeholder={'Select Industry'}
                                                    name={'industry'}
                                                    onChange={(e) => {
                                                        handleIndustrychange(e, index)
                                                    }}
                                                    defaultValue={formValues.industry && formValues.industry[index]}
                                                    isSearchable={true}
                                                    options={
                                                        industry_List && industry_List.data && industry_List.data.rows.map((option) => {
                                                            return {
                                                                label: option.industry_name,
                                                                value: option.id,
                                                                name: "industry"
                                                            }
                                                        })


                                                    }
                                                />
                                                {errStatus === true && formValues.industry === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.industryErr}</p>}
                                            </div>

                                            <div className="formInput">
                                                {formValuesForIDS[index].industry &&
                                                    <>
                                                        <label>Select Departments</label>
                                                        <Select
                                                            // isMulti
                                                            classNamePrefix="select"
                                                            placeholder={'Select Departments'}
                                                            name={'department'}
                                                            onChange={(e) => {
                                                                handleDepartmentChange(e, index);
                                                            }}
                                                            value={formValuesForIDS[index].department ? { label: formValuesForIDS[index]?.department_data?.label, value: formValuesForIDS[index]?.department_data?.value } : null}
                                                            isSearchable={true}
                                                            options={
                                                                formValuesForIDS[index]?.departmentList && formValuesForIDS[index]?.departmentList.length > 0 && formValuesForIDS[index]?.departmentList[0] ? formValuesForIDS[index]?.departmentList?.map((option) => {
                                                                    return {
                                                                        label: option.name,
                                                                        value: option.id,
                                                                        name: "department",
                                                                    };
                                                                }) : []

                                                            }
                                                        />
                                                        {/* {errStatus === true && formValues.department === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.departmentsErr}</p>} */}
                                                    </>
                                                }

                                            </div>
                                            <div className="formInput">
                                                {formValuesForIDS[index].department &&
                                                    <>
                                                        <label >Select Specialization</label>
                                                        <Select
                                                            // isMulti
                                                            classNamePrefix="select"
                                                            placeholder={'Select Specialization'}
                                                            name={'specialization'}
                                                            onChange={(e) => {
                                                                handleSpecializationChange(e, index)
                                                            }}
                                                            defaultValue={formValuesForIDS[index].specialization_data}
                                                            value={formValuesForIDS[index].specialization_data}
                                                            isSearchable={true}
                                                            options={
                                                                updatedspecList && updatedspecList.length > 0 ? updatedspecList.map((option) => {
                                                                    return {
                                                                        label: option.name,
                                                                        value: option.id,
                                                                        name: "specialization"
                                                                    }
                                                                }) : []

                                                            }
                                                        />
                                                        {/* {errStatus === true && formValues.specialization === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.specializationErr}</p>} */}
                                                    </>
                                                }
                                            </div>

                                            {formValuesForIDS[index].specialization && <div className="formInput">
                                                <label >Select Skills</label>
                                                <Select
                                                    isMulti
                                                    classNamePrefix="select"
                                                    placeholder={'Select Skills'}
                                                    name={'skills'}
                                                    onChange={(evt) => handleSkill(evt, index)}
                                                    defaultValue={formValuesForIDS[index].skill_datas}
                                                    value={formValuesForIDS[index].skill_datas}

                                                    isSearchable={true}
                                                    options={
                                                        updatedSkillList && updatedSkillList.length > 0 ? updatedSkillList.map((option) => {
                                                            return {
                                                                label: option.skill_name,
                                                                value: option.id,
                                                                name: "skills"
                                                            }
                                                        }) : []

                                                    }
                                                />
                                                {/* {errStatus === true && formValues.skills === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.skillsErr}</p>} */}
                                            </div>}
                                        </div>
                                    ))
                                }

                                {/* Services section */}
                                <div className="client-progress-detailss"> Services</div>
                                <div className="add-new-user-form">
                                    <div className="formInput">
                                        <label >Type of Service</label>
                                        <Select
                                            classNamePrefix="select"
                                            placeholder={'Type of Service'}
                                            name={'service_type'}
                                            onChange={handleChange}
                                            defaultValue={formValues.service_type}
                                            isSearchable={true}
                                            options={
                                                servicetype_List && servicetype_List.data && servicetype_List.data.rows.map((option) => {
                                                    return {
                                                        label: option.name,
                                                        value: option.id,
                                                        name: "service_type"
                                                    }
                                                })
                                            }
                                        />
                                        {errStatus === true && formValues.service_type === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.serviceErr}</p>}
                                    </div>
                                    <div className="formInput">
                                        <label >Purchase Pakage</label>
                                        <Select
                                            classNamePrefix="select"
                                            name={'purchased_package'}
                                            placeholder={'Purchase Package'}
                                            onChange={handleChange}
                                            defaultValue={formValues.purchased_package}
                                            isSearchable={true}
                                            options={
                                                purchasedPackeges_List && purchasedPackeges_List.data && purchasedPackeges_List.data.rows.map((option) => {
                                                    return {
                                                        label: option.name,
                                                        value: option.id,
                                                        name: "purchased_package"
                                                    }
                                                })
                                            }
                                        />
                                        {errStatus === true && formValues.purchased_package === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.purchasePackageErr}</p>}
                                    </div>
                                    <div className="formInput"></div>
                                </div>

                                <button className="submit-modal" onClick={submitData}>{ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}</button>
                            </div>
                        </form>
                    }
                </div>

            </div>
        </div >
    )
}

export default AddnewClient