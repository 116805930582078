import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DataService from "../services/data.service";
import { STATUSES } from "../common-status/constant";

export const workFlowList = createAsyncThunk(
    "get/workFlowList",
    async (params) => {
        const res = await DataService.workflow_list(params);
        const resp = res.data;
        return resp;
    }
);


export const workflowStatusList = createAsyncThunk(
    "get/workflowStatusList",
    async (params) => {
        const res = await DataService.workflow_status_mapping_list(params);
        const resp = res.data;
        return resp;
    }
);

export const workflowMappingForWorkflowId = createAsyncThunk(
    "get/workflowMappingForWorkflowId",
    async (params) => {
        const res = await DataService.workflow_status_mapping_list({ filter: { status: { value: "True", operation: "eq" }, workflow_id: { value: params, operation: "eq" } }, "no_limit": "True" });
        const resp = res.data;
        return resp;
    }
);

export const statusList = createAsyncThunk(
    "get/statusList",
    async (params) => {
        const res = await DataService.status_list(params);
        const resp = res.data;
        return resp;
    }
);

const workflowSlice = createSlice({
    name: "domain",
    initialState: {
        workflows: [],
        apiResponse:{},
        count:0,
        status: STATUSES.IDLE,
        error: '',
        status_List: [],
        updatedStatusList:[],
        sts_count:0,
        sts_status: STATUSES.IDLE,
        sts_error: '',
        wfStatus:"",
        wfError:"",
        wfCount:0,
        wfWorkflows:[],

        workflow_Status:"",
        workflow_Error:"",
        workflow_Count:0,
        workflow_StatusMapping:[],
        workflow_StatusMapSingleObj:[],
    },
    reducers: {
        updateStatusList:(state, action)=>{
            state.updatedStatusList=state.status_List.map(e=>action.payload!==e.id && e).filter(e=>e!==false)
        },
        clearState:(state, action)=>{
            state.workflow_StatusMapping=[]
        }
    },
    extraReducers(builder) {
        builder
            .addCase(workFlowList.pending, (state, action) => {
                state.status = STATUSES.LOADING;
            })
            .addCase(workFlowList.fulfilled, (state, action) => {
                state.status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                state.error = !!action.payload.data ;
                state.workflows = !action.payload.data.rows ? [] : action.payload.data.rows;
                state.count = action.payload.data.count;
            })
            .addCase(workFlowList.rejected, (state, action) => {
                state.status = STATUSES.FAILURE;
                state.error = action.error
            })

        // GET WORKFLOW STATUS MAPPING LIST
        .addCase(workflowStatusList.pending, (state, action) => {
            state.status = STATUSES.LOADING;
        })
        .addCase(workflowStatusList.fulfilled, (state, action) => {
            state.wfStatus = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.wfError = !!action.payload.data ;
            state.wfWorkflows = !action.payload.data.rows ? [] : action.payload.data.rows;
            state.wfCount = action.payload.data.count;
        })
        .addCase(workflowStatusList.rejected, (state, action) => {
            state.status = STATUSES.FAILURE;
            state.error = action.error
        })

                // GET WORKFLOW STATUS MAPPING LIST FOR WORKFLOW ID
                .addCase(workflowMappingForWorkflowId.pending, (state, action) => {
                    state.workflow_Status = STATUSES.LOADING;
                })
                .addCase(workflowMappingForWorkflowId.fulfilled, (state, action) => {
                    state.workflow_Status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
                    state.workflow_Error = !!action.payload.data ;
                    state.workflow_StatusMapping = !action.payload.data.rows ? [] : action.payload.data.rows;
                    state.workflow_Count = action.payload.data.count;
                    if(action?.payload?.data?.rows){
                        let data = {
                            id: "",
                            workflow: "",
                            workflow_status: [],
                            workflow_mapping: [],
                            status: "",
                          }
                          action.payload.data.rows.forEach(e => {
                            data = {
                                workflow_id:e.workflow_id,
                                workflow: e.workflow,
                                workflow_status_id:data.workflow_status_id ? [ ...data.workflow_status_id, e.workflow_status_id]:[ e.workflow_status_id] ,
                                workflow_status: [...data.workflow_status, { ...e.workflow_status, is_initial: e.is_initial }],
                                workflow_mapping: [...data.workflow_mapping, { workflow_status: e.workflow_status_id, related_status: e.related_status }],
                                status:e.status,
                                created_by:e.created_by,
                                created_on:e.created_on,
                                updated_by:e.updated_by,
                                updated_on:e.updated_on,
                            }
                          })
                          state.workflow_StatusMapSingleObj = data;
                    }

                })
                .addCase(workflowMappingForWorkflowId.rejected, (state, action) => {
                    state.workflow_Status = STATUSES.FAILURE;
                    state.workflow_Error = action.error
                })
        

        //  STATUS LIST
        .addCase(statusList.pending, (state, action) => {
            state.sts_status = STATUSES.LOADING;
        })
        .addCase(statusList.fulfilled, (state, action) => {
            state.sts_status = !action.payload.data ? STATUSES.FAILURE : STATUSES.SUCCESS;
            state.sts_error = !!action.payload.data ;
            state.status_List= !action.payload.data.rows ? [] : action.payload.data.rows.map((e)=>({...e, label:e.name, value:e.id }));
            state.updatedStatusList = !action.payload.data.rows ? [] : action.payload.data.rows;
            state.sts_count = action.payload.data.count;
        })
        .addCase(statusList.rejected, (state, action) => {
            state.sts_status = STATUSES.FAILURE;
            state.sts_error = action.error
        })

    },
});

export const {updateStatusList, clearState}=workflowSlice.actions

export default workflowSlice.reducer;