import "./punchinout.scss"
import React, { useEffect, useState, useCallback } from 'react';
import dataService from "../../redux/services/data.service";
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from "react-redux";
import { logout } from '../../redux/features/authSlice';
import { Oval } from "react-loader-spinner";
import Clockin from './clock.png';
import Clockout from './time.png';
import Break from './coffee-break.png';
import Back from './go-to-work.png';
import Logintime from './back-in-time.png';
import Hours from './working-hours.png';
import Logout from './check-out.png';

const Punchinout = () => {
  const { REACT_APP_ENV } = process.env;

  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const inTime = rtm_user?.inTime

  const [, setCurrentDate] = useState("")
  const [, setCurrentTime] = useState("")
  const [userId, setUserId] = useState("")
  const [attendenceId, setAttendenceId] = useState("")

  const [clockOutBtn, setClockOutBtn] = useState(false)
  const [cancelBtn, setCancelBtn] = useState(false)
  const [totalTime, setTotalTime] = useState("")
  const [currenUserStatus, setUserCurrentStatus] = useState("punchin")
  const [userAttendanceData, setUserAttendance] = useState({})

  const [showLoader, setShowLoader] = useState(false)
  const [firstLoggedInTime, setFirstLoggedInTime] = useState("")

  const getCurrentDate = () => {
    let date = new Date().toDateString();
    setCurrentDate(date);
  };

  const getCurrentTime = () => {
    let time = new Date().toLocaleTimeString('en-US', { hour12: true });
    setCurrentTime(time)
  };

  const userAttendenceDetail = async () => {
    setShowLoader(true)
    const response = await dataService.attandance_list_for_dashboard()
      .catch(error => {
        setShowLoader(false)
        return error;
      });
    response && setShowLoader(false);
    const data = response?.data && response.data.data
    let date = moment().format('YYYY-MM-DD')
    const currentDateArray = [];

    const item = data?.rows && data.rows[0]
    if (item && item.is_punchout_enable === true) {
      const date = new Date(item.punchin_date + " " + item.punchin_time)
      const moonLanding = new Date(date);
      localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
        'token': rtm_user.token,
        'user': rtm_user.user,
        'lastInteractionTime': rtm_user.lastInteractionTime,
        'userAttendance': item,
        'currenStatus': item.current_status === null ? "punchin" : item.current_status,
        'inTime': moonLanding.getTime()
      }))
    }

    if (item && item.punchin_date === date) {
      currentDateArray.push(item);
    }
    // })

    if (currentDateArray.length > 0) {
      setFirstLoggedInTime(currentDateArray[0].punchin_time)
    }

  }

  useEffect(() => {
    userAttendenceDetail();

    getCurrentDate();
    getCurrentTime();
    setInterval(getCurrentTime, 1000)
    if (rtm_user?.inTime) {
      setClockOutBtn(true);
      CalculateTiming()
      // setInterval(CalculateTiming, 1000)
      viewAttendanceDetails()
    } else { setClockOutBtn(false) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtm_user?.inTime])

  useEffect(() => {
    if (rtm_user?.inTime) {
      setInterval(CalculateTiming, 1000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (localStorage.getItem(`${REACT_APP_ENV}_rtms`)) {
      rtm_user.user?.data && setUserId(rtm_user.user.data.id)
      if (rtm_user?.userAttendance) {
        const userData = rtm_user.userAttendance
        userData && setUserAttendance(userData)
        userData && setUserCurrentStatus(rtm_user.currenStatus);
      }
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rtm_user?.currenStatus])

  const punchIn = async () => {
    setShowLoader(true);

    const request = {
      created_by: userId,
      resource: userId,
      button_type: "punchin"
    }

    const response = await dataService.punchInOut(request);

    if (response?.data && response.data.status === 200) {
      setShowLoader(false);
      const data = response.data.data

      setClockOutBtn(true)
      setCancelBtn(false)
      localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
        'token': rtm_user.token,
        'user': rtm_user.user,
        'lastInteractionTime': rtm_user.lastInteractionTime,
        'userAttendance': data,
        'currenStatus': "punchin",
        'inTime': new Date().getTime()
      }))

      CalculateTiming()
      // window.location.reload()
    }
    if (response?.data && (response.data.status === 400 || response.data.status === 401)) {
      setShowLoader(false);
      toast.error(response.data.message)
      logout_user();
    }

  }

  const dispatch = useDispatch();

  const logout_user = useCallback(() => {
    dispatch(logout());
    window.location.reload()
  }, [dispatch]);

  const punchOut = async () => {
    setClockOutBtn(false)
    setShowLoader(true);

    const request = {
      resource: userId,
      button_type: "punch_out",
      created_by: userId,
      resource_attendance_id: attendenceId,
    }
    const response = await dataService.punchInOut(request);
    if (response?.data && response.data.status === 200) {
      localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
        'token': rtm_user.token,
        'user': rtm_user.user,
        'lastInteractionTime': rtm_user.lastInteractionTime,
      }))
      logout_user();
      setCancelBtn(true)
      setShowLoader(false);
      window.location.reload();
    }
    if (response?.data && (response.data.status === 400 || response.data.status === 401)) {
      setShowLoader(false);
      toast.error(response.data.message)
      logout_user();
    }
  }

  const onBreak = async () => {
    setShowLoader(true)

    if (currenUserStatus === "punchin" || currenUserStatus === "on_seat") {

      const request = {
        resource: userId,
        button_type: "break",
        created_by: userId,
        resource_attendance_id: attendenceId,
      }

      const response = await dataService.punchInOut(request);
      if (response?.data && response.data.status === 200) {
        setShowLoader(false)
        setCancelBtn(false)

        localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
          'token': rtm_user.token,
          'user': rtm_user.user,
          'lastInteractionTime': rtm_user.lastInteractionTime,
          'userAttendance': userAttendanceData,
          'currenStatus': "break",
          'inTime': inTime
        }))
        // window.location.reload()
      }
      if (response?.data && (response.data.status === 400 || response.data.status === 401)) {
        setShowLoader(false)
        toast.error(response.data.message)
        logout_user();
      }
    }
  }

  const onBackToWork = async () => {
    setShowLoader(true)

    if (currenUserStatus === "break") {
      const request = {
        resource: userId,
        button_type: "on_seat",
        created_by: userId,
        resource_attendance_id: attendenceId,
      }
      const response = await dataService.punchInOut(request);
      if (response?.data && response.data.status === 200) {
        setShowLoader(false)

        setCancelBtn(false)
        localStorage.setItem(`${REACT_APP_ENV}_rtms`, JSON.stringify({
          'token': rtm_user.token,
          'user': rtm_user.user,
          'lastInteractionTime': rtm_user.lastInteractionTime,
          'userAttendance': userAttendanceData,
          'currenStatus': "on_seat",
          'inTime': inTime
        }))

        // window.location.reload()
      }
      if (response?.data && (response.data.status === 400 || response.data.status === 401)) {
        setShowLoader(false)
        toast.error(response.data.message)
        logout_user();
      }
    }
  }

  function CalculateTiming() {
    const startTime = rtm_user.inTime
    const endTime = new Date().getTime()
    const diffTime = (endTime - startTime)
    const hours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const min = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));
    setTotalTime(hours + "h :" + min + "m")
  }

  const onCancel = () => {
    setCancelBtn(false)
    setClockOutBtn(true)
  }

  const viewAttendanceDetails = () => {
    const attendenceDetails = rtm_user?.userAttendance
    attendenceDetails && setAttendenceId(attendenceDetails.id)
  }

  return (
    <div className='featured' style={{ position: "relative" }}>

      {
        //loader 
        showLoader && <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '99999' }}>
          <Oval
            height={60}
            width={60}
            color="cornflowerblue"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor="#6495ed75"
            strokeWidth={2}
            strokeWidthSecondary={3}
          />
        </div>
      }

      <div className="top">
        <ToastContainer />


        <div className="row">
          <div className="col">
            {
              !clockOutBtn &&
              <div className="psection" onClick={punchIn}>

                <div className="left-dataicon">
                  <img src={Clockin} alt="not found" />
                </div>
                <div className="right-dataicon">
                  <p>Punch In</p>
                </div>
              </div>
            }

            {
              !cancelBtn && clockOutBtn && (currenUserStatus === "punchin" || currenUserStatus === "on_seat") &&
              <>
                <div className="psection" onClick={() => setCancelBtn(true)}>
                  <div className="left-dataicon">
                    <img src={Clockout} alt="" />
                  </div>
                  <div className="right-dataicon">
                    <p>Punch Out</p>
                  </div>
                </div>

                <div className="psection" onClick={onBreak}>
                  <div className="left-dataicon">
                    <img src={Break} alt="not found" />
                  </div>
                  <div className="right-dataicon">
                    <p>Break</p>
                  </div>
                </div>
              </>
            }

            {
              !cancelBtn && clockOutBtn && currenUserStatus === "break" &&
              <>

                <div className="psection" onClick={onBackToWork}>
                  <div className="left-dataicon">
                    <img src={Back} alt="not found" />
                  </div>
                  <div className="right-dataicon">
                    <p>Back</p>
                  </div>
                </div>
              </>
            }
            {
              clockOutBtn && cancelBtn &&
              <div className="out-button-group">
                <button className="btn out-btn" onClick={punchOut}>Out</button>&nbsp;
                <button className="btn out-btn" onClick={onCancel}>Cancel</button>
                {/* <span className="total-time">{totalTime === NaN + "h :" + NaN + "m" ? "" : totalTime}</span> */}
              </div>
            }

            {
              firstLoggedInTime &&
              <div className="psection">
                <div className="left-dataicon">
                  <img src={Logintime} alt="not found" />
                </div>
                <div className="right-dataicon">
                  <p>Loggedin Time : {firstLoggedInTime}</p>
                </div>
              </div>
            }

            {
              totalTime &&
              <div className="psection">
                <div className="left-dataicon">
                  <img src={Hours} alt="not found" />
                </div>
                <div className="right-dataicon">
                  <p>Working Hour : {totalTime === NaN + "h :" + NaN + "m" ? "" : totalTime}</p>
                </div>
              </div>
            }

            <div className="psection" onClick={() => logout_user()}>
              <div className="left-dataicon">
                <img src={Logout} alt="not found" />
              </div>
              <div className="right-dataicon">
                <p>Logout</p>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default Punchinout
