import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./master.scss"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate} from "react-router-dom";
import Uploadfile from "../../components/Uploadfile";
import { useState,useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getresumeSource, getresumetemplate, postUploadResume } from "../../redux/features/masterSlice";
import { STATUSES } from "../../redux/common-status/constant";
import CONSTANTS from "../../components/constant/constantComponents";
import * as React from 'react';
import Button from '@mui/material/Button';
import LoaderForm from "../../components/common-function/LoaderForm";
import { AppBar, Box, Tab, Tabs ,Tooltip} from '@mui/material';
import { getResourceWiseProjectList, postTeamMemberCreate, getMyJobList, getTeamMemberList } from "../../redux/features/taskSlice";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dropzone from 'react-dropzone'
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import ResumeList from "../resume/ResumeList";
import ResumeAnalysisReport from "../reports/ResumeAnalysisReport";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { components } from "react-select";
import dataService from "../../redux/services/data.service";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ConstructionOutlined, Download} from "@mui/icons-material";



const InputOption = ({ getStyles, Icon, isDisabled, isFocused, isSelected, children, innerProps, ...rest }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);
     

    

    // styles
    let bg = "transparent";
    if (isFocused) bg = "#eee";
    if (isActive) bg = "#B2D4FF";

    const style = {
        alignItems: "center",
        backgroundColor: bg,
        color: "inherit",
        display: "flex "
    };

    // prop assignment
    const props = { ...innerProps, onMouseDown, onMouseUp, onMouseLeave, style };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
        >
            <input type="checkbox" checked={isSelected} />&nbsp;&nbsp;&nbsp;
            {children}
        </components.Option>
    );
};

const initialFormData = Object.freeze({
    epic_id: "",
    file: ""
});

const formErrData = Object.freeze({
    epicErr: "Job is required",
    fileErr: "File is required",
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`horizontal-tabpanel-${index}`}
            aria-labelledby={`horizontal-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}

const user = GetLocalstorage.userDetail();
const UploadResume = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formValues, setFormValues] = useState(initialFormData)
    const [formErr] = useState(formErrData)
    const [errStatus, setErrStatus] = useState(false);
    const { status, error, createUploadResume, resume_source_List,resume_source_template} = useSelector(state => state.master)
    const { resumeList } = useSelector(state => state.resume)
    const [reload, setReload] = useState(false)
    const [fileErr, setFileErr] = useState("")
    const [defaultEpicValue, setDefaultEpicValue] = useState(null)
    const [defaultProjectValue, setDefaultProjectValue] = useState(null)
    const [tabvalue, settabValue] = useState(0);
    
    const [jobApproverStatus, setJobApproverStatus] = useState("");
    const handleTabChange = (event, newValue) => {
        settabValue(newValue);
    };

    const { createTeamMember, myJobList } = useSelector(state => state.task);
    const [defaultValueInTeam, setDefaultValueInTeam] = useState([])
    const [apiSubmitLoading, setApiSubmitLoading] = useState(false)
    const [loader, setLoader] = useState(false)
    const [teamMemberLists, setTeamMemberLists] = useState([])
    const [filteredTemp,setFilteredTemp] =useState()
    const [template, setTemplate] = useState('');
    const [mapedsource,setmappedsource]=useState()
    const { REACT_APP_API_BASEURL, REACT_APP_ENCRPTION_KEY_FOR_IV, REACT_APP_ENCRPTION_KEY_FOR_UI } = process.env;

    const progressCount = useSelector(state => state.master.progressPercentage)


    const roleWiseFilter = `{"filter":{"resource_id":{"value":${user.id},"operation": "eq"}}, "order_by":{"column":"-id"}, "skip":0, "limit":25} `
    useEffect(() => {
        dispatch(getMyJobList(roleWiseFilter))
        dispatch(getResourceWiseProjectList(`{"filter":{"resource":{"value":${GetLocalstorage.userDetail().id},"operation":"eq"},"status":{"value":"True","operation":"eq"}},"order_by":{"column":"-id"},"no_limit":True}`))
        dispatch(getresumeSource())
        dispatch(getresumetemplate())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        } else if (status === `${STATUSES.SUCCESS}_createUploadResume`) {
            setReload(false);
        }
    }, [error])

    if (reload === 'submit' && status === `${STATUSES.SUCCESS}_createUploadResume` && createUploadResume && createUploadResume.message) {
        if (createUploadResume.status === 200) {
            setFormValues(initialFormData);
            setDefaultProjectValue(null)
            setDefaultValueInTeam([])
            setTeamMemberLists([])
            setDefaultEpicValue(null)
            toast.success(createUploadResume.message)
        } else {
            toast.error(createUploadResume.message)
        }
        setReload(false)
        setLoader(false)
    }
    const updateTeamMember = (members) => {

        let array = []
        let array1 = []
        let array2 = []
        defaultValueInTeam.map((o) => array1.push(o.value));
        members.map(e => {
            return array.push(e.value)
        })
         teamMemberLists?.data?.rows && teamMemberLists.data.rows?.map((o) => array2.push(o.resource_id))

        let teamMemberData = {
            "jira_rtms_issue": formValues.epic_id,
            "resource": teamMemberLists?.data?.rows && teamMemberLists.data.rows?.map((o) => o.resource_id),
            "temp_reallocated": array,
            "status": 1,
            "created_by": GetLocalstorage.userDetail().id
        }

        const ut = array2?.filter(o => !array.includes(o));
        setDefaultValueInTeam(ut);

        if (ut.length > 0) {
            teamMemberData.temp_reallocated = ut.map(e => {
                return e
            })
        } else {
            teamMemberData.temp_reallocated = []
        }

        dispatch(postTeamMemberCreate(teamMemberData))
        setDefaultValueInTeam(members);
        setApiSubmitLoading('submit')
    }

    if (apiSubmitLoading === 'submit' && createTeamMember && createTeamMember.message) {
        if (createTeamMember.status === 200) {
            toast.success(createTeamMember.message)
        } else {
            toast.error(createTeamMember.message)
        }
        setApiSubmitLoading(false)
    }

    const handleChange = async (evt) => {
        if (evt) {
            const { name, value, label } = evt;
            if (name === 'epic_id') {
                setLoader(true)
                setDefaultEpicValue({label, value:label})
                setJobApproverStatus(evt.job_approver_status)
                setDefaultValueInTeam([])
                const res = await dataService.team_member_list(`{"filter":{"jira_rtms_issue_id":{"value":${value}, "operation":"eq"},"status":{"value":True, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`);
                const result = res?.data
                let array = []
                result?.data && result.data?.rows.map((item) => {
                    if (item.is_temporary_reallocated === false) {
                        array.push({
                            label: item?.resource?.display_name,
                            value: item.resource_id,
                        })
                    }
                    return array
                })
                array.length > 0 && setDefaultValueInTeam(array)
                setTeamMemberLists(result)
                setLoader(false)
            }
            setFormValues({ ...formValues, [name]: value, project: evt.project });
        } else {
            setDefaultEpicValue(null)
            setDefaultValueInTeam([])
            dispatch(getTeamMemberList(0))
            setFormValues({ ...formValues, epic_id: null });
            setLoader(false)
        }
    }

    const submitData = (e) => {
        e.preventDefault()

        if (formValues.epic_id === "" || formValues.file === "") {
            setErrStatus(true)
        }
        if (formValues.epic_id && formValues.file) {
            const formData = new FormData();
            (formValues?.file).forEach((file, i) => {
                formData.append(`file`, file, file.name);
            });
            formData.append("epic", `${formValues.epic_id}`);
            formData.append("project", `${formValues.project}`);
            const obj = {dispatch}
            dispatch(postUploadResume(formData,obj))
            setReload('submit')
            setLoader(true)
        }
    }



    const onFileChange = (event) => {
        for(let i=0;i<event.length;i++){

            let file = event[0]
            const fileType = file.name.split(".")[1];
            if (fileType === "pdf" || fileType === "doc" || fileType === "docx" || fileType === 'jpeg' || fileType === 'jpg' || fileType === 'png' || fileType === 'txt' || fileType === "zip" || fileType === "csv" || fileType === "xlsx") {
                setFileErr("")
                
            } else {
                setFormValues({ ...formValues, file: "" });
                setFileErr("You can only upload pdf, docx, doc, zip,jpg,jpeg,png,txt csv and xlsx files");
                return;
            }

        }
        setFormValues({ ...formValues, file: event });
    }

   
    const handleChangeSource=(evt)=>{
        setmappedsource(resume_source_template && resume_source_template.data.rows.filter((ele)=>ele.source_type.source_type===evt.value))
        setFormValues({ ...formValues, source_type:evt});
        setFilteredTemp("")
        setTemplate("")
    }
    
    const handlechangeTemplate=(evt)=>{
      const data=  resume_source_template && resume_source_template.data && resume_source_template.data.rows.filter((item)=>{
            if( item.template_name === evt.value)
            {return item}
        })
        setFormValues({ ...formValues, source_template:evt});
        setFilteredTemp(data)
        setTemplate(evt);
    }
    const downloadtemplate= ()=>{
      const url=  filteredTemp &&  filteredTemp[0].sample_file_path
      if(url && filteredTemp){
          window.open(`${REACT_APP_API_BASEURL}recruitment/source-templates-download/${filteredTemp[0].id}/${filteredTemp[0].source_type_id}`);
      }else
      {
        toast.error("file not available for this template")
      }
    }
    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />
                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Upload Resume</h3>

                        <div>
                            <Tooltip title="Back">
                                <button onClick={() => navigate(-1)} className="btn btn-add-new"><ArrowBackIcon /></button>
                            </Tooltip>
                        </div>
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data')&& (!progressCount) && <LoaderForm percentage={progressCount}/>}
                    <form>
                        <div className="add-new-user-form" style={{ marginTop: "20px" }}>

                            <div style={{ flexBasis: "49%", marginBottom: "20px" }}>
                                <label style={{ marginBottom: "15px", display: "block" }}> Select Job </label>
                                <Select
                                    classNamePrefix="select"
                                    label={"Job"}
                                    placeholder={'Select Job'}
                                    name={'epic_id'}
                                    onChange={handleChange}
                                    defaultValue={defaultEpicValue}
                                    value={defaultEpicValue}
                                    isSearchable={true}
                                    options={
                                         myJobList?.data?.rows ? myJobList.data.rows.map((e) => {
                                            return {
                                                name: "epic_id",
                                                value: e?.id, label: e?.summary,
                                                job_approver_status: e?.job?.approver_status,
                                                project: e.project
                                            }
                                        }) : []
                                    }
                                />
                                {errStatus === true && formValues && formValues.epic_id === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.epicErr}</p>}
                            </div>

                            {loader === false &&
                                <div style={{ flexBasis: "49%", marginBottom: "20px" }}>
                                    <label style={{ marginBottom: "15px", display: "block" }} > Temporary Remove Member </label>
                                    <div>
                                        <Select
                                            defaultValue={defaultValueInTeam}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            onChange={(options) => {
                                                updateTeamMember(options)
                                            }}
                                            placeholder="Select Team Member"
                                            options={teamMemberLists?.data?.rows ? teamMemberLists.data.rows.map((item, index) => {
                                                return {
                                                    key: index,
                                                    label: item?.resource?.display_name,
                                                    value: item.resource_id,
                                                    is_temporary_reallocated: item?.is_temporary_reallocated,
                                                    name: 'team_members'
                                                }
                                            }) : []}
                                            components={{
                                                Option: InputOption
                                            }}
                                        />
                                    </div>
                                </div>
                            }

                             {loader === false &&
                                <div style={{ flexBasis: "49%", marginBottom: "20px" }}>
                                    <label style={{ marginBottom: "15px", display: "block" }} > Source </label>
                                    <div>
                                        <Select
                                            onChange={handleChangeSource}
                                            isSearchable={true}
                                            placeholder="Select Source Type"
                                            options={resume_source_List  && resume_source_List.data && resume_source_List.data.rows ? resume_source_List.data.rows.map((option, index) => {
                                                return { label: option.source_type, value:option.source_type, name: "source_type" }
                                            }) : []}
                                        />
                                    </div>
                                </div>
                            }
                            {loader === false &&
                                <div style={{ display:'flex', justifyItems:'space-between', alignItems:'center' ,flexBasis: "49%", marginBottom: "20px"}}>
                                    
                                    <div style={{width:'90%'}}>
                                        <label style={{ marginBottom: "15px", display: "block" }} > Template </label>
                                        <div className="formInput">
                                            <Select
                                                defaultValue={defaultValueInTeam}
                                                isSearchable={true}
                                                onChange={(options) => {
                                                    handlechangeTemplate(options)
                                                    
                                                }}
                                                value={template}
                                                placeholder="Select Template"
                                                options={mapedsource? mapedsource.map((item, index) => {
                                                    return {
                                                        key: index,
                                                        label: item?.template_name,
                                                        value: item.template_name,
                                                        name: 'source_template'
                                                    }
                                                }) : []}
                                            />
                                        </div>
                                    </div>
                                    {mapedsource?   <Tooltip title={template?" download ":"not found"}>
                                        <div className="action-attechment-btn">
                                            
                                                <Button
                                              style={{ color:template?"blue":"gray", marginTop:"40px"}}
                                                className="download-icon"
                                                onClick={() => downloadtemplate()}
                                                disabled={template? false :true}
                                                >
                                                    <Download/>
                                                </Button>
                                           
                                        </div>
                                        </Tooltip>:""
                                        }
                                    </div>
                            }

                            {defaultEpicValue &&
                                <div className="formInput">
                                    <label>Upload Resume(Pdf/Zip/Docx/Doc/Csv/Xlsx/jpeg/png/txt/jpg)   </label>
                                    <div className="file-dropzone-tabs" style={{display:"flex",gap:"20px",alignItems:"center"}}>
                                        <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)}>
                                            {({ getRootProps, getInputProps }) => (
                                                <section>
                                                    <div {...getRootProps()}>
                                                        <input {...getInputProps()} multiple={true} />
                                                        {/* <p>{formValues.file ? formValues.file.name: "Drag 'n' drop some files here, or click to select files"}</p> */}
                                                        <p>{(formValues?.file.length)? (formValues?.file?.map((item,index)=><p>{item?.name}</p>)):("Drag 'n' drop some files here, or click to select files")}</p>
                                                        
                                                        

                                                    </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        {(loader && reload=='submit')?<div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}><div><Uploadfile value={progressCount}/></div>{formValues?.file.length?<div>{formValues?.file.length} File Selected</div>:""}</div>:""}
                                    </div>
                                    {errStatus === true && formValues && formValues.file === "" && <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.fileErr}</p>}
                                    {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
                                </div>
                            }
                        </div>

                        {jobApproverStatus === 4 ?
                            <Button className="submit-modal" onClick={submitData}>{CONSTANTS.COMMON_BUTTONS.SUBMIT}</Button>
                            : jobApproverStatus === "" ? " "
                                : <p style={{ color: "#96332c", fontSize: 20, margin: "10px" }}>Job is not live</p>}
                    </form>

                    <Box className="resume-tabs" sx={{ bgcolor: 'background.paper', width: '100%', marginTop: '10px' }}>
                        <AppBar position="static" style={{ backgroundColor: '#e7e7e7', color: '#000', borderRadius: '6px' }}>
                            <Tabs
                                value={tabvalue}
                                onChange={handleTabChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="fullWidth"
                                aria-label="full width tabs example"
                            >
                                <Tab
                                    value={0}
                                    label="Resume Analysis Report"
                                />
                                <Tab value={1} label={`Failed Resume List(${ resumeList?.data?.count ? resumeList.data.count : 0})`} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabvalue} index={1} className="custom-loader-tabs" >
                            <ResumeList />
                        </TabPanel>
                        <TabPanel value={tabvalue} index={0} className="custom-loader-tabs" >
                            <ResumeAnalysisReport type={'tab'} />
                        </TabPanel>
                    </Box>
                </div>
            </div >
        </div >
    )
}
export default UploadResume