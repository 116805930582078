import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./report.scss"
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { STATUSES } from "../../redux/common-status/constant";
import { useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { resourceList } from "../../redux/features/resourceSlice";

const WorkLogReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const { status, error } = useSelector(state => state.resource)

    useEffect(() => {
        if (status === STATUSES.IDLE) {
            dispatch(resourceList(`{"no_limit":True}`))
        }

        if (status === STATUSES.FAILURE) {
            errorHandling(error, navigate)
        }

    }, [status, dispatch, error, navigate])
    return (
        <div className="report">
            <Sidebar />
            <div className="reportContainer">
                <Navbar />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Work Log</h3>
                        <button className="btn-add-new ">
                            Back
                        </button>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        </div>
    )
}

export default WorkLogReport