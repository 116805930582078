import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./report.scss";
import {DataGrid} from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { resourceList } from "../../redux/features/resourceSlice";
import {
  attandanceReport,
  defaultAttendenceReportColumn,
} from "../../redux/features/calendarSlice";
import {
  FormControl,
  TextField,
  Button,
  TablePagination,
  Tooltip,
  List,
  ListItem,
  Stack,
  Grid,
  CardContent,
  Card,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import moment from "moment";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import { ArrowBack, Download } from "@mui/icons-material";
import { AttendanceReportColumns } from "../../components/datatable-coulms/tableCoulmns";
import TablePaginationActions from "../../components/TablePaginationActions";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import { RefreshButton } from "../../components/common-function/RefreshButton";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import MoreFilters from "../../components/moreFilters/MoreFIlters";

const { REACT_APP_API_BASEURL } = process.env;
const default_attemdemce_reports_Columns =
GetLocalstorage.get_default_attendence_report_column();

const ReportOfAttendance = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data } = useSelector((state) => state.resource);
  const {
    status,
    error,
    attendaceReportsList,
    defaultAttendenceReportColumns,
  } = useSelector((state) => state.calender);
  const [currentPage, setCurrentPage] = useState(0);
  const [resourceId, setResourceId] = useState("select resources");
  const [resourceLabel, setResourceLabel] = useState("");
  const [loader, setLoader] = useState(true);
  const [value, setValue] = useState();
  const [list, setList] = useState([]);
  const [startMaxDate, setStartMaxDate] = useState([null]);
  const [fromDate, setFromDate] = useState([null]);
  const [toDate, setToDate] = useState([null]);
  const [minToDate, setMinToDate] = useState([null]);
  const [rowCount, setRowCount] = useState(50);
  const [downliadFile, setDownloadFile] = useState(false);


  const [pageSize, setPageSize] = useState(25);
  const [limit, setLimit] = useState(25);
  const [filterQuery, setFilterQuery] = useState(
    `{,"order_by":{"column":"-id"}, "skip":0,"limit":25}`
  );
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
   
      default_attemdemce_reports_Columns?.all_attendencereport_column
      ? default_attemdemce_reports_Columns.all_attendencereport_column
      : {
          resource_id: false,
          working_hours: false,
        }
  );

  const setKeyForList = (obj) => {
    let data;
    if (default_attemdemce_reports_Columns) {
      data = {
        all_attendencereport_column: obj,
        reported_attendence_report_column:
          default_attemdemce_reports_Columns.reported_attendence_report_column,
        all_projectlist_column:
          default_attemdemce_reports_Columns.all_projectlist_column,
        task_column: default_attemdemce_reports_Columns.task_column,
      };
    } else {
      data = { all_attendencereport_column: obj };
    }
    if (defaultAttendenceReportColumns.reported_attendence_report_column) {
      data["reported_attendence_report_column"] =
        defaultAttendenceReportColumns.reported_attendence_report_column;
    }
    if (defaultAttendenceReportColumns.all_projectlist_column) {
      data["all_projectlist_column"] =
        defaultAttendenceReportColumns.all_projectlist_column;
    }
    if (defaultAttendenceReportColumns.task_column) {
      data["task_column"] = defaultAttendenceReportColumns.task_column;
    }
    dispatch(defaultAttendenceReportColumn(data));
  };

  //error handling
  useEffect(() => {
    if (status === STATUSES.FAILURE && error) {
      toast.error(error.message);
      setLoader(false);
    }
  }, [error]);

  useEffect(() => {
    daysInMonth(moment().format("MM"), moment().format("YYYY"));
    let monthDays = new Date(
      moment().format("YYYY"),
      moment().format("MM"),
      0
    ).getDate();
    let startMonth = `${moment().format("YYYY")}-${moment().format("MM")}-1`;
    let endMonth = `${moment().format("YYYY")}-${moment().format(
      "MM"
    )}-${monthDays}`;

    let filter = `{"filter":{"punchin_date":{"value":["${startMonth}","${endMonth}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
    dispatch(
      resourceList(
        `{"filter":{"status":{"value":"True","operation": "eq"}},"order_by":{"column":"name"},"no_limit":True,"columns":[${DefaultFilterColumns.RESOURCE_DROUPDOWN_COLUMNS}]}`
      )
    );
    setFilterQuery(filter);
    dispatch(attandanceReport(filter));
    if (status === STATUSES.FAILURE) {
      errorHandling(error, navigate);
    }
  }, []);

  const handleChange = (evt) => {
    if (evt) {
      const { name, value, label } = evt.target || evt;
      if (name === "month") {
        setLoader(true);
        const d = new Date();
        let year = d.getFullYear();
        daysInMonth(value + 1, year);
      }
      if (name === "resourceId") {
        setResourceLabel(label)
        setResourceId(value);
      }
    } else {
      setResourceId("select resources");
      setResourceLabel("");
    }
  };
  if (
    loader === true &&
    status === `${STATUSES.SUCCESS}_attendaceReportsList` &&
    attendaceReportsList &&
    attendaceReportsList.data
  ) {
    setLoader(false);
    let reportRows = attendaceReportsList.data.rows;
    let reportCount = attendaceReportsList.data.count;
    let reportSummary = attendaceReportsList.data.summary;
    let reportList = [];
    let summaryExtra1 = {
      id: -1,
    };
    let summaryExtra2 = {
      id: -2,
    };
    let summaryExtra3 = {
      id: -5,
      punch_out_time: "No Data Found!",
    };
    let summaryHeader = {
      id: -6,
      punchin_date: "No Of Days",
      name: "Total Break Hours",
      break_hours: "Total Effective Working Hours",
      effective_working_hours: "Total Working Hours",
      working_hours: "Total Expected Working Hours",
    };

    let summaryBody = {
      id: -4,
      punchin_date: reportSummary.no_of_days,
      name: reportSummary.total_break_hours,
      break_hours: reportSummary.total_effective_working_hours,
      effective_working_hours: reportSummary.total_working_hours,
      working_hours: reportSummary.total_expected_working_hours,
    };
    reportRows &&
      reportRows.length > 0 &&
      reportRows.map((e, i) => {
        return reportList.push({
          id: i,
          break_hours: e.break_hours,
          created_on: e.created_on,
          date: e.date,
          effective_working_hours: e.effective_working_hours,
          emp_code: e.emp_code,
          expected_punch_in_date_time: e.expected_punch_in_date_time,
          expected_punch_out_date_time: e.expected_punch_out_date_time,
          expected_working_hours: e.expected_working_hours,
          name: e.name,
          punch_out_date: e.punch_out_date,
          punch_out_time: e.punch_out_time,
          punchin_date: e.punchin_date,
          punchin_time: e.punchin_time,
          resource_id: e.resource_id,
          working_hours: e.working_hours,
        });
      });
    if (reportList && reportList.length > 0) {
      reportList.push(summaryExtra1);
      reportList.push(summaryExtra2);
      reportList.push(summaryHeader);
      reportList.push(summaryBody);
      setList(reportList);
    } else {
      reportList.push(summaryExtra1);
      reportList.push(summaryExtra3);
      reportList.push(summaryExtra2);
      reportList.push(summaryHeader);
      reportList.push(summaryBody);
      setList(reportList);
    }
    if (reportCount > 0) {
      setRowCount(reportCount);
    }
  }

  const setYearAndMonth = (newValue) => {
    const d = new Date(newValue);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    daysInMonth(month, year);
    setValue(newValue);
  };

  const daysInMonth = (month, year) => {
    let monthDays = new Date(year, month, 0).getDate();
    setMinToDate(`${year}-${month}-1`);
    setStartMaxDate(`${year}-${month}-${monthDays}`);
    setFromDate(`${year}-${month}-1`);
    setToDate(`${year}-${month}-${monthDays}`);
    return new Date(year, month, 0).getDate();
  };

  const setToDateFunction = (newValue) => {
    setToDate(newValue);
  };

  const downloadCsvFile = () => {
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(
        fromDate
      ).format("YYYY-MM-DD")}","${moment(toDate).format(
        "YYYY-MM-DD"
      )}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`;
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format(
        "YYYY-MM-DD"
      )}","${moment(toDate).format(
        "YYYY-MM-DD"
      )}"],"operation":"range"}},"for_download":"True","no_limit":"True"}`;
    }
    setFilterQuery(filter);
    setDownloadFile(true);
    dispatch(attandanceReport(filter));
  };
  const handleClick = (url) => {
    setDownloadFile(false);
    window.open(url);
  };

  if (downliadFile === true) {
    let api_url =
       attendaceReportsList?.data?.download_api_url;
    if (REACT_APP_API_BASEURL && api_url) {
      let url = REACT_APP_API_BASEURL + api_url;
      if (url) {
        handleClick(url.replace("api//", "api/"));
      }
    }
  }

  const CustomToolbar = () => {
    //nothing
  };

  //custom pagination
  const handlePagination = (data) => {
    setCurrentPage(data);
    setLoader(true);
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(
        fromDate
      ).format("YYYY-MM-DD")}","${moment(toDate).format(
        "YYYY-MM-DD"
      )}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${pageSize}}`;
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format(
        "YYYY-MM-DD"
      )}","${moment(toDate).format(
        "YYYY-MM-DD"
      )}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${pageSize}}`;
    }
    let newFilterData = JSON.parse(filter);
    newFilterData["skip"] = data * pageSize;
    newFilterData["limit"] = limit;
    setFilterQuery(JSON.stringify(newFilterData));
    dispatch(attandanceReport(JSON.stringify(newFilterData)));
  };

  const handlePageSizeChange = (newPageSize) => {
    setLoader(true);
    setPageSize(newPageSize.target.value);
    setCurrentPage(0);
    setLimit(newPageSize.target.value);
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${moment(
        fromDate
      ).format("YYYY-MM-DD")}","${moment(toDate).format(
        "YYYY-MM-DD"
      )}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${
        newPageSize.target.value
      }}`;
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${moment(fromDate).format(
        "YYYY-MM-DD"
      )}","${moment(toDate).format(
        "YYYY-MM-DD"
      )}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${
        newPageSize.target.value
      }}`;
    }
    let newFilterData = JSON.parse(filter);
    newFilterData["skip"] = 0;
    newFilterData["limit"] = newPageSize.target.value;
    setFilterQuery(JSON.stringify(newFilterData));
    dispatch(attandanceReport(JSON.stringify(newFilterData)));
  };

  function CustomPagination() {
    return (
      <TablePagination
        component='div'
        count={rowCount || 0}
        page={currentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
        onPageChange={() => setCurrentPage(pageSize)}
        onRowsPerPageChange={handlePageSizeChange}
        ActionsComponent={(subProps) => (
          <TablePaginationActions
            {...subProps}
            handlePagination={handlePagination}
          />
        )}
      />
    );
  }

  // advance search filter fields
  const FilterComponents = () => {
    return (
      <>
        <List>
          <ListItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["month", "year"]}
                label='Year and Month'
                minDate={dayjs("2012-03-01")}

                value={value}
                onChange={(newValue) => {
                  setYearAndMonth(newValue);
                }}
                format='YYYY-MM'
                renderInput={(params) => (
                  <TextField disabled={true} {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </ListItem>
          <ListItem>
            <FormControl style={{ width: "96%" }}>
              <Autocomplete
                id='controllable-states-demo'
                value={resourceLabel?resourceLabel:""}
                onChange={(event, newValue) => {
                  handleChange(newValue);
                }}
                options={
                  data &&
                  data?.data ?
                  data?.data?.rows.map((e, key) => {
                    return {
                      key: e.id,
                      name: "resourceId",
                      value: e.id,
                      label: e.name + "(" + e.email + ")",
                    };
                  })
                  : []
                }
                renderInput={(params) => (
                  <TextField {...params} label='Select resources' />
                )}

              />
            </FormControl>
          </ListItem>
          <ListItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label='From  Date'
                value={fromDate}
                onChange={(newValue) => {
                  setFromDate(newValue["$d"]);
                }}
                format='DD-MM-YYYY'
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </ListItem>
          <ListItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                minDate={minToDate}
                label='To Date'
                value={toDate}
                onChange={(newValue) => {
                  setToDateFunction(newValue);
                }}
                format='DD-MM-YYYY'
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </ListItem>
        </List>
      </>
    );
  }

  const handleFilterSearch = (setOpen) =>
  {
    let filter;
    if (resourceId > 0) {
      filter = `{"filter":{"resource_id":{"value":"${resourceId}","operation":"eq"},"punchin_date":{"value":["${fromDate.toString().includes("GMT") ? moment(fromDate).format("YYYY-MM-DD") : fromDate}","${toDate.toString().includes("GMT") ? moment(toDate).format("YYYY-MM-DD") : toDate}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
    } else {
      filter = `{"filter":{"punchin_date":{"value":["${fromDate.toString().includes("GMT") ? moment(fromDate).format("YYYY-MM-DD") : fromDate}","${toDate.toString().includes("GMT") ? moment(toDate).format("YYYY-MM-DD") : toDate}"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":${limit}}`;
    }
    setLoader(true);
    setFilterQuery(filter);
    dispatch(attandanceReport(filter));
    setOpen(false)

  }
  
  // advance search - resetHandler
  const handleFilterReset = () =>
  {
    setResourceId("select resources")
    setResourceLabel("");
    setValue();
    setFromDate(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
    setToDate(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0))
    setLoader(true);
    let filter = `{"filter":{"punchin_date":{"value":["2023-10-1","2023-10-31"],"operation":"range"}},"order_by":{"column":"-punchin_date"},"skip":0,"limit":25}`;
    setFilterQuery(filter);
    dispatch(attandanceReport(filter));

  }

  return (
    <div className='report'>
      <Sidebar />
      <div className='reportContainer'>
        <Navbar />
        <div className='datatable'>
          <div className='top-headings'>
            <h3>Daily Attendance Report</h3>
            <div style={{display:'flex', alignItems:'flex-end'}}>
              {/* advance search filter start */}
              <MoreFilters filterComponents={FilterComponents} handleFilterReset={handleFilterReset} handleFilterSearch={handleFilterSearch} isFilterDisabled={status === `${STATUSES.SUCCESS}_attendaceReportsList` ? false : true} inlineStyle={{width:'60px'}}/>
              {/* advance search filter end */}
              <RefreshButton api={attandanceReport} filter={filterQuery} />
              <Tooltip title={"Back"}>
                <button onClick={() => navigate(-1)} className='btn-add-new '>
                  <ArrowBack />
                </button>
              </Tooltip>
            </div>
          </div>
          {(loader === true || status === STATUSES.LOADING) && <LoaderForm />}
          <ToastContainer />
          <Card>
            <CardContent>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
              >
                <Grid item md={7}>
                  <CustomPagination />
                </Grid>
                <Grid item md={5}>
                  <Stack
                    style={{
                      justifyContent: "end",
                      marginTop: "6px",
                    }}
                    direction={"row"}
                  >
                    <div style={{ marginLeft: "3px" }}>
                      <Button
                        startIcon={<Download />}
                        variant='contained'
                        onClick={() => downloadCsvFile()}
                        style={{background:'#0052cc'}}
                      >
                        CSV(server)
                      </Button>
                    </div>
                  </Stack>
                </Grid>
              </Grid>
              <DataGrid
                className='datagrid'
                rows={list || []}
                columnVisibilityModel={
                  defaultAttendenceReportColumns?.all_attendencereport_column
                    ? defaultAttendenceReportColumns.all_attendencereport_column
                    : columnVisibilityModel
                }
                onColumnVisibilityModelChange={(newModel) => {
                  setColumnVisibilityModel(newModel);
                  setKeyForList(newModel);
                }}
                columns={AttendanceReportColumns}
                components={{
                  Toolbar: CustomToolbar,
                  Pagination: CustomPagination,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
                pageSize={pageSize === 100 ? pageSize : pageSize + 4}
                style={{ fontSize: 16 }}
                hideFooterPagination={true}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ReportOfAttendance;