import React, { useEffect, useState } from "react";
import Widget from "../../components/widget/Widget"
import "./dashboardMenu.scss"
import { useDispatch, useSelector } from "react-redux";
import { allProjects, allResources, allShift, allTask, checkIsInterviewerAval, myProjects, dailyWorkFlowSummary } from "../../redux/features/dashboardSlice";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import { Box, Card, CardActions, CardContent, CardHeader, Dialog, DialogContent, Grid, Link, Stack, SwipeableDrawer, Typography } from "@mui/material";
import DailyWorkLog from "./DailyWorkLog";
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import moment from "moment";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import CircleIcon from '@mui/icons-material/Circle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
const rtm_user = GetLocalstorage.userDetail()
const availability = localStorage.getItem('is_interviewr_availabilty')

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const DashboardMenu = () => {

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const screenWidth = windowDimensions?.width
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    if (window.location === window.location.origin + '/dashboard' || window.location === `${window.location.origin}/dashboard`) {
        window.location = '/dashboard?reload=done'
    }
    const dispatch = useDispatch();

    const { resourceList, shiftList, projectList, myProjectList, taskList, interviewerAval, dailyWorkFlowSummaryList, activityDropDownList, projectDropDownList } = useSelector(state => state.dashboardCard)
    const tmsRole = rtm_user && rtm_user.tms_role
    const setAvl = useState((availability === 'true' || interviewerAval === true) ? true : false)[1]
    //const [openInterviewerModal, setOpenInterviewerModal] = useState(rtm_user.is_interviewer === true ? avl === true ? false : true : avl === true ? false : true)
    const [openInterviewerModal, setOpenInterviewerModal] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [clearOpen, setClearOpen] = useState(true)

    useEffect(() => {
        dispatch(dailyWorkFlowSummary(`{"filter":{"resource":{"value":${GetLocalstorage?.userDetail().id},"operation":"eq"},"date":{"value":"${moment().format("YYYY-MM-DD")}","operation":"eq"}},"skip":0,"limit":10}`))
        dispatch(allResources('{"skip":0,"no_limit":True}'))
        dispatch(allProjects(`{"order_by":{"column":"project_name"},"no_limit":True,"columns":[${DefaultFilterColumns.MAIN_PROJECT_DROUPDOWN_COLUMNS}]}`))
        if (tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin')) {
            dispatch(allShift('{"skip":0,"limit":10}'))
            // dispatch(allReport())
            // dispatch(allProjects('name'))
        } else {
            dispatch(allTask())
            dispatch(myProjects())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
    const closeModal = () => {
        setAvl(true)
        dispatch(checkIsInterviewerAval())
        localStorage.setItem("is_interviewr_availabilty", true)
        setOpenInterviewerModal(false)
    }

    // const handleClose = () => {
    //     setOpenModal(false)
    //     setOpenModal(false)
    //     setClearOpen(true)
    //     setTimeout(() => {
    //         setClearOpen(false)
    //     }, 200)

    // }

    const handleOpen = () => {
        setOpenModal(true)
        setClearOpen(false)
    }

    const closeModalAPI = () => {
        setOpenModal(false)
        dispatch(dailyWorkFlowSummary(`{"filter":{"resource":{"value":${GetLocalstorage?.userDetail().id},"operation":"eq"},"date":{"value":"${moment().format("YYYY-MM-DD")}","operation":"eq"}},"skip":0,"limit":10}`))
        setClearOpen(false)
        setTimeout(() => {
            setClearOpen(true)
        }, 200)
    }

    return (

        <div className="dashboard">
            <Sidebar />
            <div className="dashboardContainer">
                <Navbar />
                <Box m={3} sx={{ flexGrow: 1 }}>
                    <Grid container spacing={{ xs: 2, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                        {
                            tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ?
                                <>
                                    {/* <Grid item sx={12} sm={12} md={3}>
                                        <Widget type="resources" count={(resourceList && resourceList.count) ? resourceList.count : 0} />
                                    </Grid>
                                    <Grid item sx={12} sm={12} md={3}>
                                        <Widget type="projects" count={(projectList && projectList.count) ? projectList.count : 0} />
                                    </Grid>
                                    <Grid item sx={12} sm={12} md={3}>
                                        <Widget type="shifts" count={(shiftList && shiftList.count) ? shiftList.count : 0} />
                                    </Grid>
                                    <Grid item sx={12} sm={12} md={3}>
                                        <Widget type="reports" count={2} />
                                    </Grid> */}
                                    <Grid item sx={6} sm={6} md={7}>
                                        <Card className="cardShadow">
                                            <Stack direction={"row"}>
                                                <span style={{ width: '100%' }}>
                                                    <Widget type="resources" count={(resourceList && resourceList.count) ? resourceList.count : 0} />
                                                    <Widget type="projects" count={(projectList && projectList.count) ? projectList.count : 0} />
                                                </span>
                                                <span style={{ width: '100%' }}>
                                                    <Widget type="shifts" count={(shiftList && shiftList.count) ? shiftList.count : 0} />
                                                    <Widget type="reports" count={2} />
                                                </span>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item sx={12} sm={12} md={7}>
                                        <Card className="cardShadow">
                                            <Stack direction={"row"}>
                                                <span style={{ width: '100%' }}>
                                                    <Widget type="tasks" count={(taskList && taskList.count) ? taskList.count : 0} />
                                                </span>
                                                <span style={{ width: '100%' }}>
                                                    <Widget type="myProjects" count={(myProjectList && myProjectList.count) ? myProjectList.count : 0} />
                                                </span>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                </>
                        }
                        <Grid item sm={tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ? 8 : 5 || screenWidth <= 768 ? 12 : 5} md={tmsRole && (tmsRole.role_key === 'admin' || tmsRole.role_key === 'super_admin') ? 5 : 5 || screenWidth <= 768 ? 5 : 12} sx={12}>
                            {/* {screenWidth <= 968 ?  */}
                            <Card className="cardShadow" sx={{ width: '100%' }}>
                                <CardHeader
                                    avatar={
                                        <>
                                            <span style={{ display: "flex", alignItems: "center" }}>
                                                <PlaylistAddIcon className="icon"
                                                    style={{
                                                        color: "#fff",
                                                        backgroundColor: "#379ae6",
                                                        marginRight: '20px'
                                                    }}
                                                />
                                                <Typography sx={{ cursor: 'pointer' }} color={'#42526e'} onClick={handleOpen} variant="h2" fontSize={"15px"}>DAILY WORKLOG</Typography>
                                            </span>
                                        </>
                                    }
                                />
                                <CardContent sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                    <Stack direction={"column"}>
                                        <Stack direction={"row"} alignItems={'center'}>
                                            <CircleIcon sx={{ color: "#ddde", fontSize: '15px', marginRight: "20px" }} />
                                            <Typography width={"100%"} variant="h3" fontSize={"14px"} fontWeight={400}>Total Logged Hours: </Typography>
                                            <Typography width={"40%"} variant="subtitle2" textAlign={"end"} fontSize={"14px"} fontWeight={500} > {dailyWorkFlowSummaryList?.data?.total_logged_hour ? dailyWorkFlowSummaryList?.data?.total_logged_hour : 0} H</Typography>
                                        </Stack>
                                        <Stack direction={"row"} alignItems={'center'}>
                                            <CircleIcon sx={{ color: "#ddde", fontSize: '15px', marginRight: "20px" }} />
                                            <Typography width={"85%"} variant="h3" fontSize={"14px"} fontWeight={400}>Efficiency till now: </Typography>
                                            <Typography width={"40%"} variant="subtitle2" textAlign={"end"} fontSize={"14px"} fontWeight={500} > {dailyWorkFlowSummaryList?.data?.efficiency_till_now ? dailyWorkFlowSummaryList?.data?.efficiency_till_now : 0} </Typography>
                                        </Stack>
                                        <Stack direction={"row"} alignItems={'center'}>
                                            <CircleIcon sx={{ color: "#ddde", fontSize: '15px', marginRight: "20px" }} />
                                            <Typography width={"100%"} variant="h3" fontSize={"14px"} fontWeight={400}>Efficiency for the day:</Typography>
                                            <Typography width={"40%"} variant="subtitle2" textAlign={"end"} fontSize={"14px"} fontWeight={500} > {dailyWorkFlowSummaryList?.data?.efficiency_for_the_day ? dailyWorkFlowSummaryList?.data?.efficiency_for_the_day : 0} </Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                                {/* <CardActions sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <span className="link" onClick={handleOpen}>
                                        <Link>See All Worklog Summary</Link>
                                    </span>
                                </CardActions> */}
                            </Card>
                            {/* : 
                            <Card className="cardShadow" sx={{ maxWidth: 655 }}>
                                <CardHeader
                                    avatar={
                                        <Typography variant="h2" fontSize={"15px"}>DAILY WORKLOG</Typography>
                                    }
                                />
                                <CardContent sx={{ paddingTop: '5px', paddingBottom: '5px' }}>
                                    <Stack direction={"row"} alignItems={'center'}>
                                        <Typography width={"100%"} variant="h3" fontSize={"14px"} fontWeight={700}> Total Logged Hours: </Typography>
                                        <Typography width={"40%"} variant="subtitle2" textAlign={"start"} fontSize={"14px"} fontWeight={500} > {dailyWorkFlowSummaryList?.data?.total_logged_hour ? dailyWorkFlowSummaryList?.data?.total_logged_hour : 0} H</Typography>
                                        <Typography width={"85%"} variant="h3" fontSize={"14px"} fontWeight={700}>Efficiency till now: </Typography>
                                        <Typography width={"40%"} variant="subtitle2" textAlign={"start"} fontSize={"14px"} fontWeight={500} > {dailyWorkFlowSummaryList?.data?.efficiency_till_now ? dailyWorkFlowSummaryList?.data?.efficiency_till_now : 0} </Typography>
                                        <Typography width={"100%"} variant="h3" fontSize={"14px"} fontWeight={700}>Efficiency for the day:</Typography>
                                        <Typography width={"40%"} variant="subtitle2" textAlign={"center"} fontSize={"14px"} fontWeight={500} > {dailyWorkFlowSummaryList?.data?.efficiency_for_the_day ? dailyWorkFlowSummaryList?.data?.efficiency_for_the_day : 0} </Typography>
                                    </Stack>
                                </CardContent>
                                <CardActions sx={{ justifyContent: "space-between", alignItems: "center" }}>
                                    <span className="link" onClick={handleOpen}>
                                        <Link>See All Worklog Summary</Link>
                                    </span>
                                    <CalendarTodayRoundedIcon className="icon"
                                        style={{
                                            color: "green",
                                            backgroundColor: "rgba(0,128,0,0.2)",
                                        }}
                                    />
                                </CardActions>
                            </Card>
                            } */}

                        </Grid>
                    </Grid>
                </Box>
                <Dialog
                    open={openInterviewerModal}
                    onClose={() => closeModal()}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">

                    <DialogContent>
                        <div className="popup-confirming">
                            <h3 style={{ color: '#ff0000d1' }}>Assignment for Interviewer</h3>
                            <p>{"If you do not mark your custom availability, It will be considered you are available throughout your shift time for any interviews."}</p>
                        </div>
                    </DialogContent>
                </Dialog>

                {/* <div className="charts">
                    <Featured />

                </div> */}

                <SwipeableDrawer
                    anchor={"right"}
                    open={openModal}
                    // onClose={handleClose}
                    onOpen={openModal}
                >

                    <DailyWorkLog
                        allProjects={allProjects}
                        allResources={allResources}
                        resourceList={resourceList}
                        projectList={projectList}
                        activityDropDownList={activityDropDownList}
                        projectDropDownList={projectDropDownList}
                        setOpenModal={closeModalAPI}
                        openModal={openModal}
                        clearOpen={clearOpen}
                        setClearOpen={setClearOpen}
                    />
                </SwipeableDrawer>

            </div>

        </div>
    )
}

export default DashboardMenu
