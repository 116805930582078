import { useEffect, useState } from "react"
import './master.scss'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { toast, ToastContainer } from 'react-toastify';
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"
import { Tooltip } from "@mui/material";
import {domainList} from "../../redux/features/domainSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import GetLocalstorage from '../../components/common-function/GetLocalstorage';
import { ArrowCircleLeft } from '@mui/icons-material';
import { getGridStringOperators } from "@mui/x-data-grid";
const { REACT_APP_ENV } = process.env;

const Domain = () => {

    const dispatch = useDispatch();
    const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
    const userId = rtm_user.user.data.id;

    const { domains, status,count } = useSelector(state => state.domain)
    const [pageSize, setPageSize] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
    const [skip, setSkip] = useState(0);
    const [params, setParams] = useState({})
    const [limit, setLimit] = useState(25);
    // Modal Related data
    const [open, setOpen] = useState(false)
    const [formLoading, setFormLoading] = useState(false)
    const [updating, setUpdating] = useState(false);
    const [editable, setEditable] = useState(false);

    const whereClause = { ...params, "skip": skip, "limit": pageSize || 25, "order_by": { "column": "-id" }, "columns": DefaultFilterColumns.DOMAIN_LIST_COLUMNS }

    const [domainsData, setDomainsData] = useState({})
    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
const statusFilterOperators = getGridStringOperators().filter(({ value }) =>
['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);

    const modalDetail = {
        title: "Domain Details",
        editModalTitle: "Edit Domain",
        addModalTitle: "Add Domain",
        formInputs: [
            { id: 3, name: "name", label: "Domain Name", placeholder: "Enter Name", type: "text", required: true, disabled: false },
            { id: 3, name: "code", label: "Domain Code", placeholder: "Enter Code", type: "text", required: true, disabled: false },
            {
                id: 2, name: "status", label: "Status", placeholder: "Enter For Date", type: "select",
                options: [
                    { id: 1, label: "Active", value: true },
                    { id: 2, label: "Inactive", value: false },
                ],
                required: true, disabled: false
            },

            { id: 2, name: "description", label: "Descriptions", placeholder: "Enter Descriptions", type: "textarea", required: false, disabled: false },
        ],
    }

    const domainsColumns = [
        { field: "id", headerName: "ID", width: 70 },
        {
            field: "name",
            headerName: "Name",
            filterOperators,
            width: 400,
        },
        {
            field: "code",
            headerCode: "Code",
            filterOperators,
            width: 300,
        },

        {
            field: "status",
            headerName: "Status",
            width: 300,
            type: 'singleSelect',
            valueOptions: [
                { label: "Active", value: "True" },
                { label: "Inactive", value: "False" },
            ],
            renderCell: (params) => {
                return (
                    <div className={`cellWithStatus ${params.row.status}`}>
                        {params.row.status === true ? 'Active' : 'Inactive'}
                    </div>
                );
            },
        },
    ];

    const onSubmit = async (data) => {
        const finalData = domainsData.id ? { ...domainsData, ...data, updated_by: userId } : { ...data, created_by: userId }
        const res = await dataService.save_domain(finalData);
        if (res.data && res.status === 200) {
            toast.success(res.data.message)
            dispatch(domainList(whereClause))
            onCloseModal();
            setDomainsData({})
        }
        else {
            toast.error(res.data.message)
        }
    }

    const onView = (data) => {
        setDomainsData({ ...data });
        setEditable(false);
        setOpen(true);

    }

    // Edit Domain
    const handleEdit = (data) => {
        setDomainsData({ ...domainsData, ...data, updatedBy: 1 });
        setUpdating(true)
        setEditable(true);
        setOpen(true);
    }

    // Delete Domain
    const deleteDomain = async (data) => {
        let addvalues = {
            code: data.code,
            description: data.description,
            name: data.name,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id,
            id: data.id
        }
        const res = await dataService.save_domain(addvalues)
        if (res?.data?.status === 200) {
            toast.success(res.data.message)
            dispatch(domainList(whereClause))
        } else {
            toast.error(res.data.message)
        }
    }

    //  Open Modal
    const onOpenModal = () => {
        setOpen(true)
    }

    //  AddDomain Modal
    const onAddDomain = () => {
        setOpen(true)
        setEditable(true);
    }

    //  Close Modal
    const onCloseModal = () => {
        setOpen(false)
        setUpdating(false);
        setEditable(false);
        setDomainsData({});
    }

    useEffect(() => {
        dispatch(domainList(whereClause))
    }, [skip, params])

    return (
        <div className="new">
            <Sidebar />
            <div className="newContainer">
                <Navbar />

                <div className="top-headings new-rtms-heading new-design-icon">
                 <h3><i><ArrowCircleLeft className='new-back-icon'/> </i>Domains</h3>
                    <div>
                        <Tooltip title="Add Domain">
                            <button onClick={onAddDomain} className="new-add-icon">
                                Add Domain
                            </button>
                        </Tooltip>
                    </div>
                </div>

                <ToastContainer />
                <>
                    {
                        <DynamicTable
                            moduleName="domains"
                            tableColumns={domainsColumns}
                            tableRows={domains || []}
                            currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                            setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                            paginationMode={"server"}
                            rowCount={count}
                            loading={status === STATUSES.LOADING}
                            columnVisibilityModel={{
                                id: false,
                            }}
                            onViewDetail={onView}
                            onEdit={handleEdit}
                            deleteRow={deleteDomain}
                            setLimit={setLimit}
                            limit={limit}
                        />
                    }
                </>
                {
                    <RegularizeModal
                        modalDetail={modalDetail}
                        open={open}
                        onOpen={onOpenModal}
                        onClose={onCloseModal}
                        onSubmit={onSubmit}
                        fullWidth
                        maxWidth
                        updating={updating}
                        editData={domainsData}
                        editable={editable}
                        formLoading={formLoading}
                        setFormLoading={setFormLoading}
                        additionalActions={modalDetail.additionalActions}
                        onAdditionalAction={() => { console.log("") }}
                    />
                }
            </div>
        </div>
    )
}

export default Domain