import UcActionComponent from "../../components/common-function/UcActionComponent";
import CustomNoRowsOverlay from "../../components/CustomEmptyOverlay";
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import { Add, Close } from '@mui/icons-material'
import { Button, Dialog, DialogContent, DialogTitle, Tooltip, TablePagination } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import { RefreshButton } from '../../components/common-function/RefreshButton'
import CONSTANTS from "../../components/constant/constantComponents";
import { InputFields, InputRadioGroupFields, InputTextareaField } from "../../components/common-function/InputFields";
import { useDispatch, useSelector } from "react-redux";
import { getMasterStatusCategory, masterStatusCategoryCreate, masterStatusCategoryEdit, masterStatusCategoryUpdate } from "../../redux/features/masterSlice";
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns";
import { masterStatusCategoryColumns } from "../../components/datatable-coulms/tableCoulmns";
import GetLocalstorage from "../../components/common-function/GetLocalstorage";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from "../../redux/common-status/constant";
import DeleteDialogModal from "../../components/common-function/DeleteDialogModal";
import { MuiColorInput } from 'mui-color-input'
import TablePaginationActions from "../../components/TablePaginationActions";
import { getGridStringOperators } from "@mui/x-data-grid";

// initialFormData
const initialFormData = Object.freeze({
    status_category_name:"",
    background_color_code:"",
    foreground_color_code:"",
    key:"",
    description: "",
    status: "",
});

// Form errors 
const formErrData = Object.freeze({
    categoryErr: "Category name is required",
    bgColorErr: "Background Color Code is required",
    fgColorErr: "Font Color Code is required",
    keyErr: "Key is required",
    descriptionErr: "Description is required",
    statusErr: "Status is required"
});


const MasterStatusCategory = () => {

    const [reload, setReload] = useState(false);

    const [ID, setID] = useState(0);

    const [formValues, setFormValues] = useState(initialFormData)


    const [errStatus, setErrStatus] = useState(false);
    const [formErr] = useState(formErrData)

    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const [changeStatus, setChangeStatus] = useState('');

    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);

    const [open, setOpen] = useState(false);

    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});
    const [pageSize, setPageSize] = useState(25);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-id"},"skip":0, "limit":25,"columns":[${DefaultFilterColumns.MASTER_STATUS_CATEGORY_COLUMNS}]}`)

    const { status, error, master_status_category_list, master_status_category_creat, master_status_category_Edit, master_status_category_Update } = useSelector(state => state.master)
    const dispatch = useDispatch();


    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        if (ID) { setTimeout(()=>setID(0),100); setFormValues(initialFormData) } setOpen(false); setFormValues(initialFormData);;
        setErrStatus(false);
    }

    const handleChange = (evt) => {
        const { name, value } = evt.target || evt;
        setFormValues({ ...formValues, [name]: value })
    }

    const handleClickEdit = (id) => 
    {
        dispatch(masterStatusCategoryEdit(id))
        setReload('edit_data')
    }

    const handleArchive = (id) => {
        setArchiveId(id)
        setArchiveOpen(true)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
    }
    const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: 150,
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="meeting_type"
                            rowid={params.row.id}
                            addMoreData=""
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            viewButton={false}
                            deleteButton={() => handleArchive(params.row)} />
                </div>
                );
            },
        },
    ]


    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            status_category_name:data.status_category_name,
            background_color_code:data.background_color_code,
            foreground_color_code:data.foreground_color_code,
            key:data.status_category_name,
            description: data.status_category_name,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        setReload(true)
        dispatch(masterStatusCategoryUpdate({ ID, addvalues }))
    }

    const submitData = (e) =>
    {
        e.preventDefault();

        let isAllFieldsFilled = !(Object.values(formValues).some(item=>item===""));

        if(isAllFieldsFilled)
        {
            let addvalues = { ...formValues, created_by: GetLocalstorage.userDetail().id }
            setErrStatus(false)
            setReload(true)
            handleClose()
            if (ID) 
                dispatch(masterStatusCategoryUpdate({ ID, addvalues }))
            else 
                dispatch(masterStatusCategoryCreate(addvalues))
        }
        else
        {
            setErrStatus(true)
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_master_status_category_create` && master_status_category_creat && master_status_category_creat.message) {
        if (master_status_category_creat.status === 200) {
            toast.success(master_status_category_creat.message)
            dispatch(getMasterStatusCategory(filterQuery));
        } else {
            toast.error(master_status_category_creat.message)
        }
        setReload(false)
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}_master_status_category_Edit` && master_status_category_Edit && master_status_category_Edit.data) {
        let editData = master_status_category_Edit.data
        setFormValues({
                status_category_name:editData.status_category_name,
                background_color_code:editData.background_color_code,
                foreground_color_code:editData.foreground_color_code,
                key:editData.key,
                description: editData.description,
                status: editData.status,
        })
        setID(editData.id)
        setOpen(true)
        setReload(false)
    }


    if (reload === true && status === `${STATUSES.SUCCESS}_master_status_category_Update` && master_status_category_Update && master_status_category_Update.message) {
        if (master_status_category_Update.status === 200) {
            toast.success(master_status_category_Update.message)
            dispatch(getMasterStatusCategory(filterQuery))
        } else {
            toast.error(master_status_category_Update.message)
        }
        setReload(false)
    }


    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMasterStatusCategory(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMasterStatusCategory(JSON.stringify(newFilterData)))
    }

    
    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(master_status_category_list?.data?.rows && master_status_category_list.data?.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MASTER_STATUS_CATEGORY_COLUMNS}]}`)
                dispatch(getMasterStatusCategory(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit},"columns":[${DefaultFilterColumns.MASTER_STATUS_CATEGORY_COLUMNS}]}`))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MASTER_STATUS_CATEGORY_COLUMNS}]}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getMasterStatusCategory(filterData))
        }
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getMasterStatusCategory(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" },"columns":[${DefaultFilterColumns.MASTER_STATUS_CATEGORY_COLUMNS}] }`)
        }
    }

    useEffect(()=>
    {
        dispatch(getMasterStatusCategory(filterQuery));

    },[]);


    return (
        <div className="jiraStatus">
            <Sidebar />
            <div className="jiraStatusContainer">
                <Navbar />

                <ToastContainer />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Master Status Category</h3>
                        <div>
                            <Tooltip title="Add master status category">
                                <button onClick={handleOpen} className="btn-add-new ">
                                    <Add />
                                </button>
                            </Tooltip>
                            <RefreshButton api={getMasterStatusCategory} filter={filterQuery} style={{ marginTop: 0 }} />

                        </div>
                    </div>
                    <div className="custom-pagination-class">
                        <CustomPagination />
                    </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}

                    {
                        !reload &&
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) =>
                                setColumnVisibilityModel(newModel)
                            }
                            rows={master_status_category_list?.data?.rows || []}
                            columns={masterStatusCategoryColumns.concat(actionColumn)}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                // Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                        />
                    }
                </div>

                <Dialog
                    hideBackdrop
                    fullWidth
                    maxWidth={"md"}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="child-modal-title"
                >
                    <Button
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: "absolute", top: "10px", right: "10px",color:"white" }}>
                        <Close />
                    </Button>
                    <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>{ID ? 'Edit' : 'Add'} Status Category</DialogTitle>
                    <DialogContent>
                        <form>
                            <div className="add-new-user-form">
                                <InputFields
                                    label={"Category Name"}
                                    name="status_category_name"
                                    defaultValue={formValues.status_category_name}
                                    type="text"
                                    placeholder="Category Name"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.categoryErr}
                                />

                                {/* <InputFields
                                    label={"Background Color Code"}
                                    name="background_color_code"
                                    defaultValue={formValues.background_color_code}
                                    type="text"
                                    placeholder="Background Color Code"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.bgColorErr}
                                /> */}
                                <div style={{display:'flex', flexDirection:'column', flexBasis:'49%'}}>
                                    <label style={{marginBottom: "5px"}}>Background Color Code</label>
                                    <MuiColorInput 
                                        placeholder="Background Color Code"
                                        value={formValues.background_color_code}
                                        onChange={(evt, ...props)=> {
                                            handleChange({ name:"background_color_code", value:props[0].hex})
                                        }}
                                    />
                                    {
                                        errStatus && formValues.background_color_code === '' &&  <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.bgColorErr}</p>
                                    }
                                </div>

                                <div style={{display:'flex', flexDirection:'column', flexBasis:'49%'}}>
                                    <label style={{marginBottom: "5px"}}>Font Color Code</label>
                                    <MuiColorInput 
                                        placeholder="Font Color Code"
                                        value={formValues.foreground_color_code}
                                        onChange={(evt, ...props)=> {
                                            handleChange({ name:"foreground_color_code", value:props[0].hex})
                                        }}
                                    />
                                    {
                                        errStatus && formValues.foreground_color_code === '' &&  <p style={{ color: "#96332c", fontSize: 13 }}>{formErr.fgColorErr}</p>
                                    }
                                </div>

                                {/* <InputFields
                                    label={"Font Color Code"}
                                    name="foreground_color_code"
                                    defaultValue={formValues.foreground_color_code}
                                    type="text"
                                    placeholder=""
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.fgColorErr}
                                /> */}

                                <InputFields
                                    label={"Key"}
                                    name="key"
                                    defaultValue={formValues.key}
                                    type="text"
                                    placeholder="Key"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.keyErr}
                                />

                                <InputTextareaField
                                    label={"Description"}
                                    name="description"
                                    defaultValue={formValues.description}
                                    type="text"
                                    placeholder="Description"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.descriptionErr}
                                />
                                <InputRadioGroupFields
                                    label={"Status"}
                                    name="status"
                                    defaultValue={formValues.status}
                                    type="text"
                                    placeholder="Status"
                                    onChange={handleChange}
                                    errStatus={errStatus}
                                    formErr={formErr.statusErr}
                                />
                            </div>
                            <div style={{display:'flex', width:'100%', justifyContent:'flex-end', gap:'10px'}}>
                                <Button 
                                    variant="contained" 
                                    sx={{
                                        backgroundColor:'#0052cc', 
                                        color:'white', 
                                        
                                    }} 
                                    onClick={submitData}>
                                        {ID ? CONSTANTS.COMMON_BUTTONS.UPDATE : CONSTANTS.COMMON_BUTTONS.SUBMIT}
                                </Button>
                                <Button 
                                    variant="contained" 
                                    sx={{
                                        backgroundColor:'#2e3032', 
                                        color:'white',
                                        "&:hover": {
                                            backgroundColor:'#2e3032',
                                            opacity:'.8'
                                        }
                                    }}
                                    onClick={handleClose}>
                                        Close
                                </Button>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
                <DeleteDialogModal
                    open={archiveOpen}
                    onClose={setArchiveOpen}
                    heading={changeStatus}
                    paragraph={`Are you sure to ${changeStatus} this Master Status`}
                    handleArchive={handleClickArchive}
                    id={archiveId}
                />
            </div>
        </div >
    )
}

export default MasterStatusCategory