import React, { useState, useEffect } from 'react'
import "./manageshift.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { shiftColumns } from "../../datatablesource";
import { Link, useNavigate } from "react-router-dom";
import api from '../../redux/APIS/api';
import DynamicTable from '../../components/dynamic-table/DynamicTable';
import { toast, ToastContainer } from 'react-toastify';
import { Tooltip } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Refresh } from '@mui/icons-material';
import { DefaultFilterColumns } from '../../components/common-function/DefaultFilterColumns';
import LocalStorageDataService from '../../components/common-function/GetLocalstorage';
import { useDispatch, useSelector } from 'react-redux';
import { defaultshiftColumn } from '../../redux/features/projectSlice';

const default_shift_Columns = LocalStorageDataService.get_default_shift_column()


const AllShifts = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [shiftRows, setShiftRows] = useState([])

  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState({})
  const [setFieldName] = useState("")
  const [limit,setLimit] = useState(25)
  const [_,setDefaultValue] = useState({})

  const { defaultshiftColumns } = useSelector(state => state.projects)

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_shift_Columns && default_shift_Columns.all_shift_column ? default_shift_Columns.all_shift_column : {
    id: false,   
    timing_display: false,
  
});

const setKeyForList = (obj) => {
  let data;
  if (default_shift_Columns) {
      data = { 'all_shift_column': obj, 'reported_shift_column': default_shift_Columns.reported_shift_column, 'all_projectlist_column': default_shift_Columns.all_projectlist_column, 'task_column': default_shift_Columns.task_column }
  } else {
      data = { 'all_shift_column': obj }
  }
  if (defaultshiftColumns.reported_shift_column) {
      data['reported_shift_column'] = defaultshiftColumns.reported_shift_column
  }
  if (defaultshiftColumns.all_projectlist_column) {
      data['all_projectlist_column'] = defaultshiftColumns.all_projectlist_column
  }
  if (defaultshiftColumns.task_column) {
      data['task_column'] = defaultshiftColumns.task_column
  }
  dispatch(defaultshiftColumn(data))
}

  // Load All Shifts

  const loadShifts = async () => {
    setLoading(true);
    const data = { ...params, "skip": skip, "limit": pageSize || 25, "order_by": { "column": "-id" }, "columns": DefaultFilterColumns.SHIFT_LIST_COLUMNS }
    const res = await api.get(`/mst/shift?where=${JSON.stringify(data)}`)
    if (res && res.data && res.data.data) {
      setShiftRows(res.data.data.rows.map(e => ({ ...e, status_value: e.status ? "True" : "False" })))
      setRowCount(res.data.data.count)
    }
    setLoading(false);
  }

  // Delete Shift
  const deleteShift = async (data) => {
    setLoading(true)
    let addvalues={
        description:data.description,
        end_time:data.end_time,
        id:data.id,
        shift_name: data.shift_name,
        start_time:data.start_time,
        status:!data.status,
        time_zone:data.time_zone,
        timing_display:"24Hours",
        updated_by: LocalStorageDataService.userDetail().id,        
        
    }
    setLoading(true)
    const res = await api.put(`/mst/shift/${data.id}`, addvalues)
    if (res && res.data && res.data.data && res.status === 200) {
      toast.success(res.data.message)
      loadShifts();
    }
  }

  // Edit Shift
  const editShift = (data) => {
    navigate('/add-new-shift', { state: data })
  }

  // View Shift
  const viewShift = (data) => {
    navigate(`/view-shift/${data.id}`, { state: data.id })
  }

  useEffect(() => {
    loadShifts();
    // eslint-disable-next-line
  }, [skip, pageSize, params])

  return (
    <div className="new">
      <Sidebar />

      <div className="newContainer">
        <Navbar />

        <div className="top-headings">
          <h3>All Shifts</h3>
          <div>
            <button onClick={() => loadShifts()} className="btn-add-new ">
              <Tooltip title="Reload">
                <Refresh />
              </Tooltip>
            </button>
            <Tooltip title="Add New Shift ">
              <Link to="/add-new-shift" className="btn-add-new ">
                <AddIcon />
              </Link>
            </Tooltip>
          </div>
        </div>
        <ToastContainer />
        {
          // loading ? <LoaderForm /> :
          <>
            {
              <DynamicTable
                moduleName="allShifts"
                tableColumns={shiftColumns}
                tableRows={shiftRows || []}
                currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
                setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
                paginationMode={"server"}
                rowCount={rowCount}
                loading={loading}
                deleteRow={deleteShift}
                onViewDetail={viewShift}
                onEdit={editShift}
                editLink={"/add-new-shift"}
                // columnVisibilityModel={{
                //   id: false,
                // }}
                columnVisibilityModel={defaultshiftColumns && defaultshiftColumns.all_shift_column ? defaultshiftColumns.all_shift_column : columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                }
                setFieldName={setFieldName} setDefaultValue={setDefaultValue}
                limit={limit}
                setLimit={setLimit}
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default AllShifts