import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function MoreFilters({ filterComponents, handleFilterReset, handleFilterSearch, isFilterDisabled , inlineStyle={}}) {

  const [open, setOpen] = useState(false);
  let {pathname} = useLocation();

  return (
    <div>
      <Tooltip title={"Advance Search"}>
        <button className="btn-add-new" style={{height:'44px', width:'50px', marginTop:'11px', fontSize:'1.28rem', display:'flex', justifyContent:'center', alignItems:'center', border:"none", ...inlineStyle}} onClick={() => setOpen(true)}>
          {/* <IconButton size="small" style={{ color: "white" }} onClick={() => setOpen(true)}> */}
            <FilterAltIcon fontSize='inherit' />
          {/* </IconButton> */}
        </button>
      </Tooltip>

      <Drawer
        PaperProps={{
          sx: {
            height: 'max-content',
            maxHeight:'85vh',
            top: 70,
            borderRadius: "10px",
            minWidth:"150px",
            width: pathname === "/report-status" || pathname === "/month-wise-report" ? '22vw' : pathname === "/attendance-report" ? "18vw" : pathname === "/upload-resume" ? '32vw' : '30vw'
          },
        }}
        anchor={"right"}
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box role='presentation' sx={{ flexGrow: 1 }}>
          <AppBar position='static'>
            <Toolbar>
              <Typography
                variant='h6'
                component='div'
                sx={{ flexGrow: 1, fontWeight: "bold" }}
              >
                Advance Search
              </Typography>
              <IconButton
                style={{ color: "white" }}
                onClick={() => setOpen(false)}
              >
                <CloseIcon fontSize='inherit' />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Card>
            <CardContent>
              { typeof filterComponents === 'object' ? filterComponents : filterComponents()}
              <Divider />
              <div style={{ textAlign: "center", marginTop: "30px" }}>
                <Grid container>
                  <Grid item xs={2} md={6}>
                    <Button variant='contained' color='primary' onClick={()=>handleFilterReset()} disabled={isFilterDisabled}>
                        Reset
                    </Button>
                  </Grid>
                  <Grid item xs={2} md={6}>
                    <Button variant='contained' color='primary' onClick={()=>handleFilterSearch(setOpen)} disabled={isFilterDisabled}>
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </CardContent>
          </Card>
        </Box>
      </Drawer>
    </div>
  );
}
export default MoreFilters;