import React,{ useEffect, useState } from 'react'
import './domain.scss'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { toast, ToastContainer } from 'react-toastify';
import DynamicTable from "../../components/dynamic-table/DynamicTable"
import dataService from "../../redux/services/data.service"
import RegularizeModal from "../../components/common-function/RegularizeModal"
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { projectsList, domainList } from "../../redux/features/domainSlice"
import { useDispatch, useSelector } from "react-redux"
import { STATUSES } from "../../redux/common-status/constant"
// import { Tooltip } from '@mui/material';
// import { RefreshButton } from '../../components/common-function/RefreshButton';
import { ArrowCircleLeft } from '@mui/icons-material';
const { REACT_APP_ENV } = process.env;

const ProjectDomain = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const rtm_user = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))
  const userId = rtm_user.user.data.id;

  const { domains, projects, projects_count, projects_status } = useSelector(state => state.domain)
  const [pageSize, setPageSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [skip, setSkip] = useState(0);
  const [params, setParams] = useState({})
  const [setFieldName] = useState("")
  const [setDefaultValue] = useState("")
  const [limit, setLimit] = useState(25);
  // Modal Related data
  const [open, setOpen] = useState(false)
  const [formLoading, setFormLoading] = useState(false)
  const [updating, setUpdating] = useState(false);
  const [editable, setEditable] = useState(false);

  const whereClause = { ...params, "skip": skip, "limit": pageSize || 25, "order_by": { "column": "-id" } }

  const [projectsData, setProjectsData] = useState({

  })

  const modalDetail = {
    title: "Project Details",
    editModalTitle: "Edit Project",
    addModalTitle: "Add Project",
    formInputs: [
      { id: 1, name: "project_name", label: "Project Name", placeholder: "Enter Name", type: "textWithLabel", required: true, disabled: false },
      {
        id: 3, name: "status", label: "Status", placeholder: "Enter For Date", type: "status",
        options: [
          { id: 1, label: "Active", value: true },
          { id: 2, label: "Inactive", value: false },
        ],
        required: true, disabled: false
      },
      {
        id: 2, name: "domains", label: "Domains", placeholder: "Enter Domains", type: "multiSelect",
        options: domains.map(e => ({ id: e.id, label: e.name, value: e.id })),
        required: true, disabled: false
      },
    ],
  }

  const projectDomainColumns = [
    { field: "id", headerName: "ID", width: 70 },
    {
      field: "project_name",
      headerName: "Project Name",
      width: 275,
    },
    {
      field: "project_key",
      headerName: "Project Key",
      width: 150,
    },

    {
      field: "is_jira_project",
      headerName: "Is Jira Project",
      width: 150,
      hideable: false,
      // filterable:false,
      sortable: false,

      type: 'singleSelect',
      valueOptions: [{ label: "Yes", value: "True" }, { label: "No", value: "False" }],
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.is_jira_project ? "Yes" : "No"}
          </div>)
      },
    },
    {
      field: "domains",
      headerName: "Domains",
      width: 380,
      hideable: false,
      filterable: false,
      sortable: false,
      type: 'singleSelect',
      valueOptions: domains,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.domains && params.row.domains.length ?
              params.row.domains.map((domain, index) => {
                return (
                  <p key={index} style={{marginTop:"0px"}}>
                    {index === 0 && domain.name}
                     {index === 0 && params.row.domains.length > 1 && ","}
                    {params.row.domains.length > 1 && index === 1 && domain.name} {index === 1 && params.row.domains.length > 2 && ","}
                  </p>
                )
              })
              : ""
            }
            {(params.row.domains && params.row.domains.length > 2) ? `..[+${params.row.domains.length - 2}]` : ""}

          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,

      type: 'singleSelect',
      valueOptions: [
        { label: "Active", value: "True" },
        { label: "Inactive", value: "False" },
      ],
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`} >
            <p>{params.row.status === true ? 'Active' : 'Inactive'}</p>
          </div>
        );
      },
    },
  ];


  const onSubmit = async (data) => {
    const finalData = projectsData.id ? { ...projectsData, ...data, updated_by: userId } : { ...data, created_by: userId }
    // return;
    const res = await dataService.assign_project_domain({
      "project_id": finalData.project_id,
      "domains_ids": finalData?.domains?.map(e => e.id),
      "created_by": userId
    });
    if (res.data && res.data.status === 200) {
      toast.success(res.data.message)
      dispatch(projectsList(whereClause))
      onCloseModal();
    }
    else {
      toast.error(res.data.message)
    }
  }

  const onAdditionalAction = async (data, key) => {
    if (key === "assign_domain") {
      setProjectsData(
        {
          ...projectsData,
          project_name: data.project_name, domains: data.domains,
          project_id: data.id, status: data.status,
          created_by: userId
        });
      setUpdating(true)
      setEditable(true);
      setOpen(true);
    }
  }
  //  Open Modal
  const onOpenModal = () => {
    setOpen(true)
  }

  //  Close Modal
  const onCloseModal = () => {
    setOpen(false)
    setUpdating(false);
    setEditable(false);
    setProjectsData({});
  }

  useEffect(() => {
    dispatch(projectsList(whereClause))
    dispatch(domainList())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skip, params])

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />

        <div className="top-headings new-rtms-heading new-design-icon">
          <h3 ><i><ArrowCircleLeft className='new-back-icon'/> </i> Project-Domain </h3>
          <div>
             {/* <RefreshButton api={projectsList} filter={whereClause} style={{ marginTop: 0 }} />
             <Tooltip title="Back">
              <button onClick={() => navigate(-1)} className="btn-add-new ">
                <ArrowBackIcon />
              </button>
            // </Tooltip>  */}
          </div>
        </div>

        <ToastContainer />
        <>
          {
            <DynamicTable
              moduleName="holidays"
              tableColumns={projectDomainColumns}
              tableRows={projects || []}
              currentPage={currentPage} pageSize={pageSize} skip={skip} params={params}
              setPageSize={setPageSize} setCurrentPage={setCurrentPage} setSkip={setSkip} setParams={setParams}
              setFieldName={setFieldName} setDefaultValue={setDefaultValue}
              paginationMode={"server"}
              rowCount={projects_count}
              loading={projects_status === STATUSES.LOADING}
              columnVisibilityModel={{
                id: false,
              }}
              additionalAction={[{ id: 1, key: "assign_domain", title: "Assign Domain" }]}
              onAdditionalAction={onAdditionalAction}
              limit={limit}
              setLimit={setLimit}
            />
          }
        </>
        {
          <RegularizeModal
            modalDetail={modalDetail}
            open={open}
            onOpen={onOpenModal}
            onClose={onCloseModal}
            onSubmit={onSubmit}
            hideBackdrop
            fullWidth
            maxWidth
            updating={updating}
            editData={projectsData}
            editable={editable}
            formLoading={formLoading}
            setFormLoading={setFormLoading}
            additionalActions={modalDetail.additionalActions}
          />
        }
      </div>
    </div>
  )
}

export default ProjectDomain