import axios from "axios";

const { REACT_APP_ENV, REACT_APP_API_BASEURL } = process.env;
const rtms = localStorage.getItem(`${REACT_APP_ENV}_rtms`) && JSON.parse(localStorage.getItem(`${REACT_APP_ENV}_rtms`))

export default axios.create({
  baseURL: REACT_APP_API_BASEURL,
  headers: {
    "Content-type": "application/json",
    "Authorization": rtms ? rtms.token : '',
    // "Cookie":`token=${localStorage.getItem('token')}`
  }
});
