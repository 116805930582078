import { Add } from '@mui/icons-material'
import { TablePagination, Tooltip } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast, ToastContainer } from 'react-toastify'
import DeleteDialogModal from '../../../components/common-function/DeleteDialogModal'
import GetLocalstorage from '../../../components/common-function/GetLocalstorage'
import LoaderForm from '../../../components/common-function/LoaderForm'
import { RefreshButton } from '../../../components/common-function/RefreshButton'
import UcActionComponent from '../../../components/common-function/UcActionComponent'
import CustomNoRowsOverlay from '../../../components/CustomEmptyOverlay'
import { screeningFormColumns } from '../../../components/datatable-coulms/tableCoulmns'
import Navbar from '../../../components/navbar/Navbar'
import Sidebar from '../../../components/sidebar/Sidebar'
import TablePaginationActions from '../../../components/TablePaginationActions'
import { STATUSES } from '../../../redux/common-status/constant'
import { getScreeningFormList, screeningFormUpdate, screeningFormEdit,defaultscreeningDataColumn } from '../../../redux/features/candidateScreeningFormSlice'
import DefaultScreeningForm from './DefaultScreeningForm'
import { getGridStringOperators } from '@mui/x-data-grid'

const default_screening_Columns = GetLocalstorage.get_default_screening_data_column()

export default function DefaultScreeningFormList() {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [archiveId, setArchiveId] = useState(0)
    const [archiveOpen, setArchiveOpen] = useState(false)
    const { status, error, screeningForm_List, screeningForm_Edit, screeningForm_Update,defaultscreeningDataColumns } = useSelector(state => state.candidateScreeningForm)
    const [reload, setReload] = useState(false)
    const [editFormData, setEditFormData] = useState({})

    const [filterQuery, setFilterQuery] = useState(`{"order_by":{"column":"-status"},"skip":0, "limit":25}`)
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [currentPage, setCurrentPage] = useState(0);
   
    const [defaultQuestion, setDefaultQuestion] = useState([])
    const [changeStatus, setChangeStatus] = useState('');

    const [columnVisibilityModel, setColumnVisibilityModel] = useState(default_screening_Columns?.all_screening_column ? default_screening_Columns.all_screening_column : {
        form_key: false,
        id: false,
    });
    const [filterClosingFields, setFilterClosingFields] = useState({columnField:'', operatorValue:''});

    const setKeyForList = (obj) => {
        let data;
        if (default_screening_Columns) {
            data = { 'all_screening_column': obj, 'reported_screening_column': default_screening_Columns.reported_screening_column, 'my_task_column': default_screening_Columns.my_task_column, 'task_column': default_screening_Columns.task_column }
        } else {
            data = { 'all_screening_column': obj }
        }
        if (defaultscreeningDataColumns.reported_screening_column) {
            data['reported_screening_column'] = defaultscreeningDataColumns.reported_screening_column
        }
        if (defaultscreeningDataColumns.my_task_column) {
            data['my_task_column'] = defaultscreeningDataColumns.my_task_column
        }
        if (defaultscreeningDataColumns.task_column) {
            data['task_column'] = defaultscreeningDataColumns.task_column
        }
        dispatch(defaultscreeningDataColumn(data))
    }

    useEffect(() => {

        dispatch(getScreeningFormList(filterQuery))
    }, [dispatch])


    useEffect(() => {
        let Questions=[]
        if (screeningForm_List?.data?.default_questions?.length > 0)
         Questions = screeningForm_List?.data.default_questions.map((item) => {
            return { screening_question: item.question_title, screening_answer: "",question_key:item.question_key, }
        })

            setDefaultQuestion(Questions)
    }, [screeningForm_List])

    useEffect(() => {
        if (status === STATUSES.FAILURE && error) {
            toast.error(error.message)
            setReload(false)
        }
    }, [error])
    const handleOpen = () => setOpen(true);

    const handleArchive = (id) => {
        setArchiveId(id)
        if (id.status === true) {
            setChangeStatus("InActive")
        } else {
            setChangeStatus("Active")
        }
        setArchiveOpen(true)
    }

    const handleClickArchive = (data) => {
        setReload(true)
        setArchiveOpen(false)
        let addvalues = {
            title: data.title,
            form_data: data.form_data,
            form_key: data.form_key,
            status: !data.status,
            updated_by: GetLocalstorage.userDetail().id
        }
        let ID = data.id;
        dispatch(screeningFormUpdate({ ID, addvalues }))
    }

    const handleClickEdit = (id) => {
        dispatch(screeningFormEdit(id))
        setReload('edit_data')
    }

    if (reload === 'edit_data' && status === `${STATUSES.SUCCESS}` && screeningForm_Edit && screeningForm_Edit.data) {
        let editData = screeningForm_Edit.data
        setEditFormData(editData)
        setOpen(true)
        setReload(false)
    }
     const filterOperators = getGridStringOperators().filter(({ value }) =>
    ['contains', 'equals', 'startsWith', 'endsWith'].includes(value),
);
    const screeningFormColumns = [
        { field: "title", headerName: "Screening Question Set", width: 340 ,filterOperators},
        // { field: "form_key", headerName: "Form Key", width: 340 },
        {
          field: "status",filterOperators,
          headerName: "Status",
          width: 160,
          renderCell: (params) => {
            return (
              <div className={`cellWithStatus ${params.row.status}`}>
                {params.row.status === true ? 'Active' : 'Inactive'}
              </div>
            );
          },
        }
      ];
    const actionColumn = [
        {
            field: "action",
            headerName: "Action",
            width: "120",
            hideable: false,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent
                            deleteIconName="ArchiveIcon"
                            moduleName="source-type"
                            rowid={params.row.id}
                            addMoreData={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl=""
                            isDelete={true}
                            editButton={handleClickEdit}
                            deleteButton={() => handleArchive(params.row)} />
                    </div>
                );
            },
        },
    ];

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getScreeningFormList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize.target.value)
        setCurrentPage(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getScreeningFormList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(screeningForm_List && screeningForm_List.data && screeningForm_List.data.rows && screeningForm_List.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                rowsPerPageOptions={GetLocalstorage.get_page_item_list()}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getScreeningFormList(JSON.stringify(newFilterData)))
        } else {
            setFilterQuery(`{ "skip": 0, "limit": ${limit}, "order_by": { "column": "-id" } }`)
        }
    }

    //filter
    const onFilterChange = (filterModel, r) => {

        if(filterModel.items.length)
        {
            setFilterClosingFields(()=>({columnField:filterModel.items[0].columnField, operatorValue:filterModel.items[0].operatorValue}))
        }

        if(filterModel.items.length == 0 ? true : filterModel.items.length && filterClosingFields.columnField === filterModel.items[0].columnField && filterClosingFields.operatorValue === filterModel.items[0].operatorValue  && filterModel.items[0].value === undefined && r.reason === "upsertFilterItem")
        {
            setReload(true);
            setTimeout(()=>
            {
                setReload(false)
                setCurrentPage(0)
                setFilterQuery(`{"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`)
                dispatch(getScreeningFormList(`{"order_by":{"column":"-id"},"skip":0,"limit":${limit}}`))
            },0);
        }

        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                filterModel.items[0].operatorValue === "equals" ? 'ieq' :
                    filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit}}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(getScreeningFormList(filterData))
        }
    }

    if (reload === true && status === `${STATUSES.SUCCESS}_screeningForm_Update` && screeningForm_Update && screeningForm_Update.message) {
        if (screeningForm_Update.status === 200) {
            toast.success(screeningForm_Update.message)
            dispatch(getScreeningFormList(filterQuery))
        } else {
            toast.error(screeningForm_Update.message)
        }
        setReload(false)
    }

    return (
        <div className="listing">
            <Sidebar />
            <div className="listingContainer">
                <Navbar />
                <div className="datatable">
                    <div className="datatableTitle">
                        <h3>Screening Questions Set</h3>
                        <div>
                            <Tooltip title={"Add New Default Screening Question"}>
                                <button onClick={() => handleOpen()} className="btn-add-new" style={{ marginRight: "8px" }}><Add /></button>
                            </Tooltip>
                            <RefreshButton api={getScreeningFormList} filter={filterQuery} />
                        </div>

                    </div>
                    <ToastContainer />
                    <div className="custom-pagination-class">
            <CustomPagination />
          </div>
                    {(status === STATUSES.LOADING || reload === true || reload === 'edit_data') && <LoaderForm />}
                    {
                        !reload &&
                        <DataGrid
                            className="datagrid"
                            columnVisibilityModel={defaultscreeningDataColumns?.all_screening_column ? defaultscreeningDataColumns.all_screening_column : columnVisibilityModel}
                            onColumnVisibilityModelChange={(newModel) => { setColumnVisibilityModel(newModel); setKeyForList(newModel) }
                            }
                            rows={screeningForm_List?.data?.rows ? screeningForm_List.data.rows : []}
                            columns={screeningFormColumns.concat(actionColumn)}
                            pageSize={pageSize}
                            style={{ fontSize: 16 }}
                            onFilterModelChange={onFilterChange}
                            disableSelectionOnClick
                            components={{
                                Pagination: CustomPagination,
                                NoRowsOverlay: CustomNoRowsOverlay
                            }}
                            onSortModelChange={(sort) => handleSort(sort)}
                            hideFooterPagination={true}
                        />
                    }
                </div>
            </div>

            {/* add screening form popup */}
            {
                open === true ?
                    <DefaultScreeningForm
                        title='defaultform'
                        closeModal={setOpen}
                        editDefaultFormData={editFormData}
                        setEditDefaultFormData={setEditFormData}
                        filterQuery={filterQuery}
                        defaultQuestion={defaultQuestion}
                    />
                    : ""
            }

            {/* delete popup */}
            <DeleteDialogModal
                open={archiveOpen}
                onClose={setArchiveOpen}
                heading={changeStatus}
                paragraph={`Are you sure to ${changeStatus} default screening qusetions`}
                handleArchive={handleClickArchive}
                id={archiveId}
            />
        </div>
    )
}
