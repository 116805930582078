import "./profile.scss";
import React, { useState, useEffect } from 'react'
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import api from "../../redux/APIS/api";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Dialog, DialogContent, DialogTitle, Tooltip } from "@mui/material";
import Dropzone from 'react-dropzone';
import { imageUpload, resourceUpdateById } from "../../redux/features/resourceSlice";
import { useDispatch, useSelector } from "react-redux";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { toast, ToastContainer } from "react-toastify";
import { Close } from "@mui/icons-material";
import {SingleDatePickerInputField } from "../../components/common-function/InputFields";
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import DialogBox from "./DialogBox";
const { REACT_APP_API_BASEURL } = process.env;

const initialFormData = Object.freeze({
  date_of_birth: null,
  date_of_marriage: null,

})

const Profile = () => {
  const navigate = useNavigate()
  const { Id } = useParams()
  const [user, setUser] = useState({});
  const [formValues, setFormValues] = useState(initialFormData)
  const { status, error, profilePhoto, updateResource } = useSelector(state => state.resource);

  const dispatch = useDispatch();
  
  const loadProfile = async () => {
    setLoader(true);
    if (Id > 0) {
      const res = await api.get(`/resources/main/${Id}`)
        .then().catch(error => console.log(error))
      if (res?.data && res.data.data) {
        setUser(res.data.data);
        setLoader(false);

      }
    } else {
      const res = await api.get('/user')
        .then().catch(error => console.log(error))
      if (res?.data && res.data.data) {
        setUser(res.data.data);
        setFormValues({
          date_of_birth: res?.data?.data?.date_of_birth,
          date_of_marriage: res?.data?.data?.date_of_marriage,
        })
        setLoader(false);
      }
    }
  }
  const [selectedImage, setSelectedImage] = useState(null);
  const [fileErr, setFileErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [invalidExtension, setInvalidExtension] = useState("");
  const [openModel, setOpenModel] = useState(false)
  const [formatedDates, setFormatedDates] = useState({
    marriage_anniversary: "",
    work_anniversary: "",
  })
  const [openDialog, setOpenDialog] = React.useState(false);

  const onFileChange = (event) => {
    setInvalidExtension("")
    let file = event[0];
    const fileType = file.name.split(".")[1];
    let image_extension = ["jpg", "jpeg", "png"]
    if (image_extension.includes(fileType.toLowerCase())) {
      if (file?.size >= 20480 && file?.size <= 1048576) {
        setFileErr("");
        setSelectedImage(file);
        const getFormData = (obj) => {
          const formData = new FormData();
          Object.keys(obj).forEach(key => formData.append(key, obj[key]));
          return formData;
        }
        let obj = {
          id: user.id,
          file: file,
          display_name: file.name.split(".")[0],
          upload_type: 'resource'
        }
        dispatch(imageUpload(getFormData(obj)));
        setSelectedImage(null);
        setLoader(true);
      } else {
        setSelectedImage(null);
        setFileErr("Image size should be 20kb to 1 mb")
      }

    } else {
      setSelectedImage(null);
      setFileErr("You can only upload jpg, jpeg, png file")
    }
  }

  const handleModelClose = () => {
    setOpenModel(false)
  }

  if (loader === true && status === STATUSES.SUCCESS && profilePhoto && profilePhoto.message) {
    if (profilePhoto.status === 200) {
      loadProfile();
      setLoader(false);
      toast.success(profilePhoto.message);
    }
    if (profilePhoto.status === 400) {
      setLoader(false);
      setInvalidExtension(profilePhoto.message);
      toast.error(profilePhoto.message);
    }
  }

  if (loader === true && status === STATUSES.FAILURE && error && error.message) {
    setLoader(false)
    toast.error(error.message);
  }

  if (loader === true && status === STATUSES.SUCCESS && updateResource && updateResource.message) {
    if(updateResource.status === 200){
      handleModelClose()
      loadProfile()
      toast.success(updateResource.message);
      setLoader(false)
    }else{
      handleModelClose()
      toast.error(updateResource.message);
      setLoader(false)
    }
    
  }  

  useEffect(() => {
    loadProfile()
  }, [])
  

  const handleChange = (evt) => {
    const { name, value } = evt.target || evt;
    setFormValues({ ...formValues, [name]: value })
  }

  const sumbmitData = (event) => {
    event.preventDefault()
    if (!formValues.date_of_birth) {
      return
    }
    let techArray = user?.technologies
    const addData = {
      display_name: user?.display_name,
      email: user?.email,
      name: user?.name,
      password: user?.password,
      status: user?.status,
      user: user?.user_id,
      date_of_birth: formValues?.date_of_birth,
      contact_no:user?.contact_no,
      is_interviewer:user?.is_interviewer,
      technologies:techArray,
    }
    if (formValues.date_of_marriage) {
      addData["date_of_marriage"] = formValues.date_of_marriage
    }
    dispatch(resourceUpdateById({ id: user?.id, addvalues: addData }))
    setLoader(true)
  }

  const formatDates = () => {
    const userDOJ = new Date(user.doj);
    const userDOM = new Date(user.date_of_marriage);

    const currentYear = new Date().getFullYear();
    const arrangeDate = (date,event) => {
      if(!date){
        return ""
      }
      const anniversaryDate = new Date(date);
      anniversaryDate.setFullYear(currentYear);
      const formattedWorkAnniversary = anniversaryDate.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
      const [month, day, year] = formattedWorkAnniversary.split(' ');
      const rearrangedDate = `${day} ${month} ${year}`;
      return rearrangedDate
    }
    const joiningDate= arrangeDate(userDOJ,"work_anniversary")
    const marriageDate = arrangeDate(userDOM,"marriage_anniversary")
    
    setFormatedDates({
      marriage_anniversary:marriageDate ,
      work_anniversary: joiningDate,
    })

  }

  useEffect(()=>{
    if(user?.id){
      formatDates()
    }
  },[user,user.id])


  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <ToastContainer />

        <div className="top">
          {loader === true && <LoaderForm />}
          <div className="left">

            <div className="item">

              {user.avatar_path ?
                <img src={REACT_APP_API_BASEURL + user.avatar_path.split("").slice(1).join("")}
                  alt="" className="itemImg" />
                :
                <img src="https://images.unsplash.com/photo-1504376379689-8d54347b26c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=536&q=80"
                  alt="" className="itemImg" />
              }
              <div className="file-dropzone-tabs">
                <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)} maxFiles="1">
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>{selectedImage && selectedImage !== null ? selectedImage.name : <>Drag 'n' drop photo here, or click to upload photo <br />(&#128073;Only jpg/jpeg/png and size 20KB to 1MB file accepted.)</>}</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
              {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
              {invalidExtension !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{invalidExtension}</p>}

            </div>
            <div className="profile-change-password">
              <Button variant="contained" startIcon={<KeyOutlinedIcon/>} onClick={()=> setOpenDialog(true)}>Change Password</Button>
              <DialogBox setOpenDialog={setOpenDialog} openDialog={openDialog}/>
            </div>
          </div>
          <div className="right">
            <Tooltip title="Back">
              <button className="editButton" onClick={() => navigate(-1)}><ArrowBackIcon /></button>
            </Tooltip>
            <div>
              <h1 className="title">Official Data</h1>
              <div className="details">
                {user.display_name &&
                  <div className="detailItem">
                    <span className="itemKey">Name :</span>
                    <span className="itemKey2">{user.display_name}</span>
                  </div>
                }
                {user.email &&
                  <div className="detailItem">
                    <span className="itemKey">Email :</span>
                    <span className="itemKey2">{user.email}</span>
                  </div>
                }
                {user.contact_no &&
                  <div className="detailItem">
                    <span className="itemKey">Contact no. :</span>
                    <span className="itemKey2">{user.contact_no}</span>
                  </div>
                }

                {user?.tms_role && user.tms_role.role_name &&
                  <div className="detailItem">
                    <span className="itemKey">Role :</span>
                    <span className="itemKey2">{user.tms_role.role_name}</span>
                  </div>
                }
                {user?.department && user.department.name &&
                  <div className="detailItem">
                    <span className="itemKey">Department :</span>
                    <span className="itemKey2">{user.department.name}</span>
                  </div>
                }
                {user.address &&
                  <div className="detailItem">
                    <span className="itemKey">Address :</span>
                    <span className="itemKey2"> {user.address}</span>
                  </div>
                }

                {user?.city && user.city.city_name &&
                  <div className="detailItem">
                    <span className="itemKey">City :</span>
                    <span className="itemKey2">{user.city.city_name}</span>
                  </div>
                }
                {user?.state && user.state.name &&
                  <div className="detailItem">
                    <span className="itemKey">State :</span>
                    <span className="itemKey2">{user.state.name}</span>
                  </div>
                }
                {user?.country && user.country.name &&
                  <div className="detailItem">
                    <span className="itemKey">Country :</span>
                    <span className="itemKey2">{user.country.name}</span>
                  </div>
                }

                {user.jira_account &&
                  <div className="detailItem">
                    <span className="itemKey">Jira account :</span>
                    <span className="itemKey2"> {user.jira_account}</span>
                  </div>
                }

                {user.emp_code &&
                  <div className="detailItem">
                    <span className="itemKey">Employee Code :</span>
                    <span className="itemKey2">{user.emp_code}</span>
                  </div>
                }

                {user.reporting_manager_name &&
                  <div className="detailItem">
                    <span className="itemKey">Manager :</span>
                    <span className="itemKey2">{user.reporting_manager_name}</span>
                  </div>
                }

              {user?.domain?.name &&
                <div className="detailItem">
                  <span className="itemKey">Domain:</span>
                  <span className="itemKey2">{user?.domain?.name}</span>
                </div>
              }


                {user.employment_type &&
                  <div className="detailItem">
                    <span className="itemKey">Employment Type :</span>
                    <span className="itemKey2">{user.employment_type.employment_type}</span>
                  </div>
                }

                {user.shift &&
                  <div className="detailItem">
                    <span className="itemKey">Shift Name :</span>
                    <span className="itemKey2">{[user.shift.shift_name]}</span>
                  </div>
                }
                {user.shift &&
                  <div className="detailItem">
                    <span className="itemKey">Shift Time :</span>
                    <span className="itemKey2">{[user.shift.timing_display]}</span>
                  </div>
                }

                <div className="detailItem">
                  <span className="itemKey">Is Interviewer :</span>
                  <span className="itemKey2">{user.is_interviewer === false ? "No" : "Yes"}</span>
                </div>

                {
                  user?.technology &&
                  <div className="detailItem">
                    <span className="itemKey" style={{ float: "left" }}>Technology :</span>
                    {user.technology.length > 0 ? user.technology.map((e, index) => (
                      <span className="itemKey2" key={index} >
                        {`${index ? ', ' : ''}${(e.skill_name).trim()}`}
                      </span>
                    )
                    ) : "N/A"}
                  </div>
                }

                {user.doj &&
                  <div className="detailItem">
                    <span className="itemKey">Work anniversary :</span>
                    <span className="itemKey2"> {user.doj}</span>
                  </div>
                }

              </div>
            </div>

            <div>
              <h1 className="title">Personal Data</h1>
              <Tooltip title="Edit">
                <button className="editButton" onClick={() => setOpenModel(true)}><EditIcon /></button>
              </Tooltip>
              <div className="details">

                {user.date_of_birth &&
                  <div className="detailItem">
                    <span className="itemKey">Date of Birth :</span>
                    <span className="itemKey2"> {user.date_of_birth}</span>
                  </div>
                }

                {user.date_of_marriage &&
                  <div className="detailItem">
                    <span className="itemKey">Marriage Anniversary :</span>
                    <span className="itemKey2">{user.date_of_marriage} </span>
                  </div>
                }


              </div>
              <Dialog
                hideBackdrop
                fullWidth
                maxWidth={"md"}
                open={openModel}
                onClose={handleModelClose}
                aria-labelledby="child-modal-title"
              >
                <Button
                  edge="start"
                  color="inherit"
                  onClick={handleModelClose}
                  aria-label="close"
                  style={{ position: "absolute", top: "10px", right: "10px" }}>
                  <Close />
                </Button>
                <DialogTitle style={{ background: '#2196f3', color: '#fff', marginBottom: "20px" }}>Edit Personal Details</DialogTitle>

                <DialogContent>
                  <form>
                    <div className="add-new-user-form">
                      <SingleDatePickerInputField
                        label={"Date Of Birth"}
                        name="date_of_birth"
                        defaultValue={formValues?.date_of_birth}
                        onChange={(e) => {
                          handleChange({
                            name: "date_of_birth",
                            value: e
                          })
                        }}
                      />

                      <SingleDatePickerInputField
                        label={"Date of Marriage"}
                        name="date_of_marriage"
                        defaultValue={formValues?.date_of_marriage}
                        onChange={(e) => {
                          handleChange({
                            name: "date_of_marriage",
                            value: e
                          })
                        }}
                      />

                    </div>
                    <button className="submit-modal" onClick={sumbmitData} >Update</button>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Profile