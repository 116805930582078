import Navbar from "../../components/navbar/Navbar"
import Sidebar from "../../components/sidebar/Sidebar"
import "./report.scss"
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { STATUSES } from "../../redux/common-status/constant";
import LoaderForm from "../../components/common-function/LoaderForm";
import { useNavigate } from "react-router-dom";
import { errorHandling } from "../../redux/features/authLogoutSlice";
import { resourceList } from "../../redux/features/resourceSlice";
import { resourceReportColumns } from "../../components/datatable-coulms/tableCoulmns";
import UcActionComponent from "../../components/common-function/UcActionComponent";



const AttendanceReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data, status, error } = useSelector(state => state.resource)
    const [filterQuery, setFilterQuery] = useState(`{"skip":0, "limit":10}`)
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        dispatch(resourceList(filterQuery))

        if (status === STATUSES.FAILURE) {
            errorHandling(error, navigate)
        }
    }, [])

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage)
        let pageSize = 10;
        let skip = 10

        if (newPage >= currentPage) {
            if (newPage === 0) {
                skip = (currentPage * 10)
            } else {
                skip = (currentPage * 10) + 10
            }
        } else if (newPage < currentPage) {

            if (skip < 0) {
                skip = 10
            } else {
                skip = currentPage * 10 - 10
            }

        }
        else {
            skip = 0
        }
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = skip
        newFilterData['limit'] = pageSize
        dispatch(resourceList(JSON.stringify(newFilterData)))
    }


    const actionColumn = [
        {
            field: "action",
            headerName: "View Attendance",
            width: 200,
            renderCell: (params) => {
                return (
                    <div className="cellAction">
                        <UcActionComponent moduleName="attendance-report" rowid={params.row.id}
                            editLinkUrl=""
                            viewLinkUrl="report"
                            isDelete={false}
                            editButton={false}
                            viewButton={false}
                            deleteButton={false} />
                    </div>
                );
            },
        },
    ];

    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value && filterModel.items[0].value.length > 2) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue
            const filterData = `{"filter":{"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"skip":0, "limit":10}`
            setFilterQuery(filterData)
            setCurrentPage(0)
            dispatch(resourceList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setFilterQuery(`{"skip":0, "limit":10}`)
            dispatch(resourceList('{"skip":0,"limit":10}'))
        }
    }

    return (
        <div className="report">
            <Sidebar />
            <div className="reportContainer">
                <Navbar />
                <div className="datatable">
                    <div className="top-headings">
                        <h3>Resource Attendance Report</h3>
                        <button onClick={() => navigate(-1)} className="btn-add-new ">
                            Back
                        </button>
                    </div>

                    {status === STATUSES.SUCCESS || status === STATUSES.FAILURE ?
                        <DataGridPro
                            className="datagrid"
                            columnVisibilityModel={{
                                id: false
                            }}
                            rows={data?.data && data.data.rows ? data.data.rows : []}
                            columns={resourceReportColumns.concat(actionColumn)}
                            pageSize={10}
                            paginationMode="server"
                            page={currentPage}
                            rowsPerPageOptions={[10]}
                            rowCount={(data?.data && data.data.count) || 10}
                            onPageChange={(newPage) => handlePageChange(newPage)}
                            filterMode="server"
                            onFilterModelChange={onFilterChange}
                        />
                        :
                        <LoaderForm />
                    }
                </div>
            </div>
        </div>
    )
}

export default AttendanceReport