import { useState, useEffect, useMemo,useRef } from 'react'
import { Link } from "react-router-dom";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Dropzone from 'react-dropzone'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { getCommentId, getFileUploadList, getMYEpicTaskList, getTaskHistory, postCommentCreate, resourceProjectList, taskAssigneeUpdateById, taskStatusUpdate, taskViewById, postTeamMemberCreate, getTeamMemberList, jireStatus, taskUpdateById, getProjectWorkflowStatus, getMYEpicTaskListByIDs } from '../../redux/features/taskSlice';
import { Tab, Tabs, Avatar, Button, Autocomplete, TextField, Box, TablePagination,Tooltip, Typography, Stack, Chip } from '@mui/material';
import LocalStorageDataService from "../../components/common-function/GetLocalstorage";
import LoaderForm from "../../components/common-function/LoaderForm";
import { STATUSES } from '../../redux/common-status/constant';
import moment from 'moment';
import { postUploadResume } from '../../redux/features/masterSlice';
import { resourceListForTaskEdit } from "../../redux/features/resourceSlice";
import { CKEditor } from 'ckeditor4-react';
import dataService from '../../redux/services/data.service';
import { toast, ToastContainer } from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import { DataGrid, getGridSingleSelectOperators } from '@mui/x-data-grid';
import TaskBreadCrumb from '../../components/breadcrumb/TaskBreadCrumb';
import { UploadFile, Close, ZoomOutMap, KeyboardArrowLeft, KeyboardArrowRight, ZoomInMap, Refresh } from '@mui/icons-material';
import TablePaginationActions from "../../components/TablePaginationActions";
import { DefaultDataGrid } from '../../components/common-function/DefaultDataGrid';
import Filter from '../../components/Filter';
import { DefaultFilterColumns } from "../../components/common-function/DefaultFilterColumns"
import { StoryDetails } from './StoryDetails';


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}
function firstLetterWord(str) {
    let result = "";

    // Traverse the string.
    let v = true;
    for (let i = 0; i < str.length; i++) {
        // If it is space, set v as true.
        if (str[i] === ' ') {
            v = true;
        }

        // Else check if v is true or not.
        // If true, copy character in output
        // string and set v as false.
        else if (str[i] !== ' ' && v === true) {
            result += (str[i]);
            v = false;
        }
    }
    return result;
}

function stringAvatar(name) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: firstLetterWord(name),
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`horizontal-tabpanel-${index}`}
            aria-labelledby={`horizontal-tab-${index}`}
            {...other}
        >
            {value === index && (
                <>
                    {children}
                </>
            )}
        </div>
    );
}
const columnsFile = [
    { field: 'id', headerName: 'ID', width: 90, filterable: false, hideable: false },
    {
        field: 'file_name',
        headerName: 'File Name',
        width: 300,
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 380,
        type: 'singleSelect',
        valueOptions: LocalStorageDataService.get_file_status(),
        renderCell: (params) => {
            return (
                <div className="cellWithStatus">
                    {LocalStorageDataService.get_file_status().map(e => { return e.value === params.row.status && e.label })}
                </div>
            )
        }

    },
    {
        field: 'error_log',
        headerName: 'Error Log',
        width: 380,
        type: 'singleSelect',
        renderCell: (params) => {
            const errorLog = params.row.error_log;
            return (
                <div className="cellWithStatus">
                    {/* {errorLog && errorLog.error_message ? (
                        <p>{errorLog.error_message}</p>
                    ) : "N/A"} */}
                    {typeof (errorLog) === 'object' ? <p>{errorLog.error_message}</p> : typeof (errorLog) === 'string' ?
                        <p>{errorLog}</p>
                        : "N/A"}
                </div>
            )
        }

    },
    {
        field: 'created_on',
        headerName: 'Date',
        //sortable: false,
        width: 160,
        type: 'date',
        renderCell: (params) => {
            return (
                <div className="cellWithStatus">
                    {moment(params.row.created_on).format('DD-MM-YYYY')}
                </div>
            )
        }
    },
];

// make clickable link
function makeUrlsClickable(text) {
    const urlRegex = /(http[s]?:\/\/[^\s<]+)/g;
    const transformedText = text.replace(urlRegex, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
    return transformedText;
}

let flag = 1;

export const EpicTaskNew = ({ epicId, hideDiv, activeDivs, activeClass, setActiveClass }) => {
    const dispatch = useDispatch()
    const [value, setValue] = useState(0)
    const [apiLoading, setApiLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null);
    const [formValues, setFormValues] = useState({})
    const [selected, setSelected] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [memberLoader, setMemberLoader] = useState(true)
    const [, setMember_List] = useState([]);
    const [selectedMember, setSelectedMember] = useState([]);
    const [selectedMemberLoader, setSelectedMemberLoader] = useState(true);
    const [assignee_name_default, setAssignee_name_default] = useState("")
    const [teamMembers, setTeamMembers] = useState([])
    const [teamMembersStaus, setTeamMembersStatus] = useState(false)
    const [commentActive, setCommentActive] = useState(false)
    const [comment, setComment] = useState("")
    const [fileErr, setFileErr] = useState("")
    const [activeTab, setActiveTab] = useState(0)
    const [activeMultiEpic, setActiveMultiEpic] = useState(false)
    const [issueSelectedAssignee, setIssueSelectedAssignee] = useState({})
    const [updateTeamMemberListForEpic, setUpdateTeamMemberListForEpic] = useState(false)
    const { status, defaultLoader, teamMemberStatus, taskData, myEpicTaskList, memberList, commentById, resourceProject_list, taskHistoryList, teamMemberList, taskAssigneeUpdate, uploadFileList, newData, updateTaskData, projectWorkflowStatusList, jireStatusList } = useSelector(state => state.task)
    const { resourceListForTsk } = useSelector(state => state.resource)
    const { createUploadResume } = useSelector(state => state.master)
    const [fileButtonActive, setFileButtonActive] = useState(false)
    const [relatedLoader, setRelatedLoader] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [limit, setLimit] = useState(25);
    const [skip, setSkip] = useState(0);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ id: false });
    const [show, setShow] = useState(false)
    const [filterQuery, setFilterQuery] = useState()
    const [filterResourceList, setFilterResourceList] = useState(false);
    const [filterIssueTypeList, setFilterIssueTypeList] = useState(false);
    const [descriptionDiv, setDescriptionDiv] = useState(false)
    const [descriptionComment, setDescriptionComment] = useState("")
    const [defaultValue, setDefaultValue] = useState({})
    const [options, setOptions] = useState({})
    const [fieldName, setFieldName] = useState("")
    const [storyDiv, setStoryDiv] = useState(false)
    const [activeDives, setActiveDives] = useState("")
    const editorInput = useRef(null);
    const [jobApproverStatus, setJobApproverStatus] = useState("");

    const [workflowStatus, setWorkflowStatus] = useState(null);

    const nothing = () => { }

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 0) {
            setActiveTab(0)
            setFilterQuery(`{"filter":{"project_id":{"value":${formValues && formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit}, "columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`)
            dispatch(getMYEpicTaskList(`{"filter":{"project_id":{"value":${formValues && formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit}, "columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))

        }
        if (newValue === 1) {
            //nothing
        }
        if (newValue === 2) {
            setActiveTab(2)
            dispatch(getFileUploadList(`{"filter":{"epic_id":{"value":${epicId}, "operation":"eq"}}, "exclude": { "status": { "value": 3, "operation": "eq" } }, "order_by":{"column":"-id"}, "no_limit":True}`))
        }

        if (newValue === 3) {
            setActiveTab(3)
            dispatch(getCommentId(epicId))
        }
        if (newValue === 4) {
            setActiveTab(4)
            dispatch(getTaskHistory(epicId))
        }
    }


    if (activeTab === 0 && myEpicTaskList && myEpicTaskList.data) {
        setActiveTab(false)
    }
    if (activeTab === 2 && uploadFileList && uploadFileList.data) {
        setActiveTab(false)
    }
    if (activeTab === 3 && commentById && commentById.data) {
        setActiveTab(false)
    }
    if (activeTab === 4 && taskHistoryList && taskHistoryList.data) {
        setActiveTab(false)
    }

    const assignToMulti = async (e, item, type) => {
        let userId = LocalStorageDataService.userDetail().id
        if (type === 'unassign') {
            setActiveMultiEpic(true)
            setIssueSelectedAssignee({ ...issueSelectedAssignee, issue_id: item.id, assignee: `${item.id}_0` })
            setRelatedLoader(item.id)
            dispatch(taskAssigneeUpdateById({ issue_id: item.id, resource_id: 0, assign_to_me: 0 }))

        }
        if (type === 'assign') {
            const request = await dataService.get_task_By_id(item.id)
            const result = request.data
            if (result.status === 200) {
                if (result.data && !result.data.assignee_id) {
                    setActiveMultiEpic(true)
                    setRelatedLoader(item.id)
                    setIssueSelectedAssignee({ ...issueSelectedAssignee, issue_id: item.id, assignee: `${item.id}_${userId}` })
                    dispatch(taskAssigneeUpdateById({ issue_id: item.id, resource_id: userId, assign_to_me: 1 }))
                }
                else {
                    setRelatedLoader(false)
                    setActiveMultiEpic(false)
                    toast.warning("You Can't changed assignee already assigned another member!")
                }
            } else {
                if (result.message) {
                    setRelatedLoader(false)
                    setActiveMultiEpic(false)
                    toast.error(result.message)
                }
            }
        }

    }

    if (activeMultiEpic === true && taskAssigneeUpdate && taskAssigneeUpdate.status) {
        setActiveMultiEpic(false)
        setRelatedLoader(false)
        if (taskAssigneeUpdate.status === 200) {
            setTimeout(() => {
                dispatch(getMYEpicTaskList(`{"filter":{"project_id":{"value":${formValues && formValues.project_id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit}, "columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))
            }, 200);
        } else {
            toast.error(taskAssigneeUpdate.message)
        }

    }

    const assignToMe = (e, item) => {
        e.preventDefault()
        let userId = LocalStorageDataService.userDetail().id
        setLoading(true)
        if (item === 'unassign') {
            setAssignee_name_default("")
            dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: 0, assign_to_me: 0 }))

        } else if (item === 'assign') {
            setAssignee_name_default({ key: userId, label: LocalStorageDataService.userDetail().display_name, value: userId, name: 'assignee' })
            dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: userId, assign_to_me: 0 }))
        }
        // else {
        //     dispatch(taskAssigneeUpdateById({ issue_id: item.id, resource_id: userId, assign_to_me: 0 }))

        // }
    }

    const checkAssignedfield = () => {
        let status = true
        if (teamMembers.length > 0) {
            teamMembers.map(e => {
                if (e.value === LocalStorageDataService.userDetail().id) {
                    status = false
                }
                return status
            })
        }
        return status
    }

    const changeRelatedStatus = (evt, task_id) => {
        if (evt) {
            const { name, value } = evt.target || evt;
            if (name === "status_name") {
                setLoading(true)
                setRelatedLoader(task_id)
                const transition = statusData.filter(x => (x.status_id).toString() === value ? x : "")
                dispatch(taskStatusUpdate({ issue_id: task_id, from_status_id: formValues.t_status ? formValues.t_status : value, to_status_id: value, transition_id: transition.length > 0 ? transition[0].transition_id : null }))
            }
        }
    }


    const changeMultiStatus = (evt, item) => {
        if (evt) {
            const { value } = evt.target || evt;
            setLoading('multi_assignee')
            setRelatedLoader(item.id)
            dispatch(taskAssigneeUpdateById({ issue_id: item.id, resource_id: value, assign_to_me: 0 }))
        }
    }

    const changeStatus = (evt, props) => {
        if (evt) {
            const { name, value } = evt.target || evt;

            if (name === "status_name") {
                setLoading(true)
                const transition = statusData.filter(x => (x.status_id).toString() === value ? x : "")
                setWorkflowStatus(props);
                dispatch(taskStatusUpdate({ issue_id: epicId, from_status_id: formValues.status_id ? formValues.status_id : value, to_status_id: value, transition_id: transition.length > 0 ? transition[0].transition_id : null }))
            }
            if (name === 'assignee') {
                value && setLoading(true)
                value && dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: value, assign_to_me: 0 }))

            }
            if (name === "lables") {
                setSelected(value)
                setFormValues({ ...formValues, labels: value })
            }

        }
    }
    const updateTeamMember = (members) => {
        let array = []
        let array1 = []
        selectedMember.map((o) => array1.push(o.value));
        members.map(e => {
            return array.push(e.value)
        })
        let array3 = array1.filter(function (obj) { return array.indexOf(obj) === -1; });

        let teamMemberData = {
            "jira_rtms_issue": epicId,
            "resource": array,
            "status": 1,
            "created_by": LocalStorageDataService.userDetail().id
        }
        if (myEpicTaskList && myEpicTaskList.data && myEpicTaskList.data.rows && myEpicTaskList.data.rows.length > 0) {
            myEpicTaskList.data.rows.map(item => (item.assignee === array3[0]) && assignToMulti('', item, 'unassign'))
        }

        dispatch(postTeamMemberCreate(teamMemberData))
        setSelectedMember(members);
    }

    useEffect(() => {
        if (epicId && activeDivs === `epic_${epicId}`) {
            setApiLoading(true)
            dispatch(taskViewById(epicId))
            dispatch(getProjectWorkflowStatus(epicId))
            dispatch(resourceListForTaskEdit())
            dispatch(getTeamMemberList(0))
            dispatch(jireStatus())
        }
        setTimeout(() => {
            setApiLoading(false)
            setActiveTab(false)
            setRelatedLoader(false)
        }, 2000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDivs])

    if (memberLoader === true && memberList && memberList.data) {
        let array = []
        memberList.data.rows.map(e => {
            return array.push({ display_name: e.resource.display_name, assignee_id: e.resource_id, jira_rtms_issue_id: e.jira_rtms_issue_id, actual_id: e.id })
        })
        setMember_List(array)
        setMemberLoader(false)
    }


    if (loading === true && file && file[0].name) {
        setFile(null)
        setLoading(false)
        setApiLoading(true)
        dispatch(taskViewById(epicId))
        dispatch(getTaskHistory(epicId));

    }
    if (loading === 'comment_true' && status === `${STATUSES.SUCCESS}_createComment`) {
        setComment("")
        setLoading(false)
        setActiveTab(3)
        dispatch(getCommentId(epicId))
    }

    if (loading === 'description_comment_true' && updateTaskData && updateTaskData.status) {
        setLoading(false)
        if (updateTaskData.data && updateTaskData.status === 200) {
            setDescriptionDiv(false)
            setApiLoading(true)
            dispatch(taskViewById(epicId))
            dispatch(getTaskHistory(epicId));
        } else {
            updateTaskData.message && toast.error(updateTaskData.message)
        }

    }

    if (loading === 'upload_true' && createUploadResume && createUploadResume.status) {
        if (createUploadResume.status === 200) {
            toast.success(createUploadResume.message)
        } else {
            toast.error(createUploadResume.message)
        }
        setFile("")
        setFileButtonActive(false)
        setLoading(false)
        setActiveTab(2)
        dispatch(getFileUploadList(`{"filter":{"epic_id":{"value":${epicId}, "operation":"eq"}}, "exclude": { "status": { "value": 3, "operation": "eq" } }, "order_by":{"column":"-id"}, "no_limit":True}`))
    }
    if (loading === true && status === `${STATUSES.SUCCESS}_taskStatusData`) {
        setLoading(false)
        setRelatedLoader(false)
        dispatch(getMYEpicTaskList(filterQuery))
        //dispatch(getMYEpicTaskList(`{"filter":{"project_id":{"value":${formValues.project_id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit}, "columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))
    }

    if (status === `${STATUSES.FAILURE}` && activeMultiEpic === true) {
        setActiveMultiEpic(false)
        setRelatedLoader(false)
    }
    if (loading === 'multi_assignee' && status === `${STATUSES.SUCCESS}_taskAssigneeUpdate`) {
        setLoading(false)
        setRelatedLoader(false)
        dispatch(getMYEpicTaskList(filterQuery))
        //dispatch(getMYEpicTaskList(`{"filter":{"project_id":{"value":${formValues.project_id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit}, "columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))

    }
    if (loading === true && status === `${STATUSES.SUCCESS}_taskAssigneeUpdate`) {
        setLoading(false)
        setApiLoading(true)
        dispatch(taskViewById(epicId))
        dispatch(getTaskHistory(epicId));
    }
    if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskHistoryList`) {
        setApiLoading(false)
    }
    if (apiLoading === true && status === `${STATUSES.SUCCESS}_commentById`) {
        setApiLoading(false)
    }
    if (updateTeamMemberListForEpic !== false && teamMemberList && teamMemberList.data) {
        if (updateTeamMemberListForEpic !== true &&  teamMemberList?.data && teamMemberList.data.rows && teamMemberList.data.rows.length === 0) {
            setSelectedMember(updateTeamMemberListForEpic)
            updateTeamMember(updateTeamMemberListForEpic)
        }
        if ( teamMemberList?.data && teamMemberList.data.rows && teamMemberList.data.rows.length > 0) {
            let array = []
            teamMemberList.data.rows.map(e => {
                return array.push({
                    label: e.resource.display_name,
                    value: e.resource.id
                })

            })
            setSelectedMember(array);
        }
        setUpdateTeamMemberListForEpic(false)
    }
    if (selectedMemberLoader === true && teamMemberStatus === `${STATUSES.SUCCESS}_teamMemberList`) {
        let array = []
        if (teamMemberList && teamMemberList.data && teamMemberList.data.rows && teamMemberList.data.rows.length > 0) {
            teamMemberList.data.rows.map(e => {
                //if (e.status === true) {
                return array.push({
                    label: e.resource.display_name,
                    value: e.resource.id
                })
                //}
            })
        }
        setSelectedMemberLoader(false)
        setSelectedMember(array)
    }

    if (apiLoading === true && status === `${STATUSES.SUCCESS}_taskData`) {

        let data = taskData.data
        if (taskData.status === 400) {
            toast.error(taskData.message)
            setFormValues({})
            dispatch(getMYEpicTaskList('type'))
        }
        setApiLoading(false)
        if (taskData.status === 200) {
            setFormValues(data)
            let job_approver_status = data && data.job && data.job.approver_status
            setJobApproverStatus(job_approver_status)
            setDescriptionComment(data.description)
            if (data && data.status_data) {
                let arrayForEpicStatus = []
                if (data.status_data.length > 0) {
                    data.status_data.map(e => {
                        if ((e.status_name).match("backlog") || (e.status_name).match("closed") || (e.status_name).match("process") || (e.status_name).match("progress")) {
                            arrayForEpicStatus.push(e)
                        }
                        return arrayForEpicStatus
                    })
                }
                setStatusData(arrayForEpicStatus)
            }
            if (data && data.assignee) {
                setAssignee_name_default({ label: data.assignee.display_name, value: data.assignee.id })
                if (data.assignee_id) {
                    let team_member = [{ label: data.assignee.display_name, value: data.assignee.id }]
                    setUpdateTeamMemberListForEpic(team_member)
                }

            }
            if (data && !data.assignee) {
                setUpdateTeamMemberListForEpic(true)
                // setAssignee_name_default({ label: data.reported_by.display_name, value: data.reported_by_id })
                // setLoading(true)
                // dispatch(taskAssigneeUpdateById({ issue_id: epicId, resource_id: data.reported_by_id, assign_to_me: 0 }))
            }

            if (data && data.project) {
                setTeamMembersStatus(true)
                dispatch(resourceProjectList(`{"filter":{"project":{"value":${data.project.id},"operation":"eq"}}, "no_limit":True}`))
                setFilterQuery(`{"filter":{"project_id":{"value":${data.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit}}`)
                dispatch(getMYEpicTaskList(`{"filter":{"project_id":{"value":${data.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit}, "columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))
                dispatch(getMYEpicTaskListByIDs(`{"filter":{"project_id":{"value":${data.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"no_limit":True, "columns":["id", "status__name", "epic_id", "assignee_id", "reported_by", "issue_type", "project_id"]}`))

            }
            dispatch(getTeamMemberList(`{"filter":{"jira_rtms_issue_id":{"value":${epicId}, "operation":"eq"},"status":{"value":True, "operation":"eq"}}, "order_by":{"column":"-id"}, "no_limit":True}`))

        }

    }
    if (teamMembersStaus === true && status === `${STATUSES.SUCCESS}_resourceProject_list` && resourceProject_list) {
        setTeamMembersStatus(false)
        let array = []

        if (resourceProject_list?.length > 0) {
            resourceProject_list.map(e => {
                return array.push({ label: e.display_name, value: e.id })
            })
        }
        setTeamMembers(array)
    }

    if (filterResourceList === false && resourceListForTsk && resourceListForTsk.status) {
        if (resourceListForTsk.status === 200) {
            let array = [{ label: "Unassigned", value: '0' }]
            resourceListForTsk.data && resourceListForTsk.data.rows.map(e => { return array.push({ value: e.id, label: e.name + "(" + e.email + ")" }) })
            array.length > 0 && setFilterResourceList(array)
            array.length > 0 && setOptions({ ...options, assignee: array })

        } else {
            setFilterResourceList([])
            setOptions({ ...options, assignee: [] })

        }
    }


    if (filterIssueTypeList === false && newData && newData.status) {
        if (newData.status === 200) {
            let array = []
            newData.data && newData.data.rows.map(e => { return e.status === true && array.push({ value: e.id, label: e.name }) })
            array.length > 0 && setFilterIssueTypeList(array)
            array.length > 0 && setOptions({ ...options, status: array })

        } else {
            setFilterIssueTypeList([])
            setOptions({ ...options, status: [] })
        }
    }
    const getStatusToRelatedStatus = (status_id) => {
        let array = []
        const status_list = projectWorkflowStatusList.status === 200 ? projectWorkflowStatusList.data : []
        let filterData = status_list.filter(x => x.status_id === status_id);
        if (filterData.length > 0) {
            array.push({ status_id: filterData[0].status_id, status_name: filterData[0].status_name })
            if (filterData[0].related_status && filterData[0].related_status.length > 0) {
                // filterData[0].related_status.map(e => {
                filterData[0].related_status.forEach(e => {
                    array.push(e)
                })
            }
        }
        return array
    }

    const inputHandler = (evt) => {
        let newContent = evt.editor.getData();
        setComment(newContent)

    }

    const inputDescriptionHandler = (evt) => {
        let newContent = evt.editor.getData();
        setDescriptionComment(newContent)
    }


    //}, [apiLoading, taskData])
    const uploadFile = () => {
        const formData = new FormData();
        formData.append("file", file[0]);
        formData.append("project", `${formValues && formValues.project.id}`);
        formData.append("epic", `${epicId}`);
        setLoading('upload_true')
        dispatch(postUploadResume(formData))
    }

    const editCommentChanges = (name) => {

        if (name === 'description') {
            setFormValues({
                ...formValues,
                description: descriptionComment,
            });
            setLoading('description_comment_true')
            dispatch(taskUpdateById({ id: epicId, addvalues: { [name]: descriptionComment } }))
        } else {
            const parser = new DOMParser();
            const doc = parser.parseFromString(comment, 'text/html');

            // might be a more efficient way to do this. just querying for anchors
            // and filtering out the ones that don't have a <sup> child
            const anchors = [...doc.querySelectorAll('a')]
            setCommentActive(false)
            let data = {
                "jira_issue_id": formValues && formValues.ticket_jira_key,
                "rtms_issue": epicId,
                "comment_url": anchors && anchors.length > 0 ? anchors[0].innerText : "",
                "is_jira_attachment": false,
                "body": comment,
                "created_resource": LocalStorageDataService.userDetail().id,
                "status": true,
                "created_by": LocalStorageDataService.userDetail().id

            }
            setLoading('comment_true')
            dispatch(postCommentCreate(data))
        }
        setDescriptionDiv(false);
        //dispatch(taskUpdateById({ id: taskId, addvalues: { [name]: value } }))
    }

    // const calculateDays = (startDate) => {
    //     if (new Date() === new Date(startDate)) {
    //         return 'Toady'
    //     }
    //     const diffInMs = new Date() - new Date(startDate)
    //     const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    //     return parseInt(diffInDays) + "  days ago";
    // }

    const disabledAssigne = () => {
        let statusIs = false;
        if (selectedMember && selectedMember.length > 0) {
            selectedMember.map(e => {
                if (e.value === LocalStorageDataService.userDetail().id && e.is_temporary_reallocated === false) {
                    statusIs = true
                }
                return statusIs
            })
        }
        return statusIs

    }

    const onFileChange = (event) => {
        let file = event[0]
        const fileType = file.name.split(".")[1];
        if (fileType === "pdf" || fileType === "doc" || fileType === "docx" || fileType === "zip" || fileType === "csv" || fileType === "xlsx") {
            setFileErr("")
            setFile(event);
        } else {
            setFile("");
            setFileErr("You can only upload pdf, docx, doc, zip, csv and xlsx files")
        }

        // using file type validation
        // if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || file.type === "application/pdf" || file.type === "application/zip" || file.type === "application/msword") {
        //     setFileErr("")
        //     setFile(event);
        // } else {
        //     setFile("");
        //     setFileErr("You can only upload pdf, docx, doc and zip files")
        // }

    }
    const activeStoryDiv = (id) => {
        setActiveDives(`story_${id}`)
        if (storyDiv > 0) { setStoryDiv(id) } else { setStoryDiv(id); }
    }
    const columnsRelatedStory = [
        {
            field: 'summary',
            headerName: 'Summary',
            width: 400,
            renderCell: (params) => {
                return (
                    <div style={{ cursor: 'pointer', color: '#0052cc' }} onClick={() => activeStoryDiv(params.row.id)} className="related-storys-colum1">
                        {params.row.summary}
                    </div>
                )
            }
        },

        {
            field: 'assignee',
            headerName: 'Assignee',
            width: 400,
            valueOptions: filterResourceList,
            type: 'singleSelect',
            renderCell: (params) => {
                return (
                    <div className="related-storys-colum3 custom-loader-tabs2">
                        {relatedLoader === params.row.id && <LoaderForm />}
                        {disabledAssigne() === true && !params.row.assignee ?
                            <button style={relatedLoader === params.row.id ? { pointerEvents: 'none', cursor: "pointer", backgroundColor: '#ddd', border: '1px solid #ddd', color: '#000' } : {}} onClick={(e) => assignToMulti(e, params.row, 'assign')} className='btn assign-btn'>{'Assign to me'}</button>
                            :
                            params.row.assignee && params.row.assignee !== LocalStorageDataService.userDetail().id ?
                                <button disabled className='btn'>{params.row && params.row.assignee_name}</button>
                                :
                                params.row.assignee === LocalStorageDataService.userDetail().id ?
                                    <>
                                        <select disabled={relatedLoader === params.row.id ? true : false} name='assignee' value={params.row.assignee} onChange={(evt) => changeMultiStatus(evt, params.row)} className="select-status-ticket">
                                            {selectedMember && selectedMember.length > 0 &&
                                                selectedMember.map((items, index) => {
                                                    return (
                                                        <option key={index} value={items.value}>{items.label}</option>
                                                    )
                                                })}
                                        </select>
                                        <button style={relatedLoader === params.row.id ? { pointerEvents: 'none', cursor: "pointer", backgroundColor: '#ddd', border: '1px solid #ddd', color: '#000' } : {}} onClick={(e) => assignToMulti(e, params.row, 'unassign')} className='btn assign-btn' >{'Unassign'}</button>
                                    </>
                                    :
                                    disabledAssigne() === true ?
                                        <button style={relatedLoader === params.row.id ? { pointerEvents: 'none', cursor: "pointer", backgroundColor: '#ddd', border: '1px solid #ddd', color: '#000' } : {}} onClick={(e) => assignToMulti(e, params.row, 'assign')} className='btn assign-btn'>{'Assign to me'}</button>
                                        :
                                        <button disabled className='btn' >{'Assign to me'}</button>

                        }
                    </div>
                )
            }
        },

        {
            field: 'status',
            headerName: 'Status',
            width: 300,
            type: 'singleSelect',
            valueOptions: filterIssueTypeList,
            renderCell: (params) => {
                return (
                    <div style={{width:'100%'}}>
                        {params.row.assignee === LocalStorageDataService.userDetail().id ?
                            getStatusToRelatedStatus(params.row.status_id).length > 0 &&
                            // <select disabled={relatedLoader === params.row.id ? true : false} name='status_name' value={params.row.status_id} onChange={(evt) => changeRelatedStatus(evt, params.row.id)} className="select-status-ticket">

                            //     {getStatusToRelatedStatus(params.row.status_id).map((itm, index) => {
                            //         return (
                            //             <option key={index} value={itm.status_id}>{itm.status_name}</option>
                            //         )
                            //     })}
                            // </select>
                            <Autocomplete
                            id="checkboxes-tags-demo"
                            name="status_name"
                            disabled={relatedLoader === params.row.id ? true : false}
                            options={getStatusToRelatedStatus(params.row.status_id)}
                            sx={{
                                border: 'none', borderRadius: '10px 0 10px 0', outline: 'none', fontSize: '12px', boxShadow: '-3px 3px 1px 0px rgb(0 0 0 / 10%)', width: '100%',
                                backgroundColor: getStatusToRelatedStatus(params.row.status_id).find(item=>item.status_id === params.row?.status_id)?.workflow_status_category__background_color_code,
                                '.MuiAutocomplete-input': {
                                    fontWeight: '800',
                                    color: getStatusToRelatedStatus(params.row.status_id).find(item=>item.status_id === params.row?.status_id)?.workflow_status_category__foreground_color_code,
                                    cursor:'pointer',
                                }
                            }}
                            defaultValue={getStatusToRelatedStatus(params.row.status_id).find(item=>item.status_id === params.row?.status_id)}
                            disableCloseOnSelect
                            onChange={(event, newValue) => {
                                newValue && changeRelatedStatus({name:'status_name', value:newValue.status_id}, params.row.id, newValue);
                            }}
                            getOptionLabel={(option) => option?.short_code}
                            renderOption={(props, option, { selected }) => (
                                <li style={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
                                    {/* <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    /> */}
                                    <Typography variant="subtitle1">{ option?.status_name}</Typography>
                                    <Stack direction={'row'} alignItems={"center"}>
                                        <ArrowRightAltIcon />
                                        <Chip
                                            // color="success"
                                            sx={{ borderRadius: '3px', backgroundColor: option.workflow_status_category__background_color_code, color:option.workflow_status_category__foreground_color_code, minWidth:'80px' }}
                                            disabled={false}
                                            variant="elevated"
                                            label={option?.short_code}
                                        />
                                    </Stack>
                                </li>
                            )}
                            // sx={{
                            //     '.MuiAutocomplete-tag': {
                            //         display: 'none',
                            //     }
                            // }}
                            style={{ width: '100%'}}
                            renderInput={(params) => (
                                <TextField size='small' {...params}/>
                            )}
                            />
                            :
                            (params.row.assignee !== LocalStorageDataService.userDetail().id) ?
                                <button disabled style={{ marginLeft: '5px' }} className='btn' >{params.row && params.row.status_name ? params.row.status_name : 'select'}</button>
                                :
                                (checkAssignedfield() === false && !params.row.assignee) ?
                                    getStatusToRelatedStatus(params.row.status_id).length > 0 ?
                                        // <select name='status_name' value={params.row.status} onChange={(evt) => changeRelatedStatus(evt, params.row.id)} className="select-status-ticket">

                                        //     {getStatusToRelatedStatus(params.row.status_id).map((itm, index) => {
                                        //         return (
                                        //             <option key={index} value={itm.status_id}>{itm.status_name}</option>
                                        //         )
                                        //     })}
                                        // </select>
                                        <Autocomplete
                                        id="checkboxes-tags-demo"
                                        name="status_name"
                                        options={getStatusToRelatedStatus(params.row.status_id)}
                                        sx={{
                                            border: 'none', borderRadius: '10px 0 10px 0', outline: 'none', fontSize: '12px', boxShadow: '-3px 3px 1px 0px rgb(0 0 0 / 10%)', width: '100%',
                                            backgroundColor: getStatusToRelatedStatus(params.row.status_id).find(item=>item.status_id === params.row?.status_id)?.workflow_status_category?.background_color_code,
                                            '.MuiAutocomplete-input': {
                                                fontWeight: '800',
                                                color: getStatusToRelatedStatus(params.row.status_id).find(item=>item.status_id === params.row?.status_id)?.workflow_status_category?.foreground_color_code,
                                                userSelect:'none'
                                            }
                                        }}
                                        defaultValue={getStatusToRelatedStatus(params.row.status_id).find(item=>item.status_id === params.row?.status_id)}
                                        disableCloseOnSelect
                                        onChange={(event, newValue) => {
                                            newValue && changeRelatedStatus({name:'status_name', value:newValue.status_id}, params.row.id, newValue);
                                        }}
                                        getOptionLabel={(option) => option?.short_code}
                                        renderOption={(props, option, { selected }) => (
                                            <li style={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
                                                {/* <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                /> */}
                                                <Typography variant="subtitle1">{ option?.status_name}</Typography>
                                                <Stack direction={'row'} alignItems={"center"}>
                                                    <ArrowRightAltIcon />
                                                    <Chip
                                                        // color="success"
                                                        sx={{ borderRadius: '3px', backgroundColor: option?.workflow_status_category?.background_color_code, color:option?.workflow_status_category?.foreground_color_code, minWidth:'80px' }}
                                                        disabled={false}
                                                        variant="elevated"
                                                        label={option?.short_code}
                                                    />
                                                </Stack>
                                            </li>
                                        )}
                                        // sx={{
                                        //     '.MuiAutocomplete-tag': {
                                        //         display: 'none',
                                        //     }
                                        // }}
                                        style={{ width: '100%'}}
                                        renderInput={(params) => (
                                            <TextField size='small' {...params} style={{ fontSize:'.5rem'}}/>
                                        )}
                                    />
                                        :
                                        <button style={{ marginLeft: '5px' }} disabled className='btn' >{'Workflow not assigned'}</button>
                                    :
                                    <button style={{ marginLeft: '5px' }} disabled className='btn' >{params.row && params.row.status_name ? params.row.status_name : 'select'}</button>

                        }
                    </div>
                )
            }
        },
        // {
        //     field: 'action',
        //     headerName: 'Action',
        //     width: 500,
        //     hideable: false,
        //     filterable: false,
        //     sortable: false,
        //     renderCell: (params) => {
        //         return (
        //             <div className="related-storys-colum3 custom-loader-tabs2">
        //                 {relatedLoader === params.row.id && <LoaderForm />}
        //                 {disabledAssigne() === true && !params.row.assignee ?


        //                     <button style={relatedLoader === params.row.id ? { pointerEvents: 'none', backgroundColor: '#ddd', border: '1px solid #ddd', color: '#000' } : {}} onClick={(e) => assignToMulti(e, params.row, 'assign')} className='btn assign-btn'>{'Assign to me'}</button>

        //                     :
        //                     params.row.assignee && params.row.assignee !== LocalStorageDataService.userDetail().id ?
        //                         <button disabled className='btn'>{params.row && params.row.assignee_name}</button>
        //                         :
        //                         params.row.assignee === LocalStorageDataService.userDetail().id ?
        //                             <>
        //                                 <select disabled={relatedLoader === params.row.id ? true : false} name='assignee' value={params.row.assignee} onChange={(evt) => changeMultiStatus(evt, params.row)} className="select-status-ticket">
        //                                     {selectedMember && selectedMember.length > 0 &&
        //                                         selectedMember.map((items, index) => {
        //                                             return (
        //                                                 <option key={index} value={items.value}>{items.label}</option>
        //                                             )
        //                                         })}
        //                                 </select>
        //                                 <button style={relatedLoader === params.row.id ? { pointerEvents: 'none', backgroundColor: '#ddd', border: '1px solid #ddd', color: '#000' } : {}} onClick={(e) => assignToMulti(e, params.row, 'unassign')} className='btn assign-btn' >{'Unassign'}</button>
        //                             </>
        //                             :
        //                             checkAssignedfield() === false ?

        //                                 <button style={relatedLoader === params.row.id ? { pointerEvents: 'none', backgroundColor: '#ddd', border: '1px solid #ddd', color: '#000' } : {}} onClick={(e) => assignToMulti(e, params.row, 'assign')} className='btn assign-btn'>{'Assign to me'}</button>

        //                                 :

        //                                 <button disabled className='btn' >{'Select'}</button>

        //                 }
        //                 {params.row.assignee === LocalStorageDataService.userDetail().id ?
        //                     multiStatusList && multiStatusList.length > 0 &&
        //                     multiStatusList.map((items, i) => {
        //                         return items && items.issue_id === params.row.id &&
        //                             <select disabled={relatedLoader === params.row.id ? true : false} name='status_name' value={items.status_id} onChange={(evt) => changeRelatedStatus(evt, params.row.id)} className="select-status-ticket">
        //                                 {items.status_data && items.status_data.length > 0 &&
        //                                     items.status_data.map((itm, index) => {
        //                                         return (
        //                                             <option key={index} value={itm.status_id}>{itm.status_name}</option>
        //                                         )
        //                                     })}
        //                             </select>
        //                     })
        //                     :
        //                     (params.row.assignee !== LocalStorageDataService.userDetail().id) ?
        //                         <button disabled style={{ marginLeft: '5px' }} className='btn' >{params.row && params.row.status_name ? params.row.status_name : 'select'}</button>
        //                         :
        //                         (checkAssignedfield() === false && !params.row.assignee) ?
        //                             statusData && statusData.length > 0 ?
        //                                 <select name='status_name' value={params.row.status} onChange={(evt) => changeRelatedStatus(evt, params.row.id)} className="select-status-ticket">

        //                                     {statusData.map((itm, index) => {
        //                                         return (
        //                                             <option key={index} value={itm.status_id}>{itm.status_name}</option>
        //                                         )
        //                                     })}
        //                                 </select>
        //                                 :
        //                                 <button style={{ marginLeft: '5px' }} disabled className='btn' >{'Workflow not assigned'}</button>
        //                             :
        //                             <button style={{ marginLeft: '5px' }} disabled className='btn' >{params.row && params.row.status_name ? params.row.status_name : 'select'}</button>

        //                 }

        //             </div>
        //         )
        //     }
        // }
    ]

    //custom pagination
    const handlePagination = (data) => {
        setCurrentPage(data)
        //let filterQuery = `{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = data * pageSize
        newFilterData['limit'] = limit
        //setLimit(limit)
        setSkip(data * pageSize)
        setActiveTab(0)
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMYEpicTaskList(JSON.stringify(newFilterData)))
    }

    const handlePageSizeChange = (newPageSize) => {
        //let filterQuery = `{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`
        setPageSize(newPageSize.target.value)
        setActiveTab(0)
        setCurrentPage(0)
        setSkip(0)
        setLimit(newPageSize.target.value)
        let newFilterData = JSON.parse(filterQuery);
        newFilterData['skip'] = 0
        newFilterData['limit'] = newPageSize.target.value
        setFilterQuery(JSON.stringify(newFilterData))
        dispatch(getMYEpicTaskList(JSON.stringify(newFilterData)))
    }

    function CustomPagination() {
        return (
            <TablePagination
                component="div"
                count={(myEpicTaskList && myEpicTaskList.data && myEpicTaskList.data.count) || 0}
                page={currentPage}
                rowsPerPage={pageSize}
                onPageChange={() => setCurrentPage(pageSize)}
                onRowsPerPageChange={handlePageSizeChange}
                ActionsComponent={(subProps) =>
                    <TablePaginationActions {...subProps}
                        handlePagination={handlePagination}
                    />}
            />
        );
    }

    const focusOnEditor = () => {
        if (editorInput.current) {
            editorInput.current.focus();
            // This will highlight and select text if there is some. Useful for editing

        } else {
            console.error("Auto focus did not work");
        }

    }

    // sorting
    const handleSort = (sort) => {
        if (sort.length) {
            let newFilterData = JSON.parse(filterQuery);
            newFilterData['skip'] = 0;
            newFilterData['limit'] = limit;
            newFilterData['order_by'] = {
                "column": sort[0].sort === "desc" ?
                    '-' + sort[0].field : sort[0].field
            }
            setFilterQuery(JSON.stringify(newFilterData))
            dispatch(getMYEpicTaskList(JSON.stringify(newFilterData)))
        } else {
            setCurrentPage(0)
            setSkip(0)
            dispatch(getMYEpicTaskList(filterQuery))
            // setFilterQuery(`{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`)
            // dispatch(getMYEpicTaskList(`{"filter":{"project_id":{"value":${formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))
        }
    }
    const refresh = () => {
        dispatch(getMYEpicTaskList(filterQuery))
    }


    const onFilterChange = (filterModel) => {
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField) {
            setFieldName(filterModel.items[0].columnField)
        }
        if (filterModel.items && filterModel.items.length > 0 && filterModel.items[0].columnField && filterModel.items[0].value) {
            let operatorValue = filterModel.items[0].operatorValue === "contains" ? "icontains" :
                filterModel.items[0].operatorValue === "startsWith" ? 'istartswith' :
                    filterModel.items[0].operatorValue === "is" ? 'eq' :
                        filterModel.items[0].operatorValue === "onOrAfter" ? 'date_gte' :
                            filterModel.items[0].operatorValue === "after" ? 'date_gt' :
                                filterModel.items[0].operatorValue === "before" ? 'date_lt' :
                                    filterModel.items[0].operatorValue === "onOrBefore" ? 'date_lte' :
                                        filterModel.items[0].operatorValue === "endsWith" ? 'iendswith' : filterModel.items[0].operatorValue;
            const filterData = `{"filter":{"project_id":{"value":${formValues && formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"},"${[filterModel.items[0].columnField]}":{"value":"${filterModel.items[0].value}","operation": "${operatorValue}"}},"order_by":{"column":"-id"},"skip":0, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`
            setCurrentPage(0)
            setSkip(0)
            setFilterQuery(filterData)
            dispatch(getMYEpicTaskList(filterData))
        }
        if (filterModel.items && filterModel.items.length === 0) {
            setCurrentPage(0)
            setSkip(0)
            setDefaultValue({})
            setFilterQuery(`{"filter":{"project_id":{"value":${formValues && formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`)
            dispatch(getMYEpicTaskList(`{"filter":{"project_id":{"value":${formValues && formValues.project.id}, "operation":"eq"}, "epic_id":{"value":${epicId}, "operation":"eq"}}, "order_by":{"column":"-id"},"skip":${skip}, "limit":${limit},"columns":[${DefaultFilterColumns.MYTASK_LIST_COLUMNS}]}`))
        }
    }


    // Custom filter
    function CustomFilterInputValue(props) {
        const { item, applyValue } = props;
        const handleFilterChange = (evt) => {
            if (evt) {
                setDefaultValue(evt)
                const { value } = evt;
                applyValue({ ...item, value: value });
            }
        };
        return (
            fieldName === "assignee" ?
                <Filter options={filterResourceList} onChange={handleFilterChange} defaultValue={defaultValue} />
                :
                <Filter options={filterIssueTypeList} onChange={handleFilterChange} defaultValue={defaultValue} />);
    }

    const columns = useMemo(() =>
        columnsRelatedStory.map((col) => {
            if (col.field === "summary") {
                return col;
            }
            return {
                ...col,
                filterOperators: getGridSingleSelectOperators()
                    .filter((operator) => operator.value !== "isAnyOf" && operator.value !== "not")
                    .map((operator) => ({
                        ...operator,
                        InputComponent: operator.InputComponent
                            ? CustomFilterInputValue
                            : undefined
                    }))
            };
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }), [columnsRelatedStory]
    );

    if( flag === 1 && (formValues?.status_data && formValues?.status_data?.length > 0 )){
        setWorkflowStatus(formValues.status_data.find(item=>item.status_id === formValues.status_id));
        flag = 0;
    }

    return (
        storyDiv > 0 ?
            <StoryDetails
                epicId={storyDiv}
                hideDiv={setStoryDiv}
                activeDivs={activeDives}
            />
            :
            <div className="datatable">

                <div className="row">
                    <div className="col ss-top-icon" style={{ textAlign: "end" }}>
                        <Tooltip title="Close">
                            <Button
                                style={{
                                    marginTop: "3px",
                                    backgroundColor: "rgb(201 201 201)",
                                    color: "rgb(110 110 110)"
                                }}
                                onClick={() => hideDiv(false)}
                            >
                                <Close />
                            </Button>
                        </Tooltip>


                        {!activeClass === true ? (
                            <Tooltip title="Zoom Out">
                                <Button
                                    style={{
                                        marginTop: "3px",
                                        backgroundColor: "rgb(201 201 201)",
                                        color: "rgb(110 110 110)"
                                    }}
                                    onClick={() => setActiveClass(!activeClass)}
                                >
                                    <ZoomOutMap />
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Zoom In">
                                <Button
                                    style={{
                                        marginTop: "3px",
                                        backgroundColor: "rgb(201 201 201)",
                                        color: "rgb(110 110 110)"
                                    }}
                                    onClick={() => setActiveClass(!activeClass)}
                                >
                                    <ZoomInMap />
                                </Button>
                            </Tooltip>
                        )}

                    </div>

                </div>

                <div className="top-headings">
                    {/* <h3>Epic Task</h3> */}
                    <div>
                        {
                            formValues && formValues.project && formValues.project.project_name
                                ?
                                <TaskBreadCrumb formValues={formValues} />
                                :
                                <p>Project</p>
                        }
                        <button style={{ borderWidth: 0, backgroundColor: '#fff' }} ><h3 className='story-epic-summary'>{formValues && formValues.summary}</h3></button>

                    </div>
                    <div className="btn-section-top ss-section-top">
                        <Tooltip title="Comment">
                            <Button style={{ marginTop: '3px', height: '37px', marginLeft: '3px', backgroundColor: '#0052cc', color: '#fff' }} ><a style={{ color: '#fff' }} href='#ss-comment-scroll' onClick={() => { handleChange("tset", 3); setShow(false); setCommentActive(true); }}><InsertCommentIcon /></a></Button>
                        </Tooltip>
                    </div>
                </div>
                <ToastContainer />
                {(apiLoading === true || loading === true) && <LoaderForm />}
                <div className="edit-details-row ss-detail-row">
                    <div className={`edit-details-col-left ${show ? 'show-hide-left-side-colum1' : ''}`}>
                        <div className="left-description">
                            <h6>Description
                                <Tooltip title="Open Right Side Details">
                                    <span style={{ float: "right" }}>
                                        <IconButton onClick={() => { setShow(!show) }} aria-label="delete" className="ss-arrowicon">
                                            {!show === true ? (
                                                <KeyboardArrowLeft />
                                            ) : (
                                                <KeyboardArrowRight />
                                            )}
                                        </IconButton>

                                    </span>
                                </Tooltip>
                            </h6>

                            {descriptionDiv === false && <p onClick={() => formValues && formValues.created_by === LocalStorageDataService.userDetail().id ? setDescriptionDiv(true) : nothing()} style={{ marginBottom: 20 }} > <span dangerouslySetInnerHTML={{ __html: formValues && formValues.description }} /></p>}
                            {descriptionDiv === true &&
                                <div className="bottom-commrnt-col-right">
                                    <CKEditor
                                        name="comment"
                                        initData={descriptionComment}
                                        config={LocalStorageDataService.ckEditor_config_toolbar()}
                                        onChange={(e) => inputDescriptionHandler(e)}
                                    />
                                    <button onClick={() => editCommentChanges('description')} className="save-btn">Save</button>
                                    <button onClick={() => { setDescriptionDiv(false); setDescriptionComment(formValues && formValues.description) }} className="cancel-btn">Cancel</button>
                                </div>
                            }
                            {/* {formValues?.description ? <RichTextEditor data={JSON.parse(formValues.description )} /> : ""} */}
                        </div>


                        <h4>Activity:</h4>

                        <div className="left-description">
                            <div className="epik-tabs">
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="scrollable"
                                    scrollButtons={false}
                                    aria-label="scrollable prevent tabs example"
                                >
                                    <Tab label="Related Stories" />
                                    <Tab label="Suggested Stories" />
                                    <Tab label="Upload Resumes" />
                                    <Tab label="Comments" href="#ss-comment-scroll" />
                                    <Tab label="History" />
                                </Tabs>


                                <TabPanel value={value} index={0} className="custom-loader-tabs1">
                                    <p style={{ marginBottom: 10 }} className="related-stories-head">Relates to stories
                                        <Tooltip title={'Refresh'}><span style={{ cursor: 'pointer', marginLeft: 8 }}>
                                            <Refresh style={{ fontSize: 20, color: 'blue', marginBottom: '-5px' }} onClick={() => refresh()} /></span>
                                        </Tooltip>
                                    </p>
                                    <DataGrid
                                        columnVisibilityModel={columnVisibilityModel}
                                        onColumnVisibilityModelChange={(newModel) =>
                                            setColumnVisibilityModel(newModel)
                                        }
                                        loading={relatedLoader !== false ? false : defaultLoader}
                                        //style={{ marginTop: "20px", height: "unset" }}
                                        rows={(myEpicTaskList && myEpicTaskList.data && myEpicTaskList.data.rows && myEpicTaskList.data.rows) || []}
                                        columns={columns}
                                        pageSize={pageSize}
                                        filterMode="server"
                                        //loading={relatedLoader === false ? false : true}
                                        //rowsPerPageOptions={[5]}
                                        //checkboxSelection
                                        disableSelectionOnClick
                                        experimentalFeatures={{ newEditingApi: true }}
                                        components={{
                                            Pagination: CustomPagination,
                                        }}
                                        onPageSizeChange={(pageSize) => handlePageSizeChange(pageSize)}
                                        onFilterModelChange={onFilterChange}
                                        onSortModelChange={(sort) => handleSort(sort)}

                                    />
                                </TabPanel>
                                <TabPanel value={value} index={1} className="custom-loader-tabs">
                                    <p className="related-stories-head">Suggested stories</p>
                                    <div className="related-stories-row">
                                        <div className="related-storys-colum4">
                                            <ul>
                                                <li><Link to={"/"} title="Anchal Gupta"><span className="short-name">AG</span> Anchal Gupta</Link></li>

                                            </ul>
                                        </div>
                                        <div className="related-storys-colum5">
                                            <p>00000000</p>
                                        </div>

                                        <div className="related-storys-colum6">
                                            <p className="match-color">89% Match</p>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel value={value} index={2} className="custom-loader-tabs">
                                    {(loading === 'upload_true') && <LoaderForm />}
                                    {/* {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id &&
                                        <Button style={{ marginTop: 14, backgroundColor: '#0052cc', color: '#fff', marginBottom: 14 }} endIcon={<UploadFile />}
                                            onClick={() => setFileButtonActive(!fileButtonActive)} >Upload New File</Button>} */}
                                    {jobApproverStatus === 4 && formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id
                                        ? <Button style={{ marginTop: 14, backgroundColor: '#0052cc', color: '#fff', marginBottom: 14 }} endIcon={<UploadFile />}
                                            onClick={() => setFileButtonActive(!fileButtonActive)} >Upload New File</Button>
                                        : jobApproverStatus !== 4 && formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ?
                                            <p style={{ color: "#96332c", fontSize: 20, margin: "10px" }}>Job is not live</p>
                                            : jobApproverStatus === "" ? " "
                                                : " "
                                    }
                                    {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ?
                                        <div>
                                            {fileButtonActive === true && <p className="related-stories-head"> Upload Resume(Pdf/Zip/Docx/Doc/Csv/Xlsx)</p>}
                                            {fileButtonActive === true && <div className="file-dropzone-tabs">
                                                <Dropzone onDrop={acceptedFiles => onFileChange(acceptedFiles)}>
                                                    {({ getRootProps, getInputProps }) => (
                                                        <section>
                                                            <div {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <p style={{ lineBreak: 'anywhere' }}>{file ? file[0].name : "Drag 'n' drop some files here, or click to select files"}</p>
                                                            </div>
                                                        </section>
                                                    )}
                                                </Dropzone>
                                                {fileErr !== "" && <p style={{ color: "#96332c", fontSize: 13 }}>{fileErr}</p>}
                                                {fileErr === "" && loading === false && file && file[0].name && <Button className="submit-modal" style={{ margin: 'auto', position: 'relative', bottom: '15px', display: 'block' }} onClick={() => uploadFile()}>submit</Button>}

                                            </div>}
                                            <DefaultDataGrid
                                                style={{ marginTop: "20px", height: "unset" }}
                                                list={(uploadFileList && uploadFileList.data && uploadFileList.data.rows) || []}
                                                count={(uploadFileList && uploadFileList.data && uploadFileList.data.count) || 0}
                                                columns={columnsFile}
                                                defaultApi={getFileUploadList}
                                                loading={defaultLoader}
                                                defaultFilter={{ "filter": { "epic_id": { "value": epicId, "operation": "eq" } }, "exclude": { "status": { "value": 3, "operation": "eq" } }, "order_by": { "column": "-id" }, "skip": 0, "limit": 25 }}
                                            />
                                            {/* <DataGrid
                                            columnVisibilityModel={columnVisibilityFilemModel}
                                            onColumnVisibilityModelChange={(newModel) =>
                                                setColumnVisibilityFileModel(newModel)
                                            }
                                            style={{ marginTop: "20px", height: "unset" }}
                                            rows={(uploadFileList && uploadFileList.data && uploadFileList.data.rows) || []}
                                            columns={columnsFile}
                                            pageSize={10}
                                            rowsPerPageOptions={[5]}
                                            //checkboxSelection
                                            disableSelectionOnClick
                                            experimentalFeatures={{ newEditingApi: true }}
                                        /> */}
                                        </div>

                                        :
                                        <div>
                                            <p>Not Allowed!</p>
                                        </div>
                                    }
                                </TabPanel>
                                <TabPanel value={value} index={3} className="custom-loader-tabs" id="ss-comment-scroll">
                                    {(activeTab === 3) && <LoaderForm />}
                                    <div className="bottom-comments-row" >
                                        <div className="bottom-commrnt-col-left">
                                            <span style={{ textTransform: 'capitalize' }}><Avatar {...stringAvatar(LocalStorageDataService.userDetail().display_name)} /></span>
                                        </div>
                                        {commentActive === false &&
                                            <div className="bottom-commrnt-col-right">
                                                <div className="formInput" style={{ flexBasic: "100%" }}>
                                                    <input type="text" className="comment-single-text-box" placeholder="Add a comment..." onClick={() => { setCommentActive(true); focusOnEditor(); }} />
                                                </div>
                                                {/* <p style={{ marginBottom: 20 }} ><span dangerouslySetInnerHTML={{ __html: formValues.comment }} /></p> */}
                                            </div>
                                        }
                                        {commentActive === true &&
                                            <div className="bottom-commrnt-col-right">
                                                <CKEditor
                                                    name="comment"
                                                    initData={comment}
                                                    config={{
                                                        toolbar: [
                                                            ['Source'],
                                                            ['Styles', 'Format', 'Font', 'FontSize'],
                                                            ['Bold', 'Italic'],
                                                            ['Undo', 'Redo'],
                                                            ['EasyImageUpload'],
                                                        ],
                                                    }}
                                                    onChange={(e) => inputHandler(e)}
                                                    ref={editorInput}
                                                />
                                                <button onClick={() => editCommentChanges('comment')} className="save-btn">Save</button>
                                                <button onClick={() => { setCommentActive(false); setComment("") }} className="cancel-btn">Cancel</button>
                                            </div>
                                        }
                                    </div>


                                    {commentById && commentById.data && commentById.data.rows && commentById.data.rows.length > 0 && commentById.data.rows.map((item, index) => {
                                        return (
                                            <div className="bottom-comments-row">
                                                <div className="bottom-commrnt-col-left">
                                                    {/* {item.rtms_issue && item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee &&
                                                            <span style={{ textTransform: 'capitalize' }}><Avatar {...stringAvatar(item.rtms_issue.assignee.display_name)} /></span>
                                                        } */}
                                                    {item.created_resource && item.created_resource.display_name &&
                                                        <span style={{ textTransform: 'capitalize' }}><Avatar {...stringAvatar(item.created_resource.display_name)} /></span>
                                                    }
                                                </div>
                                                <div className="bottom-commrnt-col-right">
                                                    {item.created_resource && item.created_resource.display_name &&
                                                        <h6 style={{ textTransform: 'capitalize' }}>{item.created_resource.display_name}
                                                            {/* {item.rtms_issue && item.rtms_issue && item.rtms_issue.assignee && item.rtms_issue.assignee &&
                                                                    <span>{item.rtms_issue.assignee && calculateDays(item.rtms_issue.assignee.created_on)}</span>
                                                                } */}
                                                        </h6>
                                                    }
                                                    {/* <a href={item.comment_url}>Commit Link : {item.comment_url}</a> */}
                                                    <p><span dangerouslySetInnerHTML={{ __html: makeUrlsClickable(item.body) }} /></p>
                                                    <p style={{ fontStyle: "italic", fontSize: "smaller" }}>Comment Date : {item.rtms_issue && moment(item.rtms_issue.created_on).format('YYYY-MM-DD')}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </TabPanel>
                                <TabPanel value={value} index={4} className="custom-loader-tabs">
                                    {(activeTab === 4) && <LoaderForm />}
                                    {taskHistoryList && taskHistoryList.data && taskHistoryList.data.rows && taskHistoryList.data.rows.length > 0 ?
                                        taskHistoryList.data.rows.map((item, index) => {
                                            return (
                                                <div key={index} className="bottom-comments-row">
                                                    <div className="bottom-commrnt-col-left">
                                                        {item.assigned_by_resource_name && (
                                                            <span>  <Avatar
                                                                {...stringAvatar(
                                                                    item?.assigned_by_resource_name
                                                                )}
                                                            />
                                                            </span>
                                                        )}
                                                    </div>

                                                    <div className="bottom-commrnt-col-right">
                                                        <p><b>{item.assigned_by_resource_name} </b>  &nbsp; changed the &nbsp;
                                                            <b>{item.change_type === `description` ? ' Description '
                                                                : item.change_type === 'assignee' ? ' Assignee '
                                                                    : item.change_type === 'status' ? ' Status '
                                                                        : item.change_type === 'email' ? ' Email '
                                                                            : item.change_type === 'phone' ? ' Phone '
                                                                                : item.change_type === 'schedule' ? ' Schedule '
                                                                                    : ""
                                                            }
                                                            </b>&nbsp;
                                                            <span>
                                                                {item.created_on &&
                                                                    moment(item.created_on).format(
                                                                        "YYYY-MM-DD hh:mm"
                                                                    )}
                                                            </span>
                                                        </p>

                                                        {item.change_type === `description` ?
                                                            <div className="col-assignee-history-1">
                                                                <h5 className="assignee">{item.assigned_by_resource_name}</h5>
                                                            </div>
                                                            : item.change_type === 'assignee' ?
                                                                <div className="row-assignnee-history">
                                                                    <div className="col-assignee-history-1">
                                                                        <Tooltip title={item?.assigned_by_resource_name}>
                                                                            <span>
                                                                                {item.assigned_by_resource_name ? (
                                                                                    <Avatar
                                                                                        {...stringAvatar(
                                                                                            item?.assigned_by_resource_name
                                                                                        )}
                                                                                    />
                                                                                ) : (<Avatar
                                                                                    {...stringAvatar(
                                                                                        'Unassigne'
                                                                                    )}
                                                                                />)
                                                                                }
                                                                                {item?.assigned_by_resource_name ? item?.assigned_by_resource_name : 'Unassigne'}
                                                                            </span>
                                                                        </Tooltip>
                                                                    </div>

                                                                    <div className="col-assignee-history-2">
                                                                        <ArrowRightAltIcon />
                                                                    </div>

                                                                    <div className="col-assignee-history-1">
                                                                        <Tooltip title={item?.assigned_to_resource_name}>
                                                                            <span>
                                                                                {item.assigned_to_resource_name ? (
                                                                                    <Avatar
                                                                                        {...stringAvatar(
                                                                                            item?.assigned_to_resource_name
                                                                                        )}
                                                                                    />
                                                                                ) : (<Avatar
                                                                                    {...stringAvatar(
                                                                                        'Unassigne'
                                                                                    )}
                                                                                />)}
                                                                                {item?.assigned_to_resource_name ? item?.assigned_to_resource_name : 'Unassigne'}
                                                                            </span>
                                                                        </Tooltip>
                                                                    </div>
                                                                </div>
                                                                : item.change_type === 'status' ?
                                                                    <div className="row-assignnee-history">

                                                                        <div className="col-assignee-history-1">
                                                                            <h5 className="assignee">{item.assign_from_status_name}</h5>
                                                                        </div>

                                                                        <div className="col-assignee-history-2">
                                                                            <ArrowRightAltIcon />
                                                                        </div>

                                                                        <div className="col-assignee-history-1">
                                                                            <h5 className="assignee">{item.assign_to_status_name}</h5>
                                                                        </div>
                                                                    </div>
                                                                    : item.change_type === 'email' ?
                                                                        <div className="row-assignnee-history">

                                                                            <div className="col-assignee-history-1">
                                                                                <h5 className="assignee">{item.assigned_by_resource_email}</h5>
                                                                            </div>

                                                                            <div className="col-assignee-history-2">
                                                                                <ArrowRightAltIcon />
                                                                            </div>

                                                                            <div className="col-assignee-history-1">
                                                                                <h5 className="assignee">{item.assigned_to_resource_email}</h5>
                                                                            </div>
                                                                        </div>
                                                                        : item.change_type === 'schedule' ?
                                                                            <div className="row-assignnee-history">
                                                                                <div className="">
                                                                                    <p className=""><b style={{ color: "#6c6b6b" }}>Test Name</b> :- {item.test_name}</p>
                                                                                    {item?.hiring_story_screenflow_status === 2 && <p className=""><b style={{ color: "#6c6b6b" }}>Result </b> :- {item.is_passed === true ? 'Pass' : 'Fail'}</p>}
                                                                                </div>
                                                                            </div>

                                                                            : ""
                                                        }

                                                    </div>
                                                </div>
                                            );
                                        }) : <p>No history found !</p>
                                    }

                                </TabPanel>
                            </div>


                        </div>
                    </div>

                    <div className={`edit-details-col-right ${show ? 'show-hide-right-side-colum1 ss-task-summry-right' : ''}`}>
                        {/* <select name="status_name" value={formValues && formValues.status_id} onChange={changeStatus} className="select-status-ticket">
                            <option>SELECT</option>
                            {statusData && statusData.length > 0 && statusData.map((item, index) => {
                                return (
                                    <option key={index} value={item.status_id}>{item.status_name}</option>
                                )
                            })}
                        </select> */}
                        {
                            formValues?.status_data && formValues?.status_data?.length>0 && workflowStatus &&  
                            <Autocomplete
                            id="checkboxes-tags-demo"
                            name="status_name"
                            options={formValues.status_data}
                            sx={{
                                backgroundColor: workflowStatus && workflowStatus?.background_color,
                                '.MuiAutocomplete-input': {
                                    fontWeight: '800',
                                    color: workflowStatus && workflowStatus?.foreground_color,
                                    cursor:'pointer',
                                }
                            }}
                            value={workflowStatus || []}
                            disableCloseOnSelect
                            onChange={(event, newValue) => {
                                newValue && changeStatus({name:'status_name', value:newValue.status_id}, newValue);
                            }}
                            getOptionLabel={(option) => option?.short_code ?? option?.status_name}
                            renderOption={(props, option, { selected }) => (
                                <li style={{ display: 'flex', justifyContent: 'space-between' }} {...props}>
                                    {/* <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    /> */}
                                    <Typography variant="subtitle1">{option?.status_name}</Typography>
                                    <Stack direction={'row'} alignItems={"center"}>
                                        <ArrowRightAltIcon />
                                        <Chip
                                            // color="success"
                                            sx={{ borderRadius: '3px', backgroundColor: option?.background_color, color:option?.foreground_color, minWidth:'80px' }}
                                            disabled={false}
                                            variant="elevated"
                                            label={option?.short_code}
                                        />
                                    </Stack>
                                </li>
                            )}
                            // sx={{
                            //     '.MuiAutocomplete-tag': {
                            //         display: 'none',
                            //     }
                            // }}
                            style={{ width: '100%', color:'white' }}
                            renderInput={(params) => (
                                <TextField {...params}/>
                            )}
                            />
                        }
                        {/* <p style={{marginTop:'3%'}}><a href='#ss-comment-scroll' style={{padding:'12px', backgroundColor:'#0052cc', color:'#fff', borderRadius:'4px'}} onClick={()=>{handleChange("tset", 3); setShow(false); setCommentActive(true)}}>ADD COMMENT</a></p> */}
                        <div className="right-side-details">
                            <h4>Create page for edit task</h4>
                            <div className="right-and-left-name-lable-first">
                                <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Assignee</h6>
                                <div>
                                    <Autocomplete
                                        disabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : (formValues && !formValues.assignee_id && disabledAssigne() === true) ? false : true}
                                        className="custom-select-name"
                                        sx={{ width: 300 }}
                                        autoHighlight
                                        value={assignee_name_default}
                                        renderOption={(props, option) => (
                                            <Box key={props.id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <span className="name-and-short">
                                                    <Avatar style={{ marginRight: "10px", textTransform: "uppercase", height: "30px", width: "30px", fontSize: "16px" }} {...stringAvatar(option.label)} />{"   "} <span className="short-cut-full-name" style={{ fontSize: "16px", textTransform: "capitalize", }}>{option.label}</span>
                                                </span>
                                            </Box>
                                        )}
                                        onChange={(event, newValue) => {
                                            changeStatus(newValue);
                                        }}
                                        options={
                                            selectedMember && selectedMember.map((option) => {
                                                return { key: option.value, value: option.value, label: option.label, name: "assignee" }
                                            })

                                        }
                                        renderInput={(params) => <TextField {...params} label={"Select Assignee"} style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }} />}
                                    />
                                    {formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id && <p style={{ color: 'blue', cursor: "pointer", }} onClick={(e) => assignToMe(e, 'unassign')} ><u>Unassigned</u></p>}
                                    {formValues && !formValues.assignee_id && (formValues.reported_by_id === LocalStorageDataService.userDetail().id || disabledAssigne() === true) && <p style={{ color: 'blue', cursor: "pointer", }} onClick={(e) => assignToMe(e, 'assign')} ><u>Assign to me</u></p>}
                                    {/* {formValues && !formValues.assignee_id && disabledAssigne() === true && <p style={{ color: 'blue' }} onClick={(e) => assignToMe(e, 'assign')} ><u>Assign to me</u></p>} */}
                                    {/* {
                                            formValues.assignee_id === LocalStorageDataService.userDetail().id ? "" :
                                                checkAssignedfield() === false && <button onClick={(e) => assignToMe(e)} className="btn btn-submit">assign to me</button>
                                                } */}
                                </div>
                            </div>
                            <div className="right-and-left-name-lable">
                                <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Reporter</h6>
                                <input type="text" value={formValues && formValues.reported_by && formValues.reported_by.display_name ? formValues.reported_by.display_name : ''} disabled={true} />
                            </div>

                            <div className="right-and-left-name-lable">
                                <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Label</h6>
                                <Select
                                    isMulti
                                    defaultValue={selected}
                                    width='300px'
                                    options={LocalStorageDataService.labels_list().map(e => {
                                        return { label: e, value: e, name: 'lables' }
                                    })} />
                            </div>
                            <div className="right-and-left-name-lable">
                                <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Story Point</h6>
                                <input type="text" value={formValues && formValues.story_point ? formValues.story_point : ''} />
                            </div>

                            <div className="right-and-left-name-lable">
                                <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Sprint</h6>
                                <input type="text" value={formValues && formValues.sprint ? formValues.sprint : ""} disabled={true} />
                            </div>

                            <div className="right-and-left-name-lable">
                                <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Priority</h6>
                                <Select
                                    defaultValue={formValues && formValues.priority}
                                    onChange={changeStatus}
                                    width='300px'
                                    options={LocalStorageDataService.priority_list().map(e => {
                                        return { label: e, value: e, name: 'priority' }
                                    })} />
                            </div>
                            <div className="right-and-left-name-lable">
                                <h6 style={{ fontSize: "14px", color: "#42526e", fontWeight: "400" }}>Team Members</h6>
                                {resourceProject_list && resourceProject_list.length > 0 ?
                                    <Select
                                        isMulti
                                        isDisabled={formValues && formValues.assignee_id === LocalStorageDataService.userDetail().id ? false : true}
                                        value={selectedMember}
                                        onChange={(evt) => updateTeamMember(evt)}
                                        width='300px'
                                        options={resourceProject_list && resourceProject_list.length > 0 &&
                                            resourceProject_list.map((item, index) => {
                                                return { key: index, label: item.display_name, value: item.id, name: 'team_members' }
                                            })} />
                                    :
                                    <p>Please assign member for this project "{formValues && formValues.project && formValues.project.project_name}"</p>
                                }
                            </div>

                        </div>


                    </div>
                </div>
            </div>

    )
}

